import { useState, useEffect, useContext, createContext } from "react";
import PropTypes from "prop-types";
import SocialUser from "../services/socialUser";
// types
import type { ReactNode } from "react";
import type { SocialSignupUserInterface } from "../types/types";

const defaultUser = SocialUser.getDefaultUser();

const SocialSignupUserContext = createContext<SocialSignupUserInterface>(defaultUser);

export const useSocialUser = () => useContext(SocialSignupUserContext);

type Props = {
	children: ReactNode;
};

export const SocialUserProvider = (props: Props) => {
	const [user, setUser] = useState<SocialSignupUserInterface>(defaultUser);

	useEffect(() => {
		const handleSocialUserChanged = (newUser: any) => {
			console.info("Social new user : ", newUser);
			setUser(newUser);
			console.info("Social user : ", user);
		};

		SocialUser.on("socialUserChanged", handleSocialUserChanged);

		return () => {
			SocialUser.on("userChanged", handleSocialUserChanged);
		};
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SocialSignupUserContext.Provider value={user}>{props.children}</SocialSignupUserContext.Provider>
	);
};

SocialUserProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
