import {
	Card,
	CardContent,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@mui/material";
import HelpAndTips from "./HelpAndTips";
import i18n from "../l10n/strings.json";
import { useEffect, useState } from "react";
import { useUser } from "../context/UserContext";
import BlockedIcon from "../images/profile-delete.svg";
import { styled } from "@mui/material/styles";
import VerifyAccount from "../dialogBox/VerifyAccount";
import { useAlertStatus } from "../context/AlertStatusContext";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";

const AccountListItemText = styled(ListItemText)({
	"& .MuiListItemText-secondary": {

		fontStyle: "normal",
		fontSize: "1rem",
		fontWeight: 400,
		color: "#7A7985",
	},
	"& .MuiListItemText-primary": {

		fontStyle: "normal",
		fontSize: "1.125rem",
		fontWeight: 900,
		color: "#000000",
	},
});

export const BlockedUserList = () => {
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const [blockedUsers, setBlockedUsers] = useState([]);
	const [totalBlockedUsers, setTotalBlockedUsers] = useState(0);
	const [open, setOpen] = useState(false);
	const { updateAlertStatus } = useAlertStatus();
	const [verification, setVerfication] = useState(false);
	const [toUnblockUser, setToUnblockUser] = useState("");

	const calculateTimeAgo = (inputDatetime: string): string => {
		const inputDate = new Date(inputDatetime);

		const currentDate = new Date();

		const timeDifference = currentDate.getTime() - inputDate.getTime();

		const millisecondsInDay = 24 * 60 * 60 * 1000;
		const millisecondsInMonth = 30 * 24 * 60 * 60 * 1000;
		const millisecondsInYear = 365 * 24 * 60 * 60 * 1000;

		const yearsAgo = Math.floor(timeDifference / millisecondsInYear);
		const monthsAgo = Math.floor(timeDifference / millisecondsInMonth);
		const daysAgo = Math.floor(timeDifference / millisecondsInDay);

		if (yearsAgo > 0) {
			return `${yearsAgo} year${yearsAgo === 1 ? "" : "s"} ago`;
		} else if (monthsAgo > 0) {
			return `${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
		} else {
			return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
		}
	};

	const handleDialogClose = () => {
		setOpen(false);
	};

	const fetchBlockedUsers = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-blocked`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					userProfileId:user.userProfileId
				}),
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setTotalBlockedUsers(data[0]?.total);
					setBlockedUsers(data);
				}
			})
			.catch((err) => {
			});
	};

	useEffect(() => {
		fetchBlockedUsers();
	}, []);

	return (
		<Grid>
			<Grid container>
				<Grid item xs={9} className="centerPanelWidth">
					<Card>
						<CardContent>
							<Typography
								sx={{
									fontSize: "24px",
									display: "flex",
									alignItems: "center",
									fontWeight: "500",
									fontFamily: "Lexend",
								}}
								color="#000000"
							>
								Blocked user list
							</Typography>
							<Typography
								variant="subtitle1"
								sx={{
									fontSize: "1rem",
									display: "flex",
									alignItems: "center",
									fontWeight: "400",

									justifyContent: totalBlockedUsers ? "flex-start" : "center",
								}}
								color="#000000"
							>
								{totalBlockedUsers
									? totalBlockedUsers + " people blocked"
									: "No people blocked"}
							</Typography>
							<List>
								{blockedUsers.map((user: any) => {
									return (
										<>
											<ListItem>
												<ListItemIcon>
													<img src={BlockedIcon} alt="BlockedUser" />
												</ListItemIcon>
												<AccountListItemText
													secondary={calculateTimeAgo(user.blockedAt)}
													primaryTypographyProps={{
														sx: {
															color: "#7A7985",
															whiteSpace: "nowrap",       
															overflow: "hidden",      
															textOverflow: "ellipsis",    
															maxWidth: "400px",  
														},
													}}
													primary={user.blockedUserName}
												/>
												<Typography
													onClick={() => {
														setOpen(true);
														setToUnblockUser(user);
													}}
													sx={{
														color: "#0273E6",
														fontSize: "1rem",
														fontWeight: "500",
														cursor: "pointer",
													}}
												>
													Unblock
												</Typography>
											</ListItem>
											<Divider variant="fullWidth" component="li" />
										</>
									);
								})}
							</List>
						</CardContent>
					</Card>
				</Grid>
				{/* <Grid item xs={3}  className="rightPanelWidth">
					<HelpAndTips
						title={i18n.settings.accountSetup.instructions.helpAndTips.title}
					/>
					<MyActivitiesSidePanel />
				</Grid> */}
			</Grid>
			<VerifyAccount
				open={open}
				handleClose={handleDialogClose}
				unblock
				toUnblockUser={toUnblockUser}
				fetchBlockedUsers={fetchBlockedUsers}
				source="blockedUserList"
			/>
		</Grid>
	);
};
