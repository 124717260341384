//import CancelIcon from '../../images/Group 4282.svg';
import CancelIcon from "../../images/close.svg";
import {
	Card,
	IconButton,
	Typography,	
	Dialog,
    CardContent,Grid, Avatar, Stack, Box,  CircularProgress,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {  useNavigate } from "react-router-dom";
const ViewLikeUsers = ({ open, handleClose,  post, viewer,
    userName, likedDto,
    profileUrl, }: any) => {
        const navigate = useNavigate();
        const viewUserProfile = (profileNickName:string) => {
          const encodedNickName = encodeURIComponent(profileNickName);
            navigate("/socialHome/" + encodedNickName);
          };
    return (
        <Dialog open={open} onClose={handleClose}
            sx={{
                border: "1px solid #888888",
                boxShadow: "customColor.dropShadow",
                borderRadius: '12px',
            }}
        >
             <Card sx={{   width:"450px", height:"520px", borderRadius: '12px', }}>
                <CardContent sx={{ alignItems: "center", padding:0}}>
                    <label style={{
                        zIndex: 2,}}>
                        <Typography sx={{  fontSize: "18px", fontWeight: "400", padding:'16px',paddingBottom:'26px',borderBottom:'1px solid #c2c2c3'}}> 
                            Post Likes
                            <IconButton id="photo-Cancel" sx={{float:"right", cursor: 'pointer',}}
                                aria-label="Edit" size='small' onClick={() => handleClose()} >
                                <img src={CancelIcon} alt="Edit" style={{ height: '24px', width: '24px' }} />
                            </IconButton> 
                        </Typography> 
                        {/* <hr style={{  width: "100%", margin:0 }} />               */}
                    </label>
						
                    <Grid container rowGap={1} columns={{ xs: 12, sm: 6, md: 12 }} sx={{overflowX:'hidden', overflowY:'auto', maxHeight:"450px", padding:'16px'}}>
							
                           
                            { 
    
           likedDto && likedDto.length > 0 && (
            likedDto.sort((a: any, b: any) => b.createdAt - a.createdAt).map(((likedUser: any) => (
            <Stack flexDirection="column"
                alignItems="flex-start" sx={{width:"100%"}}>
                      <Stack flexDirection="row"
                      alignItems="center"
                      pb={1}
                      mt={1}
                      sx={{width: "100%", marginTop:0, cursor: 'pointer',borderBottom: '1px solid #c2c2c3'}}
                      onClick={() => viewUserProfile(`${likedUser.profileNickName}`)}>
                        <Stack sx={{ mr: "10px" }}>
                          <Avatar
                            alt={likedUser.name}
                            src={
                              likedUser.profilePhotoUrl
                                ? `${process.env.REACT_APP_PICTURE_URL}${likedUser.profilePhotoUrl}`
                                : ""
                            }
                            className="profile-img-style"
                            sx={{width:"50px", height:"50px"}}
                          />
                        </Stack>
                        <Stack>
                            <Stack flexDirection="row" alignItems="flex-start">
                              <Typography
                                className="card-title-txt-sx"
                                sx={{
                                  fontSize: "18px",
                                  color: "#000000",
                                  fontWeight: 600,
                                  fontFamily: "Lexend",
                                  lineHeight: "22.5px",
                                  maxWidth: "12.125rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {`${likedUser.name}`} 
                              </Typography>
                            </Stack>
                            {likedUser.profileTitle !== null ? (
                              <Typography
                                className="card-sub-title-txt-sx wrapTextTitle"
                                sx={{
                                  fontSize: "14px",
                                  color: "#5B5B65",
                                  fontWeight: 400,
                                  fontFamily: "Lexend",
                                  lineHeight: "17.5px",
                                  maxWidth: "100%",
                                  whiteSpace: "normal",
                                  textWrap: "wrap !important",
                                  wordWrap:'break-word',
                                  textOverflow:'ellipsis',
                                  
                                }}
                              >
                                {`${
                                  likedUser.profileTitle && likedUser.profileTitle?.length > 100
                                    ? likedUser.profileTitle?.substring(0, 100) + "..."
                                    : likedUser.profileTitle
                                }`} 
                              </Typography>
                            ) : (
                              ""
                            )}  
                        </Stack>
                  </Stack>
                   
              {/* <hr style={{ width: "100%", margin:0 }} /> */}
            </Stack>
            )))) 
          }
          {/* </InfiniteScroll> */}
					</Grid>
				</CardContent>
           
          </Card>
           

        </Dialog>
    );
};

export default ViewLikeUsers;
