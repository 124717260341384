import { Avatar, Card, CardContent, Grid, Button, Typography } from "@mui/material"
import { useUser } from "../../context/UserContext"
import { useEffect, useState } from "react";
import Post from "./Post";
import PostButtons from "./PostButtons";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import InfiniteScroll from "react-infinite-scroll-component";
import CreatePostNew from "./CreatePostNew";
import PostSkeleton from "./PostSkeleton";
import React from "react";

const PostsHome = () => {

    const SelfDetails = useUser();
    const [open, setOpen] = useState(false);
    const [postDetails, setPostDetails] = useState<any>([])
    const token = localStorage.getItem("sessionId");
    const [showAlert, setShowAlert] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [alertMsg, setAlertMsg] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [alertStatus, setAlertStatus] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [itemPerPage, setItemPerPage] = useState(10);
    const [profileImageUrl, setProfileImageUrl] = useState('')

    useEffect(() => {
        setProfileImageUrl(SelfDetails.profilePhotoUrl ? `${process.env.REACT_APP_PICTURE_URL}${SelfDetails.profilePhotoUrl}` : '')
    }, [SelfDetails])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
    };

    const getPostDetails = () => {
        postDetails.length === 0 && setLoading(true);
        try {
            fetch(
                `${process.env.REACT_APP_BASE_URL}/api/getAllPosts?page=${pageNumber}&size=${itemPerPage}`,
                // `${process.env.REACT_APP_BASE_URL}/api/getAllPosts`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((res) => {
                    if (res.status > 400) {
                        throw new Error("api_error");
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data.length > 0) {
                        setPostDetails([...postDetails, ...data]);
                        var nextPageNumber = pageNumber + 1;
                        setPageNumber(nextPageNumber);
                        setLoading(false)
                    }
                    if (data.length === 0) {
                        setHasMore(false);
                    }
                })
                .catch((err) => {
                    setHasMore(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };
    const resetPostDetails = () => {
        setLoading(true);
        try {
            setPostDetails([]);
            setHasMore(true);
            setPageNumber(0);
            fetch(
                `${process.env.REACT_APP_BASE_URL}/api/getAllPosts?page=0&size=${itemPerPage}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((res) => {
                    if (res.status > 400) {
                        throw new Error("api_error");
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data.length > 0) {
                        setPostDetails(data);
                        setPageNumber(1);
                        setLoading(false)
                    }
                    if (data.length < itemPerPage) {
                        // setHasMore(false);
                    }
                })
                .catch((err) => {
                    setHasMore(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPostDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAlertClose = () => {
        setShowAlert(false);
    };
    // const reversedMappedArray = postDetails && postDetails.map((element: any, index: any, array: any) => {
    //     return array[array.length - 1 - index];
    // });

    // const getCall = ()=>{
    //     getPostDetails(pageNumber)
    // }

    const updateConnectionStatus = (id: any, newValue: any, requestFrom: any, requestTo: any) => {
        const newData = postDetails && postDetails.map((obj: any) => {
            if (obj.userProfileId === id) {
                return { ...obj, conneStatus: newValue, conneRequestFrom: requestFrom, conneRequestTo: requestTo, canSendConnection: (newValue === 'REJECTED' || newValue === 'WITHDRAWN' || newValue === 'REMOVED') && true }
            }
            return obj;
        })
        setPostDetails(newData);
    }

    return (
        <>
            <ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />
            {SelfDetails.userAccountStatus === "SUSPEND" ?
                // <Card sx={{ minWidth: 300, flexGrow: 1, minHeight: 117, height: 117, maxHeight: 117, boxShadow: '1px 1px 16px 1px #D9E7FFC4', borderRadius: '12px', backgroundColor:'#FFF8D6', textAlignLast:'center'}}>
                //     <CardContent sx={{ alignItems: 'center', gap: 1, py:5 }}>
                //     <Typography sx={{color:'red', fontSize:'18px'}}>Your account is temporarily Suspended. You are in view mode</Typography>
                //     </CardContent>
                // </Card>
                ''
                :
                <>
                    <Card sx={{ minWidth: 300, flexGrow: 1, minHeight: 117, height: 117, maxHeight: 117, boxShadow: '0px 1px 14px 0px rgba(217, 231, 255, 0.77)', borderRadius: '12px', mb: 3 }}>
                        <CardContent sx={{ alignItems: 'center', gap: 1 }}>
                            <Grid container>
                                <Grid item xs={1.5}>
                                    <Avatar alt={SelfDetails.firstName} src={profileImageUrl} sx={{ height: '50px', width: '50px' }} />
                                </Grid>
                                <Grid item xs={10.28}>
                                    <Grid container rowGap={1} columns={{ xs: 12, sm: 5, md: 12 }}>
                                        <Grid item xs={12}>
                                            <Button fullWidth variant="outlined" color="info" sx={{ fontSize: '18px', color: '#5C5C5C', borderColor: '#C5C5C5', justifyContent: 'flex-start', height: '50px', borderRadius: '25px', fontFamily: 'Lexend' }}
                                                onClick={handleClickOpen} >
                                               Share a post, audio or photo
                                            </Button>
                                        </Grid>
                                        <PostButtons handleClose={handleClose} resetPostDetails={resetPostDetails} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            }

            {/* {params.id === '' || params.id === 'undefined' ?  */}
            {loading ? (
                // <div
                //     style={{
                //         display: "flex",
                //         justifyContent: "center",
                //         alignItems: "center",
                //         height: "100px",
                //     }}
                // >
                //     <CircularProgress />
                // </div>
                <PostSkeleton />
            ) :

                ((postDetails && postDetails.length > 0) ?
                    <InfiniteScroll
                        style={{ overflow: 'inherit', }}
                        dataLength={postDetails && postDetails.length}
                        next={getPostDetails}
                        hasMore={hasMore}
                        loader={
                            <PostSkeleton />
                            // <Box
                            //     className={classes.spinner}

                            // >
                            //     <CircularProgress style={{ boxShadow: 'none' }} />
                            // </Box>
                        }
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >
                        {
                            (postDetails && postDetails.length > 0) ?
                                postDetails.map((post: any) =>
                                <React.Fragment key={post.id}>
                                    <Post post={post} resetPostDetails={resetPostDetails} postDetails={postDetails} setPostDetails={setPostDetails}
                                        updateConnectionStatus={updateConnectionStatus} />
                                        </React.Fragment>
                                )
                                :
                                <Typography sx={{ textAlignLast: 'center' }}>Posts not available</Typography>
                        }
                    </InfiniteScroll>
                    :
                    <Typography sx={{ textAlignLast: 'center' }}>Posts not available</Typography>
                )
            }

            <CreatePostNew
                open={open}
                handleClose={handleClose}
                resetPostDetails={resetPostDetails}
                editPost={false}
            />
        </>
    )
}

export default PostsHome