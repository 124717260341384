
import { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	Box,
	Typography,
	Grid
} from "@mui/material";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";

const RemoveMusic = (props: any) => {
	const token = localStorage.getItem("sessionId");
	
	const [fileName, setFileName] = useState(props.recording.fileName);

	const saveData = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/removesharedmusic`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				musicIds: [props.recording.id],
				// fileName: fileName
			}),
		})
		.then((response) => {
			if (response.status > 400) {
				throw new Error();
			} else {
				props.getRecordingList();
            props.handleClose(false);
				return response.json();
			}
		})
		.then((result) => {
			// props.handleClose(true, "nameSuccess");
			// if (result !== null) {
			// }
			props.getRecordingList();
            props.handleClose(false);
		});
	};

	const handleClose = () => {
		setFileName(props.recording.fileName);
		props.handleClose(false);
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			open={props.deleteMusicOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				{/* <Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={handleClose}
						onMouseDown={(
							event: React.MouseEvent<HTMLButtonElement>
						) => event.preventDefault()}
						edge="end"
						sx={{ marginLeft: "90%" }}>
						<img
							src={CloseIcon}
							alt="close"
						/>
					</IconButton>
				</Box> */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.myMusic.dialog.removeMusic}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: "5px 20px",
				}}
			>
				<Grid container
					columnSpacing={2}
				>
					<Grid item={true} xs={12}>
						<FormHelperText
							sx={{
								fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.myMusic.dialog.removeMusicMsg}
						</FormHelperText>
						<b>{props.recording.fileName}</b>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid container
					columnSpacing={1}
					style={{
						display: "flex", flexDirection: "row", justifyContent: "flex-end",
					}}
				>
					<Grid item={true}>
						<Button
							sx={{ color: "#1D2D53", width: "120px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleClose}
						>
							{i18n.dialog.cancel}
						</Button>
					</Grid>

					<Grid item={true}>
						<Button
							className="submit-button"
							size="large"
							variant="contained"
							fullWidth
							sx={{ width: "120px" }}
							// width: "135px"
							// disabled={isInvalid}
							onClick={saveData}
						>
							{i18n.myMusic.dialog.removeBtn}
						</Button>

					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
};
export default RemoveMusic;
