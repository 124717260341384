import {
    Dialog,
    IconButton,
    DialogTitle,
    Box,
    DialogActions,
    Typography,
    Button,
    DialogContent,
    FormControl,
    MenuItem,
    Select,
    InputBase,
    Grid
} from "@mui/material";
import CloseIcon from "../../images/close.svg";
import i18n from "../../l10n/strings.json";
import { useState } from "react";
import { styled } from '@mui/material/styles';
import Guitar from "../../images/image 35.svg";
import Drums from "../../images/image 36.svg";
import Mic from "../../images/image 52.svg";
import MixerIcon from "../../images/Instrument placeholderMixer-fill.svg";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
           "Lexend"
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0px 1px 14px 0px rgba(217, 231, 255, 0.77)',
        },
    },
}));

const MixerSliderEdit = (props: any) => {

    const dummyListForInstruments = [
        { name: "CH1", icon: MixerIcon },
    ]


    const [channel1Icon, setChannel1Icon] = useState('');
    const [channel1Text, setChannel1Text] = useState('');
    const handleChangeChannel1 = (event: { target: { value: string } }) => {
        setChannel1Icon(event.target.value);
        setChannel1Text(event.target.value)
    };
    const handleChangeNameChannel1 = (event: { target: { value: string } }) => {
        setChannel1Text(event.target.value)
    }
    const [channel2Icon, setChannel2Icon] = useState('');
    const [channel2Text, setChannel2Text] = useState('');
    const handleChangeChannel2 = (event: { target: { value: string } }) => {
        setChannel2Icon(event.target.value);
        setChannel2Text(event.target.value)
    };
    const handleChangeNameChannel2 = (event: { target: { value: string } }) => {
        setChannel2Text(event.target.value)
    }
    const [micIcon, setMicIcon] = useState('');
    const [micText, setMicText] = useState('');
    const handleChangeMic = (event: { target: { value: string } }) => {
        setMicIcon(event.target.value);
        setMicText(event.target.value)
    };
    const handleChangeNameMic = (event: { target: { value: string } }) => {
        setMicText(event.target.value)
    }

    const handleClose = () => {
        props.handleClose(false)
    };
    const handleSubmit = (event: any) => {
        event.preventDefault();
        props.setVolumeText.setChannel1VolumeText(channel1Text);
        props.setVolumeText.setChannel2VolumeText(channel2Text);
        props.setVolumeText.setMicVolumeText(micText);
        handleClose();
    }
    return (
        <Dialog
            sx={{
                border: "1px solid #888888",
                boxShadow: "customColor.dropShadow",
                borderRadius: "8px"
            }}
            fullWidth
            maxWidth="xs"
            open={props.editSlider}
        >
            <DialogTitle
                className="edit-heading-name"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                    }}
                >
                    {/* <IconButton
                        aria-label="close dialogbox"
                        onClick={handleClose}
                        onMouseDown={(
                            event: React.MouseEvent<HTMLButtonElement>
                        ) => event.preventDefault()}
                        edge="end"
                        sx={{ marginLeft: "90%" }}>
                        <img
                            src={CloseIcon}
                            alt="close"
                        />
                    </IconButton> */}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <Typography className="dialog-title-custom-size"
                        sx={{ fontWeight: 500, color: "#000000", fontSize: '1.5rem' }}>
                        Edit: {props.participantName}
                    </Typography>
                </Box>
            </DialogTitle>
            <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 400, alignSelf: 'center' }} gutterBottom>
                {i18n.jamSession.mixerSlider.text}
            </Typography>
            <form onSubmit={(e) => handleSubmit(e)}>
                <DialogContent sx={{ mx: 3 }} >
                    <Grid container>
                        <Grid item xs={7}>
                            <FormControl sx={{ m: 1 }} variant="standard">
                                <Typography className="sliderText">{i18n.jamSession.mixerSlider.channel1}</Typography>
                                <BootstrapInput id="channel1" className="sliderInput" sx={{
                                    '& .MuiInputBase-input': {
                                        padding: '7.7px 3px 7.7px 3px !important',
                                        width: '153.4px !important'
                                    }
                                }}
                                    value={channel1Text}
                                    onChange={(e: any) => handleChangeNameChannel1(e)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl sx={{ m: 1, minWidth: 130 }} variant="standard">
                                <Typography className="sliderText">{i18n.jamSession.mixerSlider.icon}</Typography>
                                <Select
                                    labelId="channel1-Icon"
                                    id="channel1-Icon"
                                    value={channel1Icon}
                                    onChange={handleChangeChannel1}
                                    input={<BootstrapInput />}
                                    className="sliderInput"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '7.7px 3px 7.7px 3px !important',
                                            width: '125.4px !important'
                                        },
                                        textAlign: "left"
                                    }}
                                >
                                    <MenuItem value="">
                                        <em></em>
                                    </MenuItem>
                                    {dummyListForInstruments.map((icon, index) => (
                                        <MenuItem key={index} value={icon.name}><img src={icon.icon} alt="Icon" /></MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={7}>
                            <FormControl sx={{ m: 1 }}  >
                                <Typography className="sliderText" >{i18n.jamSession.mixerSlider.channel2} </Typography>
                                <BootstrapInput id="channel2" value={channel2Text} onChange={(e) => handleChangeNameChannel2(e)}
                                    className="sliderInput" sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '7.7px 3px 7.7px 3px !important',
                                            width: '153.4px !important'
                                        }
                                    }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl sx={{ m: 1, minWidth: 130 }} >
                                <Typography className="sliderText">{i18n.jamSession.mixerSlider.icon}</Typography>
                                <Select
                                    labelId="channel2-Icon"
                                    id="channel2-Icon"
                                    value={channel2Icon}
                                    onChange={handleChangeChannel2}
                                    input={<BootstrapInput />}
                                    className="sliderInput"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '7.7px 3px 7.7px 3px !important',
                                            width: '125.4px !important'
                                        },
                                        textAlign: "left"
                                    }}
                                >
                                    <MenuItem value="">
                                        <em></em>
                                    </MenuItem>
                                    {dummyListForInstruments.map((icon, index) => (
                                        <MenuItem key={index} value={icon.name}><img src={icon.icon} alt="Icon" /></MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

{/* As per issue, now no need for mic label and icon */}
                        {/* <Grid item xs={7}>
                            <FormControl sx={{ m: 1 }} variant="standard" >
                                <Typography className="sliderText">{i18n.jamSession.mixerSlider.mic}</Typography>
                                <BootstrapInput disabled id="mic" value={micText} onChange={(e) => handleChangeNameMic(e)}
                                    className="sliderInput" sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '7.7px 3px 7.7px 3px !important',
                                            width: '153.4px !important'
                                        }
                                    }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl sx={{ m: 1, minWidth: 130 }} variant="standard">
                                <Typography className="sliderText">{i18n.jamSession.mixerSlider.icon}</Typography>
                                <Select
                                    labelId="mic-Icon"
                                    id="mic-Icon"
                                    value={micIcon}
                                    onChange={handleChangeMic}
                                    input={<BootstrapInput />}
                                    className="sliderInput"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '7.7px 3px 7.7px 3px !important',
                                            width: '125.4px !important'
                                        },
                                        textAlign: "left"
                                    }}
                                    disabled
                                    placeholder="Mic"
                                >
                                    <MenuItem value="">
                                        <em></em>
                                    </MenuItem>
                                    {dummyListForInstruments.map((icon, index) => (
                                        <MenuItem key={index} value={icon.name}><img src={icon.icon} alt="Icon" /></MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> */}
                    </Grid>
                </DialogContent>

                <DialogActions style={{ textAlign: "center", display: 'block' }}>
                    <Button
                        className="cancel-edit-button"
                        size="large"
                        variant="outlined"
                        onClick={handleClose}
                    >
                        {i18n.jamSession.mixerSlider.cancel}
                    </Button>
                    <Button
                        className="edit-button"
                        size="large"
                        variant="contained"
                        type="submit"
                    >
                        {i18n.jamSession.mixerSlider.save}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default MixerSliderEdit;