import { Box, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const MusiciansRecommendedSkeleton = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" animation="wave" width={210} height={118} />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Skeleton width="60%" />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" animation="wave" width={210} height={118} />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Skeleton width="60%" />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" animation="wave" width={210} height={118} />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Skeleton width="60%" />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" animation="wave" width={210} height={118} />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Skeleton width="60%" />
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
}