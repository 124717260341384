import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Popover, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const CompatibilityPopup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const currentPath = window.location.hash;

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isFirefox = /Firefox/.test(userAgent);
        // const isChrome = /Chrome/.test(userAgent) && !/Edg|OPR|Opera/.test(userAgent);
        // const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

        const dismissed = sessionStorage.getItem(currentPath === "#/home" ? "compatibilityHomeDismiss" : "compatibilityLiveDismiss");

        if (isFirefox && dismissed !== "true") {
            setShowPopup(true);
        }

    }, []);

    const dismissPopup = () => {
        setShowPopup(false);
        sessionStorage.setItem(currentPath === "#/home" ? "compatibilityHomeDismiss" : "compatibilityLiveDismiss", "true");
    };

    if (!showPopup) return null;

    return (
          <Dialog
        sx={{
            border: "1px solid #888888",
            boxShadow: "customColor.dropShadow",
            borderRadius: "8px"
        }}
        fullWidth
        maxWidth="sm"
        onClose={() => dismissPopup()}
        open={showPopup}
    >
        <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                    }}
                >
                    <Typography mt="7px">
                        For the best experience on this site, please use Chrome, Edge or Safari. Other browsers may not support all features.
                    </Typography>

                    {/* Align button to the right */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={dismissPopup}
                            sx={{ width: "100px" }}
                        >
                            Dismiss
                        </Button>
                    </div>
                </div>
        </DialogContent>
    </Dialog>
    )
};


