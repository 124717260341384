import {
     AlertTitle,
    Snackbar, 
    Alert
} from "@mui/material";
import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";

const ErrorAlertDisplay = ({alertMsg, alertStatus, showAlert, handleAlertClose}:any) =>{
return (
    <>
    {showAlert && (
                    <Snackbar
                        sx={{
                            "&.MuiSnackbar-anchorOriginTopCenter": {
                                top: "70px",
                            },
                        }}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={showAlert}
                        autoHideDuration={6000}
                        onClose={handleAlertClose}
                    >
                        <Alert
                            onClose={handleAlertClose}
                            icon={
                                alertStatus ? (
                                    <img src={SuccessIcon} alt="error-icon" />
                                ) : (
                                    <img
                                        src={ErrorIcon}
                                        alt="error-icon"
                                        style={{ marginRight: "10px" }}
                                    />
                                )
                            }
                            sx={{
                                backgroundColor: "#FFFFFF",
                                boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                                borderRadius: "6px",
                                fontSize: "1.125rem",
                                fontWeight: "500",
                                color: "black",
                            }}
                        >
                            <AlertTitle
                                sx={{
                                    fontSize: "1.125rem",
                                    fontWeight: "500",
                                    color: "#000000",
                                    display: "flex",
                                    margin: "auto",
                                    padding: "5px",
                                    justifyContent: "center !important",
                                }}
                            >
                                {alertMsg}
                            </AlertTitle>
                        </Alert>
                    </Snackbar>
                )}
    </>
)
}

export default ErrorAlertDisplay;