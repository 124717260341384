import { Navigate, useLocation, useSearchParams } from "react-router-dom";

const RootRenderElement = () => {
	const location = useLocation();
	let [searchParams] = useSearchParams();

	const sessionId = localStorage.getItem("sessionId") ?? null;
	const sid = searchParams.get("sid");
	const nickName = searchParams.get("nickName");
	const userProfileId = searchParams.get("userProfileId");
	const postId = searchParams.get("postId");
	let redirectTo = "/home";
	if (location.pathname.toString() === "/jamsessionlink") {
		if (sid) {
			redirectTo = `/jamsession?sid=${sid}`;
		}
	} else if (location.pathname.toString() === "/postView") {
		if (postId !== null && nickName !== null) {
			redirectTo = `/singlePostView?postId=${postId}&nickName=${nickName}`;
		}
	} else if (location.pathname.toString() === "/viewsocialprofile") {
		if (nickName !== null) {
			const encodedNickName = encodeURIComponent(nickName);
			redirectTo = `/userProfile/${encodedNickName}`;
		}
	} else if (location.pathname.toString() === "/technicalSupport") {
			redirectTo = `/helpandsupport`;
	} else if (location.pathname.toString() === "/viewMessages") {
		redirectTo = `/messages`;
	}


	const url = window.location;
	if (url.href.includes("redirectPath")) {
        const redirectPath = url.href.replace("redirectPath", "#");
        url.replace(redirectPath);
    }
	window.history.pushState(null, "", url.href);
	const toHash = url.hash.length > 2 ? url.hash.substring(1) : "/login";

	return sessionId  ? (
		<Navigate to={redirectTo} />
	) : toHash.includes("/postView") ? (
		<Navigate
			to={`/login?referrer=singlePostView&postId=${postId}&nickName=${nickName}`}
		/>
	) : sessionId && sid ? (
		<Navigate to={redirectTo} />
	) : toHash.includes("/jamsessionlink") ? (
		<Navigate to={`/login?referrer=jamsession&sid=${sid}`} />
	) : toHash.includes("/viewsocialprofile") ? (
		<Navigate to={`/login?referrer=userProfile&nickName=${nickName}`} />
	) : toHash.includes("/technicalSupport") ? (
		<Navigate to={`/login?referrer=helpandsupport`} />
	) : toHash.includes("/viewMessages") ? (
		<Navigate to={`/login?referrer=messages`} />
	)
	: (
		<Navigate to={toHash} />
	);
};

export default RootRenderElement;
