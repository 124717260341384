import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Box,
	Typography,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	FormHelperText,
} from "@mui/material";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import CopyIcon from "../images/copy.svg";
import { useUser } from "../context/UserContext";

const SessionBroadcastDialog = (props: any) => {
	const navigate = useNavigate();
	const user = useUser();
	const [isCopied, setIsCopied] = useState(false);

	const url = `${process.env.REACT_APP_BASE_URL_FRONTEND}/live?id=` + props.twilioSessionId;

	async function copyTextToClipboard() {
		if ("clipboard" in navigator) {
			return await navigator.clipboard.writeText(url);
		} else {
			return document.execCommand("copy", true, url);
		}
	}

	// onClick handler function for the copy button
	const handleCopyClick = () => {
		// Asynchronously call copyTextToClipboard
		copyTextToClipboard()
			.then(() => {
				// If successful, update the isCopied state value
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 10000);
			})
			.catch((err) => {
			});
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			open={props.broadcastDialogOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={props.closeBroadcastDialog}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%" }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.dialog.broadcastingTitle}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: "5px 20px",
				}}
			>
				<Grid
					container
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignContent: "center",
					}}
				>
					<Typography>{i18n.dialog.broadcastingDesc}</Typography>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ pb: "50px" }}>
				<Grid
					container
					style={{
						display: "flex",
						flexDirection: "row",
					}}
				>
					<FormHelperText
						sx={{
							fontSize: "1rem",
							color: "#7A7985",
						}}
						id="outlined-weight-helper-text"
					>
						{i18n.dialog.copyUrl}
					</FormHelperText>
					<OutlinedInput
						type={"text"}
						sx={{ background: "#F8F8F8" }}
						fullWidth
						disabled
						defaultValue={url}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleCopyClick}
									edge="end"
								>
									<img src={CopyIcon} alt="Eye Slash" />
								</IconButton>
							</InputAdornment>
						}
					/>
					{isCopied && (
						<FormHelperText
							sx={{
								color: "#7A7985",
								textAlign: "right",
								fontSize: "14px",
							}}
						>
							Copied!!
						</FormHelperText>
					)}
				</Grid>
			</DialogActions>
		</Dialog>
	);
};
export default SessionBroadcastDialog;
