import { Button, Grid, Typography } from "@mui/material";
import Picture from "../../images/Posts/picture-upload-blue.svg";
import Music from "../../images/Posts/music-upload-blue.svg";
import Gigs from "../../images/Posts/post-a-gigs.svg";
import Event from "../../images/Posts/post-on-event.svg";
import { useRef, useState } from "react";
import SelectMusic from "./selectMusic";
import SelectMusicFromHome from "./SelectMusicFromHome";
import PostImageFromHome from "./PostImageFromHome";
import NewEvent from "../Events/NewEvent";
import NewGig from "../Gigs/NewGig";
import { useAlertStatus } from "../../context/AlertStatusContext";
import PostEventFromHome from "./PostEventFromHome";
import PostGigFromHome from "./PostGigFromHome";
import SettingsAlert from "../../pages/SettingsAlert";

const PostButtons = (props: any) => {
	const [file, setFile] = useState<any>("");
	const [result, setResult] = useState("");
	const [editOpen, setEditOpen] = useState(false);
	const [musicOpen, setMusicOpen] = useState(false);
	const inputFileRef = useRef<any>(null);
	const [isEventDialogOpen, setEventDialogOpen] = useState(false);
	const [isGigDialogOpen, setGigDialogOpen] = useState(false);
	const { updateAlertStatus } = useAlertStatus();
	const imageExtensions = ["jpg", "jpeg", "png", "gif"];
	const imageMimeTypes = ["image/jpeg", "image/png", "image/gif"];
	const handleEditClose = () => {
		setEditOpen(false);
		setMusicOpen(false);
		setEventDialogOpen(false);
		setGigDialogOpen(false);
		// props.handleClose();
	};

	const handleOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];

		if (!file) return; // Check if a file was selected

		// Get the file extension
		const fileExtension = file.name.split(".").pop()?.toLowerCase();

		// Check both MIME type and extension
		if (
			fileExtension &&
			imageExtensions.includes(fileExtension) &&
			imageMimeTypes.includes(file.type)
		) {
			setResult(URL.createObjectURL(file));
			setFile(file);
			if (file.size > 4 * 1024 * 1024) {
				updateAlertStatus(true, false, 'Please Select Image Smaller than 4 MB.');
			} else {
				setEditOpen(true);
			}
			// setEditOpen(true);

			if (inputFileRef.current) {
				inputFileRef.current.value = null; // Clear the input value
				const newInput = document.createElement("input");
				newInput.style.display = "none";
				newInput.type = "file";
				newInput.accept = ".jpg, .jpeg, .png, .gif"; 
				newInput.addEventListener("change", handleOpenEditor);
				inputFileRef.current.parentNode.replaceChild(
					newInput,
					inputFileRef.current
				);
				inputFileRef.current = newInput;
			}
		} else {
			updateAlertStatus(true, false, "Invalid file type");
		}
	};

	const photoUpload = (destFile: any) => {
		const reader = new FileReader();
		const file = destFile;
		reader.onloadend = () => {
			props.setFile(file);
			props.setImagePreviewUrl(reader.result as string);
		};
		reader.readAsDataURL(file);
	};

	const openEventDialog = () => {
		setEventDialogOpen(true);
	};

	const closeEventDialog = () => {
		setEventDialogOpen(false);
	};

	const openGigDialog = () => {
		setGigDialogOpen(true);
	};

	const closeGigDialog = () => {
		setGigDialogOpen(false);
	};

	return (
		<>
		<SettingsAlert/>
			<Grid item xs={2.5} sx={{ pl: "2px" }}>
				<Button
					sx={{ color: "black", fontSize: "14px", pt: "12px", height: "32px" }}
				>
					<label
						style={{ cursor: "pointer", color: "black", fontSize: "14px" }}
					>
						<Grid container>
							<img
								src={Picture}
								alt="Picture"
								style={{ height: "24px", width: "24px" }}
							/>
							&nbsp;
							<input
								id="photo-upload"
								ref={inputFileRef}
								type="file"
								accept =".jpg, .jpeg, .png, .gif"
								style={{ display: "none" }}
								onChange={(e) => handleOpenEditor(e)}
							/>
							<Typography sx={{ color: "black", fontSize: "14px" }}>
								Photo
							</Typography>
						</Grid>
					</label>
				</Button>
			</Grid>
			<Grid item xs={2.5}>
				<label>
					<Button
						startIcon={
							<img
								src={Music}
								alt="Music"
								style={{ height: "24px", width: "24px" }}
							/>
						}
						onClick={() => setMusicOpen(true)}
						sx={{ color: "black", fontSize: "14px", pb: 0.5, height: "32px" }}
					>
						<Typography sx={{ color: "black", fontSize: "13px" }}>
							{" "}
							Audio
						</Typography>
					</Button>
				</label>
			</Grid>
			<Grid item xs={3.3}>
				<label>
					<Button
						startIcon={
							<img
								src={Gigs}
								alt="Gig"
								style={{ height: "24px", width: "24px" }}
							/>
						}
						sx={{ color: "black", fontSize: "14px", pb: 0.5, height: "32px" }}
						onClick={openGigDialog}
					>
						<Typography sx={{ color: "black", fontSize: "13px" }}>
							Post a Gig
						</Typography>
					</Button>
				</label>
			</Grid>
			<Grid item xs={3.7}>
				<label>
					<Button
						startIcon={
							<img
								src={Event}
								alt="Event"
								style={{ height: "24px", width: "24px" }}
							/>
						}
						sx={{ color: "black", fontSize: "14px", pb: 0.5, height: "32px" }}
						onClick={openEventDialog}
					>
						<Typography sx={{ color: "black", fontSize: "13px" }}>
							Post an Event
						</Typography>
					</Button>
				</label>
			</Grid>
			<PostImageFromHome
				onClose={handleEditClose}
				imagePreviewUrl={result}
				open={editOpen}
				file={file}
				getPostDetails={props.resetPostDetails}
			/>
			<SelectMusicFromHome
				musicOpen={musicOpen}
				handleMusicClose={handleEditClose}
				resetPostDetails={props.resetPostDetails}
			/>
			<PostEventFromHome
				open={isEventDialogOpen}
				handleEventClose={handleEditClose}
				resetPostDetails={props.resetPostDetails}
				eventType={"post"}
			/>
			<PostGigFromHome
				open={isGigDialogOpen}
				handleGigClose={handleEditClose}
				gigType={"post"}
				resetPostDetails={props.resetPostDetails}
			/>
		</>
	);
};
export default PostButtons;
