import { createContext, useState } from "react";

type PeopleContextType = {
	isPeopleWindowOpen: boolean;
	setIsPeopleWindowOpen: (isPeopleWindowOpen: boolean) => void;
};

export const PeopleContext = createContext<PeopleContextType>(null!);

export const PeopleProvider = ({ children }: any) => {
	const [isPeopleWindowOpen, setIsPeopleWindowOpen] = useState(false);

	return (
		<PeopleContext.Provider
			value={{
				isPeopleWindowOpen,
				setIsPeopleWindowOpen,
			}}
		>
			{children}
		</PeopleContext.Provider>
	);
};
