import { identity, pickBy } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import React, { useRef } from "react";
import { Manufacturer } from "./AdminConstants";
import useAdminAuthorized from "../../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

export const AdminDeviceManagementContext = createContext<any>(null);

export const AdminDeviceManagementProvider = ({ children }: any) => {
	const token = localStorage.getItem("sessionId");
	const [pageNumber, setPageNumber] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [itemPerPage, setItemPerPage] = useState(20);

	const [searchDeviceId, setSearchDeviceId] = useState("");
	const inputRef = useRef(null);
	const [orderByField, setOrderByField] = useState("");
	const [manufacturer, setManufacturer] = useState([]);

	const [deviceManagementFilter, setDeviceManagementFilter] = useState<any>({
		isGlobalSearch: true,
		deviceManufacturedBy: "",
		deviceManufacturedYear: "",
		deviceVersion: "",
	});

	const [selectedDeviceIds, setSelectedDeviceIds] = useState<any>([]);

	const [isDisabledOptions, setIsDisabledOptions] = useState(false);

	interface Data {
		userName: string;
		city: string;
		joinDate: string;
		subscription: string;
		expiryDate: string;
		userStatus: string;
		actions: string;
	}

	const [isFilterActive, setIsFilterActive] = useState(false);

	const [rowData, setRowData] = useState<Data[]>([]);

	const [deviceId, setDeviceId] = useState(null);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	const fetchDevicesData = async (isFetchingNextBatch = false) => {
		const nextPage = isFetchingNextBatch ? pageNumber + 1 : 0;

		let url = `${process.env.REACT_APP_BASE_URL}/api/devices?page=${nextPage}&size=${itemPerPage}`;

		const queryParams = pickBy(
			{
				orderBy: orderByField,
				deviceId: searchDeviceId,
				deviceManufacturedBy: deviceManagementFilter.deviceManufacturedBy,
				deviceManufacturedYear: deviceManagementFilter.deviceManufacturedYear,
				deviceVersion: deviceManagementFilter.deviceVersion,
			},
			identity
		);

		const queryString = new URLSearchParams(queryParams).toString();

		if (queryString) {
			url = `${url}&${queryString}`;
		}
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		};
		try {
			const resp = await fetch(url, options);
			if (resp.status > 400) {
				throw new Error("api_error");
			} else {
				const data = await resp.json();
				if (data.length === 0) {
					setHasMore(false);
				} else {
					isFetchingNextBatch
						? setRowData((prevRowData) => [...prevRowData, ...data])
						: setRowData(data);
					setPageNumber(nextPage);
				}
			}
		} catch (e) {
			setHasMore(false);
			setRowData([]);
			setPageNumber(0);
			console.log(e);
		}
	};

	const resetDeviceManagementFilter: any = () => {
		setDeviceManagementFilter({
			isGlobalSearch: true,
			deviceManufacturedBy: "",
			deviceManufacturedYear: "",
			deviceVersion: "",
		});
	};

	const fetchAllDeviceManufacturer = async () => {
		const url = `${process.env.REACT_APP_BASE_URL}/api/devices/manufacturers`;
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		};
		try {
			const resp = await fetch(url, options);
			if (resp.status > 400) {
				throw new Error("api_error");
			} else {
				const data = await resp.json();
				if (data.length === 0) {
					setHasMore(false);
				} else {
					const formatedData = data.map((item: Manufacturer) => {
						return {
							...item,
							id: item.id.toString(),
						};
					});
					setManufacturer(formatedData);
				}
			}
		} catch (e) {
			setManufacturer([]);
			console.log(e);
		}
	};

	useEffect(() => {
		if (isAdmin) {
			fetchAllDeviceManufacturer();
		}

		if (
			deviceManagementFilter.deviceManufacturedBy ||
			deviceManagementFilter.deviceManufacturedYear ||
			deviceManagementFilter.deviceVersion.length > 0
		) {
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
	}, [deviceManagementFilter]);

	const handleFilterReset = async () => {
		setRowData([]);
		try {
			setHasMore(true);
			setPageNumber(0);

			await fetchDevicesData();
		} catch (error) {
			setHasMore(false);
			setRowData([]);
		}
	};

	return (
		<AdminDeviceManagementContext.Provider
			value={{
				rowData,
				setRowData,
				hasMore,
				deviceId,
				setDeviceId,
				pageNumber,
				setPageNumber,

				fetchDevicesData,

				deviceManagementFilter,
				setDeviceManagementFilter,
				isFilterActive,
				resetDeviceManagementFilter,

				handleFilterReset,
				inputRef,

				itemPerPage,
				setItemPerPage,

				setSelectedDeviceIds,
				selectedDeviceIds,

				searchDeviceId,
				setSearchDeviceId,

				manufacturer,

				orderByField,
				setOrderByField,
				setIsDisabledOptions,
				isDisabledOptions,
			}}
		>
			{children}
		</AdminDeviceManagementContext.Provider>
	);
};

// Custom hook to consume the context
export function useAdminDevciceManagement() {
	return useContext(AdminDeviceManagementContext);
}
