import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ReloadContextProps {
  children: ReactNode;
}

interface ReloadContextType {
  reloadJamSession: number ;
  toggleReload: () => void;
}

const ReloadContext = createContext<ReloadContextType>({
  reloadJamSession: 0,
  toggleReload: () => {},
});

export const ReloadProvider = ({ children }: ReloadContextProps) => {
	const [reloadJamSession, setReloadJamSession] = useState(0);

  const toggleReload = () => {
    setReloadJamSession(prevState => prevState + 1);
  };

  return (
    <ReloadContext.Provider value={{ reloadJamSession, toggleReload }}>
      {children}
    </ReloadContext.Provider>
  );
};

export const useReload = () => useContext(ReloadContext);
