
import { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	IconButton,
	Typography,
	Box,
	Grid,
	FormControl,
} from "@mui/material";
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import CheckIcon from "../images/check-fill.svg";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { Link, useNavigate } from "react-router-dom";


const EditNickName = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();

	const [nickname, setNickName] = useState(props.nickName);
	const [nicknamemessage, setNickNameMessage] = useState("");
	const [validInput, setValidInput] = useState(true);
	const [exceedLimit, setExceedLimit] = useState(false);
	const navigate = useNavigate();


	const checkNickName = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/user-profiles/check-nickName`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					nickname: nickname,
				}),
			}
		)
			.then((response) => {
				if(response.status === 502){
					navigate('/defaultPage')
				} else if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				if (result.status === "OK") {
					setNickNameMessage(result.message);
				}
				if (result.status === "BAD_REQUEST") {
					setNickNameMessage(result.message);
				}
			});
	};

	const saveNickName = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/save-nickName`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				nickname: nickname,
				user: {
					// id: user.id,
					id: props.userIdByAdmin ? props.userIdByAdmin : user.id,
				},
			}),
		})
			.then((response) => {
				if(response.status === 502){
					navigate('/defaultPage')
				} else if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				props.handleClose(true, "nickNameSuccess");
			});
	};

	function validateTextField(input: string) {
        const regex = /^[a-zA-Z0-9_-]+$/;
    
        if (!regex.test(input)) {
            setValidInput(false);
        } else {
            setValidInput(true);
        }
    }

	const handleClose = () => {
		setNickName(props.nickName);
		setNickNameMessage("");
		props.handleClose(false)
	};

	const isValidNickname = (nickname: any) => {
		// Regular expression to match only letters A-Z, digits 0-9, and underscore
		const regex = /^[A-Za-z0-9_]+$/;
		return regex.test(nickname);
	}

	const isExceedLimit = (nickname: string) => {
		return nickname.length > 50;
	};
	 const isDisabledNickname = !nickname || !isValidNickname(nickname) || exceedLimit;

	const handleChangeNickName = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newNickname = event.target.value.trim();
		if (newNickname.length > 50) {
			setExceedLimit(true); 
			setNickNameMessage("Nickname cannot exceed 50 characters.");
			// return; 
		} else {
			setExceedLimit(false);
			setNickNameMessage("");
		}
		// validateTextField(newNickname);
		// setNickNameMessage("");
		// setNickName(newNickname);
		   // Validate nickname format (regex)
		   setNickName(newNickname);
		   validateTextField(newNickname.trim());

		   // Set the nickname state
		  
	};

	const isEnabled = nicknamemessage === "Nickname is available" && nickname !== props.nickName && validInput && !exceedLimit;

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px"
			}}
			fullWidth
			maxWidth="sm"
			open={props.nickNameOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={handleClose}
						onMouseDown={(
							event: React.MouseEvent<HTMLButtonElement>
						) => event.preventDefault()}
						edge="end"
						sx={{ marginLeft: "90%" }}>
						<img
							src={CloseIcon}
							alt="close"
						/>
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.settings.accountSetup.editNicknameDialog}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent>
				{/* <FormControl sx={{ width: "100%" }}> */}
				<Grid container
					style={{ display: "flex", flexDirection: "column", alignContent: "center" }}
				>
					<Grid item={true}>
						<FormHelperText
							sx={{
								fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text">
							{i18n.dialog.nickName}
						</FormHelperText>
					</Grid>
					<Grid item={true}>
						<TextField id="nick-name"
							sx={{
								background: "#F8F8F8",
								width: "240px",
								marginRight: "24px"
							}}
							value={nickname}
							// onFocus={() => setDirty(true)}
							onChange={handleChangeNickName}
							variant="outlined"
						/>
						<Button
							sx={{
								width: "192px !important",
								height: "40px !important",
								color: "#0273e6 !important",
								backgroundColor: "#fff !important",
								borderColor: "#0273e6 !important",
								padding: "7px 20px"
							}}
							className={`settings-check-availability ${isDisabledNickname ? 'disabled' : ''}`}
							size="large"
							disabled={isDisabledNickname}
							variant="outlined"
							onClick={checkNickName}
							fullWidth
						>
							{i18n.dialog.checkAvailability}
						</Button>
						<Grid item={true}>

						{!validInput ? (
								<FormHelperText sx={{
								display: "flex", 
								flexDirection: "center",
								alignItems: "center",
								color: "#FD4B24",
								width: "100%",
								marginRight: "20px",
								ml:'0px !important',
								}}>
									Allowed characters: A-Z a-z 0-9 - _
								 </FormHelperText>
							) : null}

						{exceedLimit ? (
								<FormHelperText sx={{
								display: "flex", 
								flexDirection: "center",
								alignItems: "center",
								color: "#FD4B24",
								width: "100%",
								marginRight: "20px",
								ml:'0px !important',
								}}>
            						Nickname cannot exceed 50 characters.
								 </FormHelperText>
							) : null}
						
							{nicknamemessage === "Nickname is not available" && <FormHelperText sx={{
								display: "flex",
								alignItems: "center",
								color: "#FD4B24",
								width: "100%",
								marginRight: "20px",
								ml:'0px !important',
							}}>
							{/* <img src={CloseIcon} alt="error-icon" /> */}
							{nicknamemessage}</FormHelperText>}
							{nicknamemessage === "Nickname is available" && <FormHelperText sx={{
								display: "flex",
								alignItems: "center",
								width: "100%",
								marginRight: "20px",
								color:'#3EB92A',
								ml:'0px !important',
							}}>
								{/* <img src={CheckIcon} alt="error-icon" /> */}
							{nicknamemessage}</FormHelperText>}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					sx={{  width: "135px" }}
					className="cancel-button"
					size="large"
					variant="outlined"
					color="primary"
					disabled={!isDisabled}
					onClick={handleClose}
				>
					{i18n.dialog.cancel}
				</Button>
				<Button
					className="submit-button"
					color="primary"
					size="large"
					variant="contained"
					sx={{ width: "135px" }}
					disabled={!isEnabled}
					onClick={saveNickName}
				>
					{i18n.dialog.save}
				</Button>
			</DialogActions>
		</Dialog>
	)
};
export default EditNickName;
