import React from 'react'
import LuteFishLogo from "../icons/LuteFish_Logo_UI_withtagline_RGB.svg";
import LuteFishIcon from "../icons/LuteFish_maintenance 1.svg";
import MusicIcon from "../icons/Group 4537.svg";
import { Typography } from '@mui/material';



const Maintainance = () => {
  return (
   <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "100vh"}}>
          <img
              src={LuteFishLogo}
              alt={"Lutefish Logo"}
            //   style={{height: "96px", width: "187px"}}
              style={{ height: "10vh", width: "20vw" }}
          />
          <img
              src={LuteFishIcon}
              alt={"Lutefish Icon"}
            //   style={{ height: "293px", width: "396px", padding: "10px" }}
              style={{ height: "30vh", width: "35vw", padding: "10px" }}
          />
          <Typography
        //    sx={{fontSize: "60px", lineHeight: "75px", fontWeight: "400"}}
              sx={{ fontSize: "7vh", lineHeight: "75px", fontWeight: "400" }}
            >Our site is offline for upgrade!</Typography>
          <Typography
        //    sx={{ fontSize: "30px", lineHeight: "37.5px", fontWeight: "400" }}
              sx={{ fontSize: "3.5vh", lineHeight: "37.5px", fontWeight: "400" }}
           >We are making it more melodious for you.</Typography>
          <Typography 
        //   sx={{ fontSize: "26px", lineHeight: "32.5px", fontWeight: "400", borderRadius: "10px", bgcolor: "#FFE4D2", padding: "10px 20px 10px 20px", mt: "70px" }}
              sx={{ fontSize: "3vh", lineHeight: "32.5px", fontWeight: "400", borderRadius: "10px", bgcolor: "#FFE4D2", padding: "10px 20px 10px 20px", mt: "70px" }}
          >
              We  will be back on <span style={{fontWeight: "700"}}>15th Oct 9:00 PM!</span>
          </Typography>
          <Typography 
        //   sx={{ fontSize: "18px", lineHeight: "22.5px", fontWeight: "400", mt: "20px" }}
              sx={{ fontSize: "2.3vh", lineHeight: "22.5px", fontWeight: "400", mt: "20px" }}
          >Visit <a href="https://www.lutefish.com" target="_blank" rel="noopener noreferrer">
                  <span>lutefish.com</span>
              </a>
{" "} to learn about our products</Typography>
          <img
              src={MusicIcon}
              alt={"Music Icon"}
            //   style={{ height: "80px", width: "1291px", alignContent: "flex-end" }}
              style={{ height: "28vh", width: "70vw", alignContent: "flex-end" }}
          />
   </div>
  )
}

export default Maintainance