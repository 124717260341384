import { useState, useEffect } from "react";
import EventCardCom from "../Events/EventCardCom";

export const EventsSidePanel = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const [similarEvents, setSimilarEvents] = useState<any[]>([]);

	useEffect(() => {
		getSimilarEventsRecommendations();
	}, []);

	const getSimilarEventsRecommendations = (page = 0, size = 8, retryCount = 0) => {
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/events/recommendation/similar-artist?eventType=${props.type}&${page}&size=${size}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					setSimilarEvents(data);
					if (data.length < 2 && retryCount < 2) {
						// Retry with the same page and size
						getSimilarEventsRecommendations(page, size + 8, retryCount + 1);
					}
				})
				.catch((err) => {
				})
				.finally(() => {});
		} catch (error) {
		}
	};

	return (
		<>
			{similarEvents.slice(0, 2).map((event) => {
				return (
					<div style={{ display: "flex", marginBottom: "24px", justifyContent:"center"}}>
						<EventCardCom data={event} type={props.type.toLowerCase()} />
					</div>
				);
			})}
		</>
	);
};
