import { useState, useEffect } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";

const CalenderCmp = (props: any) => {
	const [values, setValues] = useState<DateObject | DateObject[] | null>([
		new DateObject(props.startDate),
		new DateObject(props.startDate),
	]);
	useEffect(() => {
		if (props.filter === "agenda") {
			setValues([
				new DateObject(props.startDate),
				new DateObject(props.startDate),
			]);
		} else if (props.filter === "week") {
			setValues([
				new DateObject(props.startDate),
				new DateObject(props.startDate).add(6, "d"),
			]);
		} else if (props.filter === "month") {
			setValues([
				new DateObject(props.startDate),
				new DateObject(props.startDate),
			]);
		}
	}, [props.startDate, props.filter]);

	const handleDateChange = (
		selectedDates: DateObject | DateObject[] | null
	) => {
		props.onDateChange(selectedDates);
	};

	return (
		<div className="calender-custom"
			style={{
				//height: "300px",
				//width: "300px",
				overflow: "hidden",
				boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
			}}
			>
			{props.filter === "month" ? (
				<Calendar  weekDays={[
					 "S",
					"M",
					 "T",
					"W",
					 "T",
					 "F",
					 "S"
					  ]}
					onlyMonthPicker
					value={values}
					onChange={(selectedDates) => handleDateChange(selectedDates)}
				/>
			) : props.filter === "week" ? (
				<Calendar weekDays={[
					"S",
				   "M",
					"T",
				   "W",
					"T",
					"F",
					"S"
					 ]}
					weekPicker
					value={values}
					onChange={(selectedDates) => handleDateChange(selectedDates)}
					range
				/>
			) : (
				<Calendar weekDays={[
					"S",
				   "M",
					"T",
				   "W",
					"T",
					"F",
					"S"
					 ]}
					value={values}
					onChange={(selectedDates) => handleDateChange(selectedDates)}
				/>
			)}
		</div>
	);
};
export default CalenderCmp;
