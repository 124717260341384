export const menuOptions = [
  {
    title: 'Export to CSV', 
    value: 'export'
  },
  {title: 'Import CSV',
    value: 'import'
  },
  {
    title: 'Download CSV Template',
    value: 'template'
  } 
];


export const MfgYears = Array.from({ length: new Date().getFullYear() - 2022 + 1 }, (_, i) => 2022 + i);


export interface Manufacturer {
  id: string;
  manufacturerName: string;
  createdAt?: string;
  updatedAt?: string;
}