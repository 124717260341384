import { useState } from "react";
import {
	Card,
	CardActions,
	CardContent,
	Button,
	Typography,
	Grid,
} from "@mui/material";
import AddDeviceDialog from "../../dialogBox/AddDeviceDialog";
import Danger from "../../images/danger.svg";
import RightAarrow from "../../images/right arrow.svg";
import i18n from "../../l10n/strings.json";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../context/UserContext";

const AddDevice = (props: any) => {
	const user = useUser();
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	const handleSuccess = () => {
		setOpen(false);
		props.onSuccess();
	};

	return (
		<>
			<Grid container={true} xs={12}>
				<Card
					sx={{
						background: "secondary",
						boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
						borderRadius: "12px",
						// height: "376px",
					}}
				>
					<CardContent>
						<Grid container={true} xs={12}>
							<Grid item={true} xs={1} sx={{ textAlign: "center" }}>
								<img src={Danger} alt="Preferences" />
							</Grid>

							<Grid item={true} xs={11}>
								<Typography variant="h3" sx={{color: "customColor.black"}}>
									{i18n.jamSession.addDevice}
								</Typography>
							</Grid>
						</Grid>

						<Grid item={true}>
							<Typography variant="h6"
								sx={{
									padding: "30px 0 0 0",
									color: "customColor.black",
									ml: 2
								}}
							>
								{i18n.jamSession.infoAddDevice}
							</Typography>
						</Grid>

					</CardContent>
					<CardActions
						sx={{
							justifyContent: "flex-end",
							marginRight: "10px",
							marginTop: "25px",
						}}
					>
						<Button
							sx={{
								background: user.userAccountStatus === "SUSPEND" ? "grey" : theme.gradient.gradient1,
								height: "48px",
								color: "secondary",
								borderRadius: "24px",
								maxWidth: "247px"
							}}
							className="submit-button"
							size="large"
							fullWidth
							endIcon={<img src={RightAarrow} alt="Right Arrow" />}
							variant="contained"
							disabled={user.userAccountStatus === "SUSPEND"}
							onClick={() => (setOpen(true))}
						>
							<Typography>
								{i18n.jamSession.add}
							</Typography>
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<AddDeviceDialog
				open={open}
				updateAlertMsg={props.updateAlertMsg}
				onSuccess={handleSuccess}
				onClose={() => (setOpen(false))}
			/>
		</>
	);
};

export default AddDevice;
