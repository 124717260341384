import { useState, useEffect, useRef } from "react";
import {
	Grid,
    CircularProgress,
    Box,
    Typography
} from "@mui/material";
import { createStyles,  makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import i18n from "../../l10n/strings.json";
import UserCardShort from "./UserCardShort";

import { musicianCardInterface } from "../../types/types";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        shadowedBox:{
             //padding: "1% 2%", 
            borderRadius: "15px",
            //boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
        },
		activeMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#ffffff",
            background: "#0273E6"
		},
		musicMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#000 !important",
            // background: "#0273E6"
		},
        instrumentStack: {
            margin: "5% 0",
            "& div": {
                padding: "1%",
                margin: "1% 0",
                backgroudColor: "#FAFAFA",
                borderRadius: "15px"
            }
        },
        spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		}
	})
);

const MyConnections = () => {
    const classes = useStyles();
    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
	const [itemPerPage, setItemPerPage] = useState(10);
    const [myConnections, setMyConnections] = useState<Array<musicianCardInterface>>( [] );
    const [hasMore, setHasMore] = useState(true);
    const [previousSearchString, setPreviousSearchString] = useState("");
	const [searchString, setSearchString] = useState("");
    const inputSearchRef = useRef("");

	useEffect(() => {
        setLoading(true);
		getMyConnections();
	}, []);


    const getMyConnections = () => {
		try {
            //@ts-ignore
            let searchStringForService = inputSearchRef.current?.value;
			fetch(
                `${process.env.REACT_APP_BASE_URL}/api/user-connections/my-connection/${user.userUuid}?searchString=${''}&page=${pageNumber}&size=${itemPerPage}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if(data.length > 0){
                    setHasMore(true);
                    setMyConnections([...myConnections, ...data]);
                    var nextPageNumber = pageNumber+1;
                    setPageNumber(nextPageNumber);
                }
                if(data.length < itemPerPage){
                    setHasMore(false);
                }
            })
            .catch((err) => {
                setHasMore(false);
            })
            .finally(() => {
                setLoading(false);
            });
		} catch (error) {
			setLoading(false);
		}
    };
    
    const resetMyConnections = () => {
        setLoading(true);
		try {
            setPageNumber(0);
            setMyConnections([]);
			setHasMore(true);
            //@ts-ignore
            let searchStringForService = inputSearchRef.current?.value;
            fetch(
                `${process.env.REACT_APP_BASE_URL}/api/user-connections/my-connection/${user.userUuid}?searchString=${searchStringForService}&page=0&size=${itemPerPage}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                // setMyConnections(data);
                setPreviousSearchString(searchString);
                if(data.length > 0){
                    setHasMore(true);
                    setMyConnections(data);
                    var nextPageNumber = pageNumber+1;
                    setPageNumber(nextPageNumber);
                }
                if(data.length < itemPerPage){
                    setHasMore(false);
                }
            })
            .catch((err) => {
                setHasMore(false);
            })
            .finally(() => {
                setLoading(false);
            });
		} catch (error) {
			setLoading(false);
		}
    };

    const clearSearch = () => {
		setSearchString("");
        //@ts-ignore
        inputSearchRef.current.value = '';
		setPreviousSearchString("");
		resetMyConnections();
	};

	return (
		<>
            {loading ? 
                <Grid container item justifyContent="center" sx={{ p: "16px" }}>
                    <CircularProgress /> {/* The loading spinner */}
                </Grid>
                :   
                <Grid xs={12} container  >
                    
                        {/* { */}
                            {/* // (myConnections && myConnections.length > 0 || searchString.length > 0) ? */}

                                <Grid container xs={12} className={classes.shadowedBox} margin={1} padding={"1% 2%"} mt={0}>
                                    {/* ADDED A NEW FILTER CONSTRAINT AS NEW FIGMA CHANGES SO REMOVING THIS SEARCH */}

                                    {/* <Grid xs={12} mb={1}>
                                        <Grid item={true} xs={4}>
                                            <TextField
                                                hiddenLabel
                                                id="filled-hidden-label-small"
                                                variant="outlined"
                                                placeholder="Search your connections"
                                                size="small"
                                                InputProps={{
                                                    endAdornment: (
                                                    <InputAdornment position="start" sx={{cursor: "pointer"}}>
                                                        {searchString != "" &&
                                                        searchString === previousSearchString ? (
                                                            <Cancel onClick={clearSearch} />
                                                        ) : (
                                                            <SearchIcon onClick={resetMyConnections} />
                                                        )}
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                value={searchString}
                                                onChange={(e) => setSearchString(e.target.value)}
                                                focused={searchString.length > 0 ? true : false}
                                                inputRef={inputSearchRef}
                                            />
                                        </Grid>
                                    </Grid>  */}

                                {/* User Cards */}
                                { loading ? (
                                                    <Grid sx={{backgroundColor: "white"}}>
                                                    <CircularProgress/>
                                                    </Grid>
                                                ) :

                                    (myConnections && myConnections.length > 0 || searchString.length > 0) ?
                                    <Grid item xs={12} >
                                        <InfiniteScroll
                                            dataLength={myConnections.length}
                                            next={getMyConnections}
                                            hasMore={hasMore}
                                            loader={
                                                <Box 
                                                    className={classes.spinner}
                                                >
                                                    <CircularProgress />
                                                </Box>
                                            }
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                            }
                                        >
                                        {
                                            (myConnections && myConnections.length > 0) ?
                                                    <Grid container xs={12}>
                                                        {
                                                            myConnections.map(user => {
                                                                return (
                                                                    // COMMENTED FOR DEPLOYMENT PURPOSE BUT DEVELOPMENT IS PENDING
                                                                    //    <UserCardShort user={user} refreshList={getMyConnections}/>

                                                                    // <Grid item={true} xs={12} margin={1}>
                                                                        <UserCardShort user={user} refreshList={resetMyConnections}/>
                                                                    // </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                            :
                                            <Grid container item justifyContent="center">
                                                {loading && (
                                                    <Grid sx={{backgroundColor: "white"}}>
                                                    <CircularProgress/>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        }
                                        </InfiniteScroll>
                                    </Grid>
                                    :
                                    <Grid container item justifyContent="center">
                                        {loading ? (
                                                    <Grid sx={{backgroundColor: "white"}}>
                                                    <CircularProgress/>
                                                    </Grid>
                                                ):(
                                                    <Typography>No connections available yet.</Typography>
                                                )}
                                    </Grid>
                                }
                                </Grid>

                        {/* } */}
                    {/* </Grid> */}
                </Grid>
            }
		</>
	);
};

export default MyConnections;
