import * as React from "react";
import { useRef, useState, useEffect } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import SuccessIcon from "../../../images/tick-square.svg";
import ErrorIcon from "../../../images/danger.svg";
import {
	Alert,
	AlertTitle,
	ButtonGroup,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Pagination,
	Select,
	Snackbar,
	Tab,
	Tabs,
} from "@mui/material";
import { Button, Grid, Theme } from "@mui/material";
import CloseIcon from "../../../images/close.svg";
import Search from "../../../images/search.svg";
import { createStyles, makeStyles } from "@mui/styles";
import AdminRow from "./AdminRow";
import { useAdminContext } from "../ManageUserDashBoard/UserDetailsProvider";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import ActionDialog from "../../reusable-components/ActionDialog";
import useFetch from "../../../hooks/useFetch.tsx/useFetch";
import { useAlertStatus } from "../../../context/AlertStatusContext";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		searchField: {
			borderRadius: "17px",
			"& fieldset": {
				border: "1px solid #C5C5C5",
				borderRadius: "17px",
			},
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
		folderDropdown: {
			background: "#FFF",
			border: "1.5px solid #0273E6",
			borderRadius: "15px !important",
			"& #demo-simple-select": {
				color: "#0273E6",
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiSelect-icon": {
				color: "#0273E6",
			},
		},
		resetSubHeading: {
			fontSize: "16px",
			color: "#5B5B65",
			fontWeight: "400",
		},
	})
);

interface Data {
	id: number;
	userName: string;
	email: string;
	role: string;
	joinDate: string;
	action: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
	array: readonly T[],
	comparator: (a: T, b: T) => number
) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
	width: string;
}

const headCells: readonly HeadCell[] = [
	{
		id: "userName",
		numeric: false,
		disablePadding: true,
		label: "Name",
		width: "130px",
	},
	{
		id: "email",
		numeric: true,
		disablePadding: false,
		label: "Email",
		width: "240px",
	},
	{
		id: "role",
		numeric: true,
		disablePadding: false,
		label: "Role",
		width: "100px",
	},
	{
		id: "joinDate",
		numeric: true,
		disablePadding: false,
		label: "Created Date",
		width: "100px",
	},
	{
		id: "action",
		numeric: true,
		disablePadding: false,
		label: "Actions",
		width: "70px",
	},
];

interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
	isHeadCkeckBoxChecked: any;
	isHeadCheckBoxIndeterminate: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
		isHeadCkeckBoxChecked,
		isHeadCheckBoxIndeterminate,
	} = props;
	const {
		selectedUserEmails,
		orderByField,
		setOrderByField,
		getAllAdminUsers,
		selectedButton,
		setPage
	} = useAdminContext();

	// const createSortHandler =
	// 	(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
	// 		onRequestSort(event, property);
	// 	};

	const handleSorting = (field: any) => {
		setPage(0);
		setOrderByField((prevValue: any) => {
			const newOrderByField = orderByField === field ? `${field}Desc` : field;
			return newOrderByField;
		});
	};

	// useEffect(() => {
	// 	getAllAdminUsers("", selectedButton);
	// }, [orderByField]);

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox" sx={{bgcolor:'#F5F5F5',borderRight: '1px solid #C2C2C3 !important',}}>
					{/* <Checkbox
						color="primary"
						indeterminate={selectedUserEmails > 0 && selectedUserEmails < rowCount}
						checked={rowCount > 0 && selectedUserEmails === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/> */}
					<Checkbox
						color="primary"
						indeterminate={isHeadCheckBoxIndeterminate}
						checked={isHeadCkeckBoxChecked}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align="left"
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						width={headCell.width}
						sx={{ fontSize: "16px", fontWeight: 500 , fontFamily: "Lexend",bgcolor:'#F5F5F5', borderRight: '1px solid #C2C2C3 !important',paddingLeft: '8px !important'}}
					>
						<TableSortLabel
							active={orderByField === headCell.id}
							direction={orderByField === headCell.id ? "asc" : "desc"}
							onClick={() => handleSorting(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

interface EnhancedTableToolbarProps {
	numSelected: number;
	handleActionChange: any;
	adminAction: string;
	setAdminAction: (value: string) => void;
}

export const userRoleMenu = [
	{ id: "menu", title: "All Roles", value: "" },
	{ id: "menu1", title: "Admin", value: "ROLE_ADMIN" },
	{ id: "menu2", title: "Super User", value: "ROLE_SUPER_ADMIN" },
	{
		id: "menu3",
		title: "Content Moderator",
		value: "ROLE_ADMIN_CONTENT_MODERATOR",
	},
	{ id: "menu4", title: "Admin Viewer", value: "ROLE_ADMIN_VIEWER" },
];

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
	const { numSelected, handleActionChange, adminAction, setAdminAction } =
		props;
	const classes = useStyles();
	const searchRef = useRef<HTMLInputElement>(null);
	const [searchMode, setSearchMode] = useState(false);
	const [fetchData, setFetchData] = useState(false);

	const {
		adminSearchRef,
		userRole,
		setUserRole,
		orderByField,
		getAllAdminUsers,
		searchUserName,
		setSearchUserName,
		selectedUserEmails,
		selectedButton,
		setSelectedButton,
		setPage,
		page
	} = useAdminContext();

	const adminActionMenu = [
		{ id: "menu", title: "Select Action", value: "" },
		{ id: "deleteMultipleUsers", title: "Delete User", value: "deleteUser" },
		{
			id: "changeMultipleUserRoles",
			title: "Change Role",
			value: "changeRole",
		},
		{ id: "downloadData", title: "Download Data", value: "downloadData" },
	];

	useEffect(() => {
		getAllAdminUsers("", selectedButton);
		setPage(0);
	}, [orderByField, userRole, selectedButton]);

	useEffect(() => {
			getAllAdminUsers(searchUserName?.trim(), selectedButton);
			setFetchData(false);
	}, [fetchData]);
	

	const handleMusicSearch = () => {
		if (searchUserName !== "") {
			setSearchMode(true);
			setFetchData(true);

			const inputValue = searchRef.current?.value;
			setSearchUserName(inputValue);
			setPage(0);
			
		}
	};

	const handleClick = () => {
		if (selectedButton === "active") {
			setPage(0);
			setSelectedButton("deleted");
			setUserRole("");
			setSearchUserName("");
			setSearchMode(false);
			setAdminAction("");
		} else {
			setPage(0);
			setSelectedButton("active");
			setUserRole("");
			setSearchUserName("");
			setSearchMode(false);
			setAdminAction("");
		}
	};

	const handleMusicReset = () => {
		setSearchMode(false);

		const inputValue = searchRef.current?.value;
		setSearchUserName(inputValue);
		setPage(0);
		setSearchUserName("");
		setFetchData(true);
	};

	const handleUserNameSearch = () => {};
	const handleSearchReset = () => {};
	const handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			handleMusicSearch();
		}
	};

	const filteredMenu =
		selectedButton === "deleted"
			? adminActionMenu.filter(
					(item) => item.id === "downloadData" || item.id === "menu"
			  )
			: adminActionMenu;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}
		>
			{selectedUserEmails.length > 0 ? (
				<Grid container>
					<Grid item md={2} lg={2} xl={2}>
						<Typography
							sx={{
								flex: "1 1 100%",
								fontSize: "18px",
								fontWeight: "500",
								color: "#000",
							}}
							color="inherit"
							variant="subtitle1"
							component="div"
						>
							{selectedUserEmails.length} selected
						</Typography>
					</Grid>
					<Grid item md={3} lg={3} xl={3}>
						<Select
							size="small"
							style={{ paddingLeft: "10px" }}
							displayEmpty
							inputProps={{ "aria-label": "Without label" }}
							className={classes.folderDropdown}
							id="demo-simple-select"
							value={adminAction}
							sx={{ width: "150px", height: "30px" }}
							onChange={(e) => handleActionChange(e.target.value)}
						>
							{filteredMenu.map((menu: any) => {
								return (
									<MenuItem key={menu.id} value={menu.value}>
										<span
											style={{
												fontSize: "1rem",
												fontWeight: "400",
												fontFamily: "Lexend",
											}}
										>
											{menu.title}
										</span>
									</MenuItem>
								);
							})}
						</Select>
					</Grid>
					<Grid item md={4} lg={4} xl={4}>
						<div
							className={classes.searchField}
							style={{ marginRight: "10px" }}
						>
							<FormControl fullWidth variant="outlined" size="small">
								<OutlinedInput
									id="outlined-adornment-password"
									type="text"
									value={searchUserName}
									onChange={(e) => setSearchUserName(e.target.value)}
									inputRef={searchRef}
									onKeyDown={handleKeyDown}
									endAdornment={
										<>
											{searchMode ? (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleMusicReset}
														edge="end"
													>
														<img src={CloseIcon} alt="Reset Search" />
													</IconButton>
												</InputAdornment>
											) : (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleMusicSearch}
														edge="end"
													>
														<img src={Search} alt="Search" />
													</IconButton>
												</InputAdornment>
											)}
										</>
									}
									placeholder="Search user name, email"
									autoComplete="off"
									ref={adminSearchRef}
									sx={{
										width: "273px",
										height: "30px",
									}}
								/>
							</FormControl>
						</div>
					</Grid>
					<Grid item md={3} lg={3} xl={3}>
						<Select
							size="small"
							style={{ paddingLeft: "10px" }}
							displayEmpty
							inputProps={{ "aria-label": "Without label" }}
							className={classes.folderDropdown}
							id="demo-simple-select"
							value={userRole}
							sx={{ width: "140px", height: "30px" }}
							onChange={(e) => setUserRole(e.target.value)}
						>
							{userRoleMenu.map((menu: any) => {
								return (
									<MenuItem value={menu.value}>
										<span
											style={{
												fontSize: "1rem",
												fontWeight: "400",
												fontFamily: "Lexend",
											}}
										>
											{menu.title}
										</span>
									</MenuItem>
								);
							})}
						</Select>
					</Grid>
				</Grid>
			) : (
				<Grid container>
					<Grid item md={6} lg={4.5} xl={4.5}>
						<div
							className={classes.searchField}
							style={{ marginRight: "10px" }}
						>
							<FormControl fullWidth variant="outlined" size="small">
								<OutlinedInput
									id="outlined-adornment-password"
									type="text"
									value={searchUserName}
									onChange={(e) => setSearchUserName(e.target.value)}
									inputRef={searchRef}
									onKeyDown={handleKeyDown}
									endAdornment={
										<>
											{searchMode ? (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleMusicReset}
														edge="end"
													>
														<img src={CloseIcon} alt="Reset Search" />
													</IconButton>
												</InputAdornment>
											) : (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleMusicSearch}
														edge="end"
													>
														<img src={Search} alt="Search" />
													</IconButton>
												</InputAdornment>
											)}
										</>
									}
									placeholder="Search user name, email"
									// onKeyDown={handleKeyPress}
									autoComplete="off"
									ref={adminSearchRef}
									sx={{
										width: "273px",
										height: "30px",
									}}
								/>
							</FormControl>
						</div>
					</Grid>
					<Grid item md={6} lg={4.5} xl={4.5}>
						<ButtonGroup
							sx={{
								display: " flex",
								justifyContent: "center",
								width: "300px",
								height: "31px",
								borderRadius: "17px",
								// border: "1px",
							}}
						>
							<Button
								variant={selectedButton === "active" ? "contained" : "outlined"}
								id="user"
								sx={{
									width: "50%",
									backgroundColor:
										selectedButton === "active" ? "#0273e6" : "#fff",
									color: selectedButton === "active" ? "#fff" : "#0273e6",
									"&:hover": {
										backgroundColor:
											selectedButton === "active" ? "#0273e6" : "#fff",
									},
									border: "1px solid #0273e6 !important",
								}}
								onClick={handleClick}
							>
								Active Users
							</Button>
							<Button
								variant={
									selectedButton === "deleted" ? "contained" : "outlined"
								}
								id="device"
								sx={{
									width: "50%",
									backgroundColor:
										selectedButton === "deleted" ? "#0273e6" : "#fff",
									color: selectedButton === "deleted" ? "#fff" : "#0273e6",
									"&:hover": {
										backgroundColor:
											selectedButton === "deleted" ? "#0273e6" : "#fff",
									},
									border: "1px solid #0273e6 !important",
								}}
								onClick={handleClick}
							>
								Deleted Users
							</Button>
						</ButtonGroup>
					</Grid>
					<Grid item md={6} lg={3} xl={3}>
						<Select
							size="small"
							style={{ paddingLeft: "10px" }}
							displayEmpty
							inputProps={{ "aria-label": "Without label" }}
							className={classes.folderDropdown}
							id="demo-simple-select"
							value={userRole}
							sx={{ width: "140px", height: "30px" }}
							onChange={(e) => setUserRole(e.target.value)}
						>
							{userRoleMenu.map((menu: any) => {
								return (
									<MenuItem key={menu.id} value={menu.value}>
										<span
											style={{
												fontSize: "1rem",
												fontWeight: "400",
												fontFamily: "Lexend",
											}}
										>
											{menu.title}
										</span>
									</MenuItem>
								);
							})}
						</Select>
					</Grid>
				</Grid>
			)}
		</Toolbar>
	);
}
export default function AdminUserActionTb() {
	const classes = useStyles();
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<keyof Data>("email");
	const [selected, setSelected] = React.useState<readonly number[]>([]);
	const [dense, setDense] = React.useState(true);
	const [adminAction, setAdminAction] = useState("");
	const [openDeleteMultipleUsersDialog, setOpenDeleteMultipleUsersDialog] =
		useState(false);
	const [openChangeRoleMultipleUsers, setOpenChangeRoleMultipleUsers] =
		useState(false);
	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus();
	const [selectedRoleType, setSelectedRoleType] = useState<any>("");
	const [fetchData, setFetchData] = useState(false);

	const {
		getAllAdminUsers,
		rowsData,
		hasMore,
		getNextUsers,
		selectedButton,
		loading,
		handleExport,
		selectedUserEmails,
		setSelectedUserEmails,
		selectedUser,
		setSelectedUser,
		setRowsData,
		setUserRole,
		setSelectedButton,
		searchUserName,
		setRowsPerPage,
		rowsPerPage,
		page,
		setPage
	} = useAdminContext();

	useEffect(() => {
		// getAllAdminUsers();

		return () => {
			setSelectedUserEmails([]);
			setSelectedUser([]);
			setRowsData([]);
			setUserRole("");
			setPage(0);
			setRowsPerPage(15);
			setSelectedButton("active");
		};
	}, []);

	const {
		data: multipleUsersDeleteData,
		loading: multipleUsersDeleteLoading,
		error: multipleUsersDeleteError,
		triggerFetch: triggerMultipleUsersDeleteFetch,
	} = useFetch();
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rowsData.map((n: any) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	// const handleChangePage = (event: unknown, newPage: number) => {
	// 	setPage(newPage);
	// };

	// const handleChangeRowsPerPage = (
	// 	event: React.ChangeEvent<HTMLInputElement>
	// ) => {
	// 	setRowsPerPage(parseInt(event.target.value, 10));
	// 	setPage(0);
	// };

	const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const isSelected = (email: string) =>
		selectedUserEmails.indexOf(email) !== -1;

	// Avoid a layout jump when reaching the last page with empty rowsData.
	// const emptyRows =
	// 	page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0;

	const rowCount = rowsData.length;

	const isHeaderCheckboxChecked =
	selectedUserEmails.length === Math.min(rowCount, 10) && selectedUserEmails.length > 0;
	const isHeaderCheckboxIndeterminate =
	selectedUserEmails.length > 0 && selectedUserEmails.length < Math.min(rowCount, 10);

	const handleHeaderCheckboxClick = () => {
		if (isHeaderCheckboxChecked) {
			// If header checkbox is already checked, deselect all rows
			setSelectedUserEmails([]);
			setSelectedUser([]);
		} else {
			// If header checkbox is not checked, select the first 10 rows or less if rowsData.length is less than 10
			const allUserEmails = rowsData.slice(0, 10).map((row: any) => row.email);
			setSelectedUserEmails(allUserEmails);
			setSelectedUser(rowsData.slice(0, 10));
		}
	};



	const handleCheckBoxClick = (event: any, row: any) => {
		const selectedIndex = selectedUserEmails.indexOf(row.email);
		let newSelectedEmails = [];
		let newSelectedUsers = [];

		if (selectedIndex === -1) {
			if (selectedUserEmails.length < 10) {
				newSelectedEmails = [...selectedUserEmails, row.email];
				newSelectedUsers = [...selectedUser, row];
			} else {
				setAlertMsg('You can only select up to 10 users.');
				setAlertStatus(false);
				setShowAlert(true);
				return;
			}
		} else {
			newSelectedEmails = selectedUserEmails.filter((email: any) => email !== row.email);
			newSelectedUsers = selectedUser.filter((user: any) => user.email !== row.email);
		}
		setSelectedUserEmails(newSelectedEmails);
		setSelectedUser(newSelectedUsers);
	};

	const handleActionChange = (value: any) => {
		if (value === "deleteUser") {
			setOpenDeleteMultipleUsersDialog(true);
		}
		if (value === "changeRole") {
			setOpenChangeRoleMultipleUsers(true);
		}
		if (value === "downloadData") {
			handleExport(selectedUser);
		}
		setAdminAction(value);
	};

	const handleCloseMultipleDeleteUsersDialog = () => {
		setOpenDeleteMultipleUsersDialog(false);
		setAdminAction("");
	};

	const handleCloseMultipleChangeRoleForUsersDialog = () => {
		setOpenChangeRoleMultipleUsers(false);
		setAdminAction("");
	};

	const handleActionMultipleUsers = (
		selectedUsers: any,
		action: string,
		adminRole: string = ""
	) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			fetchOnMount: false,
			body: JSON.stringify(selectedUsers),
		};
		triggerMultipleUsersDeleteFetch(
			`${process.env.REACT_APP_BASE_URL}/api/admin/action-on-users?action=${action}&adminRole=${adminRole}`,
			requestOptions,
			(message) => {
				updateAlertStatus(
					true,
					true,
					action === "updateRole"
						? "Selected users roles updated successfully"
						: "Selected users deleted successfully"
				);
				getAllAdminUsers();
				action === "updateRole"
					? handleCloseMultipleChangeRoleForUsersDialog()
					: handleCloseMultipleDeleteUsersDialog();
				setAdminAction("");
				setSelectedUserEmails([]);
				setSelectedUser([]);
			},
			(message) => {
				updateAlertStatus(true, false, "Internal server error..");
			}
		);
	};

	let content;

	if (
		(selectedButton === "deleted" || selectedButton === "active") &&
		loading
	) {
		content = (
			<TableRow>
				<TableCell
					colSpan={7}
					align="center"
					sx={{ fontSize: "16px !important" }}
				>
					<CircularProgress />
				</TableCell>
			</TableRow>
		);
	} else if (
		selectedButton === "deleted" &&
		!loading &&
		rowsData.length === 0
	) {
		content = (
			<TableRow>
				<TableCell
					colSpan={7}
					align="center"
					sx={{ fontSize: "16px !important" }}
				>
					No deleted users found
				</TableCell>
			</TableRow>
		);
	} else if (selectedButton === "active" && !loading && rowsData.length === 0) {
		content = (
			<TableRow>
				<TableCell
					colSpan={7}
					align="center"
					sx={{ fontSize: "16px !important" }}
				>
					No users found!
				</TableCell>
			</TableRow>
		);
	} else {
		content = rowsData.map((row: any, index: any) => {
			const isItemSelected = isSelected(row.email);
			const labelId = `enhanced-table-checkbox-${index}`;

			return (
				<AdminRow
					key={row.email}
					labelId={labelId}
					isItemSelected={isItemSelected}
					row={row}
					handleCheckBoxClick={handleCheckBoxClick}
				/>);
		});
	}
	useEffect(() => {
		if (fetchData) {
			getAllAdminUsers(searchUserName?.trim(),selectedButton, rowsPerPage);
			setFetchData(false);
		}
	}, [fetchData]);
	
	const paginatedRows = rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
		setFetchData(true);
	};
	
	
	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newRowsPerPage = parseInt(event.target.value, 10);
		setRowsPerPage(newRowsPerPage);
		setPage(0);
		setFetchData(true);
	};
	

	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					width: "100%",
					mb: 2,
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
				}}
			>
				<EnhancedTableToolbar
					numSelected={selectedUserEmails?.length}
					handleActionChange={handleActionChange}
					adminAction={adminAction}
					setAdminAction={setAdminAction}
				/>

				{rowsData.length === 0 && !hasMore ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							height: "70px",
							textAlign: "center",
						}}
					>
						<div
							style={{
								display: "table-cell",
								verticalAlign: "middle",
							}}
						>
							<Typography sx={{ fontSize: "16px !important" }}>
								No users present
							</Typography>
						</div>
					</div>
				) : (
					<TableContainer sx={{ width: "96%", margin: "0 auto", border: "1px solid #c2c2c3" }}>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size={dense ? "small" : "medium"}
						>
							<EnhancedTableHead
								numSelected={selectedUserEmails?.length}
								order={order}
								orderBy={orderBy}
								isHeadCkeckBoxChecked={isHeaderCheckboxChecked}
								isHeadCheckBoxIndeterminate={isHeaderCheckboxIndeterminate}
								onSelectAllClick={handleHeaderCheckboxClick}
								onRequestSort={handleRequestSort}
								rowCount={rowsData.length}
							/>
							<TableBody>{content}</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[15, 25, 50]}
							component="div"
							count={rowsData[0]?.allUserCount}
							page={page}
							onPageChange={handlePageChange}
							rowsPerPage={rowsPerPage} 
							onRowsPerPageChange={handleRowsPerPageChange}
						/>
					</TableContainer>
				)}
			</Paper>

			<ActionDialog
				open={openDeleteMultipleUsersDialog}
				handleClose={handleCloseMultipleDeleteUsersDialog}
				dialogWidth="420px"
				title="Delete Users"
				titleStyle={{
					color: "#5B5B65",
					fontSize: "1.5rem",
					fontWeight: "500",
				}}
				titleAlignment="center"
				actionButtons={
					<>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="submit-button"
							size="large"
							variant="contained"
							onClick={() =>
								handleActionMultipleUsers(
									selectedUserEmails,
									"delete"
								)
							}
						>
							Delete
						</Button>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleCloseMultipleDeleteUsersDialog}
						>
							Cancel
						</Button>
					</>
				}
			>
				<Typography
					variant="body1"
					component="p"
					className={classes.resetSubHeading}
					sx={{ mb: 3 }}
				>
					Are you sure you want to delete selected users?
				</Typography>
			</ActionDialog>

			<ActionDialog
				open={openChangeRoleMultipleUsers}
				handleClose={handleCloseMultipleChangeRoleForUsersDialog}
				dialogWidth="420px"
				title="Change Role"
				titleStyle={{
					color: "#5B5B65",
					fontSize: "1.5rem",
					fontWeight: "500",
				}}
				titleAlignment="center"
				actionButtons={
					<>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="submit-button"
							size="large"
							variant="contained"
							onClick={() =>
								handleActionMultipleUsers(
									selectedUserEmails,
									"updateRole",
									selectedRoleType
								)
							}
						>
							Change Role
						</Button>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleCloseMultipleChangeRoleForUsersDialog}
						>
							Cancel
						</Button>
					</>
				}
			>
				<FormHelperText sx={{ ml: 0 }}>
					<Typography variant="body4" color="customColor.label">
						New Role
					</Typography>
				</FormHelperText>

				<Select
					labelId="newRole"
					value={selectedRoleType}
					onChange={(event: any) => {
						setSelectedRoleType(event.target.value);
					}}
					sx={{
						backgroundColor: "customColor.gray1",
						color: "customColor.black",
						width: "100%",
						mb: 1,
					}}
				>
					{userRoleMenu.map((menu: any) => {
						return (
							<MenuItem value={menu.value}>
								<span
									style={{
										fontSize: "1rem",
										fontWeight: "400",
										fontFamily: "Lexend",
									}}
								>
									{menu.title}
								</span>
							</MenuItem>
						);
					})}
				</Select>
			</ActionDialog>
			{showAlert && (
				<Snackbar
					sx={{
						"&.MuiSnackbar-anchorOriginTopCenter": {
							top: "80px",
						},
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={showAlert}
					autoHideDuration={6000}
					onClose={() => setShowAlert(false)}
				>
					<Alert
						onClose={() => setShowAlert(false)}
						icon={
							alertStatus ? (
								<img src={SuccessIcon} alt="error-icon" />
							) : (
								<img
									src={ErrorIcon}
									alt="error-icon"
									style={{ marginRight: "10px" }}
								/>
							)
						}
						sx={{
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "6px",
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "black",
						}}
					>
						<AlertTitle
							sx={{
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "#000000",
								display: "flex",
								margin: "auto",
								padding: "4px",
								justifyContent: "center !important",
							}}
						>
							{alertMsg}
						</AlertTitle>
					</Alert>
				</Snackbar>
			)}
		</Box>
	);
}
