import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	Box,
	Button,
	FormControl,
	Grid,
	Card,
	CardHeader,
	CardContent,
	Typography,
} from "@mui/material";
import LeftPanel from "./LeftPanel";
import ErrorMark from "../images/danger.svg";
import RightAarrow from "../images/right arrow.svg";
import { useGradient2Styles } from "../theme";
import i18n from "../l10n/strings.json";

const AccountLocked = () => {
	const [cardContainerHeight, setCardContainerheight] = useState<number | undefined>(0);
	const gradient = useGradient2Styles();

	useEffect(() => {
		const element = document.getElementById("right-pannel-header");
		const rect = element?.getBoundingClientRect();
		setCardContainerheight(rect?.height);
	}, []);

	return (
		<Grid
			container={true}
			columns={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}
		>
			<Grid item={true} xs={6} sm={6} md={4} lg={4} xl={4}>
				<LeftPanel />
			</Grid>
			<Grid item={true} xs={6} sm={6} md={4} lg={4} xl={4} className="alignCenterTabLogin">
				<Grid container={true}
					className="column"
				>
					<Grid item
						className="right-align"
						id="right-pannel-header"
					>
						<Grid
							container={true}
							columns={{ xs: 12, sm: 12, md: 12 }}
							className="column right-align"
							rowSpacing={{ xs: 1, sm: 3, md: 5, xl: 10, xxl: 20 }}
						>
							<Grid item={true} xs={12} className="right-align">
							<Box display="flex" justifyContent="flex-end" mt={2}>
					<Typography
						mr={2}
						mt={1}
						sx={{
							fontFamily: "Lexend",
							fontWeight: "500",
							fontSize: "1rem",
							lineHeight: "20px",
							color: "#5D5D65",
						}}
					>
						{i18n.signUp.notMemberMsg}
					</Typography>
					<Button
						component={Link}
						to="/login"
						sx={{
							fontWeight: "900",
							fontSize: "1rem",
							marginRight: "5vw",
							backgroundColor: "#ffffff",
							borderRadius: "18px",
							color: "#0273E6",
							border: "1px solid #0273E6",
							height: "36px"
						}}
					>
						{i18n.login.title}
					</Button>
				</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item={true}
						xs={12}
						className="center-align"
					>
						<Card
							component="section"
							sx={{
								position: "relative",
								top: `calc(50% - ${cardContainerHeight}px)`,
								boxShadow: "none",
								paddingBottom: 0,
								marginTop: "90px",
								width: "80%"
							}}
						>
							<CardHeader className="center-align"
								title={
									<Typography variant="h1" sx={{color: "customColor.black", fontSize: "3.4rem"}}>{i18n.signUp.title1}</Typography>
								}
								style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "0" }}
							/>
							<CardContent className="center-align" sx={{ padding: 1 }}>
								<FormControl sx={{ width: "80%", padding: 1 }}>
									<Grid
										container={true}
										rowSpacing={{ xs: 2, sm: 3,  }}
									>
										<Grid item={true} xs={12} className="center-align"
											sx={{
												display: "inline-flex",
											}}
										>
											<Typography mr={1}>
												<img src={ErrorMark} alt="error-mark" height="20px" />
											</Typography>
											<Typography variant="h6"
												sx={{
													textAlign: "start",
													color: "error.main",
												}} >
												{i18n.login.accountLockedMsg}
											</Typography>
										</Grid>
										<Grid item={true} xs={12} className="center-align">
											<Typography variant="h6"
												sx={{ textAlign: "start", color: "#5B5B65" }}>
												{i18n.login.forgetPasswordHelperMsg}
											</Typography>
										</Grid>
										<Grid item={true} xs={12} style={{
											paddingLeft: 0
										}}>
											<Button
												className={`submit-button ${gradient.gradient2}`}
												sx={{borderRadius: "24px", width: "80%", marginLeft: "50px"}}
												endIcon={<img src={RightAarrow} alt="Right Arrow" />}
												component={Link}
												to="/forgotPassword"
												size="large"
												fullWidth
												variant="contained"
											>
												<Typography>{i18n.login.forgotPasswordButton}</Typography>
											</Button>
										</Grid>
									</Grid>
								</FormControl>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);

};
export default AccountLocked;
