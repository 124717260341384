import { memo, useEffect } from "react";
import { Pagination, PaginationItem, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useParticipantsContext from "../../hooks/useParticipantsContext/useParticipantsContext";
import MixerSlider from "../MixerSlider/MixerSlider";
import { usePagination } from "../GalleryView/Pagination/pagination";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useFullscreenModeContext } from "../../context/FullscreenModeContext";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		innerContainer: {
			background: "#093969",
			border: "1px solid #014D78",
			borderRadius: "12px",
		},
		pagination: {
			"& .MuiPaginationItem-root": {
				color: "white",
				background: "grey",
				borderRadius: "20px",
			},
			"& :hover": {
				"& .Mui-selected": {
					color: "black",
					background: "white",
				},
			},
			"& .MuiPaginationItem-previousNext": {
				background: "black",
			},
		},
	})
);

const Mixer = (props: any) => {
	const classes = useStyles();
	const { room } = useVideoContext();
	const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
	const { speakerViewParticipants } = useParticipantsContext();
	const { paginatedParticipants, setCurrentPage, currentPage, totalPages } =
		usePagination([room!.localParticipant, ...speakerViewParticipants]);

		useEffect(() => {
			const storedMap = JSON.parse(localStorage.getItem("userCheckedMap") ?? "{}");
			const participantIds = new Set(paginatedParticipants.map(p => p.identity));
			const filteredMap = Object.fromEntries(
				Object.entries(storedMap).filter(([userId]) => participantIds.has(userId))
			);
			localStorage.setItem("userCheckedMap", JSON.stringify(filteredMap));
		}, [paginatedParticipants]);
		
	return (
		<div
			style={{
				display: props.mixer ? "flex" : "none",
				flexDirection: "column",
				width: "100%",
				height:
					isFullscreenMode || isTabHidden
						? `calc(100% - 70px)`
						: `calc(100% - 140px)`,
				top: isFullscreenMode || isTabHidden ? "70px" : "140px",
				zIndex: "1000",
				left: 0,
				position: "fixed",
				backgroundColor: "#000000",
			}}
		>
			<div className="mixer-container">
				{paginatedParticipants.map((participant) => (
					<div className={classes.innerContainer} key={participant.sid}>
						<MixerSlider participant={participant} />
					</div>
					// <Grid container columns={{ xs: 4, sm: 8, md: 12 }}  className={classes.innerContainer} key={participant.sid}>
					// <MixerSlider participant={participant} />
					// </Grid> 
				))}
			</div>
			<div className="mixer-footer">
				<Typography
					style={{
						color: "white",
						position: "absolute",
						left: "2%",
						bottom: "1%",
					}}
				>
					{paginatedParticipants.length} Online Users
				</Typography>
				{totalPages > 1 && (
					<Pagination
						page={currentPage}
						count={totalPages}
						className={classes.pagination}
						variant="outlined"
						onChange={(_, value) => setCurrentPage(value)}
						renderItem={(item) => (
							<PaginationItem
								slots={{ previous: ArrowBack, next: ArrowForward }}
								{...item}
							/>
						)}
					/>
				)}
			</div>
		</div>
	);
};

export default memo(Mixer);
