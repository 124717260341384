import React, { useState, useEffect, useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";

interface SnackbarAlertProps {
	message: string;
	status: boolean;
}

const useSnackbarAlert = () => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(true);
	const handleClose = useRef<() => void>(() => {});

	useEffect(() => {
		let timerId: NodeJS.Timeout;

		if (showAlert) {
			timerId = setTimeout(() => {
				setShowAlert(false);
				handleClose.current();
			}, 6000);

			return () => {
				clearTimeout(timerId);
			};
		}
	}, [showAlert]);

	const handleAlertOpen = (message: string, status: boolean) => {
		setAlertMsg(message);
		setAlertStatus(status);
		setShowAlert(true);
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	handleClose.current = () => {
    setShowAlert(false);
  };

	const SnackbarAlert: React.FC = () => (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={showAlert}
			autoHideDuration={6000}
			TransitionComponent={React.Fragment}
      onClose={handleClose.current}
		>
			<Alert
      onClose={handleClose.current}
			icon={
					alertStatus ? (
						<img src={SuccessIcon} alt="success-icon" />
					) : (
						<img
							src={ErrorIcon}
							alt="error-icon"
							style={{ marginRight: "10px" }}
						/>
					)
				}
				sx={{
					backgroundColor: "#FFFFFF",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					borderRadius: "6px",
					fontSize: "1.125rem",
					fontWeight: "500",
					color: "black",
				}}
			>
				<AlertTitle
					sx={{
						fontSize: "1.125rem",
						fontWeight: "500",
						color: "#000000",
						display: "flex",
						margin: "auto",
						padding: "5px",
						justifyContent: "center !important",
					}}
				>
					{alertMsg}
				</AlertTitle>
			</Alert>
		</Snackbar>
	);

	return { SnackbarAlert, handleAlertOpen };
};

export default useSnackbarAlert;
