import { useEffect, useState } from "react";
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogContent,
	DialogTitle,
	FormHelperText,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CenterCircularProgress from "../CenterCircularProgress";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { useUser } from "../../context/UserContext";
import User from "../../services/user";

import RightArrow from "../../images/right arrow gray.svg";
import CloseIcon from "../../images/close.svg";
import Refresh from "../../images/refresh-2.svg";
import SettingsAlert from "../../pages/SettingsAlert";
import {useAlertStatus} from "../../context/AlertStatusContext";
import { Link, useNavigate } from "react-router-dom";

 // eslint-disable-next-line @typescript-eslint/no-unused-vars
 interface AnimalOption {
	label: string;
	value: string;
}

export const GenerateAvatarDialog = (props: any) => {
	const theme = useTheme();
	const [selectedAnimal, setSelectedAnimal] = useState<string | null>(null);
	const [instruments, setInstruments] = useState<any>([]);
	const [selectedInstrument, setSelectedInstrument] = useState<any>([]);
	const [selectedGenere, setSelectedGenere] = useState<{
		title: string;
	} | null>(null);
	const [generatedImageUrls, setGeneratedImageUrls] = useState<string[]>([]);
	const token = localStorage.getItem("sessionId");
	const [isEnabled, setIsEnabled] = useState(true);
	const [isEnabledSetAvatar, setIsEnabledSetAvatar] = useState(false);
	const [exceedLimit, setExceedLimit] = useState(false);
	const [loading, setLoading] = useState(false);
	const user = useUser();
	const [selectedAvtar, setSelectedAvtar] = useState<string>("");
	const [imgClickInd, setImgClickInd] = useState<number>(-1);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const navigate = useNavigate();

	const [saveLoading, setSaveLoading] = useState(false);
		const animalOptions = [
		"Armadillo",
		"Badger",
		"Bat",
		"Bear",
		"Cat",
		"Chinchilla",
		"Chipmunk",
		"Cow",
		"Deer",
		"Dog",
		"Elephant",
		"Fox",
		"Giraffe",
		"Goat",
		"Gorilla",
		"Hamster",
		"Hedgehog",
		"Horse",
		"Kangaroo",
		"Koala",
		"Lemur",
		"Lion",
		"Monkey",
		"Mouse",
		"Otter",
		"Panda",
		"Pig",
		"Platypus",
		"Rabbit",
		"Raccoon",
		"Rat",
		"Sloth",
		"Squirrel",
		"Tiger",
		"Wolverine",
		"Zebra ",
		"Bird",
		"Blue Jay",
		"Canary",
		"Chicken",
		"Duck",
		"Eagle",
		"Hummingbird",
		"Owl",
		"Parrot",
		"Peacock",
		"Penguin",
		"Rooster",
		"Robin",
		"Swan",
		"Toucan",
		"Allegator",
		"Chameleon",
		"Crocodile",
		"Frog",
		"Gecko",
		"Iguana",
		"Lizard",
		"Salamander",
		"Snake",
		"Toad",
		"Turtle",
		"Clownfish",
		"Crab",
		"Dolphin",
		"Fish",
		"Goldfish",
		"Jellyfish",
		"Manatee",
		"Octopus",
		"Seahorse",
		"Shark",
		"Starfish",
		"Walrus",
		"Ant",
		"Bee",
		"Butterfly",
		"Caterpillar",
		"Grasshopper",
		"Ladybug",
		"Spider ",
		"Dinosaur",
		"Dragon",
		"Griffin",
		"Phoenix",
		"Unicorn",
	];
	const genres = [
		{ title: "Americana" },
		{ title: "Avant-garde" },
		{ title: "Baroque" },
		{ title: "Big Band" },
		{ title: "Bluegrass" },
		{ title: "Blues" },
		{ title: "Bollywood" },
		{ title: "Broadway/Showtunes" },
		{ title: "Chamber-Music" },
		{ title: "Classic Rock" },
		{ title: "Classical" },
		{ title: "Contemporary Classical" },
		{ title: "Country" },
		{ title: "Disco" },
		{ title: "Drumline" },
		{ title: "EDM" },
		{ title: "Electronic/Dance" },
		{ title: "Folk" },
		{ title: "Funk" },
		{ title: "Gospel" },
		{ title: "Hip-hop/Rap" },
		{ title: "House" },
		{ title: "Indie/Alternative" },
		{ title: "Jazz" },
		{ title: "K-Pop" },
		{ title: "Latin" },
		{ title: "Metal" },
		{ title: "Minimalism" },
		{ title: "New Age" },
		{ title: "Opera" },
		{ title: "Orchestral" },
		{ title: "Pop" },
		{ title: "Punk" },
		{ title: "R&B" },
		{ title: "Ragtime" },
		{ title: "Reggae" },
		{ title: "Reggaeton" },
		{ title: "Religious" },
		{ title: "Rock" },
		{ title: "Romantic" },
		{ title: "Salsa" },
		{ title: "Ska" },
		{ title: "Soul" },
		{ title: "Soundtrack" },
		{ title: "Swing" },
		{ title: "Symphonic" },
		{ title: "Techno" },
		{ title: "Wind Ensemble" },
	];
	const {updateAlertStatus} = useAlertStatus()

	useEffect(() => {
		getInstrumentsList();
	}, []);

	const getInstrumentsList = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				const instruments: any = [];
				let added = false;
				// eslint-disable-next-line array-callback-return
				data.map((item: any, index:number) => {
						if (item.instrumentName.includes("Voice")) {
								if (!added) {
										instruments.push({id: index+1, instrumentName: 'Singer'});
										added = true;
								}
						} else {
								instruments.push({id: index+1, instrumentName: item.instrumentName});
						}
				});
				setInstruments(instruments);
			})
			.catch((err) => {
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const fetchPossibleChance = async () => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/check/available-chances`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const data = await res.json();
			return data; // Assuming data contains the count
		} catch (err) {
			setAlertMsg("Network Error! Please try again..");
			return 0; // or handle the error accordingly
		}
	};

	const generateAvatar = (count: number) => {
		const requestBody = {
			animalName: selectedAnimal,
			instrument: selectedInstrument?.instrumentName,
			genre: selectedGenere?.title,
			numResult: 1,
			userEmail: user?.email,
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/api/generate-avatar`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(requestBody),
		})
			.then((res) => {
				setLoading(false);
				if (res.status >= 400) {
						updateAlertStatus(true,false, 'Something Went Wrong Please Try Again');
				}
				return res.json();
			})
			.then((result) => {

				if (result[0].data[0].url !== "") {
					if(count===1){
						setExceedLimit(true);
					}
					setGeneratedImageUrls((prevUrls) => [
						...prevUrls,
						result[0].data[0].url,
					]);
				}
				setIsEnabled(true);
				setLoading(false);
			})
			.catch((err) => {
				setIsEnabled(true);
				setLoading(false);
			});
	};

	const handleGenerateAvatars = async () => {
		setIsEnabled(false);
		const count = await fetchPossibleChance();
		if (count > 0) {
			setLoading(true);
			generateAvatar(count);
		} else {
			setIsEnabled(false);
			setExceedLimit(true);
		}
	};

	const setProfileAvatar = () => {
		setSaveLoading(true);
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-profile-photourl`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					imageUrl: selectedAvtar,
					email: user.email,
				}),
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					User.fetchUserInfo(() => {});
					props.handleGenerateAvatarDialogClose(true,exceedLimit);
				}
			})
			.finally(() => {
				setSaveLoading(false);
			});
	};

	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={props.handleAlertClose}
			/>
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				maxWidth="md"
				fullWidth
				onClose={() => {}}
				open={true}
			>
				<SettingsAlert/>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<IconButton
							aria-label="close dialogbox"
							onClick={() => props.handleGenerateAvatarDialogClose(false, exceedLimit)}
							onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
								event.preventDefault()
							}
							edge="end"
							sx={{ marginLeft: "90%" }}
						>
							<img
								src={CloseIcon}
								alt="close"
								onClick={() => props.handleGenerateAvatarDialogClose(false, exceedLimit)}
							/>
						</IconButton>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							sx={{
								fontSize: "24px !important",
								fontWeight: "500",
								color: "#000",
								fontFamily: "Lexend",
							}}
						>
							Generate Avatar
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					{loading ? <CenterCircularProgress /> : <div></div>}

					<Grid item xs={12} sx={{ mt: "5px" }}>
						<Card sx={{ width: "600px", margin: "0 auto 300px" }}>
							<CardContent>
								<Box sx={{ padding: "25px 88px" }}>
									<FormHelperText sx={{ ml: 0 }}>
										<Typography variant="body4" color="customColor.label">
											Favourite Animal
										</Typography>
									</FormHelperText>
									<Autocomplete
										options={animalOptions.sort((a: any, b: any) =>
											a.localeCompare(b)
										)}
										getOptionLabel={(option) => option}
										value={selectedAnimal}
										onChange={(event, newValue) => {
											setSelectedAnimal(newValue);
										}}
										sx={{ mb: 3 }}
										renderInput={(params) => (
											<TextField
												{...params}
												sx={{
													input: {
														color: "customColor.black",
														fontSize: theme.typography.subtitle1.fontSize,
														fontWeight: theme.typography.subtitle1.fontWeight,
													},
													"&.MuiTextField-root": {
														backgroundColor: "customColor.gray1",
													},
												}}
											/>
										)}
									/>

									<FormHelperText sx={{ ml: 0 }}>
										<Typography variant="body4" color="customColor.label">
											Musical Instrument
										</Typography>
									</FormHelperText>
									<Autocomplete
										id="instruments"
										value={selectedInstrument}
										onChange={(event, newValue) => {
											setSelectedInstrument(newValue as any);
										}}
										options={instruments.sort((a: any, b: any) =>
											a.instrumentName.localeCompare(b.instrumentName)
										)}
										getOptionLabel={(option) => option.instrumentName || ""}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										sx={{ mb: 3 }}
										renderInput={(params) => (
											<TextField
												{...params}
												sx={{
													input: {
														color: "customColor.black",
														fontSize: theme.typography.subtitle1.fontSize,
														fontWeight: theme.typography.subtitle1.fontWeight,
													},
													"&.MuiTextField-root": {
														backgroundColor: "customColor.gray1",
													},
												}}
											/>
										)}
									/>

									<FormHelperText sx={{ ml: 0 }}>
										<Typography variant="body4" color="customColor.label">
											Favourite Genre
										</Typography>
									</FormHelperText>
									<Autocomplete
										id="Genres"
										value={selectedGenere}
										onChange={(event, newValue) => {
											setSelectedGenere(newValue as any);
										}}
										options={genres}
										getOptionLabel={(option) => option.title}
										isOptionEqualToValue={(option, value) =>
											option.title === value.title
										}
										sx={{ mb: 3 }}
										renderInput={(params) => (
											<TextField
												{...params}
												sx={{
													input: {
														color: "customColor.black",
														fontSize: theme.typography.subtitle1.fontSize,
														fontWeight: theme.typography.subtitle1.fontWeight,
													},
													"&.MuiTextField-root": {
														backgroundColor: "customColor.gray1",
													},
												}}
											/>
										)}
									/>

									<Button
										type="submit"
										sx={{
											height: "40px",
											color: isEnabled ? "#0273E6" : "#C2C2C3",
											fontSize: "1.125rem",
											fontWeight: "500",
											lineHeight: "22.5px",
											fontFamily: "Lexend",
											borderRadius: "24px",
											borderColor: "#0273E6",
											backgroundColor: "transparent",
											"&:hover": {
												backgroundColor: "transparent",
												borderColor: "#0273E6",
											},
											alignContent: "center",
										}}
										size="large"
										disabled={
											!isEnabled ||
											exceedLimit ||
											!(selectedAnimal && selectedInstrument && selectedGenere)||
											saveLoading
										}
										//endIcon={<img src={RightArrow} alt="Round Arrow" />}
										fullWidth
										variant="outlined"
										onClick={handleGenerateAvatars}
									>
										Generate Avatars
										{isEnabled ? (
											<img
												src={Refresh}
												alt="Round Arrow"
												style={{ marginLeft: "8px" }}
											/>
										) : (
											<img
												src={RightArrow}
												alt="Right Arrow"
												style={{ marginLeft: "8px" }}
											/>
										)}
									</Button>

									<Typography
										variant="body1"
										sx={{
											fontSize: "14px",
											fontWeight: "400",
											color: "#7A7985",
											mt: 1,
											textAlign: "center",
											mb:3
										}}
									>
									{generatedImageUrls.length > 0 && !exceedLimit  ? (<Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#7A7985"}}>Press Generate Avatar again for an additional option</Typography>) : ""}You can generate up to four avatars per day {exceedLimit ? (<Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#7A7985"}}>Come back tomorrow to generate more AI avatars</Typography>) : ""}

									</Typography>

									<Box sx={{ margin: "5px auto" }}>
										<Grid container spacing={2}>
											{generatedImageUrls.length > 0 ? (
												generatedImageUrls.map((imageUrl, index) => (
													<Grid item xs={6}>
														<img
															key={index}
															src={imageUrl}
															alt={`Generated Avatar ${index + 1}`}
															style={{
																width: "150px",
																height: "150px",
																borderRadius: "12px",
																border:
																	imgClickInd === index
																		? "4px solid #FF702A"
																		: "1px solid #7A7985",
																marginBottom: "10px",
																cursor: "pointer",
															}}
															onClick={() => {
																setImgClickInd(index);
																setSelectedAvtar(imageUrl);
																setIsEnabledSetAvatar(true);
															}}
														/>
													</Grid>
												))
											) : (
												<div style={{ margin: "45px" }}>
													{!exceedLimit ? (
														<Typography
															variant="body1"
															sx={{
																fontSize: "20px",
																fontWeight: "300",
																color: "#7A7985",
																lineHeight: "30.25px",
															}}
														>
															Avatar Images will be generated here...
														</Typography>
													) : (
														""
													)}
												</div>
											)}
										</Grid>
										
									</Box>
									<Grid container sx={{mb:2,textAlign:"center"}}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#7A7985"}}> 
                                    AI image results not guaranteed to be accurate, but almost guaranteed to be fun!
                                    </Typography>
                                        </Grid>
									<Button
										type="submit"
										sx={{
											height: "48px",
											fontSize: "1.125rem",
											fontWeight: "500",
											lineHeight: "22.5px",
											fontFamily: "Lexend",
											borderRadius: "24px",
										}}
										size="large"
										disabled={!isEnabledSetAvatar || saveLoading}
										endIcon={<img src={RightArrow} alt="Right Arrow" />}
										fullWidth
										variant="contained"
										onClick={setProfileAvatar}
									>
										{saveLoading ? "Saving.." : "Use as my Avatar"}
									</Button>
								</Box>
							</CardContent>
						</Card>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};
