import { createContext, useContext, useEffect, useState } from "react";
import React, { useRef } from "react";
import { format, utcToZonedTime } from 'date-fns-tz';

export const EventsFilterContext = createContext<any>(null);

export const EventsFilterProvider = ({ children }: any) => {
    const token = localStorage.getItem("sessionId");
    const [pageNumber, setPageNumber] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [displayDistance, setDisplayDistance] = useState(25);
    const [isNavigatedFromGlobalSearch, setIsNavigatedFromGlobalSearch] = useState(false);
    const [loading, setLoading] = useState(false);  
    const [totalEvents, setTotalEvents] = useState(0);
    const [totalGigs, setTotalGigs] = useState(0);
    const [viewMoreEvents, setViewMoreEvents] = useState(false);
    const [eventActiveMenu, setEventActiveMenu] = useState("allEvents");
    const [viewMoreGigs, setViewMoreGigs] = useState(false);
    const [gigActiveMenu, setGigActiveMenu] = useState("allGigs");


    const [eventsFilter, setEventsFilter] = useState<any>({
        title: "",
        genres: [],
        startDateTime: null,
        cityCountry: "",
        distanceFromCity: 50,
        instrumentArray: [],
        latitude: 0,
        longitude: 0,
    });

    const [gigsFilter, setGigsFilter] = useState<any>({
        title: "",
        startDateTime: null,
        cityCountry: "",
        distanceFromCity: 50,
        instrumentArray: [],
        genresArray: [],
        latitude: 0,
        longitude: 0,
    });

    const [isFilterActive, setIsFilterActive] = useState(false);

    const [eventsList, setEventsList] = useState<any>([]);
    const [gigList, setGigList] = useState<any>([]);

    const resetEventsFilter: any = () => {
        setEventsFilter({
            title: "",
            genres: [],
            startDateTime: null,
            cityCountry: "",
            distanceFromCity: 50,
            latitude: 0,
            longitude: 0,
        });
        setDisplayDistance(25);
    };

    const resetGigFilter: any = () => {
        setGigsFilter({
            title: "",
            startDateTime: null,
            cityCountry: "",
            distanceFromCity: 50,
            instrumentArray: [],
            latitude: 0,
            longitude: 0,
        });
    };

    useEffect(() => {
        if (
            eventsFilter?.title?.length > 0 ||
            eventsFilter?.genres?.length > 0 ||
            eventsFilter?.cityCountry?.length > 0 ||
            eventsFilter?.distanceFromCity !== 50 ||
            eventsFilter?.startDateTime !== null
        ) {
            setIsFilterActive(true);
        } else {
            setIsFilterActive(false);
        }
    }, [eventsFilter]);

    useEffect(() => {
        if (
            gigsFilter?.title?.length > 0 ||
            gigsFilter?.cityCountry?.length > 0 ||
            gigsFilter?.startDateTime !== null ||
            gigsFilter.distanceFromCity !== 50 ||
            gigsFilter?.instrumentArray?.length > 0
        ) {
            setIsFilterActive(true);
        } else {
            setIsFilterActive(false);
        }
    }, [gigsFilter]);


    const handleEventFilterReset = (searchOn: string, type: string) => {
        setEventsList([]);
        try {
            setLoading(true);
            setHasMore(true);
            setPageNumber(0);

            const requestBody = {
                title: eventsFilter.title,
                cityCountry: eventsFilter.cityCountry,
                duration: eventsFilter.startDateTime === 0 ? null : eventsFilter.startDateTime,
                distanceFromCity: eventsFilter.cityCountry.length > 0 ? eventsFilter.distanceFromCity : 0,
                genresArray: eventsFilter.genres,
                instrumentArray: eventsFilter.instrumentArray ? eventsFilter.instrumentArray : [],
                ...(type && { type }),
                latitude: eventsFilter.latitude,
                longitude: eventsFilter.longitude,
            };

            let query = "";

            query = searchOn === "global-search" ? "get-events-or-gigs/global-search?type=EVENT" : `get-events-or-gigs?type=EVENT&myLiked=&myPosted=&ops=${searchOn}`;
            
            fetch(
                `${process.env.REACT_APP_BASE_URL}/api/${query}&page=0&size=10`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            )
                .then((res) => {
                    if (res.status > 400) {
                        throw new Error("api_error");
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    setLoading(false);

                    if (data.length === 0) {
                        setEventsList([]);
                        setHasMore(false);
                    } else {
                        setEventsList(searchOn === "global-search" ? data.eventsDTOs : data);
                        setTotalEvents(data.total);
                        setPageNumber(1);
                    }
                })
                .catch((err) => {
                    setHasMore(false);
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (error) {
            // setLoading(false);
        }
    };

    const handleGigFilterReset = (searchOn: string, type: String) => {
        setGigList([]);
        try {
            setLoading(true);
            setHasMore(true);
            setPageNumber(0);

            const requestBody = {
                title: gigsFilter.title,
                cityCountry: gigsFilter.cityCountry,
                duration: gigsFilter.startDateTime === 0 ? null : gigsFilter.startDateTime,
                distanceFromCity: gigsFilter.cityCountry.length > 0 ? gigsFilter.distanceFromCity : 0,
                instrumentArray: gigsFilter.instrumentArray,
                genresArray: gigsFilter.genresArray ? gigsFilter.genresArray : [],
                ...(type && { type }),
                latitude: gigsFilter.latitude,
                longitude: gigsFilter.longitude,
            };
            let query = "";

            query = searchOn === "global-search" ? "get-events-or-gigs/global-search?type=GIG" : `get-events-or-gigs?type=GIG&myLiked=&myPosted=&ops=${searchOn}`;
            fetch(
                `${process.env.REACT_APP_BASE_URL}/api/${query}&page=0&size=10`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            )
                .then((res) => {
                    if (res.status > 400) {
                        throw new Error("api_error");
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    setLoading(false);

                    if (data.length === 0) {
                        setGigList([]);
                        setHasMore(false);
                    } else {
                        setGigList(searchOn === "global-search" ? data.eventsDTOs : data);
                        setPageNumber(1);
                        setTotalGigs(data.total);
                    }
                })
                .catch((err) => {
                    setHasMore(false);
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (error) {
            // setLoading(false);
        }
    };

    return (
        <EventsFilterContext.Provider
            value={{
                eventsFilter,
                setEventsFilter,
                isFilterActive,
                eventsList,
                gigList,
                setEventsList,
                setGigList,
                hasMore,
                pageNumber,
                setPageNumber,
                handleEventFilterReset,
                handleGigFilterReset,
                gigsFilter,
                setGigsFilter,
                resetEventsFilter,
                resetGigFilter,
                displayDistance,
                setDisplayDistance,
                isNavigatedFromGlobalSearch,
                setIsNavigatedFromGlobalSearch,
                loading,
                setLoading,
                totalEvents,
                totalGigs,
                viewMoreEvents,
                setViewMoreEvents,
                eventActiveMenu,
                setEventActiveMenu,
                viewMoreGigs,
                setViewMoreGigs,
                gigActiveMenu,
                setGigActiveMenu,
            }}
        >
            {children}
        </EventsFilterContext.Provider>
    );
};

// Custom hook to consume the context
export function useEventsFilter() {
    return useContext(EventsFilterContext);
}