import { createContext, useContext, useEffect, useState } from "react";
import React, { useRef } from "react";
import { format, utcToZonedTime } from "date-fns-tz";

export const ManageFilterContext = createContext<any>(null);

export const ManagementFilterProvider = ({ children }: any) => {
	const token = localStorage.getItem("sessionId");
	const [pageNumber, setPageNumber] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [itemPerPage, setItemPerPage] = useState(20);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [role, setRole] = useState("");

	const [userStatus1, setUserStatus1] = useState("");
	// const [addOns1, setAddOns1] = useState("");
	const [subscriptionStatus1, setSubscriptionStatus1] = useState("");
	const [searchUserName, setSearchUserName] = useState("");
	const [searchEmail, setSearchEmail] = useState("");
	const inputRef = useRef(null);
	const [orderByField, setOrderByField] = useState("");
	const [filterFor, setFilterFor] = useState("User");
	const [selectedButton, setSelectedButton] = useState("user");
	const [isDownloadComplete, setIsDownloadComplete] = useState<Boolean>(true);
	const [noDataToDownload, setNoDataToDownload] = useState<Boolean>(false);

	const [managementFilter, setManagementFilter] = useState<any>({
		isGlobalSearch: true,
		genres: [],
		cities: "",
		firstName: "",
		lastName: "",
		nickName: "",
		email: "",
		instrument: [],
		isOpenForCollaboration: true,
		acceptGigs: true,
		devicestatus: "",
		// lastConnected: null,
		softwareVersion: "",
		deviceVersion: "",
		deviceId: "",
		distanceFromCity: 50,
		latitude: 0,
		longitude: 0,
		joinDate: null,
		startDate: null,
		endDate: null,
	});

	const [userIdByAdmin, setUserIdByAdmin] = useState("");
	const [userProfileIdByAdmin, setUserProfileIdByAdmin] = useState("");
	const [selectedUserIds, setSelectedUserIds] = useState<any>([]);
	const [isDisabledOptions, setIsDisabledOptions] = useState(false);

	interface Data {
		userProfileId: any;
		userName: string;
		email: string;
		deviceId: string;
		deviceStatus: string;
		planExpiry: string;
		subscription: string;
		userStatus: string;
		actions: string;
	}

	const [isFilterActive, setIsFilterActive] = useState(false);

	const [rowData, setRowData] = useState<Data[]>([]);

	const [deviceId, setDeviceId] = useState("");

	const setChildData = (newData: any) => {
		setDeviceId(newData);
	};

	const resetManagementFilter: any = () => {
		setSubscriptionStatus1("");
		setUserStatus1("");
		setManagementFilter({
			isGlobalSearch: true,
			genres: [],
			cities: "",
			firstName: "",
			lastName: "",
			email: "",
			nickName: "",
			deviceId: "",
			instrument: [],
			isOpenForCollaboration: false,
			acceptGigs: false,
			distanceFromCity: 50,
			latitude: 0,
			longitude: 0,
			devicestatus: "",
			// lastConnected: null,
			startDate: null,
			endDate: null,
			softwareVersion: "",
			deviceVersion: "",
			joinDate: null,
		});
	};

	// useEffect(() => {
	// 	if (
	// 		managementFilter.genres?.length > 0 ||
	// 		managementFilter.cities?.length > 0 ||
	// 		managementFilter.instrument?.length > 0 ||
	// 		managementFilter.firstName?.length > 0 ||
	// 		managementFilter.lastName?.length > 0 ||
	// 		managementFilter.nickName?.length > 0 ||
	// 		managementFilter.joinDate !== null ||
	// 		managementFilter.deviceId?.length > 0 ||
	// 		managementFilter.deviceStatus !== null ||
	// 		managementFilter.lastConnected !== null ||
	// 		managementFilter.softwareVersion !== null ||
	// 		managementFilter.deviceVersion !== null
	// 	) {
	// 		setIsFilterActive(true);
	// 	} else {
	// 		setIsFilterActive(false);
	// 	}
	// }, [managementFilter]);

	const formatUserStatus = (userStatus: string) => {
		switch (userStatus) {
			case "INACTIVE":
				return "Inactive";
			case "REACTIVATE":
				return "Reactivated";
			case "TERMINATE":
				return "Terminated";
			case "BAN":
				return "Banned";
			case "SUSPEND":
				return "Suspended";
			default:
				return "Active";
		}
	};

	const getAllUsers1 = (userObj: any) => {
		try {
			let nameVariable: string;
			let emailVariable: string;
			if (!searchUserName) {
				nameVariable = managementFilter.firstName;
				emailVariable = managementFilter.email || searchEmail;
			} else {
				nameVariable = searchUserName;
				emailVariable = searchEmail;
			}
			const nextPage = pageNumber + 1;
			const requestBody = {
				elasticSearchDTO: {
					userId: null,
					globalSearch: managementFilter.isGlobalSearch,
					firstName: nameVariable,
					lastName: nameVariable,
					email: emailVariable,
					nickname: nameVariable,
					location: managementFilter.cities,
					genres: managementFilter.genres,
					instruments: managementFilter.instrument,
					openForCollab: null,
					openForGigs: null,
					joinDate: managementFilter.joinDate,
					distanceFromCity: managementFilter.distanceFromCity,
					latitude: managementFilter.latitude,
					longitude: managementFilter.longitude,
				},
				userDeviceDTO: {
					deviceId: deviceId.toUpperCase(),
					// lastOnline: managementFilter.lastConnected,
					pingStatus: managementFilter.devicestatus,
					version: managementFilter.softwareVersion,
					deviceVersion: managementFilter.deviceVersion,
					startDate: managementFilter.startDate,
					endDate: managementFilter.endDate,
				},
				filterFor: filterFor,
				userAccountStatus: userStatus1 === "" ? null : userStatus1,
				subscriptionStatus:
					subscriptionStatus1 === "" ? null : subscriptionStatus1,
				orderBy: orderByField,
			};
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user/user-management/filter-search?page=${page}&size=${rowsPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestBody),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {
						const updatedRows = data.map((item: any) => ({
							user: item,
							userId: item.id,
							userProfileId: item.userProfileId,
							nickName: item.nickname,
							userName: `${item.firstName} ${item.lastName}`,
							city: item.city,
							allUserCount: item.allUserCount,
							// joinDate: (() => {
							// 	const parsedDateUTC = utcToZonedTime(
							// 		new Date(item.createdDate),
							// 		"UTC"
							// 	);
							// 	return format(parsedDateUTC, "yyyy-MM-dd");
							// })(),
							// joinDate: item.createdDate.substring(0, 10),
							subscription: (() => {
								const subStr = item.subscriptionInformationDTOs.reduce(
									(accumulator: any, subscription: any) => {
										if (subscription.type === "Software") {
											if (subscription.name.toLowerCase() === "standard") {
												accumulator.push("Standard");
											} else if (
												subscription.name.toLowerCase() === "premium"
											) {
												accumulator.push("Premium");
											}
										}
										return accumulator;
									},
									[]
								);

								if (subStr.length === 0) {
									return "Free";
								} else if (subStr.length === 1) {
									return subStr[0];
								} else {
									return subStr.join(", ");
								}
							})(),
							expiryDate: (() => {
								const subscriberSubscription =
									item.subscriptionInformationDTOs.find(
										(subscription: any) =>
											subscription.name.toLowerCase() === "standard" ||
											subscription.name.toLowerCase() === "premium"
									);
								if (subscriberSubscription) {
									return format(
										new Date(subscriberSubscription.endDate),
										"yyyy-MM-dd"
									);
								} else {
									return "-";
								}
							})(),
							email: item.email,
							userStatus: formatUserStatus(item.userAccountStatus),
							deviceId: (() => {
								if (item.deviceId !== null || item.deviceId !== "") {
									return item.deviceId;
								}
								return "-";
							})(),
							deviceStatus: (() => {
								if (
									item.pingStatus === null ||
									item.pingStatus === "OFFLINE" ||
									item.pingStatus === undefined ||
									item.pingStatus === ""
								) {
									return "Offline";
								} else {
									return "Online";
								}
							})(),
							actions: "Edit",
						}));
						setRowData((prevRowData) => {
							const existingIds = new Set(
								prevRowData.map((row) => row.userProfileId)
							);

							const newUniqueRows = updatedRows.filter(
								(row: any) => !existingIds.has(row.userProfileId)
							);

							return [...prevRowData, ...newUniqueRows];
						});
						setPageNumber(nextPage);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					// setLoading(false);
				});
		} catch (error) {
			// setLoading(false);
		}
	};

	const handleFilterReset = async (itemsPerPage = 20, root: string = "") => {
		setRowData([]);
		try {
			let nameVariable: string;
			let emailVariable: string;
			if (!searchUserName) {
				nameVariable = managementFilter.firstName;
				emailVariable = managementFilter.email || searchEmail;
			} else {
				nameVariable = searchUserName;
				emailVariable = searchEmail;
			}
			setHasMore(true);
			setPageNumber(0);
			setPage(0);
			const firstName = searchUserName || "";
			const lastName = searchUserName || "";
			const nickName = searchUserName || "";
			const deviceId = searchUserName || "";

			const requestBody = {
				elasticSearchDTO: {
					userId: null,
					globalSearch: managementFilter.isGlobalSearch,
					firstName: nameVariable,
					lastName: nameVariable,
					nickname: nameVariable,
					email: emailVariable,
					location: managementFilter.cities,
					genres: managementFilter.genres,
					instruments: managementFilter.instrument,
					openForCollab: null,
					openForGigs: null,
					joinDate: managementFilter.joinDate,
					distanceFromCity: managementFilter.distanceFromCity,
					latitude: managementFilter.latitude,
					longitude: managementFilter.longitude,
				},
				userDeviceDTO: {
					deviceId: deviceId.toUpperCase(),
					// lastOnline: managementFilter.lastConnected,
					pingStatus: managementFilter.devicestatus,
					version: managementFilter.softwareVersion,
					deviceVersion: managementFilter.deviceVersion,
					startDate: managementFilter.startDate,
					endDate: managementFilter.endDate,
				},
				filterFor:
					root === "reset"
						? "User"
						: selectedButton === "user"
						? "User"
						: "Device",
				userAccountStatus: userStatus1 === "" ? null : userStatus1,
				subscriptionStatus:
					subscriptionStatus1 === "" ? null : subscriptionStatus1,
				orderBy: orderByField,
			};
			const requestBody1 = {
				elasticSearchDTO: {
					userId: null,
					globalSearch: true,
					firstName: "",
					lastName: "",
					nickname: "",
					email: "",
					location: "",
					genres: [],
					instruments: [],
					openForCollab: null,
					openForGigs: null,
					joinDate: null,
					distanceFromCity: managementFilter.distanceFromCit,
					latitude: managementFilter.latitude,
					longitude: managementFilter.longitude,
				},
				userDeviceDTO: {
					deviceId: deviceId.toUpperCase(),
					// lastOnline: "",
					pingStatus: "",
					version: "",
					deviceVersion: "",
					startDate: "",
					endDate: "",
				},
				filterFor:
					root === "reset"
						? "User"
						: selectedButton === "user"
						? "User"
						: "Device",
				userAccountStatus: userStatus1 === "" ? null : userStatus1,
				subscriptionStatus:
					subscriptionStatus1 === "" ? null : subscriptionStatus1,
				orderBy: "",
			};

			if (selectedButton === "user") {
				setFilterFor("User");
			} else {
				setFilterFor("Device");
			}
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user/user-management/filter-search?page=0&size=${rowsPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body:
						root === "reset"
							? JSON.stringify(requestBody1)
							: JSON.stringify(requestBody),
				}
			);

			if (response.status > 400) {
				throw new Error("api_error");
			}
			const data = await response.json();

			if (data.length === 0) {
				setHasMore(false);
				setRowData([]);
			} else {
				setNoDataToDownload(false);
				const updatedRows = data.map((item: any) => ({
					user: item,
					userId: item.id,
					userProfileId: item.userProfileId,
					userName: `${item.firstName} ${item.lastName}`,
					nickName: item.nickname,
					city: item.city,
					allUserCount: item.allUserCount,
					// joinDate: (() => {
					//  const parsedDateUTC = utcToZonedTime(
					//      new Date(item.createdDate),
					//      "UTC"
					//  );
					//  return format(parsedDateUTC, "yyyy-MM-dd");
					// })(),
					subscription: (() => {
						const subStr = item.subscriptionInformationDTOs.reduce(
							(accumulator: any, subscription: any) => {
								if (subscription.type === "Software") {
									if (subscription.name.toLowerCase() === "standard") {
										accumulator.push("Standard");
									} else if (subscription.name.toLowerCase() === "premium") {
										accumulator.push("Premium");
									}
								}
								return accumulator;
							},
							[]
						);

						if (subStr.length === 0) {
							return "Free";
						} else if (subStr.length === 1) {
							return subStr[0];
						} else {
							return subStr.join(", ");
						}
					})(),
					expiryDate: (() => {
						const subscriberSubscription =
							item.subscriptionInformationDTOs.find(
								(subscription: any) =>
									subscription.name.toLowerCase() === "standard" ||
									subscription.name.toLowerCase() === "premium"
							);
						if (subscriberSubscription) {
							return format(
								new Date(subscriberSubscription.endDate),
								"yyyy-MM-dd"
							);
						} else {
							return "-";
						}
					})(),
					email: item.email,
					userStatus: formatUserStatus(item.userAccountStatus),
					deviceId: (() => {
						if (item.deviceId !== null || item.deviceId !== "") {
							return item.deviceId;
						} else {
							return "-";
						}
					})(),
					deviceStatus: (() => {
						if (
							item.pingStatus === null ||
							item.pingStatus === "OFFLINE" ||
							item.pingStatus === undefined ||
							item.pingStatus === ""
						) {
							return "Offline";
						} else {
							return "Online";
						}
					})(),
					actions: "Edit",
				}));
				setRowData(updatedRows);
			}
		} catch (error) {
			setHasMore(false);
			setRowData([]);
			setNoDataToDownload(true);
			return [];
		}
	};

	const downloadAllData = async (itemsPerPage = 20, root: string = "") => {
		setIsDownloadComplete(false);
		setNoDataToDownload(false);
		try {
			let nameVariable: string;
			if (
				searchUserName === null ||
				searchUserName === "" ||
				searchUserName === undefined
			) {
				nameVariable = managementFilter.firstName;
			} else {
				nameVariable = searchUserName;
			}

			const deviceId = searchUserName || "";

			const requestBody = {
				elasticSearchDTO: {
					userId: null,
					globalSearch: managementFilter.isGlobalSearch,
					firstName: nameVariable,
					lastName: nameVariable,
					nickname: nameVariable,
					location: managementFilter.cities,
					genres: managementFilter.genres,
					instruments: managementFilter.instrument,
					openForCollab: isFilterActive
						? managementFilter.isOpenForCollaboration
						: null,
					openForGigs: isFilterActive ? managementFilter.acceptGigs : null,
					joinDate: managementFilter.joinDate,
					distanceFromCity: managementFilter.distanceFromCity,
					latitude: managementFilter.latitude,
					longitude: managementFilter.longitude,
				},
				userDeviceDTO: {
					deviceId: deviceId.toUpperCase(),
					pingStatus: managementFilter.devicestatus,
					version: managementFilter.softwareVersion,
					deviceVersion: managementFilter.deviceVersion,
					startDate: managementFilter.startDate,
					endDate: managementFilter.endDate,
				},
				filterFor:
					root === "reset"
						? "User"
						: selectedButton === "user"
						? "User"
						: "Device",
				userAccountStatus: userStatus1 === "" ? null : userStatus1,
				subscriptionStatus:
					subscriptionStatus1 === "" ? null : subscriptionStatus1,
				orderBy: orderByField,
			};
			const requestBody1 = {
				elasticSearchDTO: {
					userId: null,
					globalSearch: true,
					firstName: "",
					lastName: "",
					nickname: "",
					location: "",
					genres: [],
					instruments: [],
					openForCollab: null,
					openForGigs: null,
					joinDate: null,
					distanceFromCity: managementFilter.distanceFromCit,
					latitude: managementFilter.latitude,
					longitude: managementFilter.longitude,
				},
				userDeviceDTO: {
					deviceId: deviceId.toUpperCase(),
					pingStatus: "",
					version: "",
					deviceVersion: "",
					startDate: "",
					endDate: "",
				},
				filterFor:
					root === "reset"
						? "User"
						: selectedButton === "user"
						? "User"
						: "Device",
				userAccountStatus: userStatus1 === "" ? null : userStatus1,
				subscriptionStatus:
					subscriptionStatus1 === "" ? null : subscriptionStatus1,
				orderBy: "",
			};

			if (selectedButton === "user") {
				setFilterFor("User");
			} else {
				setFilterFor("Device");
			}
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user/user-management/filter-search?page=0&size=${itemsPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body:
						root === "reset"
							? JSON.stringify(requestBody1)
							: JSON.stringify(requestBody),
				}
			);

			if (response.status > 400) {
				throw new Error("api_error");
			}
			const data = await response.json();

			if (data.length === 0) {
				setRowData([]);
				setNoDataToDownload(true);
				setIsDownloadComplete(true);
				return;
			} else {
				setNoDataToDownload(false);
				const updatedRows = data.map((item: any) => ({
					user: item,
					userId: item.id,
					userProfileId: item.userProfileId,
					userName: `${item.firstName} ${item.lastName}`,
					nickName: item.nickname,
					city: item.city,
					allUserCount: item.allUserCount,

					subscription: (() => {
						const subStr = item.subscriptionInformationDTOs.reduce(
							(accumulator: any, subscription: any) => {
								if (subscription.type === "Software") {
									if (subscription.name.toLowerCase() === "standard") {
										accumulator.push("Standard");
									} else if (subscription.name.toLowerCase() === "premium") {
										accumulator.push("Premium");
									}
								}
								return accumulator;
							},
							[]
						);

						if (subStr.length === 0) {
							return "Free";
						} else if (subStr.length === 1) {
							return subStr[0];
						} else {
							return subStr.join(", ");
						}
					})(),
					expiryDate: (() => {
						const subscriberSubscription =
							item.subscriptionInformationDTOs.find(
								(subscription: any) =>
									subscription.name.toLowerCase() === "standard" ||
									subscription.name.toLowerCase() === "premium"
							);
						if (subscriberSubscription) {
							return format(
								new Date(subscriberSubscription.endDate),
								"yyyy-MM-dd"
							);
						} else {
							return "-";
						}
					})(),
					email: item.email,
					userStatus: formatUserStatus(item.userAccountStatus),
					deviceId: (() => {
						if (item.deviceId !== null || item.deviceId !== "") {
							return item.deviceId;
						} else {
							return "-";
						}
					})(),
					deviceStatus: (() => {
						if (
							item.pingStatus === null ||
							item.pingStatus === "OFFLINE" ||
							item.pingStatus === undefined ||
							item.pingStatus === ""
						) {
							return "Offline";
						} else {
							return "Online";
						}
					})(),
					actions: "Edit",
				}));
				if (updatedRows && updatedRows.length > 0) {
					handleExport(updatedRows);
				}
			}
		} catch (error) {
			setRowData([]);
			setNoDataToDownload(true);
			setIsDownloadComplete(true); 
			return [];
		}
	};

	const convertToCSV = (data: any) => {
		if (!data?.length) return "";

		// Define the new headers
		const headers = [
			"Name",
			"Email",
			"DeviceId",
			"DeviceStatus",
			"PlanExpiry",
			"SubscriptionStatus",
			"UserStatus",
		];

		// Sanitize and prevent CSV injection
		const sanitize = (value: string | number) => {
			if (typeof value === "string" && /^[=+\-@]/.test(value)) {
				return ` ${value}`; 
			}
			return value;
		};

		// Convert data to only include the necessary fields
		const modifiedData = data.map(
			({
				userName,
				email,
				deviceId,
				deviceStatus,
				expiryDate,
				subscription,
				userStatus,
			}: any) => ({
				Name: sanitize(userName),
				Email: sanitize(email),
				DeviceId: sanitize(deviceId),
				DeviceStatus: sanitize(deviceStatus),
				PlanExpiry: sanitize(expiryDate),
				SubscriptionStatus: sanitize(subscription),
				UserStatus: sanitize(userStatus),
			})
		);

		// Generate CSV rows
		const csvRows = [headers.join(",")]; // Add the headers as the first row
		for (const row of modifiedData) {
			const values = headers.map((header) => {
				const escaped = ("" + row[header]).replace(/"/g, '\\"');
				return `"${escaped}"`;
			});
			csvRows.push(values.join(","));
		}

		return csvRows.join("\n");
	};

	const handleExport = (selectedRows: any) => {
		if (selectedRows?.length > 0) {
			const csvData = convertToCSV(selectedRows);
			const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
			const url = URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "data.csv");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			setIsDownloadComplete(true);
			URL.revokeObjectURL(url);
		} else {
			setNoDataToDownload(true);
			return;
		}
	};

	return (
		<ManageFilterContext.Provider
			value={{
				managementFilter,
				setManagementFilter,
				isFilterActive,
				resetManagementFilter,
				rowData,
				setRowData,
				getAllUsers1,
				hasMore,
				deviceId,
				setChildData,
				setPageNumber,
				role,
				setRole,
				userIdByAdmin,
				setUserIdByAdmin,
				userProfileIdByAdmin,
				setUserProfileIdByAdmin,

				subscriptionStatus1,
				userStatus1,
				searchUserName,
				setSearchUserName,
				setUserStatus1,
				setSubscriptionStatus1,
				handleFilterReset,
				downloadAllData,
				inputRef,
				setSelectedUserIds,
				selectedUserIds,
				orderByField,
				setOrderByField,
				setIsDisabledOptions,
				isDisabledOptions,
				setFilterFor,
				handleExport,
				isDownloadComplete,
				noDataToDownload,
				selectedButton,
				setSelectedButton,
				setIsFilterActive,
				rowsPerPage,
				setRowsPerPage,
				page,
				setPage,
			}}
		>
			{children}
		</ManageFilterContext.Provider>
	);
};

// Custom hook to consume the context
export function useManageFilter() {
	return useContext(ManageFilterContext);
}
