import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

// Define the type for user data
type UserData = {
    Code: string;
    Password: string;
    Repassword: string;
    FirstName: string;
    LastName: string;
    Location: string;
};

// Define the type for the context
type SignUpContextType = {
    userData: UserData;
    setUserData: Dispatch<SetStateAction<UserData>>;
};

// Create a context with the specified type
const SignUpContext = createContext<SignUpContextType | undefined>(undefined);

// Create a custom hook to use the context
export function useSignUp() {
    const context = useContext(SignUpContext);

    if (!context) {
        throw new Error('useSignUp must be used within a SignUpProvider');
    }

    return context;
}

// Create a provider component to wrap your application
export const SignUpProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // Define the initial state for user data
    const initialUserData: UserData = {
        Code: '',
        Password: '',
        Repassword: '',
        FirstName: '',
        LastName: '',
        Location: '',

    };

    // Use the state hook to manage user data
    const [userData, setUserData] = useState<UserData>(initialUserData);

    // Value object for the context provider
    const contextValue: SignUpContextType = {
        userData,
        setUserData,
    };

    // Return the provider with its value and children
    return (
        <SignUpContext.Provider value={contextValue}>
            {children}
        </SignUpContext.Provider>
    );
};
