import { useState, useEffect } from "react";
import { Outlet, useParams, useNavigate, useSearchParams, useLocation, Link } from "react-router-dom";
import {
    Box,
    Typography,
    Divider,
    Grid,
    Button,
    FormHelperText,
    TextField,
    CardMedia,
    Collapse,
    IconButton,
    CardActionArea,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions
} from "@mui/material";
import Warning from '../../images/warning-2.svg';

const BlockProfile = () => {
    return (
        <Card sx={{ minWidth: 300, flexGrow: 1, height: '189px', boxShadow: '0px 1px 14px 0px rgba(217, 231, 255, 0.77)', mt: 1 }}>

            <CardContent sx={{ alignItems: 'center', gap: 1, mt: '21px' }}>

                <Grid container sx={{ alignContent: 'center' }}>
                    {/* <Grid item xs={12}> */}
                    <Grid item xs={5} sx={{ textAlign: 'right' }}>
                        <img src={Warning} alt='Warning' />
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="h4" sx={{ fontWeight: 500, color: '#FF702A', pt: 5, fontSize: '24px' }}>
                            This profile does not exist
                        </Typography>
                    </Grid>

                    {/* </Grid> */}
                </Grid>

            </CardContent>

        </Card>
    )
}

export default BlockProfile;