import {
	Box,
	Button,
	Card,
	CardContent,
	Typography,
	Select,
	MenuItem,
	CircularProgress,
	FormControl,
	TextField,
	InputAdornment,
	IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchIcon from "@mui/icons-material/Search";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Hourglass } from "react-loader-spinner";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);

const ActivityLogsAdmin = () => {
	const location = useLocation();
	const [activeButton, setActiveButton] = useState("");
	const [response, setResponse] = useState<any>();
	const [exportFormat, setExportFormat] = useState("pdf");
	const { adminEmail, setAdminEmail } = useAdminContext();
	const [alertMessage, setAlertMessage] = useState("");
	const [hasMore, setHasMore] = useState(true);
	const [status, setStatus] = useState<String>("");
	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(0);
	const [itemPerPage, setItemPerPage] = useState(40);
	const [apiResponse, setApiResponse] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [loading, setLoading] = useState(false);
	const [isDownloadComplete, setIsDownloadComplete] = useState<boolean>(true);

	useEffect(() => {
		fetchActivityTypes();
	}, []);

	useEffect(() => {
		setStatus("");
	}, [activeButton, location.pathname]);

	const handleMenuOpen = () => {
		fetchActivityTypes();
	};
	const { state } = location;
	const {
		userId,
		userProfileId,
		email,
		user: userDetails,
		userName,
	} = state || {};

	const fetchActivityTypes = async () => {
		const token = localStorage.getItem("sessionId");
		if (!token) {
			console.error("Token not found.");
			return;
		}
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}` +
					"/api/activityLogs/admin-activity-types",
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (!response.ok) {
				throw new Error("Failed to fetch data");
			}
			const data = await response.json();
			setApiResponse(data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		if (searchQuery === "") {
			handleClick(activeButton);
		}
	}, [searchQuery]);

	const handleSearch = async () => {
		const token = localStorage.getItem("sessionId");
		let bodyData: { email?: string | number } = { email: adminEmail };

		let endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=3&action=${status}&searchText=${searchQuery?.trim()}&page=0&size=20`;
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}${endpoint}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(bodyData),
				}
			);
			if (!response.ok) {
				throw new Error("Failed to fetch data");
			}
			const data = await response.json();

			setResponse(data);

			if (!data || data.length === 0) {
				setAlertMessage("No activity log found for the selected period.");
				setHasMore(false);
			} else {
				if (pageNumber === 0) {
					setResponse(data);
				} else {
					setResponse((prevData: any) => [...prevData, ...data]);
				}

				setAlertMessage("");
			}
		} catch (error) {
			console.error(`Error fetching data:`, error);
		}
	};

	useEffect(() => {
		handleSearch();
	}, [status]);

	useEffect(() => {
		if (location.pathname === "/home/admin/settings/dataexport/dataexport") {
			console.log("Received state values:", {
				email,
				userId,
				userProfileId,
				userName,
			});
		} else {
			handleClick("3 Months");
		}
	}, [location.pathname]);

	const convertToCSV = (data: any) => {
		const csvRows = data.map((row: any) => {
			const csvRow = row
				.map((cell: any) => `"${cell.replace(/"/g, '""')}"`)
				.join(",");
			return csvRow;
		});
		return csvRows.join("\n");
	};

	const prepareDataForExport = (data: any[]) => {
		if (!data || !Array.isArray(data)) {
			return [];
		}

		return data.map((item) => [item.date || "", item.action || ""]);
	};

	const handleExport = async () => {
		setIsDownloadComplete(false);
		try {
			const result = await handleClick(activeButton, 2147483647);

			if (!result || !Array.isArray(result) || result.length === 0) {
				console.error("No data to export.");
				return;
			}

			if (exportFormat === "pdf") {
				const doc = new jsPDF();
				doc.setFontSize(12);
				doc.text("Admin Actions", 10, 10);

				const formattedData = prepareDataForExport(result);
				const rowHeight = 10; 
				let yPos = 20; 

				formattedData.forEach((row, index) => {
					if (yPos + rowHeight > doc.internal.pageSize.height) {
						doc.addPage();
						yPos = 10; 
					}

					row.forEach((cell, cellIndex) => {
						const xPos = 10 + cellIndex * 50;
						doc.text(cell, xPos, yPos);
					});

					yPos += rowHeight; 
				});

				doc.save("exported_data.pdf");
			} else if (exportFormat === "csv") {
				const formattedData = prepareDataForExport(result);
				const csvData = convertToCSV(formattedData);
				const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
				saveAs(blob, "exported_data.csv");
			}
		} catch (error) {
			console.error("Error during export:", error);
		} finally {
			setIsDownloadComplete(true);
		}
	};

	const handleClick = async (buttonName = activeButton, pageSize = 20) => {
		setActiveButton(buttonName);
		setLoading(true);
		const token = localStorage.getItem("sessionId");
		if (!token) {
			console.error("Token not found.");
			return [];
		}
		let endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=3&action=&searchText=&page=0&size=${pageSize}`;
		let bodyData: { email?: string | number } = { email: adminEmail };

		switch (buttonName) {
			case "3 Months":
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=3&action=&searchText=&page=0&size=${pageSize}`;
				break;
			case "6 Months":
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=6&action=&searchText=&page=0&size=${pageSize}`;
				break;
			case "1 Year":
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=12&action=&searchText=&page=0&size=${pageSize}`;
				break;
			case "All":
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=1200&action=&searchText=&page=0&size=${pageSize}`;
				break;
			default:
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=3&action=&searchText=&page=0&size=${pageSize}`;
				break;
		}

		try {
			setHasMore(true);
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}${endpoint}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(bodyData),
				}
			);
			const data = await response.json();

			if (!data || data.length === 0) {
				setAlertMessage("No activity log found for the selected period.");
				setHasMore(false);
			} else {
				setPageNumber(1);
				setLoading(false);
				setAlertMessage("");
			}

			setResponse(data);
			return data;
		} catch (error) {
			setHasMore(false);
			setLoading(false);
			console.error(`Error fetching ${buttonName} data:`, error);
			return [];
		}
	};

	const fetchMoreData = async () => {
		setLoading(true);
		const token = localStorage.getItem("sessionId");
		if (!token) {
			console.error("Token not found.");
			return;
		}

		let endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=3&action=${status}&searchText=${searchQuery?.trim()}&page=${pageNumber}&size=${itemPerPage}`;
		let bodyData: { email?: string | number } = { email: adminEmail };
		switch (activeButton) {
			case "3 Months":
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=3&action=${status}&searchText=${searchQuery?.trim()}&page=${pageNumber}&size=${itemPerPage}`;
				break;
			case "6 Months":
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=6&action=${status}&searchText=${searchQuery?.trim()}&page=${pageNumber}&size=${itemPerPage}`;
				break;
			case "1 Year":
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=12&action=${status}&searchText=${searchQuery?.trim()}&page=${pageNumber}&size=${itemPerPage}`;
				break;
			case "All":
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=1200&action=${status}&searchText=${searchQuery?.trim()}&page=${pageNumber}&size=${itemPerPage}`;
				break;
			default:
				endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=3&action=${status}&searchText=${searchQuery?.trim()}&page=${pageNumber}&size=${itemPerPage}`;
				break;
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}${endpoint}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(bodyData),
				}
			);
			const data = await response.json();

			if (!Array.isArray(data) || data.length === 0) {
				setHasMore(false);
				console.log("No more data to load");
				return;
			}

			if (pageNumber === 0) {
				setResponse(data);
			} else {
				setLoading(false);
				setResponse((prevData: any) => [...prevData, ...data]);
			}

			setPageNumber(pageNumber + 1);
		} catch (error) {
			setHasMore(false);
			console.error("Error fetching more data:", error);
		}
	};

	const fetchMoreDataWrapper = () => {
		fetchMoreData();
	};

	return (
		<Grid container xs={12}>
			<Grid item xs={9} className="centerPanelWidth">
				<Grid item sx={{ display: "flex", marginTop: "-50px" }}>
					<Box
						component="span"
						sx={{
							fontWeight: "500",
							fontSize: "16px",
							color: "#4D4C4F",
							marginTop: "5px",
							marginLeft: "5px",
						}}
					>
						View Last:
					</Box>
					<div
						style={{
							border: "1px solid #0273E6",
							borderRadius: "17px",
							width: "481px",
							height: "31px",
							marginLeft: "16px",
						}}
					>
						{["3 Months", "6 Months", "1 Year", "All"].map(
							(buttonName: string) => (
								<Button
									key={buttonName}
									size="large"
									variant="outlined"
									onClick={() => handleClick(buttonName)}
									sx={{
										color: "#0273E6",
										border: "none",
										width: "120.25px",
										height: "31px",
										fontFamily: "Lexend",
										fontSize: "15px",
										fontWeight: 600,
										lineHeight: "18.75px",
										...(activeButton === buttonName && {
											backgroundColor: "#0273E6",
											color: "#fff",
											borderTopLeftRadius:
												buttonName === "3 Months" ? "17px" : "0px",
											borderBottomLeftRadius:
												buttonName === "3 Months" ? "17px" : "0px",
											borderTopRightRadius:
												buttonName === "All" ? "17px" : "0px",
											borderBottomRightRadius:
												buttonName === "All" ? "17px" : "0px",
										}),
										"&:hover": {
											backgroundColor: "#0273E6",
											color: "#fff",
											border: "none",
											...(buttonName === "3 Months" && {
												borderTopLeftRadius: "17px",
												borderBottomLeftRadius: "17px",
												borderTopRightRadius: "0px",
												borderBottomRightRadius: "0px",
											}),
											...(buttonName === "All" && {
												borderTopRightRadius: "17px",
												borderBottomRightRadius: "17px",
												borderTopLeftRadius: "0px",
												borderBottomLeftRadius: "0px",
											}),
											...(buttonName === "6 Months" && {
												borderRadius: "0Px",
											}),
											...(buttonName === "1 Year" && {
												borderRadius: "0px",
											}),
										},
									}}
								>
									{buttonName}
								</Button>
							)
						)}
					</div>
				</Grid>
				<Grid item={true} xs={12}>
					<Box>
						{alertMessage && (
							<Typography variant="body1" sx={{ color: "red", mt: 2 }}>
								{alertMessage}
							</Typography>
						)}
						<Grid>
							<Card
								sx={{
									background: "#FFFFFF",
									boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
									borderRadius: "12px",
									mb: 3,
									marginTop: "20px",
								}}
							>
								<Grid container alignItems="center" gap="40px" marginTop="11px">
									<Grid>
										<TextField
											variant="outlined"
											placeholder="Search by text "
											value={searchQuery}
											onChange={(e) => setSearchQuery(e.target.value)}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													handleSearch();
												}
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton onClick={() => handleSearch()}>
															<SearchIcon />
														</IconButton>
													</InputAdornment>
												),
												style: {
													borderRadius: "50px",
													paddingRight: "0px",
													width: "300px",
													marginLeft: "11px",
													marginTop: "4px",
												},
											}}
											sx={{
												borderRadius: "50px",
												width: "358px",
												height: "50px",
											}}
										/>
									</Grid>
									<Grid>
										<FormControl
											fullWidth
											sx={{
												width: "174px",
												height: "40px",
												borderRadius: "50px !important",
												border: "1.5px solid #0273E6 !important",
											}}
										>
											<Select
												value={status}
												onChange={(e) => setStatus(e.target.value)}
												displayEmpty
												onOpen={handleMenuOpen}
												renderValue={
													status !== ""
														? undefined
														: () => (
																<span style={{ color: "#0273E6" }}>
																	Activity type
																</span>
														  )
												}
												sx={{
													".MuiSelect-select": {
														padding: "10px",
														borderRadius: "50px",
														backgroundColor: "transparent !important",
													},
													".MuiOutlinedInput-notchedOutline": {
														border: "none",
													},
													"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
														border: "none",
													},
													"& .MuiSelect-icon": {
														color: "#0273E6",
													},
												}}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: 224,
															width: "30ch",
														},
													},
												}}
											>
												{apiResponse.map((item) => (
													<MenuItem key={item} value={item}>
														{item}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<InfiniteScroll
										dataLength={response ? response.length : 0}
										next={fetchMoreDataWrapper}
										hasMore={hasMore}
										loader={
											loading && (
												<Box className={classes.spinner}>
													<CircularProgress />
												</Box>
											)
										}
									>
										<Typography
											sx={{
												fontFamily: "Lexend",
												fontWeight: 500,
												fontSize: "20px",
												lineHeight: "25px",
												padding: "12px",
											}}
										>
											Admin Actions
										</Typography>
										{response &&
											response?.map((item: any, index: number) => (
												<CardContent key={index}>
													<Typography>
														{item.date}{" "}
														{item.action
															?.split(" ")
															.map((word: any, index: any, arr: any) => {
																if (index >= arr.length - 2) {
																	return (
																		<span style={{ color: "#0A6BFE" }}>
																			{word}{" "}
																		</span>
																	);
																}
																return word + " ";
															})}
													</Typography>
												</CardContent>
											))}
									</InfiniteScroll>
								</Grid>
							</Card>
						</Grid>
						{!hasMore && <p>No more data to load</p>}
					</Box>
				</Grid>
			</Grid>

			<Grid item xs={3} className="rightPanelWidthAdmin">
				<Box sx={{ width: "164px", height: "20px", marginTop: "20px" }}>
					<Typography sx={{ fontWeight: "400", marginBottom: "5px" }}>
						Data Format
					</Typography>
					<Box sx={{ marginBottom: "40px" }}>
						<Select
							value={exportFormat}
							onChange={(e) => setExportFormat(e.target.value)}
							sx={{
								width: "180px",
								height: "40px",
								borderRadius: "6px",
								border: "1px solid #D7D7D7",
							}}
						>
							<MenuItem value="pdf" sx={{ height: "30px" }}>
								PDF
							</MenuItem>
							<MenuItem value="csv" sx={{ height: "30px" }}>
								CSV
							</MenuItem>
						</Select>
					</Box>
					<Button
						onClick={handleExport}
						sx={{
							backgroundColor: isDownloadComplete ? "#FF7043" : "#D3D3D3",
							color: isDownloadComplete ? "#fff" : "#4F4F4F",
							border: "none",
							borderRadius: "24px",
							padding: "8px 16px",
							fontWeight: 500,
							fontSize: "18px",
							lineHeight: "20px",
							textTransform: "uppercase",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							cursor: isDownloadComplete ? "pointer" : "not-allowed",
							"&:hover": {
								backgroundColor: isDownloadComplete ? "#FF7043" : "#D3D3D3",
							},
						}}
						disabled={!isDownloadComplete}
					>
						{!isDownloadComplete ? (
							<>
								Exporting...
								<Hourglass
									visible={true}
									height="1.25rem"
									width="1.25rem"
									ariaLabel="hourglass-loading"
									wrapperStyle={{ marginLeft: "0.5rem" }}
									colors={["#a0a0a0", "#d0d0d0"]}
								/>
							</>
						) : (
							"Export Data"
						)}
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

export default ActivityLogsAdmin;
