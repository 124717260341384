import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AllNotifications from "../../images/all-notifications.svg";
import UnreadNotifications from "../../images/unread-notifications.svg";
import Next from "../../images/next.svg";

import {
	Box,
	Divider,
	Grid,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Typography,
} from "@mui/material";
import { NotificationCard } from "./NotificationCard";
import { useEffect, useState } from "react";
import HelpAndTips from "../../pages/HelpAndTips";
import MyActivitiesSidePanel from "../Social/myActivitiesSidePanel";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			padding: "1.5rem",
		},
		contentDiv: {
			backgroundColor: "red",
		},
	})
);

export const Notifications = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const [activeMenu, setActiveMenu] = useState("allNotifications");
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	const menuItems = [
		{
			text: "All Notifications",
			icon: <NotificationImportantOutlinedIcon />,
		},
		{
			text: "Unread",
			icon: <NotificationsNoneOutlinedIcon />,
		},
	];
	const changeMenu = (value: string) => {
		setActiveMenu(value);
	};

	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if ((!userDetails.firstName || !userDetails.lastName || !userDetails.location) && !isAdmin) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);

	const pauseAudioPlayer = (musicId:any) => {
		// Pause the currently playing audio player
		const audioPlayers = document.querySelectorAll("audio");
	    if(audioPlayers){
        audioPlayers.forEach((audioPlayer) => {
			const audioElement = audioPlayer as HTMLAudioElement;
             if (audioPlayer.id == musicId) {
			// //	audioElement.play();
		     }else{
			// 	//audioPlayer.pause();
				audioElement.pause();
			 }
		});
        }
	};

	return (
		<>
			<Box className="mainPanel" sx={{margin:"auto"}}>
				<Typography
					style={{  marginTop: 20, fontWeight: "500", paddingLeft:10,
					fontSize: "36px !important",
					lineHeight: "normal"}}
					className="home-title-custom-size"
				>
					Notifications
				</Typography>
				<Grid container className="mainPanel">
					<Grid item xs={3} className="leftPanelWidth">
						<Paper sx={{ height: "fit-content", width: "288px", boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)", borderRadius: "12px", marginBottom: "24px", mt: 7 }}>
							<Box>
								<MenuList>
									<MenuItem
										onClick={() => changeMenu("allNotifications")}
										sx={{
											padding: "10px 0 10px 14px",
											whiteSpace: "normal",
											overflowX: "auto",
											backgroundColor: "#fff",
										}}
									>
										<ListItemIcon>
											{activeMenu === "allNotifications" ? (
												<img src={AllNotifications} alt="AllNotifications" />
											) : (
												<img src={AllNotifications} alt="AllNotifications" />
											)}
										</ListItemIcon>
										<ListItemText sx={{span: { fontSize: "1.125rem !important"}}}>All Notifications</ListItemText>
										<ListItemIcon>
											{activeMenu === "allNotifications" && (
												<img src={Next} alt="active" />
											)}
										</ListItemIcon>
									</MenuItem>
									<Divider variant="fullWidth" component="li" />
									<MenuItem
										onClick={() => changeMenu("unread")}
										sx={{
											padding: "10px 0 10px 14px",
											whiteSpace: "normal",
											overflowX: "auto",
										}}
									>
										<ListItemIcon>
											{activeMenu === "unread" ? (
												<img
													src={UnreadNotifications}
													alt="UnreadNotifications"
												/>
											) : (
												<img
													src={UnreadNotifications}
													alt="UnreadNotifications"
												/>
											)}
										</ListItemIcon>
										<ListItemText sx={{span: { fontSize: "1.125rem !important"}}}>Unread</ListItemText>
										<ListItemIcon>
											{activeMenu === "unread" && (
												<img src={Next} alt="active" />
											)}
										</ListItemIcon>
									</MenuItem>
									{/* <Divider variant="fullWidth" component="li" /> */}
								</MenuList>
								{/* <Box sx={{ padding: 3, marginTop: 3 }}> */}
									{/* <HelpAndTips
										notification={true}
										title="How this works"
										text="This is the place for like minded musicians to practice
									their gears, you can now connect musicians around world and
										practice at your comfort from home"
									/> */}
								{/* </Box> */}
							</Box>
						</Paper>

						<MyActivitiesSidePanel />
					</Grid>
					<Grid item xs={9} sx={{p: 2}} className="centerPanelwoRight">
						<NotificationCard activeMenu={activeMenu} onItemClick={pauseAudioPlayer} />
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
