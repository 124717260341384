import React from "react";
import { Message } from "@twilio/conversations";
import MessageInfo from "./MessageInfo/MessageInfo";
import MessageListScrollContainer from "./MessageListScrollContainer/MessageListScrollContainer";
import TextMessage from "./TextMessage/TextMessage";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import MediaMessage from "./MediaMessage/MediaMessage";

interface MessageListProps {
	messages: Message[];
}

const getFormattedTime = (message?: Message) =>
	message?.dateCreated
		?.toLocaleTimeString("en-us", { hour: "numeric", minute: "numeric" })
		.toLowerCase();

export default function MessageList({ messages }: MessageListProps) {
	const { room } = useVideoContext();
	const localParticipant = room!.localParticipant;
	const userSession = localStorage.getItem("userSession") ?? "";
	const userSessions = JSON.parse(userSession);

	return (
		<MessageListScrollContainer messages={messages}>
			{messages.map((message, idx) => {
				const time = getFormattedTime(message)!;
				const previousTime = getFormattedTime(messages[idx - 1]);

				// Display the MessageInfo component when the author or formatted timestamp differs from the previous message
				const shouldDisplayMessageInfo =
					time !== previousTime || message.author !== messages[idx - 1]?.author;

				const isLocalParticipant = localParticipant.identity === message.author;

				const participantDetails = userSessions.attendees.find(
					(name: any) => name.email === message.author
				);


				return (
					<React.Fragment key={message.sid}>
						{shouldDisplayMessageInfo && (
							// <MessageInfo
							// 	author={`${participantDetails.firstName!} ` + ` ${participantDetails.lastName!}`}
							// 	isLocalParticipant={isLocalParticipant}
							// />

							//test when backend response comes
							<MessageInfo
							author={
								isLocalParticipant
								  ? participantDetails?.firstName + " " + participantDetails?.lastName
								  : participantDetails?.userPrivacySettingsDTO?.identityPermission ===
									'Name is not visible. Instead just show nickname'
								  ? `@${participantDetails?.nickname}`
								  : participantDetails?.userPrivacySettingsDTO?.identityPermission ===
									'Name is visible to all'
								  ? participantDetails?.firstName + " " + participantDetails?.lastName
								  : participantDetails?.userPrivacySettingsDTO?.identityPermission ===
									'Name is visible only to my connections. Other see nickname'
								  ? participantDetails?.isConnected
									? participantDetails?.firstName + " " + participantDetails?.lastName
									: `@${participantDetails?.nickname}`
								  : participantDetails?.firstName + " " + participantDetails?.lastName
							  }
								isLocalParticipant={isLocalParticipant}
							/>
						)}
						{message.type === "text" && (
							<TextMessage
								body={message.body!}
								isLocalParticipant={isLocalParticipant}
							/>
						)}
						{message.type === "media" && (
							<MediaMessage media={message.attachedMedia![0]} />
						)}

					</React.Fragment>
				);
			})}
		</MessageListScrollContainer>
	);
}
