import React, { useEffect } from 'react';
import { GoogleMap, Marker, LoadScript, useJsApiLoader } from '@react-google-maps/api';

const Venue = (props:any) => {
  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  const defaultCenter = {
    lat: parseFloat(props.location.lat),
    lng: parseFloat(props.location.lng),
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyD_JY9jNwrhwh1rkOtimU7sJRf3vA7E638"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map:any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds( {
      lat: parseFloat(props.location.lat),
      lng: parseFloat(props.location.lng),
    });
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map:any) {
    setMap(null)
  }, [])

  return (

      isLoaded ? 
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={defaultCenter}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Marker position={defaultCenter} />
        </GoogleMap>
        : <></>
    // <LoadScript googleMapsApiKey="AIzaSyD_JY9jNwrhwh1rkOtimU7sJRf3vA7E638">
    // </LoadScript>
  );
};

export default Venue;
