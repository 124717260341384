import {
	Box,
	Paper,
	Grid,
	Container,
	Typography
} from "@mui/material";
import LogoWengor from "../../images/SignupLogo.svg";
import i18n from "../../l10n/strings.json";
import './social.css'
import ProfileDetails from "../commonComponents/profileDetails";
import { useLocation } from "react-router";
import TickCircle from '../../images/Posts/tick-circle.svg';
import { SignUpProvider, useSignUp } from '../../context/SignUpContext'


const SocialProfile = (props: any) => {
	// const { userData, setUserData } = useSignUp();

	const location = useLocation();
	return (
		<>
			<SignUpProvider>
				<Container
					maxWidth="xl"
					sx={{
						marginTop: 2,
						position: "relative"

					}}
				>
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Grid
							container
							direction="column"
							columns={{ xs: 4, sm: 8, md: 12 }}
							sx={{ display: "flex", justifyContent: "center" }}
						>
							<Grid xs={3}>
								<img src={LogoWengor} alt="LuteFish Logo"></img>
							</Grid>
							<Grid className="headingName" sx={{mt:'-40px'}}>
								<Box sx={{ textAlign: "center", m: 1, mt: 2 }}>
									<Grid container direction='column'>
										<Grid item>
											{i18n.socialProfile.title}
										</Grid>
										<Grid item sx={{ alignSelf: 'center', mb: 1 }}>
											<Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#3EB92A', display: 'flex' }}>
												<img src={TickCircle} height='32px' width='32px' />
												Your account is created. Create your social profile</Typography>
										</Grid>
									</Grid>
								</Box>

							</Grid>

							<div className="social-up-main-div" >
								<Grid
									item
									xs={5}
									direction="column"
									component={Paper}
									sx={{
										//display: "flex",
										//justifyContent: "center",
										alignItems: "center",
										textAlign: "left",
										p: {lg:5, md:4},
										scrollbarWidth: "none",
										boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
										//margin: "0 400px"
										position: "relative",
										left: "28.5%"
									}}
								>
									<ProfileDetails
										firstName={location.state.firstName}
										lastName={location.state.lastName}
										email={location.state.email}
										location={location.state.location}
										fbUserId={location.state.fbUserId}
										password={location.state.password}
										type={location.state.type}
									/>
								</Grid>
							</div>
						</Grid>
					</Box>
				</Container>
			</SignUpProvider>
		</>
	);
}
export default SocialProfile;
