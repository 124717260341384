import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { CardMedia, Stack, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DefaultProfilePicture from "../../images/User square 198.png";
import { useNavigate } from "react-router-dom";
import {format} from "date-fns";
import { useEffect, useState } from "react";
import CommentReplyDialog from "./CommentReplyDialog";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { Theme } from "@mui/material/styles";
import calendarIcon from "../../images/calendar.svg";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dateStyle: {
			fontFamily: "Lexend",
			fontSize: "13px",
			//fontSize: "14px",
			fontWeight: "500",
			lineHeight: "20px",
			letterSpacing: "0em",
			textAlign: "left",
			marginBottom: "0 !important",
			marginTop: "5px !important",
			paddingLeft: "3px",
			paddingTop: "0px",
		},
	})
);
export const EventGigReaction = (props: any) => {
	const navigate = useNavigate();
	const classes = useStyles();

	const [openCommentReplyDialog, setOpenCommentReplyDialog] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	let finalFormattedDate;

	if (
		props.notificationType === "NewEventNearMe" ||
		props.notificationType === "NewGigInCity" 
		) {
		const utcTime: string = props.notification?.eventStartDateTime;
		const date = new Date(utcTime);
		finalFormattedDate = format(date, "MMM dd, yyyy - hh:mm a");
	}

	const closeCommentReplyDialog = () => {
		setOpenCommentReplyDialog(false);
	};

	const handleViewPostClick = (
		postId: any,
		nickName: any,
		notifactionType: string
	) => {
		if (props.notification?.notificationType === "NewEventNearMe" || 
		props.notification?.notificationType === "ReactionOnEvent"
		) {
			navigate(`/events/eventDetails/${props.notification?.eventId}`);
		}
		if (props.notification?.notificationType === "NewGigInCity" || 
			props.notification?.notificationType === "ReactionOnGig"
		) {
			navigate(`/gigs/gigDetails/${props.notification?.eventId}`);
		}
		if (props.notification?.notificationType === "LocalArtistJoined") {
			navigate("/socialHome/" + props.notification?.nickname);
		}
		if (
			props.notification?.notificationType === "EventReaction" ||
			props.notification?.notificationType === "GigReaction"
		) {
			navigate(
				`/singlePostView?postId=${postId}&nickName=${nickName}`
			);
		} else {
			if (!props.notification?.commentDeleted) {
				setOpenCommentReplyDialog(true);
			} else {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Comment has been deleted by user");
			}
		}
	};

	const [isNotificationRead, setIsNotificationRead] = useState(
		props.notification?.readStatus || false
	);

	const handleCardClick = () => {
		setIsNotificationRead(true);
	};

	useEffect(() => {
		if (props.notification?.readStatus) {
			setIsNotificationRead(true);
		}
	}, [props.notification?.readStatus]);

	const formatString = (notificationType: any): string => {
		if (notificationType === "EventReaction") {
			return (
				" liked your event - " +
				format(
					new Date(props.notification?.postLikeTime),
					"MM/dd/yyyy hh:mm aaa"
				)
			);
		} else if (notificationType === "GigReaction") {
			return (
				" liked your gig - " +
				format(
					new Date(props.notification?.postLikeTime),
					"MM/dd/yyyy hh:mm aaa"
				)
			);
		} else if (notificationType === "CommentOnGig") {
			return (
				" responded to your gig - " +
				format(
					new Date(props.notification?.postCommentedTime),
					"MM/dd/yyyy hh:mm aaa"
				)
			);
		} else if (notificationType === "CommentOnEvent") {
			return (
				" responded to your event - " +
				format(
					new Date(props.notification?.postCommentedTime),
					"MM/dd/yyyy hh:mm aaa"
				)
			);
		} else if (notificationType === "NewEventNearMe") {
			return "New event was posted in your city by ";
		} else if (notificationType === "NewGigInCity") {
			return "New gig was posted in your city by ";
		} else if (notificationType === "LocalArtistJoined") {
			return "A new musician joined from your city";
		} else if (notificationType === "ReactionOnEvent") {
			return " liked your event";
		}
		else if (notificationType === "ReactionOnGig") {
			return " liked your gig";
		}
		return "";
	};

	const extracttStateCity = (location: string) => {
		const updatedLocation = location?.split(",").slice(-3, -1).join(", ");
		return updatedLocation;
	};

	const getUserName = () => {
		const displayName =
			props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is not visible. Instead just show nickname" ||
			(props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is visible only to my connections. Other see nickname" &&
				!props.notification.connected)
				? props.notification.nickname
				: props.notification.name;
		return <span style={{ color: "#000" }}>{displayName} </span>;
	};

	const getPostCreatorName = () => {
		const creatorName =
			props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is not visible. Instead just show nickname" ||
			(props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is visible only to my connections. Other see nickname" &&
				!props.notification.connected)
				? props.notification.nickname
				: props.notification.postCreatorName;

		return <span style={{ color: "#000" }}>{creatorName}</span>;
	};
	const handleAlertClose = () => {
		setShowAlert(false);
	};

	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Grid container xs={12}>
				<Grid item xs={12}>
					<Card
						elevation={0}
						sx={{
							borderRadius: "12px",
							background: !isNotificationRead ? "#fff" : "#F9F9FA",
							border: !isNotificationRead ? "none" : "1px solid #E2E2E2",
							boxShadow: !isNotificationRead
								? "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
								: "none",
							margin: "0 8px",
						}}
					>
						<CardHeader
							action={
								<Button
									variant="contained"
									onClick={() =>
										handleViewPostClick(
											props.notification?.postId,
											props.notification?.nickname,
											props.notification?.notificationType
										)
									}
								>
									{props.notification?.notificationType === "EventReaction" ||
									props.notification?.notificationType === "NewEventNearMe" ||
									props.notification?.notificationType === "ReactionOnEvent"
										? "View Event"
										: props.notification?.notificationType === "NewGigInCity" || 
										props.notification?.notificationType === "ReactionOnGig"
										? "View Gig"
										: props.notification?.notificationType ===
										  "LocalArtistJoined"
										? "View"
										: props.notification?.notificationType === "GigReaction"
										? "View Messages"
										: props.notification?.notificationType === "CommentOnGig" ||
										  props.notification?.notificationType === "CommentOnEvent"
										? "Reply"
										: ""}
								</Button>
							}
							title={
								<>
									<Grid
										container
										xs={12}
										onClick={() => {
											props.onNotificationClick(props.notification, true);
											handleCardClick();
										}}
									>
										<Grid item xs={2.5}>
											{/* <div className="invitation-card-title-wrapper"> */}
											<Typography
												sx={{
													backgroundColor: "#DFEBFF",
													borderRadius: "8px",
													textAlign: "center",
													display: "inline-block",
													padding: "0 10px",
													color: "#000",
													fontSize: "1rem",
													fontWeight: "500",
												}}
												variant="subtitle2"
											>
												{props.notification?.notificationType ===
												"EventReaction"
													? "Event Reaction"
													: props.notification?.notificationType ===
													  "CommentOnEvent"
													? "Event Response"
													: props?.notification?.notificationType ===
													  "GigReaction"
													? "Gig Reaction"
													: props.notification?.notificationType ===
													  "CommentOnGig"
													? "Gig Response"
													: props.notification?.notificationType ===
													  "NewEventNearMe"
													? "New Event"
													: props.notification?.notificationType ===
													  "NewGigInCity"
													? "New Gig"
													: props.notification?.notificationType ===
													  "LocalArtistJoined"													
													? "New Musician"
													: props.notification?.notificationType ===
													 "ReactionOnEvent"
													?"Event Reaction"
													:props.notification?.notificationType ===
													"ReactionOnGig"
												   ?"Gig Reaction"
													:""}
											</Typography>

											{/* </div> */}
										</Grid>
										<Grid item xs={9.5}>
											{(props.notificationType === "EventReaction" ||
												props.notificationType === "GigReaction" ||
												props.notificationType === "CommentOnGig" ||
												props.notificationType === "CommentOnGig" ||
												props.notificationType === "CommentOnEvent" ||
												props.notificationType === "NewEventNearMe" ||
												props.notificationType === "NewGigInCity" ||
												props.notificationType === "LocalArtistJoined" ||
												props.notificationType === "ReactionOnEvent" ||
												props.notificationType === "ReactionOnGig") && (
												<Typography
													style={{
														fontSize: "1rem",
														fontWeight: "500",
														fontFamily: "Lexend",
														marginLeft: "11px",
													}}
												>
													{props.notificationType !== "NewEventNearMe" ||
														(props.notificationType !== "NewGigInCity" &&
															getUserName())}
													{(props.notificationType === "ReactionOnEvent" ||  props.notificationType === "ReactionOnGig")&&
														props.notification?.name}

													<span style={{ color: "#5B5B65" }}>
														{formatString(props.notificationType)}
													</span>

													{props.notificationType === "NewEventNearMe" &&
														props.notification?.eventCreaterName}
													{props.notificationType === "NewGigInCity" &&
														props.notification?.eventCreaterName} 
												</Typography>
											)}
											{props.notificationType === "reactionOnComment" && (
												<Typography
													style={{
														fontSize: "1rem",
														fontWeight: "500",
														fontFamily: "Lexend",
													}}
												>
													{getUserName()}
													<span style={{ color: "#5B5B65" }}>
														{formatString(props.notificationType)}
													</span>
													{getPostCreatorName()}'s{" "}
													<span style={{ color: "#5B5B65", marginLeft: "2px" }}>
														post -{" "}
														{format(
															new Date(props.notification?.commentLikeTime),
															"MM/dd//yyyy hh:mm aaa"
														)}
													</span>
												</Typography>
											)}
											{props.notificationType === "commentOnComment" && (
												<Typography
													style={{
														fontSize: "1rem",
														fontWeight: "500",
														fontFamily: "Lexend",
													}}
												>
													{getUserName()}
													<span style={{ color: "#5B5B65" }}>
														{formatString(props.notificationType)}
													</span>
													{getPostCreatorName()}'s{" "}
													<span style={{ color: "#5B5B65", marginLeft: "2px" }}>
														post -{" "}
														{format(
															new Date(props.notification?.postCommentedTime),
															"MM/dd//yyyy hh:mm aaa"
														)}
													</span>
												</Typography>
											)}
										</Grid>
									</Grid>
								</>
							}
						/>
						<CardContent>
							<Grid
								container
								spacing={2}
								onClick={() => {
									props.onNotificationClick(props.notification, true);
									handleCardClick();
								}}
							>
								<Grid item xs={2.5}>
									<CardMedia
										component="img"
										height="194"
										image={
											props.notification?.profilePictureUrl != null &&
											props.notification?.profilePictureUrl != ""
												? process.env.REACT_APP_PICTURE_URL +
												  props.notification?.profilePictureUrl
												: DefaultProfilePicture
										}
										sx={{ width: "80px", height: "80px", borderRadius: "50%" }}
										alt="Paella dish"
									/>
								</Grid>
								<Grid item xs={9.5}>
									<Grid
										container
										spacing={2}
										sx={{
											border: props.notification?.commentMessage
												? "1px solid #C2C2C3"
												: "none",
											borderRadius: "12px",
											// maxHeight: "88px",
										}}
									>
										<>
											<Grid container xs={12}>
												<>
													<Grid
														item
														xs={6}
														sx={{
															backgroundColor: "#F8F8F8",
															// border: "1px solid #D9E7FF",
															borderTopLeftRadius: "12px",
															borderBottomLeftRadius: "12px",
															borderTopRightRadius: props.notification
																?.commentMessage
																? 0
																: "12px",
															borderBottomRightRadius: props.notification
																?.commentMessage
																? 0
																: "12px",
															// padding: "10px",
															maxWidth: "285px",
															wordWrap: "break-word",
														}}
													>
														{props.notification.notificationType ===
														"LocalArtistJoined" ? (
															<Stack>
																<Typography variant="body1">
																	{props.notification.name}
																</Typography>
																<Typography variant="body1">
																	{props.notification.location}
																</Typography>
															</Stack>
														) : (
															<div
																style={{ display: "flex", maxWidth: "20rem" }}
															>
																<img
																	// src={
																	// 	props.notification.notificationType ===
																	// 	"NewEventNearMe"
																	// 		? process.env.REACT_APP_PICTURE_URL +
																	// 		  props.notification?.eventThumbnailUrl
																	// 		: props.notification.notificationType ===
																	// 		  "NewGigInCity"
																	// 		? process.env.REACT_APP_PICTURE_URL +
																	// 		  props.notification?.eventThumbnailUrl
																	// 		: props.notification?.eventsDTO
																	// 				?.bannerUrl !== null
																	// 		? process.env.REACT_APP_PICTURE_URL +
																	// 		  props.notification?.eventsDTO?.bannerUrl
																	// 		:  props.notification.notificationType ===
																	// 		  "ReactionOnEvent"
																	// 		? process.env.REACT_APP_PICTURE_URL +
																	// 		  props.notification?.eventThumbnailUrl
																	// 		: props.notificationType ===
																	// 		  "EventReaction"
																	// 		? process.env.REACT_APP_PICTURE_URL +
																	// 		  "/defaultimages/event-thumbnail.svg"
																	// 		: process.env.REACT_APP_PICTURE_URL +
																	// 		  "/defaultimages/gig-thumbnail.svg"
																	// }
																	
																	src={
																		((props.notification.notificationType  === "NewEventNearMe" || 
																		props.notification.notificationType  === "NewGigInCity" ||
																		props.notification.notificationType  === "ReactionOnEvent" ||
																		props.notification.notificationType  === "ReactionOnGig" ) && 
																			props.notification?.eventThumbnailUrl !=="" && 
																			props.notification?.eventThumbnailUrl !=="null" && 
																			props.notification?.eventThumbnailUrl !== null
																		)
																		?process.env.REACT_APP_PICTURE_URL + props.notification?.eventThumbnailUrl
																		: ((props.notificationType ==="EventReaction" || props.notificationType ==="GigReaction" )&& props.notification?.eventsDTO && props.notification?.eventsDTO?.thumbnailUrl !=="") ? 
																		process.env.REACT_APP_PICTURE_URL + props.notification?.eventsDTO?.thumbnailUrl
																		: props.notificationType ==="EventReaction" || props.notificationType ==="GigReaction" || props.notificationType ==="ReactionOnEvent" || props.notificationType ==="ReactionOnGig" 
																		?  process.env.REACT_APP_PICTURE_URL + `/defaultimages/event-thumbnail.svg`
																		: process.env.REACT_APP_PICTURE_URL + `/defaultimages/gig-thumbnail.svg`
																	}
																	alt="Post Image"
																	style={{
																		borderRadius: "12px 0px 0px 12px",
																		maxWidth: "100%",
																		width:
																			props.notificationType ===
																				"NewGigInCity" ||
																			props.notificationType ===
																				"NewEventNearMe"
																				? "150px"
																				: "84px",
																		height:
																			props.notificationType ===
																				"NewGigInCity" ||
																			props.notificationType ===
																				"NewEventNearMe"
																				? "150px"
																				: "84px",
																		// aspectRatio: "4/3",
																		objectFit: "cover",
																	}}
																/>
																<div
																	style={{
																		display: "flex",
																		backgroundColor: "#F8F8F8",
																		borderRadius: "0 12px 12px 0",
																		// padding: "10px 0 0 0"
																	}}
																>
																	<Stack sx={{ marginLeft: 1 }}>
																		<Typography
																			sx={{
																				color: "#000",
																				fontSize: "18px",
																				fontWeight: "500",
																				maxWidth: "14.8rem",
																				whiteSpace: "nowrap",
																				textOverflow: "ellipsis",
																				overflow: "hidden",
																			}}
																			variant="body1"
																		>
																				{props.notificationType === "NewEventNearMe" ||
																					props.notificationType === "NewGigInCity" || 
																					props.notificationType === "ReactionOnEvent" ||
																					props.notificationType === "ReactionOnGig"
																					? props.notification?.eventTitle
																					: props.notification?.eventsDTO?.title}
																		</Typography>
																		<Typography
																			variant="body1"
																			sx={{
																				fontSize: "14px",
																				fontWeight: "500",
																				color: "#000",
																				maxWidth: "14.8rem",
																				whiteSpace: "nowrap",
																				textOverflow: "ellipsis",
																				overflow: "hidden",
																			}}
																		>
																			{props.notificationType === "NewEventNearMe" ||
																				props.notificationType === "NewGigInCity" ||
																				props.notificationType === "ReactionOnEvent" ||
																				props.notificationType === "ReactionOnGig"
																				? props.notification?.eventVenue
																				: props.notification?.eventsDTO?.venue}
																		</Typography>
																		<Typography
																			variant="body1"
																			sx={{
																				fontSize: "14px",
																				fontWeight: "500",
																				color: "#000",
																			}}
																		>
																			{extracttStateCity(
																				props.notificationType === "NewEventNearMe" ||
																					props.notificationType === "NewGigInCity" ||
																					props.notificationType === "ReactionOnEvent" ||
																					props.notificationType === "ReactionOnGig"
																					? props.notification?.eventCityCountry
																					: props.notification?.eventsDTO
																							?.cityCountry
																			)}
																		</Typography>
																		{(props.notificationType ===
																			"NewEventNearMe" ||
																			props.notificationType ===
																				"NewGigInCity") && (
																			<>
																				<Typography
																					variant="body1"
																					sx={{
																						fontSize: "14px",
																						color: "#5B5B65",
																						fontWeight: "400",
																					}}
																				>
																					{props.notification?.eventDescription}
																				</Typography>
																				<div
																					style={{
																						display: "flex",
																						alignItems: "center",
																					}}
																				>
																					<img
																						width="18px"
																						height="18px"
																						style={{ paddingLeft: "0px" }}
																						src={calendarIcon}
																						alt="Date"
																					/>
																					<Typography
																						variant="body2"
																						className={classes.dateStyle}
																						sx={{
																							maxWidth: "90%",
																							overflow: "hidden",
																							textOverflow: "ellipsis",
																							whiteSpace: "nowrap",
																							fontSize: "13px",
																							paddingRight: "0px",
																						}}
																					>
																						{finalFormattedDate}
																					</Typography>
																				</div>
																			</>
																		)}
																	</Stack>
																</div>
															</div>
														)}
													</Grid>
													{props.notification?.notifactionType ===
														"LocalArtistJoined" && (
														<Grid
															item
															xs={6}
															sx={{ maxHeight: "84px", overflow: "hidden" }}
														>
															{props.notification?.commentMessage && (
																<Typography
																	sx={{
																		color: "#5B5B65",
																		backgroundColor: "#fff",
																		overflow: "hidden",
																		display: "-webkit-box",
																		WebkitBoxOrient: "vertical",
																		WebkitLineClamp: 3,
																		whiteSpace: "normal",
																		fontSize: "1rem",
																		fontWeight: "400",
																		height: "100%",
																		borderTopRightRadius: "12px",
																		borderBottomRightRadius: "12px",
																		padding: "8px",
																		boxSizing: "border-box",
																	}}
																	variant="body1"
																>
																	{props.notification?.commentMessage}
																</Typography>
															)}
															{/* </Box> */}
														</Grid>
													)}
												</>
											</Grid>
										</>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<CommentReplyDialog
					open={openCommentReplyDialog}
					notification={props.notification}
					closeCommentReplyDialog={closeCommentReplyDialog}
				/>
			</Grid>
		</>
	);
};
