import React from "react";

function ArrowDownIcon(): JSX.Element {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.4995 16.7996C11.7995 16.7996 11.0995 16.5296 10.5695 15.9996L4.04953 9.47965C3.75953 9.18965 3.75953 8.70965 4.04953 8.41965C4.33953 8.12965 4.81953 8.12965 5.10953 8.41965L11.6295 14.9396C12.1095 15.4196 12.8895 15.4196 13.3695 14.9396L19.8895 8.41965C20.1795 8.12965 20.6595 8.12965 20.9495 8.41965C21.2395 8.70965 21.2395 9.18965 20.9495 9.47965L14.4295 15.9996C13.8995 16.5296 13.1995 16.7996 12.4995 16.7996Z"
				fill="#FF702A"
			/>
		</svg>
	);
}

export default ArrowDownIcon;
