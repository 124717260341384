import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import {Close} from "@mui/icons-material";
import ErrorIcon from "../../icons/ErrorIcon";
import { IconButton, SnackbarCloseReason, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Snackbar as SnackbarT } from "@mui/material";
import WarningIcon from "../../icons/WarningIcon";
import InfoIcon from "../../icons/InfoIcon";

interface SnackbarProps {
	headline: string;
	message: string | React.ReactNode;
	variant?: "error" | "warning" | "info";
	open: boolean;
	handleClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: "flex",
		justifyContent: "space-between",
		width: "400px",
		minHeight: "50px",
		background: "white",
		padding: "1em",
		borderRadius: "3px",
		boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	contentContainer: {
		display: "flex",
		lineHeight: 1.8,
	},
	iconContainer: {
		display: "flex",
		padding: "0 1.3em 0 0.3em",
		transform: "translateY(3px)",
	},
	headline: {
		fontWeight: "bold",
	},
	error: {
		borderLeft: "4px solid #D61F1F",
	},
	warning: {
		borderLeft: "4px solid #E46216",
	},
	info: {
		borderLeft: "4px solid #0263e0",
	},
}));

export default function Snackbar({
	headline,
	message,
	variant,
	open,
	handleClose,
}: SnackbarProps) {
	const classes = useStyles();

	const handleOnClose = (event: Event | SyntheticEvent<any, Event>,
		reason: SnackbarCloseReason
	) => {
		if (reason === "clickaway") {
			return;
		}

		handleClose?.();
	};

	return (
		<SnackbarT
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={open}
			onClose={handleOnClose}
			autoHideDuration={10000}
		>
			<div
				className={clsx(classes.container, {
					[classes.error]: variant === "error",
					[classes.warning]: variant === "warning",
					[classes.info]: variant === "info",
				})}
			>
				<div className={classes.contentContainer}>
					<div className={classes.iconContainer}>
						{variant === "warning" && <WarningIcon />}
						{variant === "error" && <ErrorIcon />}
						{variant === "info" && <InfoIcon />}
					</div>
					<div>
						<Typography
							variant="body1"
							className={classes.headline}
							component="span"
						>
							{headline}
						</Typography>
						<Typography variant="body1" component="span">
							{" "}
							{message}
						</Typography>
					</div>
				</div>
				<div>
					{handleClose && (
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={handleClose}
						>
							<Close fontSize="small" />
						</IconButton>
					)}
				</div>
			</div>
		</SnackbarT>
	);
}
