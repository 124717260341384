import { useState, useEffect } from "react";
import {
	Card,
	CardContent,
	IconButton,
	Box,
	Grid,
	FormControl,
	Typography,
	Button,
	ListItem,
	ListItemText,
	ListItemIcon,
	Divider,
	List,
	Chip,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
} from "@mui/material";
import i18n from "../l10n/strings.json";
import "../components/Social/social.css";
import { styled } from "@mui/material/styles";
import EditIcon from "../images/edit.svg";
import EditTitle from "../dialogBox/EditTitle";
import EditBriefBio from "../dialogBox/EditBriefBio";
import EditGenres from "../dialogBox/EditGenres";
import EditInstruments from "../dialogBox/EditInstruments";
import Location from "../images/location1.svg";
import { useUser } from "../context/UserContext";
import CenterCircularProgress from "../components/CenterCircularProgress";
import InstrumentSkillLevel from "../components/Musicians/InstrumentSkills";
import ErrorAlert from "../components/Error/ErrorAlert";
import ErrorAlertDisplay from "../components/Error/ErrorAlertDisplay";
import User from "../services/user";

import { useNavigate } from "react-router-dom";
import DeleteProfile from "../images/deleteProfile.svg";
import EditProfile from "../images/editProfile.svg";
import uploadProfileIcon from "../images/uploadProfilePhoto.svg";
import defaultProfilePhoto from "../images/defaultProfilePhoto.svg";
import CloseIcon from "../images/close.svg";
import EditDisabledIcon from "../images/editDisableIcon.svg";
import ViewImage from "../components/commonComponents/ViewImage";
import ChooseProfilePictureDialog from "../components/commonComponents/ChooseProfilePictureDialog";
import AmericanaB from "../icons/Genres/BlackOutlines/BlackOutlines_Americana.svg";
import AvantGardeB from "../icons/Genres/BlackOutlines/BlackOutlines_Avante-Garde.svg";
import BaroqueB from "../icons/Genres/BlackOutlines/BlackOutlines_Baroque.svg";
import BigBandB from "../icons/Genres/BlackOutlines/BlackOutlines_Big Band.svg";
import BluegrassB from "../icons/Genres/BlackOutlines/BlackOutlines_Bluegrass.svg";
import BluesB from "../icons/Genres/BlackOutlines/BlackOutlines_Blues.svg";
import BollywoodB from "../icons/Genres/BlackOutlines/BlackOutlines_Bollywood.svg";
import BroadwayShowtunesB from "../icons/Genres/BlackOutlines/BlackOutlines_Broadway-Showtunes.svg";
import ChamberMusicB from "../icons/Genres/BlackOutlines/BlackOutlines_Chamber Music.svg";
import ClassicRockB from "../icons/Genres/BlackOutlines/BlackOutlines_Classic Rock.svg";
import ClassicalB from "../icons/Genres/BlackOutlines/BlackOutlines_Classical.svg";
import ContemporaryClassicalB from "../icons/Genres/BlackOutlines/BlackOutlines_Contemporary Classical.svg";
import CountryB from "../icons/Genres/BlackOutlines/BlackOutlines_Country.svg";
import DiscoB from "../icons/Genres/BlackOutlines/BlackOutlines_Disco.svg";
import DrumlineB from "../icons/Genres/BlackOutlines/BlackOutlines_Drumline.svg";
import EDMB from "../icons/Genres/BlackOutlines/BlackOutlines_EDM.svg";
import ElectronicDanceB from "../icons/Genres/BlackOutlines/BlackOutlines_Electronic-Dance.svg";
import FolkB from "../images/genres/folk.svg"; 
import FunkB from "../icons/Genres/BlackOutlines/BlackOutlines_Funk.svg";
import GenereB from "../images/genres/genere.svg";
import GospelB from "../icons/Genres/BlackOutlines/BlackOutlines_Gospel.svg";
import HiphopRapB from "../icons/Genres/BlackOutlines/BlackOutlines_HipHop-Rap.svg";
import HouseB from "../icons/Genres/BlackOutlines/BlackOutlines_House.svg";
import IndieAlternativeB from "../icons/Genres/BlackOutlines/BlackOutlines_Indie-Alt.svg";
import JazzB from "../icons/Genres/BlackOutlines/BlackOutlines_Jazz.svg";
import KPopB from "../icons/Genres/BlackOutlines/BlackOutlines_KPop.svg";
import LatinB from "../icons/Genres/BlackOutlines/BlackOutlines_Latin.svg";
import MetalB from "../icons/Genres/BlackOutlines/BlackOutlines_Metal.svg";
import MinimalismB from "../icons/Genres/BlackOutlines/BlackOutlines_Minimalism.svg";
import NewAgeB from "../icons/Genres/BlackOutlines/BlackOutlines_New Age.svg";
import OperaB from "../icons/Genres/BlackOutlines/BlackOutlines_Opera.svg";
import OrchestralB from "../icons/Genres/BlackOutlines/BlackOutlines_Orchestral.svg";
import PopB from "../icons/Genres/BlackOutlines/BlackOutlines_Pop.svg";
import PunkB from "../icons/Genres/BlackOutlines/BlackOutlines_Punk.svg";
import RBB from "../icons/Genres/BlackOutlines/BlackOutlines_R&B.svg";
import RagtimeB from "../icons/Genres/BlackOutlines/BlackOutlines_Ragtime.svg";
import ReggaeB from "../icons/Genres/BlackOutlines/BlackOutlines_Reggae.svg";
import ReggaetonB from "../icons/Genres/BlackOutlines/BlackOutlines_Reggaeton.svg";
import ReligiousB from "../icons/Genres/BlackOutlines/BlackOutlines_Religious.svg";
import RockB from "../icons/Genres/BlackOutlines/BlackOutlines_Rock.svg";
import RomanticB from "../icons/Genres/BlackOutlines/BlackOutlines_Romantic.svg";
import SalsaB from "../icons/Genres/BlackOutlines/BlackOutlines_Salsa.svg";
import SkaB from "../icons/Genres/BlackOutlines/BlackOutlines_Ska-18.svg";
import SoulB from "../icons/Genres/BlackOutlines/BlackOutlines_Soul.svg";
import SoundtrackB from "../icons/Genres/BlackOutlines/BlackOutlines_Soundtrack.svg";
import SwingB from "../icons/Genres/BlackOutlines/BlackOutlines_Swing.svg";
import SymphonicB from "../icons/Genres/BlackOutlines/BlackOutlines_Symphonic.svg";
import Techno from "../icons/Genres/BlackOutlines/BlackOutlines_Techno.svg"
import WindEnsembleB from "../icons/Genres/BlackOutlines/BlackOutlines_Wind Ensemble.svg";

const AccountListItemText = styled(ListItemText)({
	"& .MuiListItemText-primary": {
		fontFamily: "Lexend",
		fontStyle: "normal",
		fontSize: "1rem",
		fontWeight: 400,
		color: "#7A7985",
	},
	"& .MuiListItemText-secondary": {
		fontFamily: "Lexend",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "1rem",
		color: "#000000",
	},
});

export const getGenre = (genre: string) => {
    const genreImages: any = {
        Americana: AmericanaB,
        'Avant-garde': AvantGardeB,
        Baroque: BaroqueB,
        'Big Band': BigBandB,
        Bluegrass: BluegrassB,
        Blues: BluesB,
        Bollywood: BollywoodB,
        'Broadway/Showtunes': BroadwayShowtunesB,
        'Chamber-Music': ChamberMusicB,
        'Classic Rock': ClassicRockB,
        Classical: ClassicalB,
        'Contemporary Classical': ContemporaryClassicalB,
        Country: CountryB,
        Disco: DiscoB,
        Drumline: DrumlineB,
        EDM: EDMB,
        'Electronic/Dance': ElectronicDanceB,
        Folk: FolkB,
        Funk: FunkB,
		Genre:GenereB,
        Gospel: GospelB,
        'Hip-hop/Rap': HiphopRapB,
        House: HouseB,
        'Indie/Alternative': IndieAlternativeB,
        Jazz: JazzB,
        'K-Pop': KPopB,
        Latin: LatinB,
        Metal: MetalB,
        Minimalism: MinimalismB,
        'New Age': NewAgeB,
        Opera: OperaB,
        Orchestral: OrchestralB,
        Pop: PopB,
        Punk: PunkB,
        'R&B': RBB,
        Ragtime: RagtimeB,
        Reggae: ReggaeB,
        Reggaeton: ReggaetonB,
        Religious: ReligiousB,
        Rock: RockB,
        Romantic: RomanticB,
        Salsa: SalsaB,
        Ska: SkaB,
        Soul: SoulB,
        Soundtrack: SoundtrackB,
        Swing: SwingB,
        Symphonic: SymphonicB,
		Techno:Techno,
        'Wind Ensemble': WindEnsembleB,
    };

    const imageSrc: any = genreImages[genre];
    return <img src={imageSrc} style={{ height: '24px', width: '24px' }} alt={genre} />;
};


const SocialProfileSetting = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [nickName, setNickName] = useState("");
	const [location, setLocation] = useState("");
	const [title, setTitle] = useState("");
	const [bio, setBio] = useState("");
	const [genresValue, setGenresValue] = useState<any>([]);
	const [genresIcon, setGenresIcon] = useState<any>([]);
	const [instrumentsValue, setInstrumentsValue] = useState<any>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [rating, setRating] = useState<number | null>(2);
	const [titleOpen, setTitleOpen] = useState(false);
	const [bioOpen, setBioOpen] = useState(false);
	const [instrumentsOpen, setInstrumentsOpen] = useState(false);
	const [genresOpen, setGenresOpen] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [interestOpen, setInterestOpen] = useState(false);
	// const [userData, setUserData] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [userDetails, setUserDetails] = useState<any>({});
	const [skillLevelValues, setSKillLevelValues] = useState<any>([]);

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	// eslint-disable-next-line
	// const [file, setFile] = useState<any>('');
	const [imagePreviewUrl, setImagePreviewUrl] = useState("");
	const [imageData, setImageData] = useState("");
	// const [result, setResult] = useState('');
	// const [open, setOpen] = useState(false);
	const [openDeleteDialog, setOPenDeleteDialog] = useState(false);
	const navigate = useNavigate();
	const [isModalOpen, setModalOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);

	const handleClick = () => {
		imagePreviewUrl && setModalOpen(true);
	};

	const handleCloseDeleteDialog = () => {
		setOPenDeleteDialog(false);
	};

	useEffect(() => {
		setImageData(
			user?.profilePhotoUrl
				? `${process.env.REACT_APP_PICTURE_URL}${user?.profilePhotoUrl}`
				: ""
		);
		setImagePreviewUrl(
			user?.profilePhotoUrl
				? `${process.env.REACT_APP_PICTURE_URL}${user?.profilePhotoUrl}`
				: ""
		);
	}, [user]);
	// const handleClose = () => {
	//     setOpen(false);
	// };
	// const handleOpenEditor = (e: any) => {
	//     e.preventDefault();
	//     const file = e.target.files[0];
	//     setResult(URL.createObjectURL(file))
	//     setFile(file);
	//     setOpen(true)
	// }SocialProfileSetting.tsx

	//  const photoUpload = (destFile: any) => {
	//     const reader = new FileReader();
	//     let formData = new FormData();
	//     formData.append('profilePhoto', destFile);
	//     formData.append('email', user.email);
	//     fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-profile-photo`, {
	//         method: "PUT",
	//         headers: {
	//             //   "Content-Type": "multipart/form-data",
	//             Authorization: `Bearer ${token}`,
	//         },
	//         body: formData,
	//     }).then((res) => {
	//         if (res.status !== 200) {
	//             ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
	//         } else {
	//             setImageData(URL.createObjectURL(destFile))
	//             setImagePreviewUrl(URL.createObjectURL(destFile))
	//             reader.readAsDataURL(destFile);
	//             User.fetchUserInfo(() => { });
	//             // fetchUserInfo()
	//         }
	//     }).catch((err) => {
	//         setShowAlert(true);
	//         setAlertStatus(false);
	//         setAlertMsg("Network Error! Please try again..");
	//     });
	// }
	// useEffect(() => {
	//     setImagePreviewUrl(user?.profilePhotoUrl ? `${process.env.REACT_APP_PICTURE_URL}${user?.profilePhotoUrl}` : '')
	// }, [user])

	const handleDialogClose = (success: boolean, editMode: string) => {
		setTitleOpen(false);
		setBioOpen(false);
		setGenresOpen(false);
		setInstrumentsOpen(false);
		setInterestOpen(false);
		setOpenDialog(false);
	};

	const updateInstrumentFilter = (instrument: string, skillLevel: number) => {
		setSKillLevelValues([
			...skillLevelValues,
			...[{ id: instrument, skillLevel: skillLevel }],
		]);
	};

	useEffect(() => {
		fetchUserInfo();
		setIsLoading(true);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchUserInfo = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/view-user-profile/${encodeURIComponent(user.nickname)}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setUserDetails(data);
				setFirstName(data.privacySettings.userProfile.user.firstName);
				setLastName(data.privacySettings.userProfile.user.lastName);
				setNickName(data.privacySettings.userProfile.nickname);
				setLocation(data.privacySettings.userProfile.location);
				setTitle(data.privacySettings.userProfile.profileTitle);
				setBio(data.privacySettings.userProfile.profileBio);
				setImageData(
					data.privacySettings.userProfile?.profilePhotoUrl
						? `${process.env.REACT_APP_PICTURE_URL}${data.privacySettings.userProfile?.profilePhotoUrl}`
						: ""
				);
				setImagePreviewUrl(
					data.privacySettings.userProfile?.profilePhotoUrl
						? `${process.env.REACT_APP_PICTURE_URL}${data.privacySettings.userProfile?.profilePhotoUrl}`
						: ""
				);
				handleGenres(data.privacySettings.userProfile.genres);
				handleInstruments(data.instrumentsList);
				setIsLoading(false);
			})
			.catch((err) => {
				// setShowAlert(true);
				// setAlertStatus(false);
				// setAlertMsg("Network Error! Please try again..");
				setIsLoading(false);
			});
	};
	const deleteUpload = () => {
		let formData = new FormData();
		formData.append("email", user.email);
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/delete-profile-photo`,
			{
				method: "PUT",
				headers: {
					//   "Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					setImageData("");
					setImagePreviewUrl("");
					User.fetchUserInfo(() => {});
					// fetchUserInfo()
					handleCloseDeleteDialog();
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const handleInstruments = (instrument: any) => {
		const NewArray: any = [];
		instrument.map(
			(item: any) =>
				NewArray.push({
					id: item.instrument.id,
					instrumentName: item.instrument.instrumentName,
					label: item.instrument.instrumentName,
					skillLevel: item.skillLevel,
				})
			//  skillLevel
		);
		setInstrumentsValue(NewArray);
	};

	const handleGenres = (genres: any) => {
		const newGenres = genres.split(",");
		const NewArray: any = [];
		// eslint-disable-next-line array-callback-return
		newGenres.map((item: any) => {
			if (item !== "") {
				NewArray.push({
					title: item,
				});
			}
		});
		setGenresValue(NewArray);
	};

	if (isLoading) {
		return <CenterCircularProgress />;
	}

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleSetting = () => {
		navigate("/home/settings/account/account");
	};

	const handleImageUrl = (callbackImageUrl: string) => {
		setImagePreviewUrl(callbackImageUrl);
	};

	return (
		<>
			<Grid container>
				<ErrorAlertDisplay
					alertMsg={alertMsg}
					alertStatus={alertStatus}
					showAlert={showAlert}
					handleAlertClose={handleAlertClose}
				/>
				<Grid item={true} xs={9}>
					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "12px",
							mb: 1,
						}}
					>
						<CardContent sx={{ padding: "24px" }}>
							<FormControl sx={{ width: "100%" }}>
								<Box sx={{ textAlign: "left", m: 1, pb: 4 }}>
									<Grid
										container
										columns={{ xs: 4, sm: 8, md: 12 }}
										sx={{ justifyContent: "space-between" }}
									>
										<Grid xs={4} >
											{imagePreviewUrl ? (
												<div className="social-img-wrap">
													<img
														style={{
															width: "160px",
															height: "160px",
															borderRadius: "12px",
														}}
														src={imagePreviewUrl}
														onClick={handleClick}
														alt=""
													/>
													{user.userAccountStatus !== "SUSPEND" && (
														<Grid
															container
															sx={{
																width: "160px",
																position: "absolute",
																height: "44px",
																backgroundColor: "rgba(255, 255, 255, 0.60)",
																marginTop: { md: "-33%", xl: "-33%" },
															}}
														>
															<Grid
																xs={6}
																sx={{
																	textAlignLast: "start",
																	mt: "3px",
																	pl: 3,
																	pb: 1,
																}}
															>
																<label title="Upload Photo">
																	<img
																		id="photo-edit"
																		onClick={() => setOpenDialog(true)}
																		src={EditProfile}
																		alt="Upload"
																		style={{
																			cursor: "pointer",
																			marginTop: "2px",
																		}}
																	/>
																	{/* <input
                                                                        id="photo-edit"
                                                                        type="file"
                                                                        accept =".jpg, .jpeg, .png, .gif"
                                                                        style={{ display: "contents" }}
                                                                        // onChange={(e) => photoUpload(e)}
                                                                        onChange={(e) => handleOpenEditor(e)}
                                                                    />  */}
																</label>
															</Grid>
															<Grid
																xs={6}
																sx={{ textAlignLast: "end", pr: 3, pb: 1 }}
															>
																<IconButton
																	size="small"
																	onClick={() => setOPenDeleteDialog(true)}
																>
																	<img src={DeleteProfile} alt='' />
																</IconButton>
															</Grid>
														</Grid>
													)}
												</div>
											) : (
												<div className="social-img-wrap">
													<img
														id="photo-upload"
														style={{ width: "150px", height: "150px" }}
														src={defaultProfilePhoto}
														alt="Upload"
													/>
													{user.userAccountStatus !== "SUSPEND" && (
														<Grid
															container
															sx={{
																width: "150px",
																position: "absolute",
																marginTop: { md: "-41%", xl: "-32%" },
															}}
														>
															<Grid
																item
																xs={12}
																sx={{ textAlignLast: "center", mt: "3px" }}
															>
																<label title="Upload Photo">
																	<img
																		id="photo-upload"
																		onClick={() => setOpenDialog(true)}
																		src={uploadProfileIcon}
																		alt="Upload"
																		style={{ cursor: "pointer" }}
																	/>
																	{/* <input
                                                                        id="photo-upload"
                                                                        type="file"
                                                                        style={{ display: "contents" }}
                                                                        // onChange={(e) => photoUpload(e)}
                                                                        onChange={(e) => handleOpenEditor(e)}
                                                                    />  */}
																</label>
															</Grid>
														</Grid>
													)}
												</div>
											)}
										</Grid>
										<Grid
											container
											direction={"column"}
											xs={8}
											className="tabSocialHead"
										>
											<Typography
												variant="h5"
												sx={{ fontWeight: 500, fontSize: "24px" }}
											>
												{`${
													firstName && firstName?.length > 14
														? firstName?.substring(0, 14) + "..."
														: firstName
												}`}
												&nbsp;
												{`${
													lastName && lastName?.length > 14
														? lastName?.substring(0, 14) + "..."
														: lastName
												}`}
											</Typography>
											<Typography
												variant="caption"
												sx={{ fontWeight: 500, fontSize: "18px" }}
											>
												<Typography
													variant="caption"
													sx={{
														fontWeight: "bold",
														color: "#C5C5C5",
														fontSize: "18px",
													}}
												>
													
												</Typography>
												{`${
													nickName && nickName?.length > 35
														? nickName?.substring(0, 35) + "..."
														: nickName
												}`}
											</Typography>
											<Typography
												variant="caption"
												sx={{ fontSize: "14px", fontWeight: 500 }}
											>
												<img
													src={Location}
													style={{ width: "13px", height: "13px" }}
													alt="Location"
												/>
												{location}
											</Typography>
											<br />
											<Button
												variant="text"
												onClick={handleSetting}
												sx={{
													color: "#0A6BFE",
													alignSelf: "self-start",
													fontSize: "12px",
												}}
											>
												Editable in Accounts
											</Button>
										</Grid>
									</Grid>
									<List>
										<Typography
											sx={{
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "12px",
												lineHeight: "100%",
												textAlign: "right",
												color: "#7A7985",
												marginBottom: "-25px",
												mr: 3,
											}}
										>
											{i18n.socialProfile.titleHelpText}
										</Typography>
										<ListItem sx={{ padding: "8px 0"}}>
											<AccountListItemText
												primary={i18n.socialProfile.titleText}
												secondary={title}
											/>

											<ListItemIcon
												sx={{
													display: "flex",
													flexDirection: "column",
												}}
											>
												{user.userAccountStatus === "SUSPEND" ? (
													<img
														src={EditDisabledIcon}
														style={{ marginLeft: "60%" }}
														alt="Edit Icon"
														width="24px"
														height="24px"
													/>
												) : (
													<img
														src={EditIcon}
														style={{
															cursor: "pointer",
															marginLeft: "60%",
														}}
														alt="Edit Icon"
														width="24px"
														height="24px"
														onClick={() => setTitleOpen(true)}
													/>
												)}
											</ListItemIcon>
										</ListItem>
										<EditTitle
											titleOpen={titleOpen}
											setTitle={setTitle}
											title={title}
											handleClose={handleDialogClose}
										/>
										<Divider variant="fullWidth" component="li" />

										<ListItem sx={{ padding: "8px 0", display: "flex", alignItems: "start" }}>
											<AccountListItemText
												primary={i18n.socialProfile.bio}
												primaryTypographyProps={{
													sx: { color: "#7A7985" },
												}}
												secondary={bio}
											/>
											<ListItemIcon>
												{user.userAccountStatus === "SUSPEND" ? (
													<img
														src={EditDisabledIcon}
														style={{ marginLeft: "60%" }}
														alt="Edit Icon"
														width="24px"
														height="24px"
													/>
												) : (
													<img
														src={EditIcon}
														style={{
															cursor: "pointer",
															marginLeft: "60%",
														}}
														alt="Edit Icon"
														width="24px"
														height="24px"
														onClick={() => setBioOpen(true)}
													/>
												)}
											</ListItemIcon>
										</ListItem>
										<EditBriefBio
											bioOpen={bioOpen}
											setBio={setBio}
											bio={bio}
											handleClose={handleDialogClose}
										/>
										<Divider variant="fullWidth" component="li" />

										<ListItem sx={{ padding: "8px 0", display: "flex", alignItems: "start" }}>
											<AccountListItemText
												primary={i18n.socialProfile.genres}
												primaryTypographyProps={{
													sx: { color: "#7A7985" },
												}}
												secondary={genresValue.map((genre: any) => {
													return (
														<Chip
															sx={{
																backgroundColor: "#F8F8F8",
																color: "#7A7985",
																mr: 1,
																mb: 1,
																fontSize: "14px",
																fontWeight: 400,
															}}
															label={<div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>{getGenre(genre.title)}<span>{genre.title}</span></div>}
														/>
													);
												})}
											/>
											<ListItemIcon>
												{user.userAccountStatus === "SUSPEND" ? (
													<img
														src={EditDisabledIcon}
														style={{ marginLeft: "60%" }}
														alt="Edit Icon"
														width="24px"
														height="24px"
													/>
												) : (
													<img
														src={EditIcon}
														style={{
															cursor: "pointer",
															marginLeft: "60%",
														}}
														alt="Edit Icon"
														width="24px"
														height="24px"
														onClick={() => setGenresOpen(true)}
													/>
												)}
											</ListItemIcon>
										</ListItem>
										<EditGenres
											genresOpen={genresOpen}
											setGenresValue={setGenresValue}
											genresValue={genresValue}
											handleClose={handleDialogClose}
											setGenresIcon = {setGenresIcon}
										/>
										<Divider variant="fullWidth" component="li" />

										<ListItem sx={{ padding: "8px 0", display: "flex", alignItems: "start" }}>
											<AccountListItemText
												primary={i18n.socialProfile.instrument}
												primaryTypographyProps={{
													sx: { color: "#7A7985" },
												}}
												// secondary={instrumentsValue.map((genre:any)=>{
												//         return <Chip sx={{backgroundColor:'#F8F8F8', color:'#7A7985', mr:1,mb:1, fontSize:'14px', fontWeight:'400'}} label={genre.label} />
												//     })}
											/>
											<ListItemIcon>
												{user.userAccountStatus === "SUSPEND" ? (
													<img
														src={EditDisabledIcon}
														style={{ marginLeft: "60%" }}
														alt="Edit Icon"
														width="24px"
														height="24px"
													/>
												) : (
													<img
														src={EditIcon}
														style={{
															cursor: "pointer",
															marginLeft: "60%",
														}}
														alt="Edit Icon"
														width="24px"
														height="24px"
														onClick={() => setInstrumentsOpen(true)}
													/>
												)}
											</ListItemIcon>
										</ListItem>
										<EditInstruments
											instrumentsOpen={instrumentsOpen}
											setInstrumentsValue={setInstrumentsValue}
											instrumentsValue={instrumentsValue}
											handleClose={handleDialogClose}
											handleInstruments={handleInstruments}
										/>
										{/* <Divider variant="fullWidth" component="li" /> */}
									</List>
									{instrumentsValue.map((instrument: any) => {
										return (
											<InstrumentSkillLevel
												instrument={instrument.id}
												skillLevel={instrument.skillLevel}
												editable={true}
												updateInstrumentFilter={updateInstrumentFilter}
												instrumentLabel={instrument.label}
												update="false"
											/>
										);
									})}
								</Box>
							</FormControl>
						</CardContent>
					</Card>
				</Grid>
			</Grid>

			{isModalOpen && (
				<ViewImage
					open={isModalOpen}
					handleClose={() => setModalOpen(false)}
					ImageUrl={imagePreviewUrl}
				/>
			)}

			{openDialog && (
				<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid item xs={12}>
						<ChooseProfilePictureDialog
							open={openDialog}
							handleClose={handleDialogClose}
							handleImageUrlCallback={handleImageUrl}
						/>
					</Grid>
				</Grid>
			)}

			{openDeleteDialog && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteDialog}
					onClose={() => handleCloseDeleteDialog()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeleteDialog()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete Profile Picture</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={() => handleCloseDeleteDialog()}
						>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => deleteUpload()}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{/* <ImageCropper
                open={open}
                onClose={handleClose}
                imagePreviewUrl={result}
                setImagePreviewUrl={setImagePreviewUrl}
                setResult={setFile}
                post={false}
                photoUpload={photoUpload}
                cover={false}
            /> */}
		</>
	);
};

export default SocialProfileSetting;
