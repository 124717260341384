import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { makeStyles } from '@mui/styles';

export const useGradient2Styles = makeStyles(() => ({
	gradient2: {
		background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
		color: "#fff !important" //color of text for gradientbutton
	},
	gradient3: {
		// background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
		color: "#fff !important" //color of text for gradientbutton
	}
}));

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		body3: true;
		body4: true;
		body5: true;
		body6: true;
	}
}

interface ExtendedTypographyOptions extends TypographyOptions {
	body3: React.CSSProperties;
	body4: React.CSSProperties;
	body5: React.CSSProperties;
	body6: React.CSSProperties;
}
declare module "@mui/material/styles" {
	interface Theme {
		sidebarWidth: number;
		sidebarMobileHeight: number;
		brand: string;
		footerHeight: number;
		mobileTopBarHeight: number;
		mobileFooterHeight: number;
		sidebarMobilePadding: number;
		participantBorderWidth: number;
		rightDrawerWidth: number;
		galleryViewBackgroundColor: string;
		gradient: {
			gradient1: string,
			tabBar: string
		}
	}

	// allow configuration using `createMuiTheme`
	interface ThemeOptions {
		sidebarWidth?: number;
		sidebarMobileHeight?: number;
		brand: string;
		footerHeight: number;
		mobileTopBarHeight: number;
		mobileFooterHeight: number;
		sidebarMobilePadding: number;
		participantBorderWidth: number;
		rightDrawerWidth?: number;
		galleryViewBackgroundColor: string;
		gradient: {
			gradient1: string
			tabBar: string
		}
	}
}
declare module "@mui/material/styles/createPalette" {
	interface palette {
		customColor?: {
			gray?: string,
			gray1A?: string,
			gray1?: string,
			gray2?: string,
			gray4?: string,
			gray5?: string,
			blue?: string,
			primary?: string,
			black?: string,
			label?: string,
			text?: string,
			orange?: string,
			link?: string,
			search?: string,
			dropShadow?: string
		};
	}
	interface PaletteOptions {
		customColor?: {
			gray?: string,
			gray1A?: string,
			gray1?: string,
			gray2?: string,
			gray4?: string,
			gray5?: string,
			blue?: string,
			primary?: string,
			black?: string,
			label?: string,
			text?: string,
			orange?: string,
			link?: string,
			search?: string,
			dropShadow?: string
		};
	}
}

const defaultTheme = createTheme();

export default createTheme({
	breakpoints: {
		values: {
		  xs: 0, // Reset xs breakpoint to 0
		  sm: 600,
		  md: 760,
		  lg: 1280,
		  xl: 1920,
		},
	  },
	palette: {
		primary: {
			main: "#FF702A",//Primary color changed
		},
		secondary: {
			main: "#ffffff",
		},
		success: {
			main: "#3EB92A"
		},
		error: {
			main: "#FD4B24",
			light: "#F57A7A",
			dark: "#EB2A00"
		},
		customColor: {
			gray: "#F5F5F5",
			gray1A: "#F0F0F0",
			gray1: "#F8F8F8",
			gray2: "#EAEAEA",
			gray4: "#888888",
			gray5: '#5C5C5C',
			primary: "#1D2D53",
			text: "#5D5D65",
			black: "#000000",
			blue: "#0273E6",
			link: "#0A6BFE",
			label: "#7A7985",
			search: "#EDF5FF",
			orange: "#FF702A",
			dropShadow: "box-shadow: 0px 1px 17px 0px #000096"
		}
	},
	typography: {
		h1: {
			fontSize: "4rem",
			fontStyle: "normal",
			lineHeight: "80px",
			fontWeight: "500"
		},
		h2: {
			fontWeight: "300",
			fontStyle: "normal",
			fontSize: "2.25rem",
			lineHeight: "45px"
		},
		h3: {
			fontWeight: "500",
			fontStyle: "normal",
			fontSize: "1.5rem",
			lineHeight: "30px"
		},
		h4: {
			fontStyle: "normal",
			fontSize: "1.3125rem",
			lineHeight: "25px",
			fontWeight: "900"
		},
		h5: {
			fontStyle: "normal",
			fontSize: "1.3125rem",
			lineHeight: "25px",
			fontWeight: "500"
		},
		h6: {
			fontStyle: "normal",
			fontSize: "1.125rem",
			lineHeight: "22px",
			fontWeight: "500"
		},
		subtitle1: {
			fontSize: "1rem",
			fontStyle: "normal",
			lineHeight: "20px",
			fontWeight: "500"
		},
		subtitle2: {
			fontSize: "0.875rem",
			fontStyle: "normal",
			fontWeight: "500"
		},
		body1: {
			color: "rgb(40, 42, 43)",
			fontSize: "0.9375rem",
			fontWeight: "500"
		},
		body2: {
			fontSize: "0.8125rem",
			fontStyle: "normal",
			fontWeight: "400"
		},
		body3: {
			fontSize: "0.75rem",
			fontStyle: "normal",
			fontWeight: "400"
		},
		body4: {
			fontSize: "1rem !important",
			fontStyle: "normal",
			fontWeight: "400",
			lineHeight: "1rem"
		},
		body5: {
			fontSize: "1rem",
			fontStyle: "normal",
			lineHeight: "20px",
			fontWeight: "900"
		},
		body6: {
			fontSize: "1.125rem",
			fontStyle: "normal",
			lineHeight: "20px",
			fontWeight: "600"
		}
	} as ExtendedTypographyOptions,
	components: {
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: "12px",
					backgroundColor: "#fff",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "20px",
					textTransform: "none",
					color: "#ffffff",
					fontSize: "0.9rem",
					height: "36px",
					transition: defaultTheme.transitions.create(
						["background-color", "box-shadow", "border", "color"],
						{
							duration: defaultTheme.transitions.duration.short,
						}
					),
				},
				text: {
					padding: "6px 14px",
				},
				contained: {
					boxShadow: "none",
					'&:hover': {
						boxShadow: "none",
						backgroundColor: "#F35E15"
					},
					'&:active': {
						backgroundColor: "#D14C0B",
					},
				},
				outlinedPrimary: {
					border: "2px solid #FF702A",
					color: "#FF702A",
					'&:hover': {
						border: "2px solid #FF702A",
						backgroundColor: "#FFF4EE"
					},
					'&:active': {
						border: "2px solid #D14C0B",
						backgroundColor: "#D14C0B"
					}
				},
				startIcon: {
					marginRight: "6px",
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: "Lexend"
				},
				h1: {
					'@media only screen and (min-width: 0px) and (max-width: 500px)': {
						fontSize: "0.8rem"
					},
					'@media only screen and (min-width: 501px) and (max-width: 700px)': {
						fontSize: "1.5rem"
					},
					'@media only screen and (min-width: 701px) and (max-width: 900px)': {
						fontSize: "1.9rem"
					},
					'@media only screen and (min-width: 901px) and (max-width: 1200px)': {
						fontSize: "3rem"
					},
					'@media only screen and (min-width: 1201px) and (max-width: 1400px)': {
						fontSize: "3rem"
					}
				},
				h2: {
					'@media only screen and (min-width: 0px) and (max-width: 500px)': {
						fontSize: "0.8rem"
					},
					'@media only screen and (min-width: 501px) and (max-width: 700px)': {
						fontSize: "1rem"
					},
					'@media only screen and (min-width: 701px) and (max-width: 900px)': {
						fontSize: "1.2rem"
					},
					'@media only screen and (min-width: 901px) and (max-width: 1200px)': {
						fontSize: "1.6rem"
					},
					'@media only screen and (min-width: 1201px) and (max-width: 1400px)': {
						fontSize: "1.8rem"
					},
					'@media only screen and (min-width: 1401px) and (max-width: 2000px)': {
						fontSize: "2.4rem"
					},
					'@media only screen and (min-width: 2001px)': {
						fontSize: "3rem"
					},
				},
				h6: {
					fontStyle: "normal",
					fontSize: "1.125rem",
					lineHeight: "22px"
				},
				subtitle1: {
					'@media only screen and (min-width: 0px) and (max-width: 500px)': {
						fontSize: "0.6rem"
					},
					'@media only screen and (min-width: 501px) and (max-width: 700px)': {
						fontSize: "0.6rem"
					},
					'@media only screen and (min-width: 701px) and (max-width: 900px)': {
						fontSize: "0.6rem"
					},
					'@media only screen and (min-width: 901px) and (max-width: 1200px)': {
						fontSize: "0.7rem"
					},
					'@media only screen and (min-width: 1201px) and (max-width: 1400px)': {
						fontSize: "1rem"
					},
					// '@media only screen and (min-width: 1401px) and (max-width: 2000px)': {
					// 	fontSize: "1.1rem"
					// },
					// '@media only screen and (min-width: 2001px)': {
					// 	fontSize: "1.3rem"
					// },
				},
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					"fontSize": "0.9rem"
				},
			}
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: "16px",
				},
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					color: "rgb(40, 42, 43)",
				},
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					// color: "rgb(40, 42, 43)",
					fontSize: "1.1rem",
					marginBottom: "0.2em",
					fontWeight: 500,
				},
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: "rgb(136, 140, 142)",
				},
			}
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					width: 42,
					height: 18,
					padding: 0,
					display: "flex",
					marginRight: "0.5em",
				},
				switchBase: {
					padding: 2,
					color: "#FFFFFF",
					"&$checked": {
						transform: "translateX(18px)",
						top: "50%",
						marginTop: -24 / 2,
						"&$disabled": {
							"& + $track": {
								opacity: "0.5",
							},
						},
					},
				},
				colorSecondary: {
					"&$checked": {
						// Controls checked color for the thumb
						color: "FFFFF",
					},
				},
				thumb: {
					width: 14,
					height: 14,
					boxShadow: "none",
				},
				track: {
					borderRadius: 16 / 2,
					opacity: 1,
					backgroundColor: "#E1E3EA",
					"$checked$checked + &": {
						opacity: 1,
						backgroundColor: "#14B053",
					},
				},
			}
		},
	},
	brand: "#E22525",
	footerHeight: 72,
	mobileFooterHeight: 56,
	sidebarWidth: 300,
	sidebarMobileHeight: 90,
	sidebarMobilePadding: 8,
	participantBorderWidth: 2,
	mobileTopBarHeight: 52,
	rightDrawerWidth: 320,
	galleryViewBackgroundColor: "#121C2D",
	gradient: {
		gradient1: 'linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)',
		tabBar: 'linear-gradient(360deg, #5A40F5 0%, #0273E6 100%)'

	}
});
