import React, { useRef, useEffect, useState } from "react";
import "./Loader.css"; // Import CSS file for styling
import logo from "../../images/lutefish-logo.png";
import { ThreeDots } from "react-loader-spinner";

const Loader: React.FC = () => {
	const [loadingProgress, setLoadingProgress] = useState(0);
	const imageRef = useRef<HTMLImageElement>(null);

	useEffect(() => {
		const interval = setInterval(() => {
			// Increase loading progress by 10% every 500 milliseconds
			setLoadingProgress((prevProgress) =>
				prevProgress + 10 > 100 ? 100 : prevProgress + 10
			);
		}, 500);

		return () => clearInterval(interval); // Clear interval on component unmount
	}, []);

	return (
		<div className="loader-container">
			<img
				src={logo}
				alt="Logo"
				className="loader-image"
				ref={imageRef}
				onLoad={() => setLoadingProgress(100)} // Set loading progress to 100% when the image is loaded
			/>
			{/* <div className="loader-bar" style={{ width: `${loadingProgress}%` }}></div> */}
			<div style={{marginTop: "-40px"}}>
				<ThreeDots
					visible={true}
					height="80"
					width="80"
					color="#0273e6"
					radius="9"
					ariaLabel="three-dots-loading"
					wrapperStyle={{}}
					wrapperClass=""
				/>
			</div>
		</div>
	);
};

export default Loader;
