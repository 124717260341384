import { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { over } from "stompjs";
import SockJS from "sockjs-client";

import {
	Grid,
	Card,
	CardMedia,
	CardContent,
	Typography,
	AppBar,
	Container,
	Tabs,
	Tab,
	Toolbar,
	IconButton,
	CardHeader,
	Button,
	Tooltip,
	Menu,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { format, addMinutes, isBefore } from "date-fns";

import backgroundImage from "../../images/live-streming-background.svg";
import Profile from "../../images/profile-circle.svg";
//import Logo from "../../images/logo-new-horizon-1.svg";
import Logo from "../../images/logo_Lutefish_app.svg";
import Play from "../../images/play-circle.svg";
import Pause from "../../images/pause-circle.svg";
import MusicWave from "../../images/music-wave.svg";
import Avatar from "../../images/avatar.svg";
import ArrowDown from "../../images/arrow-down.svg";
import profile from "../../images/profile.svg";
import settingsIcon from "../../images/setting.svg";
import signout from "../../images/signout.svg";
import Home from "../../images/home.svg";
import Musicians from "../../images/musicians.svg";
import Gigs from "../../images/gigs.svg";
import Events from "../../images/events.svg";
import Shop from "../../images/shop.svg";
import { UserInterface } from "../../types/types";
import i18n from "../../l10n/strings.json";
import { Box } from "@mui/system";
import { useUser } from "../../context/UserContext";
import { signOut } from "../../utils";
import { CompatibilityPopup } from "../../dialogBox/CompatibilityPopup";

const TabsMenu = [
	{
		label: "Home",
		id: "home",
		disabled: false,
		icon: Home,
	},
	{
		label: "Musicians",
		id: "musicians",
		disabled: true,
		icon: Musicians,
	},
	{
		label: "Gigs",
		id: "gigs",
		disabled: true,
		icon: Gigs,
	},
	{
		label: "Events",
		id: "events",
		disabled: true,
		icon: Events,
	},
	{
		label: "Shop",
		id: "shop",
		disabled: true,
		icon: Shop,
	},
	{
		label: "Support",
		id: "support",
		disabled: true,
	},
];

const SettingsMenu = [
	{
		label: "Social Profile",
		id: "profile",
		icon: profile,
	},
	{
		label: "Settings",
		id: "settings",
		icon: settingsIcon,
	},
	{
		label: "Sign Out",
		id: "signout",
		icon: signout,
	},
];
const LiveStream = () => {
	const navigate = useNavigate();
	let user = useUser();
	let isUserAuthenticated = user.email !== "" ? true : false;
	let [searchParams] = useSearchParams();
	const sessionId = searchParams.get("id");

	const [sessionDetails, setSessionDetails] = useState<any>();
	const [sessionExpired, setSessionExpired] = useState<Boolean>(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isLivestreamActive, setIsLivestreamActive] = useState(false);
	const [selectedTab, setSelectedTab] = useState("");
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const websocketRef = useRef<WebSocket | null>();
	const websocketClient = useRef<any>();
	const peerConnectionRef = useRef<RTCPeerConnection | null>();
	const playerRef = useRef<any>();

	// this needs to have some stuff done to it to be cross platform
	(window as any).AudioContext =
		(window as any).AudioContext || (window as any).webkitAudioContext;

	const audioContextRef = useRef<AudioContext>();
	const audioSource = useRef<AudioBufferSourceNode>();
	const remoteProcessingNode = useRef<AudioWorkletNode>();
	let dataChannelRef = useRef<RTCDataChannel | null>();

	const webSocketUrl =
		`wss://${process.env.REACT_APP_SERVER_IP}:443/download/` +
		sessionId +
		"/" +
		user.id;

	const connectToServer = async () => {
		audioContextRef.current = new AudioContext({
			latencyHint: "interactive",
			sampleRate: 48000,
		});

		audioSource.current = audioContextRef.current.createBufferSource();
		try {
			await audioContextRef.current.audioWorklet.addModule("https://test.44ti.me/speaker-worklet-processor.js");
		} catch (error) {
			console.error(`Error adding audio worklet module from:`, error);
		}
		remoteProcessingNode.current = new AudioWorkletNode(
			audioContextRef.current,
			"speaker-worklet-processor",
			{
				channelCount: 2,
				processorOptions: { bufferSize: 128, channelCount: 1 },
			}
		);

		audioSource.current
			.connect(remoteProcessingNode.current)
			.connect(audioContextRef.current.destination);

		audioContextRef.current.resume();

		websocketRef.current = new WebSocket(webSocketUrl);

		const pcConfig: RTCConfiguration = {
			iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
		};

		peerConnectionRef.current = new RTCPeerConnection(pcConfig);
		dataChannelRef.current = peerConnectionRef.current.createDataChannel(
			crypto.randomUUID(),
			{
				negotiated: false,
				maxRetransmits: 0,
				ordered: false,
			}
		);

		dataChannelRef.current.onopen = () => {
			console.log("datachannel open!");
			if (
				dataChannelRef.current != null &&
				dataChannelRef.current != undefined
			) {
				dataChannelRef.current.binaryType = "arraybuffer";
			}
		};

		dataChannelRef.current.onmessage = (e) => {
			if (
				remoteProcessingNode.current != undefined &&
				remoteProcessingNode.current != null
			) {
				// feed our data to WASM
				remoteProcessingNode.current.port.postMessage(new Float32Array(e.data));
			}
		};

		websocketRef.current.onopen = async () => {
			try {
				await sendOffer();
			} catch (error) {
				console.error("Error sending offer:", error);
			}
		};

		const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");

		if (!isFirefox) {
			// firefox doesn't support trickle-ice, will all be in SDP
			peerConnectionRef.current.onicecandidate = async ({ candidate }) => {
				if (candidate) {
					//Sending ice candidate to server
					if (
						websocketRef.current &&
						websocketRef.current.readyState === WebSocket.OPEN
					) {
						websocketRef.current?.send(
							JSON.stringify({
								type: "candidate",
								candidate: candidate.candidate,
							})
						);
					}
				}
			};
		}

		// @todo this fails on firefox because it sucks and doesn't support trickle-ice yet
		// will fix
		websocketRef.current.onmessage = (message) => {
			const event = JSON.parse(message.data);
			switch (event.type) {
				case "sdp":
					peerConnectionRef.current?.setRemoteDescription({
						type: "answer",
						sdp: event.description,
					});

					console.log("Remote description added succesfully.");
					break;

				case "candidate":
					if (peerConnectionRef.current?.localDescription) {
						try {
							peerConnectionRef.current.addIceCandidate({
								candidate: event.candidate,
								sdpMid: event.mid,
								sdpMLineIndex: event.mLineIndex,
							});
						} catch (e) {
							console.log(e);
						}
					} else {
						console.log("Error");
					}
					break;
			}
		};
	};

	const sendOffer = async () => {
		try {
			const offer = await peerConnectionRef.current?.createOffer();
			await peerConnectionRef.current?.setLocalDescription(offer);

			if (peerConnectionRef.current?.localDescription !== null) {
				if (websocketRef.current?.readyState === WebSocket.OPEN) {
					const message = {
						type: "offer",
						sdp: peerConnectionRef.current?.localDescription.sdp,
					};
					websocketRef.current.send(JSON.stringify(message));
				}
			}
		} catch (error) {
			console.log("Error: ", error);
		}
	};

	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/session-details/${sessionId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((res: any) => {
				const endTime = addMinutes(new Date(res.dateTime), res.duration);
				if (isBefore(endTime, new Date())) {
					setSessionExpired(true);
				} else {
					if (res.livestreamStatus === true) {
						setIsLivestreamActive(true);
						setSessionDetails(res);
						// connectToDevice();
					} else {
						setIsLivestreamActive(false);
					}
				}
			});
	}, []);

	const connectToWebsocket = () => {
		if (isPlaying) {
			setIsPlaying(false);
		} else {
			setIsPlaying(true);
			if (sessionId) {
				connectToServer();
			}
		}
	};

	const connectToDevice = () => {
		if (!websocketClient.current) {
			let Sock = new SockJS(`${process.env.REACT_APP_BASE_URL}/ws`);
			websocketClient.current = over(Sock);
			websocketClient.current.debug = null;
			websocketClient.current.connect(
				{},
				() => {
					const subscribeTo = `/public/${sessionId}`;
					websocketClient.current.subscribe(subscribeTo, onMessageReceived);
				},
				() => {}
			);
		}
	};

	const onMessageReceived = (msg: any) => {
		// console.info("onMessageReceived", JSON.parse(msg.body));
		const response = JSON.parse(msg.body);
		if (response.action === "session_broadcasting") {
			setIsLivestreamActive(
				response.metadata.status === "STOPPED" ? false : true
			);
		}
	};

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleChange = (
		event: React.SyntheticEvent<Element, Event>,
		tabId: string
	) => {
		if (websocketRef.current) {
			websocketRef.current.close();
		}
		setSelectedTab(tabId);
		navigate(`/${tabId}`);
	};

	const handleCloseUserMenu = (itemId: string) => {
		setAnchorElUser(null);
		const token = localStorage.getItem("sessionId");
		fetch(`${process.env.REACT_APP_BASE_URL}/api/active-user-device`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					localStorage.setItem("activeDevice", data);
					handleNavigation(itemId, true);
				}
			})
			.catch((err) => {
				handleNavigation(itemId, false);
			});
	};

	const handleNavigation = (settingsId: string, isActiveDevice: boolean) => {
		if (websocketRef.current) {
			websocketRef.current.close();
		}
		if (settingsId === "signout") {
			signOut(user.email);
			navigate("/login");
		} else if (settingsId === "settings" && isActiveDevice) {
			navigate("/home/settings/account/account");
		} else if (settingsId === "settings" && !isActiveDevice) {
			navigate("/home/settings/account/account");
		}
	};

	const navigateTo = (path: string) => {
		if (websocketRef.current) {
			websocketRef.current.close();
		}
		navigate(path);
	};

	return (
			<Grid container justifyContent="center">
				
				<AppBar
					position="sticky"
					sx={{
						maxHeight: { xs: "64px", sm: "80px", md: "100px" },
						color: "#FFFFFF !important",
						background: "linear-gradient(360deg, #5A40F5 0%, #0273E6 100%) ",
					}}
				>
					<Container maxWidth="xl">
						<Toolbar disableGutters>
							<Grid container={true} columns={{ xs: 12 }}>
								<Grid item={true} xs={3} className="center-align">
									<img
										src={Logo}
										alt="logo"
										className="appbar-logo-custom-height"
									/>
								</Grid>
								<Grid item={true} xs={6}>
									<Grid
										container={true}
										columns={{ xs: 12 }}
										style={{
											flexGrow: 1,
											// display: "flex",
											// justifyContent: "center"
										}}
									>
										<Grid
											item={true}
											xs={12}
											className="center-align"
											sx={{ borderBottom: 1, borderColor: "divider" }}
										>
											<Grid
												container={true}
												columns={{ xs: 12 }}
												sx={{ display: "flex", justifyContent: "center" }}
											>
												<Tabs
													value={selectedTab}
													onChange={handleChange}
													textColor="inherit"
													indicatorColor="secondary"
													// style={{ height: "64px" }}
													sx={{
														maxHeight: { xs: "64px", sm: "80px", md: "100px" },
													}}
												>
													{TabsMenu.map((tabMenu) => (
														<Tab
															key={tabMenu.label}
															iconPosition="start"
															icon={
																tabMenu.id !== "support" ? (
																	<img src={tabMenu.icon} alt={tabMenu.label} />
																) : (
																	""
																)
															}
															label={
																<Typography
																	className="menu-label-typography-custom-font-size"
																	sx={{
																		textTransform: "capitalize",
																		color: "#FFFFFF",
																		margin: "0 5px",
																	}}
																>
																	{tabMenu.label}
																</Typography>
															}
															value={tabMenu.id}
															disabled={tabMenu.disabled}
															disableFocusRipple
															disableRipple
														/>
														// </Grid>
													))}
												</Tabs>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item={true} xs={3} className="center-align">
									{isUserAuthenticated ? (
										<Box sx={{ flexGrow: 0 }}>
											<Tooltip title="Open settings">
												<IconButton
													onClick={handleOpenUserMenu}
													sx={{
														p: 0,
														width: "150px",
														display: "flex",
														justifyContent: "space-evenly",
													}}
												>
													<img src={Avatar} alt="Profile pic" />
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textOverflow: "ellipsis",
															overflow: "hidden",
														}}
														color="white"
													>
														{`${user.firstName} ${user.lastName}`}
													</Typography>
													<img src={ArrowDown} alt="Down Arrow" />
												</IconButton>
											</Tooltip>
											<Menu
												sx={{ mt: "60px" }}
												id="menu-appbar"
												anchorEl={anchorElUser}
												anchorOrigin={{
													vertical: "top",
													horizontal: "right",
												}}
												keepMounted
												transformOrigin={{
													vertical: "top",
													horizontal: "right",
												}}
												open={Boolean(anchorElUser)}
												onClose={handleCloseUserMenu}
											>
												{SettingsMenu.map((menu) => (
													<ListItemButton
														disabled={menu.id === "profile"}
														key={menu.label}
														onClick={() => handleCloseUserMenu(menu.id)}
													>
														<ListItemIcon>
															<img src={menu.icon} alt={menu.label} />
														</ListItemIcon>
														<ListItemText primary={menu.label} />
													</ListItemButton>
												))}
											</Menu>
										</Box>
									) : (
										<Grid container justifyContent="center">
											<Button
												variant="text"
												sx={{ color: "#FFFFFF" }}
												onClick={() => navigateTo("/login")}
											>
												Sign In
											</Button>
											<Button
												variant="text"
												sx={{ color: "#FFFFFF", pl: "15px" }}
												onClick={() => navigateTo("/signUpEmail")}
											>
												Sign Up
											</Button>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Toolbar>
					</Container>
				</AppBar>
				<CompatibilityPopup />
				<Grid item mt="40px" minWidth="50%">
					{sessionExpired ? (
						<Typography>{i18n.liveStream.sessionEnded}</Typography>
					) : sessionDetails ? (
						<>
							<Card sx={{ borderRadius: "12px" }}>
								<CardMedia
									component="img"
									height="140"
									image={backgroundImage}
									// alt="green iguana"
								/>
								<CardContent>
									<Grid container justifyContent="space-between">
										<Grid item>
											<Typography
												variant="h5"
												component="div"
												sx={{ fontWeight: 800 }}
											>
												{sessionDetails?.sessionName}
											</Typography>
										</Grid>
										<Grid item>
											{!isUserAuthenticated && (
												<Button
													variant="outlined"
													onClick={() => {
														navigateTo("/signUpEmail");
													}}
												>
													Sign Up
												</Button>
											)}
											<Button
												variant="contained"
												disabled
												sx={{ marginLeft: "10px" }}
											>
												{i18n.liveStream.tipBand}
											</Button>
										</Grid>
									</Grid>

									<Typography
										variant="body2"
										sx={{ color: "#7A7985" }}
										gutterBottom
									>
										{`Started on ${format(
											new Date(sessionDetails.dateTime),
											"hh:mm aaa, eeee LLLL dd yyyy "
										)} `}
									</Typography>
									<Typography variant="body2" component="div" gutterBottom>
										{i18n.liveStream.desc}
									</Typography>
									<Grid item={true} xs={12}>
										<Grid
											container={true}
											justifyContent="normal"
											columns={{ xs: 12 }}
										>
											{sessionDetails.attendees.map(
												(attendee: UserInterface, index: any) => (
													<div
														key={attendee.id + index}
														style={{
															display: "flex",
															flexDirection: "row",
															marginRight: "15px",
														}}
													>
														{" "}
														<img
															src={Profile}
															alt="user-icon"
															className="music-logo-custom-height"
														/>{" "}
														<Typography
															className="attendee-field-custom-font-size"
															sx={{ color: "#3F77D7" }}
														>
															{" "}
															{`${attendee.firstName} ${attendee.lastName}`}{" "}
														</Typography>{" "}
													</div>
												)
											)}
										</Grid>
									</Grid>
								</CardContent>
							</Card>

							<Card sx={{ mt: "30px", borderRadius: "12px" }}>
								<CardHeader
									title={
										isLivestreamActive
											? i18n.liveStream.title
											: "Livestreaming stopped!!"
									}
									sx={{ pt: "10px", fontWeight: 900, color: "#5D5D65" }}
								></CardHeader>
								<CardContent>
									{isLivestreamActive && (
										<Grid container={true}>
											<Grid item>
												<IconButton onClick={connectToWebsocket}>
													<img src={isPlaying ? Pause : Play} alt="play" />
												</IconButton>
											</Grid>
											<Grid item p="0 10px">
												<img src={MusicWave} alt="music-wave" />
											</Grid>
										</Grid>
									)}
								</CardContent>
							</Card>
						</>
					) : (
						<Typography>{i18n.liveStream.sessionNotExists}</Typography>
					)}
				</Grid>
			</Grid>
	);
};

export default LiveStream;
