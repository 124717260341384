import { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import Screenshare from "../../../images/screenshare.svg";
import ScreenshareActive from "../../../images/screenshareactive.svg";
// import ScreenShareIcon from "../../../icons/ScreenShareIcon";
import {Tooltip} from "@mui/material";
import useScreenShareParticipant from "../../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";

export const SCREEN_SHARE_TEXT = "Share Screen";
export const STOP_SCREEN_SHARE_TEXT = "Stop Sharing Screen";
export const SHARE_IN_PROGRESS_TEXT =
	"Cannot share screen when another user is sharing";
export const SHARE_NOT_SUPPORTED_TEXT =
	"Screen sharing is not supported with this browser";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			"&[disabled]": {
				color: "#bbb",
				"& svg *": {
					fill: "#bbb",
				},
			},
		},
	})
);

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
	const classes = useStyles();
	const screenShareParticipant = useScreenShareParticipant();
	const { toggleScreenShare, room } = useVideoContext();
	const disableScreenShareButton = Boolean(screenShareParticipant);
	const isScreenShareSupported =
		navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
	const localParticipant = room!.localParticipant
	const isDisabled =
		props.disabled || disableScreenShareButton || !isScreenShareSupported;

	let tooltipMessage = SCREEN_SHARE_TEXT;

	if (disableScreenShareButton && screenShareParticipant !== localParticipant) {
		tooltipMessage = SHARE_IN_PROGRESS_TEXT;
	}
	
	if (disableScreenShareButton && screenShareParticipant == localParticipant) {
		tooltipMessage = STOP_SCREEN_SHARE_TEXT;
	}

	if (!isScreenShareSupported) {
		tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
	}

	const customeToggleScreenShare = () => {
		localStorage.setItem('gallery-view-active-key', 'false');
		toggleScreenShare();
	}

	useEffect(() => {
		localStorage.setItem('gallery-view-active-key', isDisabled ? 'false' : 'true');
	},[isDisabled])

	// tooltipMessage = "" + "Nkko share kru";
	return (
		<Tooltip
			title={tooltipMessage}
			placement="top"
			PopperProps={{ disablePortal: true }}
			style={{ cursor: (isDisabled && screenShareParticipant !== localParticipant) ? "not-allowed" : "pointer" }}
		>
			<span>
				{/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
				<IconButton
					className={classes.button}
					onClick={customeToggleScreenShare}
					disabled={isDisabled && screenShareParticipant !== localParticipant}
				>
					{(isDisabled && screenShareParticipant == localParticipant) ?
						<img src={ScreenshareActive} alt="Screenshare" /> :
						<img src={Screenshare} alt="Stop Screenshare" />
					}
				</IconButton>
			</span>
		</Tooltip>
	);
}
