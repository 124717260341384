import {
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Typography,
} from "@mui/material";
import SessionRecordings from "../MyMusic/SessionRecordings";
import { MusicListing } from "../MyMusic/MusicListing";
import MyActivitiesSidePanel from "./myActivitiesSidePanel";
import { useEffect, useRef, useState } from "react";
import ErrorAlert from "../Error/ErrorAlert";
import { useUser } from "../../context/UserContext";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { useAlertStatus } from "../../context/AlertStatusContext";
import SettingsAlert from "../../pages/SettingsAlert";
import AddMusicIcon from "../../images/Posts/music-square-add.svg";
import AddMusicToProfile from "./addMusicToProfile";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import User from "../../services/user";

const MyProfileMusic = ({ userDetails, id, getUserDetails }: any) => {
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [musicList, setMusicList] = useState<any>([]);
	const [activeMenuForMusicList, setActiveMenuForMusicList] =
		useState<string>("");
	const [laodMusicDetails, setLoadMusicDetails] = useState(false);
	const [openAddMusicDialog, setOpenAddMusicDialog] = useState(false);
	const [loadMusic, setLoadMusic] = useState(false);
	const { updateAlertStatus } = useAlertStatus();
	const [loading, setLoading] = useState(true);
	const inputFileRef = useRef<any>(null);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	const handleAlertClose = () => {
		setShowAlert(false);
	};

	console.log("user here:", user.userAccountStatus);
	let _isStorageAvaiable: boolean =
		user.storageUsage < user.storageCapacity ? true : false;
	const storagePercentage =
		(user.storageUsage / (user.storageCapacity as any)) * 100;
		console.log("storagePercentage "+ storagePercentage)
	const storeFull =
		Number(storagePercentage) === 100;

	const getAllMusicListing = () => {
		setLoading(true);
		let userProfileID;
		if (id) {
			userProfileID = id;
		} else {
			userProfileID = user.userProfileId;
		}

		fetch(`${process.env.REACT_APP_BASE_URL}/api/getAllMusic`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				userProfileId: userProfileID,
			}),
		})
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
				setLoading(false);
			})
			.then((data) => {
				setMusicList(data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getAllMusicListing();
	}, []);

	const addMusic = () => {
		setOpenAddMusicDialog(true);
	};
	const [subscriptions, setSubscriptions] = useState<any[]>([]);
	const [userStorageCapacity, setUserStorageCapacity] = useState();
	const [userStorageUsage, setUserStorageUsage] = useState();

	useEffect(() => {
		User.fetchUserInfo((userInfo: any) => {
		  if (userInfo.error) {
			console.error("Failed to fetch user info:", userInfo.error);
		  } else {
			setUserStorageCapacity(userInfo.storageCapacity)
			setUserStorageUsage(userInfo.storageUsage)
	
			setSubscriptions(userInfo.subscriptionInformationDTOs);
		  }
		});
	  
	  }, []);

	const musicUpload = (e: any) => {
		updateAlertStatus(true, true, "Music uploading is in process");
		const targetFiles = e.target.files[0];

		//Convert bytes to MB & Round to the nearest integer
		var roundedFileSizeInMB = Math.round(targetFiles.size / (1024 * 1024));
		// Convert MB back to bytes
		const roundedFileSizeInBytes = roundedFileSizeInMB * (1024 * 1024);
		

		const sUsage = userStorageUsage ? userStorageUsage : 0;
		const targetfileAfterupload = sUsage + roundedFileSizeInMB;
  const sCapacity = userStorageCapacity ? userStorageCapacity : 0; 
		if (targetFiles.type.startsWith("audio/")) {
			if (storeFull || targetfileAfterupload > sCapacity ) {
				updateAlertStatus(
					true,
					false,
					"Music size exceeds the maximum storage limit. Please select other file or buy storage."
				);
			// } else if (roundedFileSizeInBytes > 10 * 1024 * 1024) {
			// 	updateAlertStatus(
			// 		true,
			// 		false,
			// 		"Music size exceeds the maximum allowed limit of 10Mb. Please try again."
			// 	);
			} else {
				if (musicList.length > 10) {
					setShowAlert(true);
					setAlertStatus(false);
					setAlertMsg("Max 10 Pictures Allowed");
				} else {
					let formData = new FormData();
					formData.append("files", targetFiles);

					fetch(`${process.env.REACT_APP_BASE_URL}/api/upload-music`, {
						method: "POST",
						headers: {
							// "Content-Type": "multipart/form-data",
							Authorization: `Bearer ${token}`,
						},
						body: formData,
					})
						.then((res) => {
							if (res.status !== 200 && res.status !== 507) {
								ErrorAlert(
									res.status,
									setAlertStatus,
									setShowAlert,
									setAlertMsg
								);
							
							} 	if (res.status === 507) {
								updateAlertStatus(true, false, "Insufficient Storage..");
								} else {
								// updateAlertStatus(true, true, 'Music uploaded successfully!');
								getAllMusicListing();
								setActiveMenuForMusicList("myMusic");
								setLoadMusicDetails(true);
								updateAlertStatus(true, true, "Music uploaded successfully!");
							}
						})
						.catch((err) => {
							setShowAlert(true);
							setAlertStatus(false);
							setAlertMsg("Network Error! Please try again..");
						});
				}
				if (inputFileRef.current) {
					inputFileRef.current.value = null; // Clear the input value
					const newInput = document.createElement("input");
					newInput.style.display = "none";
					newInput.type = "file";
					newInput.addEventListener("change", musicUpload);
					inputFileRef.current.parentNode.replaceChild(
						newInput,
						inputFileRef.current
					);
					inputFileRef.current = newInput;
				}
			}
		} else {
			updateAlertStatus(true, false, "Invalid file type");
		}
	};
	return (
		<>
			<SettingsAlert />
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Grid container sx={{ width: "100%" }}>
				<Grid
					item
					xs={
						!isAdmin && userDetails.loggedInUser?.ViewerStatus === "SELF"
							? 12
							: 8
					}
					className="centerPanelSocialHome"
				>
					<Card
						sx={{
							minWidth: 300,
							flexGrow: 1,
							minHeight: "30%",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							padding: 1,
							borderRadius: "12px",
						}}
					>
						<CardContent sx={{ alignItems: "center", gap: 1, marginBottom: 1 }}>
							<Grid container columns={{ xs: 6, sm: 12, md: 12 }}>
								<Grid item xs={7}>
									<Typography variant="h6" sx={{ fontWeight: "bold" }}>
										{!isAdmin &&
										userDetails.loggedInUser?.ViewerStatus === "SELF"
											? "My Music"
											: "Profile Music"}
									</Typography>
								</Grid>
								{!isAdmin &&
									userDetails.loggedInUser?.ViewerStatus === "SELF" && (
										<Grid item xs={5}>
											<Grid container>
												<Typography
													variant="body2"
													sx={{
														mt: 1.5,
														color: "#5D5D65",
														mr: 3,
														ml: "auto",
													}}
												>
													Max 10 allowed
												</Typography>
												<Button
													sx={{
														borderRadius: "20px",
														border: "1px solid #0273E6",
														color: "#0273E6",
														fontSize: "16px !important",
														px: 1,
														height: "30px !important",
														maxHeight: "30px !important",
														pt: "2px",
														fontFamily: "Lexend",
														fontWeight: 400,
														"&:hover": {
															background: "#EEF6FF",
															border: "1px solid #0273E6",
														},
													}}
													size="small"
													variant="outlined"
													//onClick={() => setOpenAddMusicDialog(true)}
													onClick={() => {
														addMusic();
													}}
													disabled={
														user.userAccountStatus === "SUSPEND" || isAdmin
													}
													// disabled={musicList.length > 10 || user.userAccountStatus === "SUSPEND"}
												>
													<img src={AddMusicIcon} />
													&nbsp; Add Music
												</Button>
											</Grid>
										</Grid>
									)}
							</Grid>
						</CardContent>

						{!isAdmin && userDetails.loggedInUser?.ViewerStatus === "SELF" ? (
							<SessionRecordings
								activeMenu={
									activeMenuForMusicList ? activeMenuForMusicList : "myMusic"
								}
								laodMusicDetails={laodMusicDetails}
								profileMusic={true}
								userProfileID={id ? id : user.userProfileId}
								setLoadMusic={setLoadMusic}
								loadMusic={loadMusic}
								getUserDetails={getUserDetails}
								storagePercentage={storagePercentage}
							/>
						) : loading ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "100px",
								}}
							>
								<CircularProgress />
							</div>
						) : musicList.length === 0 ? (
							<Typography
								variant="body1"
								sx={{
									display: "flex",
									justifyContent: "center",
									marginBottom: 2,
									fontSize: "1.125rem",
								}}
							>
								No music present
							</Typography>
						) : (
							musicList.map((music: any) => (
								<Box sx={{ padding: "0 8px", marginBottom: 1 }}>
									<MusicListing
										getAllMusicListing={getAllMusicListing}
										music={music}
										id={id}
									/>
								</Box>
							))
						)}
					</Card>
				</Grid>
				{isAdmin && userDetails.loggedInUser?.ViewerStatus !== "SELF" && (
					<Grid item xs={4} className="hideInTabPro" sx={{ pl: "20px" }}>
						<MyActivitiesSidePanel />
					</Grid>
				)}
			</Grid>
			<AddMusicToProfile
				musicOpen={openAddMusicDialog}
				handleClose={() => setOpenAddMusicDialog(false)}
				setLoadMusic={setLoadMusic}
			/>
		</>
	);
};

export default MyProfileMusic;
