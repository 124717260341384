import AdsComponent from "../commonComponents/AdsComponent";

export default function GoogleAds() {
    return (
        <>
            <h1>This is the component for testing google ads for testing adsense</h1>

            <div style={{height: "350px", width: "500px", backgroundColor: "grey"}}>
                <AdsComponent dataAdSlot="6769027983" />
            </div>
        </>
    );
}