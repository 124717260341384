import React, { useEffect, useState } from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import user from './services/user';

interface PrivateRouteProps {
  element: React.ReactElement;
  allowedRoles: string[]; // An array of roles allowed to access the route
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, allowedRoles}) => {
  const [resolvedUserRole, setResolvedUserRole] = useState<string[] | null>(null);
  const navigate = useNavigate();
  const getUserRole = (): Promise<string[]> => {
		return new Promise<string[]>((resolve, reject) => {
			user.fetchUserInfo((res: any) => {
				if (res && res.authorities && Array.isArray(res.authorities)) {
					const authorities = res.authorities.map(
						(authority: { name: string }) => authority.name
					);
					resolve(authorities);
				} else {
					reject(new Error("Invalid user information"));
				}
			});
		});
	};

  const authority = getUserRole();

  useEffect(() => {
    const resolveUserRole = async () => {
      try {
        const roles = await authority;
        setResolvedUserRole(roles);
      } catch (error) {
        // Handle promise rejection
        console.error(error);
        setResolvedUserRole([]); // Set to an empty array or handle it accordingly
      }
    };

    resolveUserRole();
  }, []);

  if (resolvedUserRole === null) {
    // Promise is pending, you can show a loading indicator
    return null;
  } else if (resolvedUserRole.some(role => allowedRoles.includes(role))) {
    return element;
  } else {
    navigate("/home")
    return <p style={{display: "flex" ,alignSelf: "center"}}>permission denied</p>;
  }
};

export default PrivateRoute;
