import { Button, ButtonBase, Grid, IconButton, Paper, Typography } from "@mui/material";
import Group from "../../images/Group.svg";
import HorizontalCard from "../commonComponents/HorizontalCardComp";

const dummyData = [
    {
        "id": "1",
        "type": "EVENT",
        "title": "test event",
        "description": "Live event by Taylor Swift. Playing her latest songs and lot of fun. Come join us for this event and enjoy",
        "cost": "105$",
        "address": "Pune, Maharashtra, India",
        "startDateTime": "2023-09-20 07:30:14",
        "endDateTime": "2023-09-20 08:30:14",
        "timezone": "Asia/Calcutta",
        "duration": "30",
        "requirements": "",
        "instruments": "",
        "thumbnailUrl": "/nwusr7/8e11182872cb48d7",
        "bannerUrl": "",
        "userProfile": {
        "id": "1"
        }
    },
    {
        "id": "2",
        "type": "EVENT",
        "title": "test event 12345",
        "description": "Live event by Taylor Swift. Playing her latest songs and lot of fun. Come join us for this event and enjoy Live event by Taylor Swift. Playing her latest songs and lot of fun. Come join us for this event and enjoy",
        "cost": "10$",
        "address": "Mumbai, Maharashtra, India",
        "startDateTime": "2023-12-5 07:30:14",
        "endDateTime": "2023-09-20 08:30:14",
        "timezone": "Asia/Calcutta",
        "duration": "30",
        "requirements": "",
        "instruments": "",
        "thumbnailUrl": "/nwusr7/8e11182872cb48d7",
        "bannerUrl": "",
        "userProfile": {
        "id": "1"
        }
    }
]
const ViewMoreEvents =(props:any)=>{
    return(
        <Grid className="center-align" spacing={{ xs: 1 }}>
            <Grid container={true} className="column" sx={{width: "80%"}} rowSpacing={{ xs: 1 }}>
                <Grid item={true} sx={{zIndex: 99, width: "100%", backgroundColor: "white"}} >
                    <Grid container={true} columns={{ xs: 12 }} columnSpacing={{ xs: 1 }}>
                        <Grid item={true} xs={12} className="verticalAlign" >
                            <Button variant="text" startIcon={<img src={Group} alt="Back to previous page" />} onClick={() => { window.history.back(); }} >
                                <span style={{color: "#0273E6"}}>Back</span>
                            </Button>
                            {dummyData? dummyData.map((data:any) => (
                                <Grid item sx={{marginBottom:"12px"}}>
                                    <HorizontalCard eventData={data}/>                           
                                </Grid>   
                                )):
                                <Grid container item justifyContent="center">Not Found</Grid>
                            }       
                           </Grid>
                     </Grid>
                 </Grid>
             </Grid>
         </Grid> 
                            
    )
}

export default ViewMoreEvents;