import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  CircularProgress
} from "@mui/material";
import { isEmpty, isEqual } from 'lodash';
import CloseIcon from "../../../images/close.svg";
import i18n from "../../../l10n/strings.json";
import { useAdminDevciceManagement } from "./useAdminDeviceManagement";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import { Manufacturer, MfgYears } from "./AdminConstants";

interface Device {
  deviceId: string,
  deviceManufacturedBy: string,
  deviceManufacturedYear: string,
  deviceVersion: string,
  id: number
}
const initialDevice = { deviceId: '', deviceManufacturedBy: '', deviceManufacturedYear: '', deviceVersion: '', id: 0 };
export default function AdminDeviceAddEditModal(props: any) {

  const token = localStorage.getItem("sessionId");

  const { open, onClose, isEditType = false, currentDevice = {} } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [device, setDevice] = useState<Device>(initialDevice);

  const { updateAlertStatus } = useAlertStatus();
  const { fetchDevicesData, manufacturer } = useAdminDevciceManagement();

  useEffect(() => {
    isEditType && setDevice(currentDevice)
  }, [currentDevice]);


  const handleChange = (type: string, value: any) => {
    setDevice({
      ...device,
      [type]: value
    })
    console.log(type, value)
  }


  const handleSubmit = async () => {
    if (isEditType) {
      await updateDeviceDetails();
    } else {
      await addNewDevice();
    }
  }

  const addNewDevice = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/devices`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          deviceId: device.deviceId,
          deviceManufacturedBy: device.deviceManufacturedBy,
          deviceManufacturedYear: device.deviceManufacturedYear,
          deviceVersion: device.deviceVersion
        }),
      });

      if (response.ok) {
        setTimeout(() => {
          setIsLoading(false);
          fetchDevicesData();
          handleCloseModal()
          updateAlertStatus(true, true, 'New Device Details Added Successfully!');
        }, 1000);
      } else {
        const { message = '' } = await response.json();
        updateAlertStatus(true, false, message);
        setIsLoading(false);
      }
    } catch (error) {
      updateAlertStatus(true, false, 'Network Error! Please try again..');
      setIsLoading(false);
    }
  };

  const updateDeviceDetails = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/devices/${device.id}`, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          deviceId: device.deviceId,
          deviceManufacturedBy: device.deviceManufacturedBy,
          deviceManufacturedYear: device.deviceManufacturedYear,
          deviceVersion: device.deviceVersion
        }),
      });

      if (response.ok) {
        setTimeout(() => {
          setIsLoading(false);
          fetchDevicesData();
          handleCloseModal()
          updateAlertStatus(true, true, 'Device Details Updated Successfully!');
        }, 1000);

      } else {
        const { message } = await response.json()
        updateAlertStatus(true, false, message);
        setIsLoading(false);
      }
    } catch (error) {
      updateAlertStatus(true, false, 'Network Error! Please try again..');
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setDevice(initialDevice);
  }

  const checkIfDisabled = () => {
    const isDeviceDetailsSufficient = isEmpty(device.deviceId) || isEmpty(device.deviceManufacturedBy);
    if (isEditType) {
      return isDeviceDetailsSufficient || isEqual(device, currentDevice)
    }
    return isDeviceDetailsSufficient;
  };

  return (
    <Dialog
      sx={{
        border: "1px solid #888888",
        boxShadow: "customColor.dropShadow",
        borderRadius: "8px",
      }}
      fullWidth
      maxWidth="xs"
      open={open}
    >
      <DialogTitle
        className="edit-heading-name"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            aria-label="close dialogbox"
            onClick={handleCloseModal}
            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
              event.preventDefault()
            }
            edge="end"
            sx={{ marginLeft: "90%" }}
          >
            <img src={CloseIcon} alt="close" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Typography
            // className="dialog-title-custom-size"
            sx={{
              fontSize: "20px",
              fontWeight: 200,
              color: "#000000",
            }}
          >
            {isEditType ? 'Edit' : 'Add'} Device
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ padding: "5px 20px" }}>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12}
            sx={{
              div: {
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "20px",
              },
            }}
          >
            <FormHelperText sx={{ marginLeft: "0px !important" }}>
              <Typography
                variant="body4"
                color="customColor.label"
                sx={{ marginLeft: "0px !important" }}
              >
                {i18n.deviceManagement.filters.deviceIdTitle}
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </FormHelperText>

            <TextField
              inputProps={{
                style: {
                  background: "#F8F8F8",
                  fontStyle: "normal",
                  fontSize: "1rem",
                  lineHeight: "25px",
                  fontWeight: 900,
                  color: "#000000",
                  padding: "15px",
                },
              }}
              fullWidth
              variant="outlined"
              value={device.deviceId}
              onChange={(event) =>
                handleChange("deviceId", event.target.value)
              }
              // error={userManagementFilter.cities.length > 64}
              size="small"
            />

          </Grid>
          <Grid
            item={true}
            xs={12}
            sx={{
              div: {
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "20px",
              },
            }}
          >
            <FormHelperText sx={{ marginLeft: "0px !important" }}>
              <Typography
                variant="body4"
                color="customColor.label"
                sx={{ marginLeft: "0px !important" }}
              >
                {i18n.deviceManagement.filters.manufacturedByTitle}
              </Typography>
              <span style={{ color: "red" }}>*</span>
            </FormHelperText>
            <Select
              value={parseInt(device.deviceManufacturedBy)}
              defaultValue={parseInt(device.deviceManufacturedBy)}
              onChange={(event: any) => handleChange('deviceManufacturedBy', event.target.value)}
              sx={{
                backgroundColor: "customColor.gray1",
                color: "customColor.black",
                width: "100%",
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "20px",
              }}
              MenuProps={{
                className: "newEvent",
              }}
            >
              {manufacturer.map(({ manufacturerName, id }: Manufacturer) => (
                <MenuItem key={id} value={id}>
                  {manufacturerName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            item={true}
            xs={6}
            sx={{
              div: {
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "20px",
              },
            }}
          >
            <FormHelperText sx={{ marginLeft: "0px !important" }}>
              <Typography
                variant="body4"
                color="customColor.label"
                sx={{ marginLeft: "0px !important" }}
              >
                {i18n.deviceManagement.filters.manufacturedYearTitle}
              </Typography>
            </FormHelperText>
            <Select
              value={parseInt(device.deviceManufacturedYear)}
              onChange={(event: any) => handleChange('deviceManufacturedYear', event.target.value)}
              sx={{
                backgroundColor: "customColor.gray1",
                color: "customColor.black",
                width: "100%",
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "20px",
              }}
              MenuProps={{
                className: "newEvent",
              }}
            >
              {MfgYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6} marginBottom={2}>
            <FormHelperText sx={{ marginLeft: "0px !important" }}>
              <Typography
                variant="body4"
                color="customColor.label"
                sx={{ marginLeft: "0px !important" }}
              >
                {i18n.deviceManagement.filters.deviceVersionTitle}
              </Typography>
            </FormHelperText>

            <TextField
              inputProps={{
                style: {
                  background: "#F8F8F8",

                  fontStyle: "normal",
                  fontSize: "1rem",
                  lineHeight: "25px",
                  fontWeight: 900,
                  color: "#000000",
                  padding: "15px",
                },
              }}
              fullWidth
              variant="outlined"
              value={device.deviceVersion}
              onChange={(event) =>
                handleChange("deviceVersion", event.target.value)
              }
              // error={userManagementFilter.cities.length > 64}
              size="small"
            />

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container columnSpacing={1}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Grid item={true}>
            <Button
              className="cancel-button"
              size="large"
              variant="outlined"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              className="submit-button"
              size="large"
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoading || checkIfDisabled()}
            >
              {
                isLoading ? <> Submitting... <CircularProgress size={18} /> </> :
                  <>{isEditType ? 'Update' : 'Add'} Device</>
              }
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
