import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	Typography,
	Divider,
	Grid,
	FormHelperText,
	TextField,
	Checkbox,
	FormControlLabel,
	Autocomplete,
	Button,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../../context/UserContext";
import i18n from "../../../l10n/strings.json";
import InstrumentSkillLevel from "../../Musicians/InstrumentSkills";


import ErrorAlert from "../../Error/ErrorAlert";

// import useMusiciansFilter from "../../hooks/useMusicians/useMusiciansFilter";
import { useUserManageFilter } from "./UserManagementProvider/useUserManageFilter";
import { useTheme } from "@mui/material/styles";

type RouteParams = {
	menuId: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			padding: "1% 17px",
			borderRadius: "15px",
			//boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
		backBtn: {
			width: "100%",
			fontWeight: "500 !important",
			color: "#0273E6 !important",
		},
		root: {
			color: "white",
			// '& .MuiAutocomplete-tag': {
			//     backgroundColor: 'blue',
			//     color: 'white'
			// },
			"& .MuiSvgIcon-root": {
				color: "white",
			},
			"& .MuiAutocomplete-endAdornment": {
				"& .MuiSvgIcon-root": {
					color: "#000 !important",
				},
			},
			"& .MuiChip-root.MuiChip-filled": {
				backgroundColor: "#0273E6",
				fontFamily: "Lexend",
				fontWeight: "400",
				color: "white",
				"& svg.MuiChip-deleteIcon": {
					color: "white !important",
				},
				"& span.MuiAutocomplete-tag ": {
					color: "black !important",
					backgroundColor: "white !important",
				},
			},
		},
	})
);

type Genres = {
	// id: string;
	title: string;
};

type Instrument = {
	id: string;
	instrumentName: string;
	icon?: string;
};

const genreFilter: Array<Genres> =[
	{ "title": "Americana" },
	{ "title": "Avant-garde" },
	{ "title": "Baroque" },
	{ "title": "Big Band" },
	{ "title": "Bluegrass" },
	{ "title": "Blues" },
	{ "title": "Bollywood" },
	{ "title": "Broadway/Showtunes" },
	{ "title": "Chamber-Music" },
	{ "title": "Classic Rock" },
	{ "title": "Classical" },
	{ "title": "Contemporary Classical" },
	{ "title": "Country" },
	{ "title": "Disco" },
	{ "title": "Drumline" },
	{ "title": "EDM" },
	{ "title": "Electronic/Dance" },
	{ "title": "Folk" },
	{ "title": "Funk" },
	{ "title": "Gospel" },
	{ "title": "Hip-hop/Rap" },
	{ "title": "House" },
	{ "title": "Indie/Alternative" },
	{ "title": "Jazz" },
	{ "title": "K-Pop" },
	{ "title": "Latin" },
	{ "title": "Metal" },
	{ "title": "Minimalism" },
	{ "title": "New Age" },
	{ "title": "Opera" },
	{ "title": "Orchestral" },
	{ "title": "Pop" },
	{ "title": "Punk" },
	{ "title": "R&B" },
	{ "title": "Ragtime" },
	{ "title": "Reggae" },
	{ "title": "Reggaeton" },
	{ "title": "Religious" },
	{ "title": "Rock" },
	{ "title": "Romantic" },
	{ "title": "Salsa" },
	{ "title": "Ska" },
	{ "title": "Soul" },
	{ "title": "Soundtrack" },
	{ "title": "Swing" },
	{ "title": "Symphonic" },
	{ "title": "Techno" },
	{ "title": "Wind Ensemble" }
];

const UserManagementFilter = () => {
	const param = useParams<RouteParams>();
	const classes = useStyles();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const {
		userManagementFilter,
		setUserManagementFilter,
		isFilterActive,
		resetUserManagementFilter,
	} = useUserManageFilter();

	const [pageTitle, setPageTitle] = useState("");
	const [genres, setGenres] = useState("");
	const [instrumentList, setInstrumentList] = useState<Array<Instrument>>([]);
	const [selectedInstrumentFilter, setSelectedInstrumentsFilter] = useState<
		Array<Instrument> | []
		>([]);
	const theme = useTheme();
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [joinDate, setJoinDate] = useState<Date | null>(null);
	const updateSelectedInstruments = (newInstrumentSet: any) => {
		setSelectedInstrumentsFilter(newInstrumentSet);
	};

	const updateInstrumentFilter = (instrument: string, skillLevel: number) => {
	};

	const updateSearchFilter = (field: string, value: any) => {


		switch (field) {
			case "genres":
				var selectedGenres: any = [];
				value.map((genre: any) => {
					selectedGenres.push(genre.title);
				});
				setUserManagementFilter({
					...userManagementFilter,
					isGlobalSearch: false,
					genres: selectedGenres,
				});
				break;
			case "instruments":
				var selectedInstrument: any = [];
				value.map((inst: any) => {
					selectedInstrument.push(inst.instrumentName);
				});
				setUserManagementFilter({
					...userManagementFilter,
					isGlobalSearch: false,
					instrument: selectedInstrument,
				});
				break;
			case "collaboration":
				setUserManagementFilter({
					...userManagementFilter,
					isGlobalSearch: false,
					isOpenForCollaboration: value,
				});
				break;
			case "location":
				setUserManagementFilter({
					...userManagementFilter,
					cities: value,
					isGlobalSearch: false,
				});
				break;
			case "acceptGigs":
				setUserManagementFilter({
					...userManagementFilter,
					acceptGigs: value,
					isGlobalSearch: false,
				});
				break;

			default:
				break;
		}
	};

	const clearFilter = () => {
		resetUserManagementFilter();
	};

	useEffect(() => {
		getInstrumentsList();
	}, []);

	const getInstrumentsList = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setInstrumentList(data);
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	return (
		<>
			<Grid

				container
				margin={0.5}
				xs={12}
				sx={{ height: "fit-content", position: "fixed", width: "288px" }}
				// sx={{
				// 	position: "relative",
				// 	width: "100%",
				// }}
				className={`${classes.shadowedBox}`}
			>
				<Grid item xs={12}>
					<Typography
						variant="h5"
						sx={{
							textAlign: "center",
							fontWeight: "500 !important",
							fontSize: "24px !important",
						}}
					>
						Filters
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<FormHelperText className="jamsession-label">
						{i18n.usersmanagement.filters.genresTitle}
					</FormHelperText>
					{/* <TextField
                        inputProps={{
                            style: {
                                background: "#F8F8F8",

                                fontStyle: "normal",
                                fontSize: "1.125rem",
                                lineHeight: "25px",
                                fontWeight: 900,
                                color: "#000000",
                                // padding:"10px",
                            },
                        }}
                        fullWidth
                        variant="outlined"
                        value={genres}
                        onChange={(event) =>
                            setGenres(event.target.value.trimStart())
                        }
                        error={genres.length > 64}
                        size="small"
                    ></TextField> */}
					<Autocomplete
						multiple
						limitTags={1}
						id="multiple-limit-tags"
						options={genreFilter}
						getOptionLabel={(option) => option.title}
						classes={classes}
						// getOptionDisabled={(option) => !!selectedInstrumentFilter.find(element => element.id === option.id)}
						// defaultValue={}
						value={genreFilter.filter((genre: any) => {
							return userManagementFilter.genres.includes(genre.title as never);
						})}
						renderInput={(params) => <TextField {...params} />}
						sx={{ width: "100%" }}
						disableClearable={false}
						onChange={(event, newGenres) =>
							updateSearchFilter("genres", newGenres)
						}
					/>
					<FormHelperText
						sx={{
							fontSize: "0.8125rem",
							color: "#FD4B24",
							textAlign: "right",
						}}
					>
						{genres?.length > 64 ? "Enter maximum 64 characters" : ""}
					</FormHelperText>
				</Grid>

				{/* <Grid item xs={12}>
                    <FormHelperText className="jamsession-label">
                        {i18n.musicians.filters.dateTitle}
                    </FormHelperText>
                    <TextField
                        inputProps={{
                            style: {
                                background: "#F8F8F8",

                                fontStyle: "normal",
                                fontSize: "1.125rem",
                                lineHeight: "25px",
                                fontWeight: 900,
                                color: "#000000",
                                // padding:"10px",
                            },
                        }}
                        fullWidth
                        variant="outlined"
                        value={genres}
                        onChange={(event) =>
                            setGenres(event.target.value.trimStart())
                        }
                        error={genres.length > 64}
                        size="small"
                    ></TextField>
                    <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64
                            ? "Enter maximum 64 characters"
                            : ""}
                    </FormHelperText>
                </Grid> */}

				{/* <Grid item xs={12}>
					<FormHelperText sx={{ ml: 0 }}>
						<Typography variant="body4" color="customColor.label">
							Join Date
						</Typography>
					</FormHelperText>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							openTo="day"
							views={["year", "month", "day"]}
							inputFormat="MM/dd/yyyy"
							// minDate={minDate}
							value={joinDate}
							onChange={(newValue: Date | null) => {
								// if (checkIsValidDate(newValue)) {
								setJoinDate(newValue);
								// }
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									sx={{
										"&.MuiTextField-root": {
											backgroundColor: "customColor.gray1",
											width: "100%",
										},
										input: {
											color: "customColor.black",
											fontSize: theme.typography.subtitle1.fontSize,
											fontWeight: theme.typography.subtitle1.fontWeight,
										},
									}}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid> */}

				<Grid item xs={12}>
					<FormHelperText className="jamsession-label">
						{i18n.usersmanagement.filters.cityTitle}
					</FormHelperText>
					<TextField
						inputProps={{
							style: {
								background: "#F8F8F8",

								fontStyle: "normal",
								fontSize: "1rem",
								lineHeight: "25px",
								fontWeight: 900,
								color: "#000000",
								padding: "15px",
							},
						}}
						fullWidth
						variant="outlined"
						value={userManagementFilter.cities}
						onChange={(event) =>
							updateSearchFilter("location", event.target.value)
						}
						error={userManagementFilter.cities.length > 64}
						size="small"
					></TextField>
					<FormHelperText
						sx={{
							fontSize: "0.8125rem",
							color: "#FD4B24",
							textAlign: "right",
						}}
					>
						{genres?.length > 64 ? "Enter maximum 64 characters" : ""}
					</FormHelperText>
				</Grid>

				{/* <Grid item xs={12}>
                    <FormHelperText className="jamsession-label">
                        {i18n.musicians.filters.distanceTitle}
                    </FormHelperText>
                    <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
                    <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64
                            ? "Enter maximum 64 characters"
                            : ""}
                    </FormHelperText>
                </Grid> */}

				<Grid item xs={12}>
					<FormHelperText className="jamsession-label">
						{i18n.usersmanagement.filters.instrumentsTitle}
					</FormHelperText>
					<Autocomplete
						multiple
						limitTags={1}
						id="multiple-limit-tags1"
						options={instrumentList}
						getOptionLabel={(option) => option.instrumentName}
						classes={classes}
						// getOptionDisabled={(option) => !!selectedInstrumentList.find(element => element.id === option.id)}
						// defaultValue={}
						value={instrumentList.filter((ins: any) => {
							return userManagementFilter.instrument.includes(
								ins.instrumentName as never
							);
						})}
						renderInput={(params) => <TextField {...params} />}
						sx={{ width: "100%" }}
						onChange={(event, newInstrument) =>
							updateSearchFilter("instruments", newInstrument)
						}
					/>
				</Grid>

				{/* Instruments Music Notes */}
				<Grid item xs={12} marginY={1}>
					<Grid item>
						{selectedInstrumentFilter.map((instrument) => {
							return (
								<InstrumentSkillLevel
									instrument={instrument.id}
									skillLevel={0}
									editable={true}
									updateInstrumentFilter={updateInstrumentFilter}
								/>
							);
						})}
					</Grid>
				</Grid>



				{/* Hiding Content for Social Site Launch */}
				{/* <Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(event) =>
									updateSearchFilter("collaboration", event.target.checked)
								}
								sx={{ "&.Mui-checked": { color: "#0273e6" } }}
							/>
						}
						label={
							<span
								style={{
									color: "#7A7985",
									fontSize: "1rem",
									fontFamily: "Lexend",
									fontWeight: "500",
								}}
							>
								Open for Collaboration
							</span>
						}
						checked={userManagementFilter.isOpenForCollaboration}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(event) =>
									updateSearchFilter("acceptGigs", event.target.checked)
								}
								sx={{ "&.Mui-checked": { color: "#0273e6" } }}
							/>
						}
						label={
							<span
								style={{
									color: "#7A7985",
									fontSize: "1rem",
									fontFamily: "Lexend",
									fontWeight: "500",
								}}
							>
								Accept Gigs
							</span>
						}
						checked={userManagementFilter.acceptGigs}
					/>
				</Grid> */}

				{isFilterActive && (
					<>
					<Grid item xs={12}>
					<Divider />
				</Grid>
					<Grid container xs={12} justifyContent="center" marginY={2}>
						<Button
							variant="text"
							sx={{ border: "2px solid #0273E6", color: "#0273E6" }}
							size="small"
							onClick={() => clearFilter()}
						>
							<Typography variant="h6">Clear Filter</Typography>
						</Button>
					</Grid>
					</>
				)}
			</Grid>
		</>
	);
};

export default UserManagementFilter;
