import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
	Box,
	Button,
	FormControl,
	TextField,
	Grid,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Snackbar,
	Alert,
	AlertTitle,
} from "@mui/material";
import User from "../services/user";
import LeftPanel from "./LeftPanel";
import i18n from "../l10n/strings.json";
import { useGradient2Styles } from "../theme";
import ErrorMark from "../images/danger.svg";
import RightAarrow from "../images/right arrow.svg";
import SuccessIcon from "../images/tick-square.svg";
import ErrorIcon from "../images/danger.svg";

type RouterState = {
	email: string;
	referrer?: string;
	sid?: number;
	sessionId: string;
};

const CodeVerification = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const locationState = location.state as RouterState | undefined;
	const email = locationState?.email;
	const token = localStorage.getItem("sessionId");
	const gradient = useGradient2Styles();

	const [code, verifyCode] = useState("");
	const [error, setErrorMessage] = useState(false);
	const [attemptMsg, setAttemptMsg] = useState("");
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [loginOTPAttempt, setLoginOTPAttempt] = useState(0);
	const [cardContainerHeight, setCardContainerheight] = useState<
		number | undefined
	>(0);
	const [buttondisable, setButtonDisable] = useState(false);

	useEffect(() => {
		const element = document.getElementById("right-pannel-header");
		const rect = element?.getBoundingClientRect();
		setCardContainerheight(rect?.height);
	}, []);

	const resendCode = () => {
		setButtonDisable(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/resend-otp`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${locationState?.sessionId}`,
			},
			body: email,
		})
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				if (result?.code == 1001) {
					setAlertMsg(result.message);
					setShowAlert(true);
					setAlertStatus(false);
				} else {
					setErrorMessage(false);
				}
				if(result.status > 400){
					setErrorMessage(true);
				}
			});
		setTimeout(() => {
			setButtonDisable(false);
		}, 5000);
	};

	const Verify = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		fetch(`${process.env.REACT_APP_BASE_URL}/api/validate-otp`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${locationState?.sessionId}`,
			},
			body: JSON.stringify({
				otp: code,
				userEmail: email,
			}),
		})
			.then((response) => {
				if (response.status >= 400) {
					setAttemptMsg("Invalid Code");
					setErrorMessage(true);
					localStorage.setItem("isVerified", "false");
					return response.json();
				} else {
					if (locationState?.sessionId) {
						localStorage.setItem("sessionId", locationState.sessionId);
						localStorage.setItem("isVerified", "true");
					}
					User.fetchUserInfo(() => {
						if (
							locationState?.referrer === "jamsession" &&
							locationState?.sid
						) {
							navigate(`/jamsession?sid=${locationState.sid}`);
							setErrorMessage(false);
						} else {
							navigate("/home");
							setErrorMessage(false);
						}
					});
				}
			})
			.then((result) => {
				console.log("json", result);
				if(result?.code === 1009) {
					if (result.message === "OTP_Expired"){
						setAlertMsg("OTP Expired");
						setAttemptMsg("OTP Expired");
						setLoginOTPAttempt(result.loginOtpAttempt);
						setShowAlert(true);
						setAlertStatus(false);
					} else if (result.message === "OTP_Invalid") {
						setAttemptMsg("Invalid Code");
						setLoginOTPAttempt(result.loginOtpAttempt);
					}else {
						setAlertMsg(result.message);
						setAttemptMsg("3 Attempts Exceeded.");
						setLoginOTPAttempt(result.loginOtpAttempt);
						setShowAlert(true);
						setAlertStatus(false);
					}
					
				}
			});
	};

	const calculateRemainingAttempt = () => {
		return 3 - loginOTPAttempt;
	}

	return (
		<>
		{showAlert && (
			<Snackbar
				sx={{
					"&.MuiSnackbar-anchorOriginTopCenter": {
						top: "80px",
					},
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={showAlert}
				autoHideDuration={6000}
				onClose={() => setShowAlert(false)}
			>
				<Alert
					onClose={() => setShowAlert(false)}
					icon={
						alertStatus ? (
							<img src={SuccessIcon} alt="error-icon" style={{height: "32px", width: "32px", marginTop: "3px"}} />
						) : (
							<img
								src={ErrorIcon}
								alt="error-icon"
									style={{ marginRight: "10px", height: "32px", width: "32px", marginTop: "3px" }}
							/>
						)
					}
					sx={{
						backgroundColor: "#FFFFFF",
						boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
						borderRadius: "6px",
						fontSize: "1.125rem",
						fontWeight: "500",
						color: "black",
					}}
				>
					<AlertTitle
						sx={{
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "#000000",
							display: "flex",
							margin: "auto",
							padding: "4px",
							justifyContent: "center !important",
						}}
					>
						{alertMsg}
					</AlertTitle>
				</Alert>
			</Snackbar>
		)}
		<form autoComplete="off" noValidate onSubmit={Verify}>
			<Grid container={true} columns={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
				<Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
					<LeftPanel />
				</Grid>
				<Grid
					item
					xs={6}
					sm={6}
					md={4}
					lg={4}
					xl={4}
					className="alignCenterTabLogin"
				>
					<Grid container={true} className="column">
						<Grid item className="right-align" id="right-pannel-header">
							<Grid
								container={true}
								columns={{ xs: 12, sm: 12, md: 12 }}
								className="column right-align"
								rowSpacing={{ xs: 1, sm: 3, md: 5, xl: 10, xxl: 20 }}
							>
								<Grid item={true} xs={12} className="right-align">
									<Box className="row" sx={{ padding: "24px" }}>
										<Typography
											className="typography-field-custom-font-size"
											sx={{ marginTop: "1rem !important" }}
										>
											{i18n.signUp.notMemberMsg}
										</Typography>
										<Button
											component={Link}
											to="/signUpEmail"
											className="signuplink button-field-custom-font-size"
											variant="outlined"
											sx={{
												fontWeight: "900",
												marginRight: "5vw",
												backgroundColor: "#ffffff",
												borderRadius: "18px",
												color: "#0273E6",
												border: "1px solid #0273E6",
												height: "36px",
											}}
										>
											{i18n.signUp.title}
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} className="center-align">
							<Card
								component="section"
								sx={{
									//position: "relative",
									//top: `calc(40% - ${cardContainerHeight}px)`,
									boxShadow: "none",
									paddingBottom: 0,
									//marginTop: "100px",
									marginTop: "5vh",
									width: "80%",
								}}
							>
								<CardContent className="center-align" sx={{ py: 1, px: 3 }}>
									<FormControl sx={{ width: "80%", py: 1, px: 2 }}>
										<Grid
											container={true}
											columns={{ xs: 12, sm: 12, md: 12 }}
											rowSpacing={{ xs: 0, sm: 1, md: 2.5, lg: 3 }}
										>
											<Typography className="screen-title-custom-size">
												{i18n.login.TwoStepVerificationTitle}
											</Typography>
											<Grid item={true} xs={12}>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														textAlign: "start",
													}}
												>
													{i18n.login.TwoStepVerificationInstruction}
												</Typography>
											</Grid>
											<Grid item={true} xs={12}>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														textAlign: "start",
														color: "#707081",
													}}
												>
													{i18n.login.TwoStepVerificationRequestMsg}
												</Typography>
											</Grid>
											<Grid item={true} xs={12}>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														textAlign: "start",
													}}
												>
													{i18n.login.TwoStepVerificationHelperMsg}
												</Typography>
											</Grid>
											<Grid item={true} xs={12}>
												<Grid
													sx={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													<Typography
														className="typography-field-custom-font-size"
														sx={{ color: "#707081" }}
													>
														{i18n.login.verificationCodeFieldName}
													</Typography>
													<Typography
														className={`typography-field-custom-font-size right-align ${
															buttondisable ? "disabled" : ""
														}`}
													>
														<a
															href="/#/codeVerify"
															style={{
																color: "#0A6BFE",
															}}
															onClick={resendCode}
														>
															{i18n.dialog.resendCode}
														</a>
													</Typography>
												</Grid>
												<TextField
													id="username"
													className="responsive-outline-input-size"
													fullWidth
													sx={{
														background: "#F8F8F8",
													}}
													value={code}
													onChange={(e) => verifyCode(e.target.value)}
													variant="outlined"
													error={error}
												/>
												{/* Added error mark */}
												<Box
													sx={{
														mt: "5px",
														mr: "7px",
														display: "flex",
														//alignItems: "flex-end",
														justifyContent: "flex-end",
													}}
												>
													<Box mr={1}>
														{error ? (
															<img
																src={ErrorMark}
																alt="error-mark"
																height="20px"
																style={{marginTop: "3px"}}
															/>
														) : (
															""
														)}
													</Box>
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textAlign: "end",
															color: "#FD4B24",
														}}
													>
															{error ? (attemptMsg === "Invalid Code" ? `${attemptMsg} ${calculateRemainingAttempt()} Attempt Remaining` : attemptMsg) : ""}
													</Typography>
												</Box>
											</Grid>
											<Grid
												item={true}
												xs={12}
												style={{
													paddingLeft: 0,
												}}
											>
												<Button
													className={`submit-button ${gradient.gradient2}`}
													fullWidth
													size="large"
													variant="contained"
													sx={{
														height: "48px",
														fontWeight: "500",
														lineHeight: "22.5px",
														borderRadius: "24px",
													}}
													disabled={code === ""}
													endIcon={<img src={RightAarrow} alt="Right Arrow" />}
													type="submit"
												>
													<Typography>{i18n.login.submitButton}</Typography>
												</Button>
											</Grid>
											{/* <Grid item={true} xs={12} style={{
												paddingLeft: 0
											}}>
												<Typography className="typography-field-custom-font-size">
													<a
														href="/"
														style={{
															color: "#0A6BFE",
															display: "flex",
															alignItems: "flex-start",
														}}
													>
														{i18n.login.verificationCodeTryAnotherMethod}
													</a>
												</Typography>
											</Grid> */}
										</Grid>
									</FormControl>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
		</>
	);
};

export default CodeVerification;
