import { useState } from "react";
import {
    Typography,
    Grid,

} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

import MusicNote from "../../images/musicnote.svg"
import MusicNoteFilled from "../../images/musicnotefilled.svg";

// white
import AccordionW from '../../icons/White Outlines/SVG/WhiteOutline_Accordian.svg';
import GuitarW from '../../icons/White Outlines/SVG/WhiteOutline_Acoustic Guitar.svg';
import Alto_SaxophoneW from '../../icons/White Outlines/SVG/WhiteOutline_Alto Sax.svg';
import BagpipesW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Bagpipes.svg';
import BanjoW from "../../icons/White Outlines/SVG/WhiteOutline_Banjo.svg";
import BaritoneEW from "../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Baritone_Euphonium.svg";
import BaritoneSW from "../../icons/White Outlines/SVG/WhiteOutline_Baritone Sax.svg";
import BClarinetW from "../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Bass Clarinet.svg";
import BassGuitarW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Bass Guitar.svg';
import BassoonW from '../../icons/White Outlines/SVG/WhiteOutline_Bassoon.svg';
import BellsW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Bells.svg';
import CelloW from '../../icons/White Outlines/SVG/WhiteOutline_Cello.svg';
import ClarinetW from '../../icons/White Outlines/SVG/WhiteOutline_Clarinet.svg';
import ContrabassoonW from '../../icons/White Outlines/SVG/WhiteOutline_Contrabassoon.svg';
import CornetW from '../../icons/White Outlines/SVG/WhiteOutline_Cornet.svg';
import DrumsW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Drums.svg';
import DulcimerW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Dulcimer.svg';
import EGuitarW from '../../icons/White Outlines/SVG/WhiteOutline_Electric Guitar.svg';
import English_HornW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_English Horn.svg';
import FlugelhornW from '../../icons/White Outlines/SVG/WhiteOutline_Flugelhorn.svg';
import FluteW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Flute.svg';
import French_HornW from '../../icons/White Outlines/SVG/WhiteOutline_French Horn.svg';
import HarmonicaW from '../../icons/White Outlines/SVG/WhiteOutline_Harmonica.svg';
import HarpW from '../../icons/White Outlines/SVG/WhiteOutline_Harp.svg';
import KeyboardW from '../../icons/White Outlines/SVG/WhiteOutline_Keyboard.svg';
import MandolinW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Mandolin.svg';
import OboeW from '../../icons/White Outlines/SVG/WhiteOutline_Oboe.svg';
import OrganW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Organ.svg';
import PercussionW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Percussion.svg';
import PianoW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Piano.svg';
import PiccoloW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Piccolo.svg';
import Piccolo_TrumpetW from '../../icons/White Outlines/SVG/WhiteOutline_Piccolo Trumpet.svg';
import RecorderW from '../../icons/White Outlines/SVG/WhiteOutline_Recorder.svg';
import SitarW from '../../icons/White Outlines/SVG/WhiteOutline_Sitar.svg';
import Soprano_SaxophoneW from '../../icons/White Outlines/SVG/WhiteOutline_Soprano Sax.svg';
import SousaphoneW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Sousaphone.svg';
import SynthesizerW from '../../icons/White Outlines/SVG/WhiteOutline_Synthesizer.svg';
import Tenor_SaxophoneW from '../../icons/White Outlines/SVG/WhiteOutline_Tenor Sax.svg';
import TromboneW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Trombone.svg';
import TrumpetW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Trumpet.svg';
import TubaW from '../../icons/White Outlines/SVG/WhiteOutline_Tuba.svg';
import UkuleleW from '../../icons/White Outlines/SVG/WhiteOutline_Ukulele.svg';
import Upright_BassW from '../../icons/White Outlines/SVG/WhiteOutline_Upright Bass.svg';
import ViolaW from '../../images/whiteInstrumentsIcons/Lutefish_Icons_Outlines_White_Viola.svg';
import ViolinW from '../../icons/White Outlines/SVG/WhiteOutline_Violin.svg';
import XylophoneW from '../../icons/White Outlines/SVG/WhiteOutline_Xylophone_Marimba.svg';
import VoiceW from '../../icons/White Outlines/SVG/WhiteOutline_Voice.svg';
import DefaultIconW from '../../images/Instrument placeholder (1).svg';


// gray
import MicrophoneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Xylophone_Marimba.svg';
import SaxophoneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Sousaphone.svg';
import AccordionG from '../../icons/Black Outlines/SVG/BlackOutline_Accordian.svg';
import GuitarG from '../../icons/Black Outlines/SVG/BlackOutline_Acoustic Guitar.svg';
import Alto_SaxophoneG from '../../icons/Black Outlines/SVG/BlackOutline_Alto Sax.svg';
import BagpipesG from '../../icons/Black Outlines/SVG/BlackOutline_Bagpipes.svg';
import BanjoG from "../../icons/Black Outlines/SVG/BlackOutline_Banjo.svg";
import BaritoneEG from "../../icons/Black Outlines/SVG/BlackOutline_Baritone_Euphonium.svg";
import BaritoneSG from "../../icons/Black Outlines/SVG/BlackOutline_Baritone Sax.svg";
import BClarinetG from "../../icons/Black Outlines/SVG/BlackOutline_Bass Clarinet.svg";
import BassGuitarG from '../../icons/Black Outlines/SVG/BlackOutline_Bass Guitar.svg';
import BassoonG from '../../icons/Black Outlines/SVG/BlackOutline_Bassoon.svg';
import BellsG from '../../icons/Black Outlines/SVG/BlackOutline_Bells.svg';
import CelloG from '../../icons/Black Outlines/SVG/BlackOutline_Cello.svg';
import ClarinetG from '../../icons/Black Outlines/SVG/BlackOutline_Clarinet.svg';
import ContrabassoonG from '../../icons/Black Outlines/SVG/BlackOutline_Contrabassoon.svg';
import CornetG from '../../icons/Black Outlines/SVG/BlackOutline_Cornet.svg';
import DrumsG from '../../icons/Black Outlines/SVG/BlackOutline_Drums.svg';
import DulcimerG from '../../icons/Black Outlines/SVG/BlackOutline_Dulcimer.svg';
import EGuitarG from '../../icons/Black Outlines/SVG/BlackOutline_Electric Guitar.svg';
import English_HornG from '../../icons/Black Outlines/SVG/BlackOutline_English Horn.svg';
import FlugelhornG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Flugelhorn.svg';
import FluteG from '../../icons/Black Outlines/SVG/BlackOutline_Flute.svg';
import French_HornG from '../../icons/Black Outlines/SVG/BlackOutline_French Horn.svg';
import HarmonicaG from '../../icons/Black Outlines/SVG/BlackOutline_Harmonica.svg';
import HarpG from '../../icons/Black Outlines/SVG/BlackOutline_Harp.svg';
import KeyboardG from '../../icons/Black Outlines/SVG/BlackOutline_Keyboard.svg';
import MandolinG from '../../icons/Black Outlines/SVG/BlackOutline_Mandolin.svg';
import OboeG from '../../icons/Black Outlines/SVG/BlackOutline_Oboe.svg';
import OrganG from '../../icons/Black Outlines/SVG/BlackOutline_Organ.svg';
import PercussionG from '../../icons/Black Outlines/SVG/BlackOutline_Percussion.svg';
import PianoG from '../../icons/Black Outlines/SVG/BlackOutline_Piano.svg';
import PiccoloG from '../../icons/Black Outlines/SVG/BlackOutline_Piccolo.svg';
import Piccolo_TrumpetG from '../../icons/Black Outlines/SVG/BlackOutline_Piccolo Trumpet.svg';
import RecorderG from '../../icons/Black Outlines/SVG/BlackOutline_Recorder.svg';
import SitarG from '../../icons/Black Outlines/SVG/BlackOutline_Sitar.svg';
import Soprano_SaxophoneG from '../../icons/Black Outlines/SVG/BlackOutline_Soprano Sax.svg';
import SousaphoneG from '../../icons/Black Outlines/SVG/BlackOutline_Sousaphone.svg';
import SynthesizerG from '../../icons/Black Outlines/SVG/BlackOutline_Synthesizer.svg';
import Tenor_SaxophoneG from '../../icons/Black Outlines/SVG/BlackOutline_Tenor Sax.svg';
import TromboneG from '../../icons/Black Outlines/SVG/BlackOutline_Trombone.svg';
import TrumpetG from '../../icons/Black Outlines/SVG/BlackOutline_Trumpet.svg';
import TubaG from '../../icons/Black Outlines/SVG/BlackOutline_Tuba.svg';
import UkuleleG from '../../icons/Black Outlines/SVG/BlackOutline_Ukulele.svg';
import Upright_BassG from '../../icons/Black Outlines/SVG/BlackOutline_Upright Bass.svg';
import ViolaG from '../../icons/Black Outlines/SVG/BlackOutline_Viola.svg';
import ViolinG from '../../icons/Black Outlines/SVG/BlackOutline_Violin.svg';
import XylophoneG from '../../icons/Black Outlines/SVG/BlackOutline_Xylophone_Marimba.svg';
import VoiceG from '../../icons/Black Outlines/SVG/BlackOutline_Voice.svg';
import DefaultIconG from '../../images/Instrument placeholder.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        skillStack: {
            padding: "1% !important",
            // backgroundColor: "#FAFAFA",
            borderRadius: "15px",

            "& div": {
                padding: "1%",
                margin: "1% 0",
                // backgroudColor: "#FAFAFA",
                borderRadius: "15px",
                textAlign: "left",
            }
        },
    })
);


const InstrumentSkillLevel = ({ instrument, skillLevel, editable = false, updateInstrumentFilter, instrumentLabel, background, color, update, setRating, center, sideBar, IconSize, width, source }: any) => {

    const classes = useStyles();
    const [selectedSkill, setSelectedSkill] = useState(0);

    const updateSkill = (level: number) => {
        setSelectedSkill(level);
        source === "ratejamsession" && setRating(level);

        if (updateInstrumentFilter !== undefined) {
            updateInstrumentFilter(instrument, level);
        }
    }

    const getIcons = (instrument: string) => {
        switch (instrument) {
            case 'Accordion':
                return <img src={sideBar ? AccordionW : AccordionG} style={{ height: '24px', width: '24px' }} alt="Accordion" />
            case 'Banjo':
                return <img src={sideBar ? BanjoW : BanjoG} style={{ height: '24px', width: '24px' }} alt="Banjo" />
            case 'Bass Guitar':
                return <img src={sideBar ? BassGuitarW : BassGuitarG} style={{ height: '24px', width: '24px' }} alt="BassGuitar" />
            case 'Drums':
                return <img src={sideBar ? DrumsW : DrumsG} style={{ height: '24px', width: '24px' }} alt="Drums" />
            case 'Flute':
                return <img src={sideBar ? FluteW : FluteG} style={{ height: '24px', width: '24px' }} alt="Flute" />
            case 'Acoustic Guitar':
                return <img src={sideBar ? GuitarW : GuitarG} style={{ height: '24px', width: '24px' }} alt="Guitar" />
            case 'Keyboard':
                return <img src={sideBar ? KeyboardW : KeyboardG} style={{ height: '24px', width: '24px' }} alt="Keyboard" />
            case 'Xylophone/Marimba':
                return <img src={sideBar ? XylophoneW : XylophoneG} style={{ height: '24px', width: '24px' }} alt="Microphone" />
            case 'Piano':
                return <img src={sideBar ? PianoW : PianoG} style={{ height: '24px', width: '24px' }} alt="Piano" />
            case 'Alto Saxophone':
                return <img src={sideBar ? Alto_SaxophoneW : Alto_SaxophoneG} style={{ height: '24px', width: '24px' }} alt="Saxophone" />
            case 'Trumpet':
                return <img src={sideBar ? TrumpetW : TrumpetG} style={{ height: '24px', width: '24px' }} alt="Trumpet" />
            case 'Violin':
                return <img src={sideBar ? ViolinW : ViolinG} style={{ height: '24px', width: '24px' }} alt="Violin" />
            case 'Bagpipes':
                return <img src={sideBar ? BagpipesW : BagpipesG} style={{ height: '24px', width: '24px' }} alt="Bagpipes" />
            case 'Baritone/Euphonium':
                return <img src={sideBar ? BaritoneEW : BaritoneEG} style={{ height: '24px', width: '24px' }} alt="Baritone/Euphonium" />
            case 'Baritone Saxophone':
                return <img src={sideBar ? BaritoneSW : BaritoneSG} style={{ height: '24px', width: '24px' }} alt="Baritone Saxophone" />
            case 'Bass Clarinet':
                return <img src={sideBar ? BClarinetW : BClarinetG} style={{ height: '24px', width: '24px' }} alt="Bass Clarinet" />
            case 'Bassoon':
                return <img src={sideBar ? BassoonW : BassoonG} style={{ height: '24px', width: '24px' }} alt="Bassoon" />
            case 'Bells':
                return <img src={sideBar ? BellsW : BellsG} style={{ height: '24px', width: '24px' }} alt="Bells" />
            case 'Cello':
                return <img src={sideBar ? CelloW : CelloG} style={{ height: '24px', width: '24px' }} alt="Cello" />
            case 'Clarinet':
                return <img src={sideBar ? ClarinetW : ClarinetG} style={{ height: '24px', width: '24px' }} alt="Clarinet" />
            case 'Contrabassoon':
                return <img src={sideBar ? ContrabassoonW : ContrabassoonG} style={{ height: '24px', width: '24px' }} alt="Contrabassoon" />
            case 'Cornet':
                return <img src={sideBar ? CornetW : CornetG} style={{ height: '24px', width: '24px' }} alt="Cornet" />
            case 'Dulcimer':
                return <img src={sideBar ? DulcimerW : DulcimerG} style={{ height: '24px', width: '24px' }} alt="Dulcimer" />
            case 'Electric Guitar':
                return <img src={sideBar ? EGuitarW : EGuitarG} style={{ height: '24px', width: '24px' }} alt="Electric Guitar" />
            case 'English Horn':
                return <img src={sideBar ? English_HornW : English_HornG} style={{ height: '24px', width: '24px' }} alt="English Horn" />
            case 'Flugelhorn':
                return <img src={sideBar ? FlugelhornW : FlugelhornG} style={{ height: '24px', width: '24px' }} alt="Flugelhorn" />
            case 'French Horn':
                return <img src={sideBar ? French_HornW : French_HornG} style={{ height: '24px', width: '24px' }} alt="French Horn" />
            case 'Harmonica':
                return <img src={sideBar ? HarmonicaW : HarmonicaG} style={{ height: '24px', width: '24px' }} alt="Harmonica" />
            case 'Harp':
                return <img src={sideBar ? HarpW : HarpG} style={{ height: '24px', width: '24px' }} alt="Harp" />
            case 'Mandolin':
                return <img src={sideBar ? MandolinW : MandolinG} style={{ height: '24px', width: '24px' }} alt="Mandolin" />
            case 'Oboe':
                return <img src={sideBar ? OboeW : OboeG} style={{ height: '24px', width: '24px' }} alt="Oboe" />
            case 'Organ':
                return <img src={sideBar ? OrganW : OrganG} style={{ height: '24px', width: '24px' }} alt="Organ" />
            case 'Percussion':
                return <img src={sideBar ? PercussionW : PercussionG} style={{ height: '24px', width: '24px' }} alt="Percussion" />
            case 'Piccolo':
                return <img src={sideBar ? PiccoloW : PiccoloG} style={{ height: '24px', width: '24px' }} alt="Piccolo" />
            case 'Piccolo Trumpet':
                return <img src={sideBar ? Piccolo_TrumpetW : Piccolo_TrumpetG} style={{ height: '24px', width: '24px' }} alt="Piccolo Trumpet" />
            case 'Recorder':
                return <img src={sideBar ? RecorderW : RecorderG} style={{ height: '24px', width: '24px' }} alt="Recorder" />
            case 'Sitar':
                return <img src={sideBar ? SitarW : SitarG} style={{ height: '24px', width: '24px' }} alt="Sitar" />
            case 'Soprano Saxophone':
                return <img src={sideBar ? Soprano_SaxophoneW : Soprano_SaxophoneG} style={{ height: '24px', width: '24px' }} alt="Soprano Saxophone" />
            case 'Sousaphone':
                return <img src={sideBar ? SousaphoneW : SousaphoneG} style={{ height: '24px', width: '24px' }} alt="Sousaphone" />
            case 'Synthesizer':
                return <img src={sideBar ? SynthesizerW : SynthesizerG} style={{ height: '24px', width: '24px' }} alt="Synthesizer" />
            case 'Tenor Saxophone':
                return <img src={sideBar ? Tenor_SaxophoneW : Tenor_SaxophoneG} style={{ height: '24px', width: '24px' }} alt="Tenor Saxophone" />
            case 'Trombone':
                return <img src={sideBar ? TromboneW : TromboneG} style={{ height: '24px', width: '24px' }} alt="Trombone" />
            case 'Tuba':
                return <img src={sideBar ? TubaW : TubaG} style={{ height: '24px', width: '24px' }} alt="Tuba" />
            case 'Ukulele':
                return <img src={sideBar ? UkuleleW : UkuleleG} style={{ height: '24px', width: '24px' }} alt="Ukulele" />
            case 'Upright Bass':
                return <img src={sideBar ? Upright_BassW : Upright_BassG} style={{ height: '24px', width: '24px' }} alt="Upright Bass" />
            case 'Viola':
                return <img src={sideBar ? ViolaW : ViolaG} style={{ height: '24px', width: '24px' }} alt="Viola" />
            case 'Voice Alto':
                return <img src={sideBar ? VoiceW : VoiceG} style={{ height: '24px', width: '24px' }} alt="Viola" />
            case 'Voice Baritone':
                return <img src={sideBar ? VoiceW : VoiceG} style={{ height: '24px', width: '24px' }} alt="Viola" />
            case 'Voice Bass':
                return <img src={sideBar ? VoiceW : VoiceG} style={{ height: '24px', width: '24px' }} alt="Viola" />
            case 'Voice Mezzo Soprano':
                return <img src={sideBar ? VoiceW : VoiceG} style={{ height: '24px', width: '24px' }} alt="Viola" />
            case 'Voice Soprano':
                return <img src={sideBar ? VoiceW : VoiceG} style={{ height: '24px', width: '24px' }} alt="Viola" />
            case 'Voice Tenor':
                return <img src={sideBar ? VoiceW : VoiceG} style={{ height: '24px', width: '24px' }} alt="Viola" />

            default:
                return <img src={sideBar ? DefaultIconW : DefaultIconG} style={{ height: '24px', width: '24px' }} alt="Instrument" />;
        }
    }




    return (
        <Grid style={{ width: width }} container xs={12} sx={{ backgroundColor: background ? background : '#FAFAFA ', color: 'white', textAlign: 'center', mt: source === "ratejamsession" || source === 'Rating' ? 0 : 1 }} className={classes.skillStack}>
            {source === undefined && (
                <Grid item xs={1.5} >
                    {(source === "ratejamsession" || source === 'Rating') ? '' : getIcons(instrumentLabel)}
                </Grid>
            )}
            {
                source !== "ratejamsession" && (
                    <Grid item xs={sideBar ? 5.5 : 5.5}>
                        <Typography variant="body1" sx={{ color: color ? color : 'black', fontSize: sideBar && '14px', textAlign: sideBar && 'left', pl: sideBar && '5px' }}>
                            {instrumentLabel}
                        </Typography>
                    </Grid>
                )
            }
            {update !== 'false' ?
                <Grid container xs={source === "ratejamsession" ? 12 : source === 'Rating' ? 6.5 : sideBar ? 5 : center ? 12 : 5} justifyContent={center ? "center" : "space-evenly"} className="instruSkill" sx={{ marginTop: "2% !important" }}>
                    <img src={(skillLevel >= 1 || (editable && selectedSkill >= 1)) ? MusicNoteFilled : MusicNote} alt="Music Note" onClick={() => updateSkill(1)} style={{ cursor: "pointer", height: '1.125rem', width: '1.125rem' }} />
                    <img src={(skillLevel >= 2 || (editable && selectedSkill >= 2)) ? MusicNoteFilled : MusicNote} alt="Music Note" style={{ cursor: "pointer", height: '1.125rem', width: '1.125rem' }} onClick={() => updateSkill(2)} />
                    <img src={(skillLevel >= 3 || (editable && selectedSkill >= 3)) ? MusicNoteFilled : MusicNote} alt="Music Note" style={{ cursor: "pointer", height: '1.125rem', width: '1.125rem' }} onClick={() => updateSkill(3)} />
                    <img src={(skillLevel >= 4 || (editable && selectedSkill >= 4)) ? MusicNoteFilled : MusicNote} alt="Music Note" style={{ cursor: "pointer", height: '1.125rem', width: '1.125rem' }} onClick={() => updateSkill(4)} />
                    <img src={(skillLevel >= 5 || (editable && selectedSkill >= 5)) ? MusicNoteFilled : MusicNote} alt="Music Note" style={{ cursor: "pointer", height: '1.125rem', width: '1.125rem' }} onClick={() => updateSkill(5)} />
                </Grid>
                :
                <Grid container xs={source === "ratejamsession" || source === 'Rating' ? 6.5 : sideBar ? 5 : center ? 13 : 5} justifyContent={center ? "center" : "space-evenly"} className="instruSkill" sx={{ marginTop: "2% !important" }}>
                    <img src={(skillLevel >= 1 || (editable && selectedSkill >= 1)) ? MusicNoteFilled : MusicNote} alt="Music Note" style={{ height: '1.125rem', width: '1.125rem' }} />
                    <img src={(skillLevel >= 2 || (editable && selectedSkill >= 2)) ? MusicNoteFilled : MusicNote} alt="Music Note" style={{ height: '1.125rem', width: '1.125rem' }} />
                    <img src={(skillLevel >= 3 || (editable && selectedSkill >= 3)) ? MusicNoteFilled : MusicNote} alt="Music Note" style={{ height: '1.125rem', width: '1.125rem' }} />
                    <img src={(skillLevel >= 4 || (editable && selectedSkill >= 4)) ? MusicNoteFilled : MusicNote} alt="Music Note" style={{ height: '1.125rem', width: '1.125rem' }} />
                    <img src={(skillLevel >= 5 || (editable && selectedSkill >= 5)) ? MusicNoteFilled : MusicNote} alt="Music Note" style={{ height: '1.125rem', width: '1.125rem' }} />
                </Grid>
            }

        </Grid>
    );
};

export default InstrumentSkillLevel;
