import { useState, useEffect } from "react";
import * as React from "react";
import {
	Button,
	Dialog,
	List,
	Typography,
	Divider,
	Box,
	Tabs,
	TextField,
	InputAdornment,
	CircularProgress,
	Grid,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import ErrorAlert from "../Error/ErrorAlert";
import { useUser } from "../../context/UserContext";
import SearchIcon from "@mui/icons-material/Search";
import MusicList from "./musicList";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { useAlertStatus } from "../../context/AlertStatusContext";
import User from "../../services/user";

const AddMusicToProfile = (props: any) => {
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const [selectedTab, setSelectedTab] = useState(0);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [musicList, setMusicList] = useState<any>([]);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [isMusicLoading, setIsMusicLoading] = useState(false);
	const [isProfileMusicLoading, setIsProfileMusicLoading] = useState(false);
	const [searchText, setSearchText] = useState("");
	const inputFileRef = React.useRef<any>(null);
	const { updateAlertStatus } = useAlertStatus();
	const ALLOWED_FILE_EXT = ["mp3", "wav", "mpeg"];
	const [isLoading, setIsLoading] = useState(false);
	const [duration, setDuration] = useState<any>("");

	const handleAlertClose = () => {
		setShowAlert(false);
	};
	const handleClose = () => {
		setSelectedItems([]);
		props.handleClose(false);
	};

	useEffect(() => {
		if (props.musicOpen) {
			getRecordingList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.musicOpen]);

	const getRecordingList = () => {
		setIsProfileMusicLoading(true);
		try {
			fetch(`${process.env.REACT_APP_BASE_URL}/api/getAllMusic`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					userProfileId: user.userProfileId,
				}),
			})
				.then((res) => {
					if (res.status > 400) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					setSelectedItems(data);
					setIsProfileMusicLoading(false);
				})
				.catch((err) => {})
				.finally(() => {
					setIsProfileMusicLoading(false);
				});
		} catch (error) {
			setIsProfileMusicLoading(false);
		}
	};

	const handleSaveMusic = () => {
		setIsLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/mymusic/profile-playlist`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				musicList: selectedItems,
			}),
		}).then((res) => {
			if (res.status !== 200 && res.status !== 201) {
				ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
			} else {
				props.setLoadMusic(true);
				handleClose();
				setIsLoading(false);
				// props.getAllMusicListing();
				return res.json();
			}
		});
	};

	const handleChange = (tabId: any) => {
		setSelectedTab(tabId);
	};

	const fetchMusicList = () => {
		setIsMusicLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/getAllMusic/filter`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				folderName: selectedTab === 0 ? "MyMusic" : "Recording",
				userId: user.id,
			}),
		})
			.then((res) => {
				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setMusicList(data);
				setIsMusicLoading(false);
			})
			.catch((err) => {
				// setShowAlert(true);
				// setAlertStatus(false);
				// setAlertMsg("Network Error! Please try again..");
				setIsMusicLoading(false);
			});
	};

	useEffect(() => {
		props.musicOpen && fetchMusicList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, selectedTab]);

	const filteredItems =
		musicList &&
		musicList.length > 0 &&
		musicList.filter((item: any) =>
			item.fileName.toLowerCase().includes(searchText.toLowerCase())
		);

	const handleSearchChange = (event: any) => {
		setSearchText(event.target.value);
	};

	const handleCheckboxChange = (music: any) => {
		const index = selectedItems.findIndex(
			(selected: any) => selected.id === music.id
		);
		if (index !== -1) {
			const updatedItems = [...selectedItems];
			updatedItems.splice(index, 1);
			setSelectedItems(updatedItems);
		} else {
			setSelectedItems([...selectedItems, music]);
		}
	};

	const saveAsProfile = (id: any) => {
		const idsArray = [id];
		const ArrayOfIds = [...selectedItems, ...idsArray];
		setIsLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/mymusic/profile-playlist`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				musicList: ArrayOfIds,
			}),
		})
			.then((response: any) => {
				if (response.status !== 200 && response.status !== 201) {
					ErrorAlert(
						response.status,
						setAlertStatus,
						setShowAlert,
						setAlertMsg
					);
					setIsLoading(false);
					throw new Error();
				} else {
					props.setLoadMusic(true);
					// handleClose()
					// setIsLoading(false)
					return response.json();
				}
			})
			.catch((err: any) => {
				// updateAlertStatus(true, false, 'Something went wrong, please try again.');
			});
	};
	const [userStorageCapacity, setUserStorageCapacity] = useState();
	const [userStorageUsage, setUserStorageUsage] = useState();
	const handleOpenEditor = (e: any) => {
		e.preventDefault();

		const targetFiles = e.target.files[0];
		if (targetFiles.type.startsWith("audio/")) {
			if (targetFiles) {
				const audioUrl = URL.createObjectURL(targetFiles);
				const audio = new Audio(audioUrl);
				audio.addEventListener("loadedmetadata", () => {
					let formData = new FormData();
					formData.append("files", targetFiles);

					// return false;
					let ext = targetFiles.type.split("/").pop();
					//Convert bytes to MB & Round to the nearest integer
					var roundedFileSizeInMB = Math.round(
						targetFiles.size / (1024 * 1024)
					);
					// Convert MB back to bytes
					const roundedFileSizeInBytes = roundedFileSizeInMB * (1024 * 1024);
					if (roundedFileSizeInMB > 100) {
						updateAlertStatus(
							true,
							false,
							"File size limited to 100 MB per audio file. Please save in another format or divide into multiple files and try again."
						);
						return; // Stop further execution if file size is too large
					}
					const sUsage = userStorageUsage ? userStorageUsage : 0;
					const targetfileAfterupload = sUsage + roundedFileSizeInMB;
					const sCapacity = userStorageCapacity ? userStorageCapacity : 0;

					if (storeFull || targetfileAfterupload > sCapacity) {
						updateAlertStatus(
							true,
							false,
							"Music size exceeds the maximum storage limit. Please select other file or buy storage."
						);
					}
					// else if (roundedFileSizeInBytes > (10 * 1024 * 1024)) {
					//     updateAlertStatus(true, false, 'Music size exceeds the maximum allowed limit of 10Mb. Please try again.');
					// }
					else if (ALLOWED_FILE_EXT.includes(ext)) {
						setIsLoading(true);
						updateAlertStatus(true, true, "Music uploading is in process");
						fetch(
							`${process.env.REACT_APP_BASE_URL}/api/upload-music?musicDuration=${audio.duration}`,
							{
								method: "POST",
								headers: {
									// "Content-Type": "multipart/form-data",
									Authorization: `Bearer ${token}`,
								},
								body: formData,
							}
						)
							.then((res) => {
								if (
									res.status !== 200 &&
									res.status !== 201 &&
									res.status !== 507
								) {
									ErrorAlert(
										res.status,
										setAlertStatus,
										setShowAlert,
										setAlertMsg
									);
									setIsLoading(false);
								} else if (res.status === 507) {
									updateAlertStatus(true, false, "Insufficient Storage..");
								} else {
									return res.json();
								}
							})
							.then((data) => {
								if (data) {
									// handleSaveMusic()
									// saveAsProfile(data && data.id)
									saveAsProfile(data);
									// props.setLoadMusic(true)
									handleClose();
									setIsLoading(false);
									updateAlertStatus(true, true, "Music uploaded successfully");
								}
							})
							.catch((err: any) => {
								// updateAlertStatus(true, false, 'Something went wrong, please try again.');
							});
					} else {
						updateAlertStatus(
							true,
							false,
							"Incorrect audio file format. Please try again."
						);
						setIsLoading(false);
					}
				});
			}

			if (inputFileRef.current) {
				inputFileRef.current.value = null; // Clear the input value
				const newInput = document.createElement("input");
				newInput.type = "file";
				newInput.style.display = "none";
				newInput.addEventListener("change", handleOpenEditor);
				inputFileRef.current.parentNode.replaceChild(
					newInput,
					inputFileRef.current
				);
				inputFileRef.current = newInput;
			}
		} else {
			updateAlertStatus(true, false, "Invalid file type");
		}
	};

	const [storageConsumedPerc, setStorageConsumedPerc] = useState(0);
	const [storageConsumed, setStorageConsumed] = useState(0);
	const [storageAvailable, setStorageAvailable] = useState(0);

	type Units = "B" | "KB" | "MB" | "GB" | "TB" | "PB" | "EB" | "ZB" | "YB";

	const getReadableFileSizeString = (fileSize: number, unit: Units = "B") => {
		if (fileSize) {
			let i = -1;
			const byteUnits = [
				" KB",
				" MB",
				" GB",
				" TB",
				" PB",
				" EB",
				" ZB",
				" YB",
			];

			const unitsMap: Record<Units, number> = {
				B: 0,
				KB: 1,
				MB: 2,
				GB: 3,
				TB: 4,
				PB: 5,
				EB: 6,
				ZB: 7,
				YB: 8,
			};

			const upperUnit = unit.toUpperCase() as Units;
			if (upperUnit in unitsMap) {
				i = unitsMap[upperUnit] - 1;
				fileSize = fileSize * Math.pow(1024, i);

				i--; // Adjust to start with the correct unit for the conversion loop
			}

			do {
				fileSize = fileSize / 1024;
				i++;
			} while (fileSize > 1024 && i < byteUnits.length - 1);

			return Math.max(fileSize, 0.1).toFixed(1) + byteUnits[i];
		} else {
			return "0 MB";
		}
	};

	useEffect(() => {
		User.fetchUserInfo((userInfo: any) => {
			if (userInfo.error) {
				console.error("Failed to fetch user info:", userInfo.error);
			} else {
				setUserStorageCapacity(userInfo.storageCapacity);
				setUserStorageUsage(userInfo.storageUsage);
				if (user.storageCapacity != null) {
					let converterSize: any = getReadableFileSizeString(
						userInfo.storageUsage,
						"MB"
					);
					setStorageConsumed(converterSize);
					// Set Storage as 50 GB default untill avaialble from backend
					let totalSize: any = getReadableFileSizeString(
						userInfo.storageCapacity as any,
						"MB"
					);
					setStorageAvailable(totalSize);

					let consumptionPercent =
						(userInfo.storageUsage / (userInfo.storageCapacity as any)) * 100;
					setStorageConsumedPerc(consumptionPercent);
				}
			}
		});
	}, []);

	const canStore = Number(storageConsumedPerc) < 99.99;
	const storeFull = Number(storageConsumedPerc) === 100;
	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="xs"
				open={props.musicOpen}
			>
				<Typography
					id="variant-modal-title"
					variant="h6"
					sx={{ alignSelf: "center", fontWeight: 600, mt: 1 }}
				>
					Select music for public listening
				</Typography>
				<TextField
					variant="standard"
					fullWidth
					value={searchText}
					placeholder="Search by file name"
					onChange={handleSearchChange}
					sx={{
						"& .css-dnxhuh-MuiInputBase-root-MuiInput-root:before": {
							borderBottom: "none",
						},
						"& .css-1515x7o:before": {
							borderBottom: "none",
						},
						"& .css-dnxhuh-MuiInputBase-root-MuiInput-root:after": {
							borderBottom: "none",
						},
						"& .css-dnxhuh-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled,.Mui-error):before":
							{
								borderBottom: "none",
							},
						"& .css-1515x7o:after": {
							borderBottom: "none",
						},
						"& .css-1515x7o:hover:not(.Mui-disabled,.Mui-error):before": {
							borderBottom: "none",
						},
						maxHeight: "34px",
						height: "34px",
						fontFamily: "Lexend",
						fontWeight: 500,
						fontSize: "14px",
						width: 330,
						backgroundColor: "#F3F3F3",
						color: "#888888",
						border: "none",
						borderRadius: "20px",
						alignSelf: "center",
						m: 1,
						borderColor: "white",
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start" sx={{ pl: 2 }}>
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>

				<Tabs
					value={selectedTab}
					onChange={(event: any, value: any) => handleChange(value)}
					aria-label="Plain tabs"
					sx={{
						alignSelf: "center",
						"& .Mui-selected": {
							color: "#1D2D53 !important",
						},
						"& .MuiTabs-indicator": {
							backgroundColor: "#1D2D53 !important",
						},
					}}
				>
					<Tab
						key={0}
						sx={{
							textTransform: "none !important",
							fontWeight: 500,
							fontSize: "14px !important",
							color: "#1D2D53",
							fontFamily: "Lexend",
						}}
						label="Uploaded Music"
					/>
					{/* Hiding for Social-Launch */}
					<Tab
						key={1}
						sx={{
							textTransform: "none !important",
							fontWeight: 500,
							fontSize: "14px !important",
							color: "#1D2D53",
							fontFamily: "Lexend",
						}}
						label="Jam Recordings"
					/>
				</Tabs>

                <Divider variant="middle" light sx={{ my: 1 }} />
                <Box
                    sx={{
                        overflowY: 'auto',
                        maxHeight: '600px', 
                    }}
                >
                    {isProfileMusicLoading || isMusicLoading ?
                        <Grid sx={{ alignSelf: 'center' , marginLeft:'197px' }}>
                            <CircularProgress />
                        </Grid>
                        :
                        <List>
                            <MusicList filteredItems={filteredItems} selectedItems={selectedItems} handleCheckboxChange={handleCheckboxChange} />
                        </List>
                    }
                </Box>
                <Divider variant="middle" light sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', pt: 1, flexDirection: 'row-reverse', mx: 2, mb: 2 }}>
                    <Grid container direction={'column'}>
                        {selectedItems.length > 0 &&
                            <Typography id="variant-modal-description" variant="subtitle2" sx={{ color: '#3F77D7', mr: 'auto', ml: 1 }}>
                                {selectedItems.length} selected
                            </Typography>
                        }
                        {selectedItems.length > 10 &&
                            <Typography id="variant-modal-description" variant="subtitle2" sx={{ color: 'red', mr: 'auto', ml: 1 }}>
                                max 10 allowed
                            </Typography>
                        }
                    </Grid>
                    <Grid container>
                        <Grid item xs={5} >
                            {selectedItems.length >= 10 || !canStore?
                                <Typography sx={{ fontSize: '0.9rem', color: '#707085', ml: 1 }}>Browse Music</Typography>
                                :
                                <label style={{ marginRight: 'auto', alignSelf: "center" }}>
                                    <Typography sx={{ fontSize: '0.9rem', color: '#0273E6', cursor: 'pointer', ml: 1 }}>Browse Music</Typography>
                                    <input
                                        id="photo-upload"
                                        type="file"
                                        ref={inputFileRef}
                                        accept=".mp3,.wav,.aac,audio/mpeg,audio/wav,audio/aac"
                                        style={{ display: "none" }}
                                        // multiple
                                        onChange={handleOpenEditor}
                                    />
                                </label>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                sx={{ borderRadius: '17px', width: '93px' }}
                                size="small"
                                variant="outlined"
                                onClick={handleClose}
                                disabled={isLoading}
                            >Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ borderRadius: '17px', background: '#FF702A', px: 2, width: '120px' }}
                                onClick={handleSaveMusic}
                                disabled={selectedItems.length > 10 || isLoading}
                            > Add Music
                             </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
}

export default AddMusicToProfile;
