import { Button, Grid, Stack, Typography, Divider } from "@mui/material";

import { useEffect, useState } from "react";
import { ReccomendationTemplate } from "./ReccomendationTemplate";
import SkeletonEvents from "./SkeletonEvents";

export const ReccommendationEvents = ({
	showSkeleton,
	similarEvents,
}: {
	showSkeleton: boolean;
	similarEvents: any[];
}) => {
	return (
		<>
			{showSkeleton ? (
				<SkeletonEvents />
			) : (
				similarEvents.length > 0 && (
					<>
						{similarEvents.slice(0, 2).map((event, index) => (
							<ReccomendationTemplate event={event} key={index} />
						))}
						{/* <Divider variant="middle" light sx={{ ml: 1, mr: 1, mb: 1 }} /> */}
					</>
				)
			)}
		</>
	);
};
