import { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	FormControlLabel,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useUser } from "../context/UserContext";
import CloseAccount from "../dialogBox/CloseAccount";
import EditNickName from "../dialogBox/EditNickName";
import EditDateOfBirth from "../dialogBox/EditDateOfBirth";
import EditLocation from "../dialogBox/EditLocation";
import ChangePasswordByAdmin from "../dialogBox/ChangePasswordByAdmin";
import EditEmail from "../dialogBox/EditEmail";
import EditName from "../dialogBox/EditName";
// services
import User from "../services/user";
import EditIcon from "../images/edit.svg";
import EditDisabledIcon from "../images/editDisableIcon.svg";
import i18n from "../l10n/strings.json";
import { useAlertStatus } from "../context/AlertStatusContext";
import { useLocation } from "react-router-dom";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";
import { format, parse } from "date-fns";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import { useManageFilter } from "../components/AdminDashboard/ManageUserDashBoard/ManagementProvider/useManageFilter";
import Switch, { SwitchProps } from "@mui/material/Switch";

type MessageList = {
	[key: string]: string;
};

const AccountListItemText = styled(ListItemText)({
	"& .MuiListItemText-primary": {
		fontStyle: "normal",
		fontSize: "1rem",
		fontWeight: 400,
		color: "#7A7985",
	},
	"& .MuiListItemText-secondary": {
		fontFamily: "Lexend",
		fontStyle: "normal",
		fontSize: "1.125rem",
		fontWeight: 500,
		color: "#000000",
	},
});



const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0273E6",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));


let messageList: MessageList = {
	nickNameSuccess: "Nickname updated successfully",
	nameSuccess: "Name updated successfully",
	birthDateSuccess: "Date of Birth updated successfully",
	locationSuccess: "Location updated successfully",
	passwordSuccess: "Password reset mail sent successfully",
	emailSuccess: "Email updated successfully",
};

function AdminAccountSettingsPage() {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const { updateAlertStatus } = useAlertStatus();

	const [nickNameOpen, setNickNameOpen] = useState(false);
	const [nameOpen, setNameOpen] = useState(false);
	const [birthDateOpen, setBirthDateOpen] = useState(false);
	const [locationOpen, setLocationOpen] = useState(false);
	const [changePasswordOpen, setChangePasswordOpen] = useState(false);
	const [emailOpen, setEmailOpen] = useState(false);
	const [userData, setUserData] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [showCloseAccountDialog, setShowCloseAccountDialog] = useState(false);
	const [loggedinUser,setLoggedinUser] = useState('');

	const location = useLocation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { userIdByAdmin, userProfileIdByAdmin, setIsDisabledOptions } =
		useManageFilter();

		const { setAdminSetUserId } = useAdminContext();
		//const {userId, userProfileId, email, user: userDetails,userName,userStatus} = location?.state

		useEffect(() => {
			setAdminSetUserId(location?.state?.user);
		}, [location?.state]);
		
	const fetchUserInfo = () => {
		let searchId;
		const userRole = localStorage.getItem("user");
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		let loggedInRole;

		if (userRole) {
			const userRoleObj = JSON.parse(userRole);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			loggedInRole = userRoleObj.authorities[0].name;
			setLoggedinUser(loggedInRole);
		}
		// if (loggedInRole === "ROLE_ADMIN") {
		searchId = location.state?.userId? location.state?.userId : user.id;
		// searchId = userIdByAdmin ? userIdByAdmin : user.id;
		// } else {
		// 	searchId = user.id;
		// }

		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/id`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id:searchId
			}),
		})
			.then((response) => {
				if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();					 
				}
			})
			.then((result) => {
				setIsLoading(false);
				if (result !== null) {
					setUserData(result);								
				}
			});
	};

	const handleDialogClose = (success: boolean, editMode: string) => {
		if (success) {
			fetchUserInfo();
			updateAlertStatus(
				true,
				true,
				messageList[editMode as keyof typeof messageList]
			);
			User.fetchUserInfo(undefined);
		} else {
			updateAlertStatus(false, false, "");
		}
		setNameOpen(false);
		setNickNameOpen(false);
		setLocationOpen(false);
		setBirthDateOpen(false);
		setChangePasswordOpen(false);
		setEmailOpen(false);
	};

	useEffect(() => {
		fetchUserInfo();
		setIsLoading(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (location.state?.userId && user?.authorities[0]?.name === "ROLE_ADMIN") {
			setIsDisabledOptions(true);
		}
		return () => {
			setIsDisabledOptions(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const getDob = () => {
	// 	if (userData?.dateOfBirth) {
	// 	  try {
	// 		const parsedDate = parse(
	// 		  userData.dateOfBirth,
	// 		  "MM/dd/yyyy",
	// 		  new Date()
	// 		);

	// 		// Check if parsedDate is a valid date
	// 		if (isNaN(parsedDate.getTime())) {
	// 		  throw new Error("Invalid date string");
	// 		}

	// 		const formattedDate = format(parsedDate, "MM/dd/yyyy");
	// 		return formattedDate;
	// 	  } catch (error) {
	// 		console.error("Error parsing date:", error);
	// 		return ""; // Handle the error gracefully
	// 	  }
	// 	} else {
	// 	  return "";
	// 	}
	//   };
	  const getDob = () => {
		if (userData?.dateOfBirth) {
		  try {
			let parsedDate;
			if (userData.dateOfBirth.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)) {
				// Check if dateOfBirth is in mm/dd/yyyy format
				parsedDate = parse(
				  userData.dateOfBirth,
				  "MM/dd/yyyy",
				  new Date()
				);
			} else if (userData.dateOfBirth.match(/^\d{4}-\d{2}-\d{2}$/)) {
				// Check if dateOfBirth is in yyyy-mm-dd format
				parsedDate = parse(
				  userData.dateOfBirth,
				  "yyyy-MM-dd",
				  new Date()
				);
			} else {
			  throw new Error("Invalid date format");
			}
	  
			// Check if parsedDate is a valid date
			if (isNaN(parsedDate.getTime())) {
			  throw new Error("Invalid date string");
			}
	  
			// Format the parsed date to the desired format (MM/dd/yyyy)
			const formattedDate = format(parsedDate, "MM/dd/yyyy");
			return formattedDate;
		  } catch (error) {
			console.error("Error parsing date:", error);
			return ""; // Handle the error gracefully
		  }
		} else {
		  return "";
		}
	  };

	  const isEnable = () => {		 
		if (loggedinUser == 'ROLE_ADMIN' || loggedinUser == 'ROLE_SUPER_ADMIN'  ) {
		  return true;
		} else {
		  return false;
		}
	  };

	if (isLoading) {
		return <div />;
	}

	return (
		<>
		
			<Grid container>
				<Grid item={true} xs={9} className="centerPanelWidth">
					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;",
						}}
					>
						<CardContent
							sx={{
								padding: "24px",
							}}
						>
							<Typography
								className="card-title-custom-size"
								sx={{
									display: "flex",
									alignItems: "center",

									fontWeight: "500",
								}}
								color="#000000"
							>
								{" "}
								User
							</Typography>
							<List>
							<ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="Email"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary={userData?.user?.email}
									/>
									<ListItemIcon
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography
											sx={{
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "0.75rem",
												lineHeight: "100%",
												textAlign: "right",
												color: "#C5C5C5",
												marginBottom: "10px",
											}}
										>
											{i18n.settings.accountSetup.emailHelpingText}
										</Typography>
										 
									</ListItemIcon>
								</ListItem>
								<Divider variant="fullWidth" component="li" />
								<ListItem sx={{ padding: "8px 0" }}>
									{/* <IconButton color="danger" onClick={() => (onRemoveRoom(itemId))}><Svg src="actions/removeCircle.svg" /></IconButton> */}

									<AccountListItemText
										primary="Name"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary={`${userData?.user?.firstName}  ${userData?.user?.lastName}`}
									/>
									 
								</ListItem>
								 
								<Divider variant="fullWidth" component="li" />
								<ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="User Status"
										// secondary={`${userData?.user?.userStatus ? userData?.user?.userStatus : userData?.userStatus}`}
										secondary={`${location?.state?.userStatus ? location?.state?.userStatus : user.userAccountStatus}`}
									/>
									<ListItemIcon
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
									 
									 
									</ListItemIcon>
								</ListItem>
								  
								<Divider variant="fullWidth" component="li" />
								<ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="Nickname (Social handle)"
										secondary={`${
											userData?.nickname && userData?.nickname?.length > 50
												? userData?.nickname?.substring(0, 50) + "..."
												: userData?.nickname
										}`}
									/>
									<ListItemIcon
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography
											sx={{
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "0.75rem",
												lineHeight: "100%",
												textAlign: "right",
												color: "#C5C5C5",
												marginBottom: "10px",
											}}
										>
											{i18n.settings.accountSetup.nickNameHelpingText}
										</Typography>
									 
									</ListItemIcon>
								</ListItem>						 								 
								<Divider variant="fullWidth" component="li" />
								<ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="Home City"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary={userData?.location}
									/>
									<ListItemIcon
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography
											sx={{
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "0.75rem",
												lineHeight: "100%",
												textAlign: "right",
												color: "#C5C5C5",
												marginBottom: "10px",
											}}
										>
											{i18n.settings.accountSetup.locationHelpingText}
										</Typography>
									 
									</ListItemIcon>
								</ListItem>
								<Divider variant="fullWidth" component="li" />
								{/* <ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="Email opt-in/out Status"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary="Email opt-in?"
									/>
									<ListItemIcon
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>																 
									 
									</ListItemIcon>
									<FormControlLabel
									control={
										<IOSSwitch 						 
											sx={{ m: 1 }}
										/>
									}
									label={
										<Typography
											sx={{
												fontSize: "1rem",
												fontWeight: 500,
												lineHeight: "20px",
												fontFamily: "Lexend",
												color: "#7A7985",
											}}
										>
											YES
										</Typography>
									}
								/>
								</ListItem> */}
								{/* <Divider variant="fullWidth" component="li" /> */}
								<ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="Reset Password"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary="This will trigger email to user to reset password"
									/>
									<ListItemIcon>
										{user.userAccountStatus === "SUSPEND" ? (
											<img
												src={EditDisabledIcon}
												style={{ marginLeft: "60%" }}
												alt="Edit Icon"
												width="24px"
												height="24px"
											/>
										) : (
											 
											<Button
							sx={{ width: "200px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							disabled={!isEnable()}
							onClick={() => setChangePasswordOpen(true)}
						>
							Reset Password
						</Button>
										)}
									</ListItemIcon>
								</ListItem>
								<ChangePasswordByAdmin
								    email={
										location.state?.email ? location.state?.email : user.email
									}
									userIdByAdmin={location.state?.userId}
									changePasswordOpen={changePasswordOpen}
									handleClose={handleDialogClose}
								/>
							 
							</List>
						</CardContent>
					</Card>

				 
					{
						location?.state?.type !== "edit" && (
							<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;",
							marginTop: 3,
							marginBottom: 3,
						}}
					>
						<CardContent
							sx={{
								padding: "24px",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							}}
						>
							<Typography
								className="card-title-custom-size"
								sx={{
									// fontSize: "24px",
									display: "flex",
									alignItems: "center",
									marginBottom: "10px",

									fontWeight: "500",
								}}
								color="#000000"
							>
								Close Account
							</Typography>
							<Typography
								sx={{
									fontSize: 16,
									lineHeight: "25px",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",

									fontWeight: "500",
								}}
							>
								Once you close your account, your account will remain active for 30 days, then will be fully deleted.
								<CardActions
									sx={{
										justifyContent: "flex-end",
									}}
								>
									<Button
										sx={{
											width: "175px",
											height: "35px",
											border: "1.5px solid #FD4B24",
											color: "#FD4B24",
										}}
										className="cancel-button"
										size="large"
										variant="outlined"
										onClick={() => setShowCloseAccountDialog(true)}
									>
										Close Account
									</Button>
									<CloseAccount
										email={
											location.state?.email ? location.state?.email : user.email
										}
										userIdByAdmin={location.state?.userId}
										open={showCloseAccountDialog}
										handleClose={() => setShowCloseAccountDialog(false)}
									/>
								</CardActions>
							</Typography>
						</CardContent>
					</Card>
						)
					}
				</Grid>
				 
			</Grid>
		</>
	);
}

export default AdminAccountSettingsPage;
