import React from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Label,
} from "recharts";

interface DataItem {
	month: string;
	totalUsers: any;
}

interface Chart1Props {
	data: DataItem[];
	color: string;
}

const LineCharts: React.FC<Chart1Props> = ({ data, color }) => {
	return (
		<ResponsiveContainer width="95%" height={400}>
			<LineChart
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid horizontal={true} vertical={false} />
				<XAxis dataKey="month" tickLine={false}>
					<Label position="bottom" dy={20} style={{fontSize: "12px"}}/>
				</XAxis>
				<YAxis width={30} axisLine={false} tickLine={false} allowDecimals={false}/>
				<Tooltip />
				<Line
					type="monotone"
					dataKey="totalUsers"
					stroke={color}
					strokeWidth={3}
					// dot={false}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export default LineCharts;
