import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, Button, Divider, DialogActions, DialogContentText } from "@mui/material"
import { useState, useEffect } from "react";
import Close from '../../images/Posts/close.svg'
import ImageCropper from "../../pages/ImageCropper";
import CloseIcon from "../../images/close.svg";
import { useAlertStatus } from "../../context/AlertStatusContext";

const ChangeImageDialog = (props: any) => {

    const [postText, setPostText] = useState('');
    const [file, setFile] = useState<any>("");
    const [imagePreviewUrl, setImagePreviewUrl] = useState<any>('');
    const [editOpen, setEditOpen] = useState(false);
    const [result, setResult] = useState("");
    const [openDeleteDialog, setOPenDeleteDialog] = useState(false);
    const { updateAlertStatus } = useAlertStatus()
    const [isNextDisabled, setIsNextDisabled] = useState(false);

    useEffect(() => {
        setPostText(props.propPostText)
    }, [props.propPostText])
    useEffect(() => {
        setResult(props.imagePreviewUrl)
        setImagePreviewUrl(props.imagePreviewUrl)
        props.file && setFile(props.file)
    }, [props])

    const photoUpload = (destFile: any) => {
        const reader = new FileReader();
        const file = destFile;
        reader.onloadend = () => {
            setFile(file);
            setImagePreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
    }

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];
    
    const handleOpenEditor = (e: any) => {
        e.preventDefault();
        const file = e.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        
        if (file.size > 4 * 1024 * 1024) {
            setIsNextDisabled(true);
            updateAlertStatus(true, false, 'Max image size should be 4 MB');
        } else {
            setIsNextDisabled(false);
            // Check both extension and MIME type
            if (imageExtensions.includes(fileExtension) && imageMimeTypes.includes(file.type)) {
                setResult(URL.createObjectURL(file));
                setImagePreviewUrl(URL.createObjectURL(file));
                setFile(file);
            } else {
                updateAlertStatus(true, false, 'Invalid file type');
            }
        }
    };
    
    const handleCrop = () => {
        setEditOpen(true);
    }
    const handleEditClose = () => {
        setEditOpen(false)
    }
    
    const handleNextAction = () => {
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
    
            // Check file size
            if (file.size > 4 * 1024 * 1024) {
                setIsNextDisabled(true);
                updateAlertStatus(true, false, 'Max image size should be 4 MB');
                return;
            }
    
            // Check file extension and MIME type
            if (!imageExtensions.includes(fileExtension) || !imageMimeTypes.includes(file.type)) {
                updateAlertStatus(true, false, 'Invalid file type');
                return;
            }
    
            // If validations pass
            props.setResult(file);
            props.setImagePreviewUrl(imagePreviewUrl);
            props.photoUpload(file);
            props.onClose();
        } else {
            updateAlertStatus(true, false, 'No file selected');
        }
    };
    
    const handleCloseDeleteDialog = () => {
        setOPenDeleteDialog(false);
    }
    const handleRemoveImage = () => {
        setIsNextDisabled(false);
        props.onClose();
        handleCloseDeleteDialog();
    }

    return (
        <>
            <Dialog
                sx={{
                    border: "1px solid #888888",
                    boxShadow: "customColor.dropShadow",
                    borderRadius: "8px",
                    p: 2,
                    minWidth: 600,
                }}
                fullWidth
                maxWidth="sm"
                open={props.open}
            >
                {openDeleteDialog ?
                    <>
                        <DialogTitle
                            className="edit-heading-name"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconButton
                                    aria-label="close dialogbox"
                                    onClick={() => handleCloseDeleteDialog()}
                                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                                        event.preventDefault()
                                    }
                                    edge="end"
                                    sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
                                >
                                    <img src={CloseIcon} alt="close" />
                                </IconButton>
                            </Box>
                            <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>Confirm Remove Image</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    fontSize: '16px',
                                    fontWeight: 400
                                }}
                            >
                                If you go back, you will loose this edited image.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ alignSelf: 'center' }}>
                            <Button
                                variant="outlined"
                                sx={{ width: '200px' }}
                                onClick={() => handleCloseDeleteDialog()}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained"
                                sx={{ width: '200px' }}
                                onClick={() => handleRemoveImage()}
                            >
                                Remove image
                            </Button>
                        </DialogActions>
                    </>
                    :
                    <>
                        <DialogTitle sx={{ fontWeight: 'bold', fontSize: '24px' }}>Create a post</DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={props.onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'black',
                            }}
                        >
                            <img src={Close} alt='close' />
                        </IconButton>

                        <DialogContent>
                            <Grid container direction={"column"} rowGap={1}>
                                <Typography variant="h6" sx={{ color: '#888888', }}>
                                    Add Picture
                                </Typography>

                                <Grid item>
                                    <img src={imagePreviewUrl} alt="uploaded" width='100%'
                                        style={{ borderRadius: '12px' }} />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', pt: 1, my: 1, ml: 3, }}>
                            <Button
                                sx={{ borderRadius: '17px', color: '#0273E6', borderColor: '#0273E6', '&:hover': { background: "#EEF6FF", borderColor: '#0273E6' }, height: '30px' }}
                                size="small"
                                variant="outlined"
                            >
                                <label style={{ cursor: "pointer", }}>
                                    <span style={{ color: '#0273E6', fontSize: '16px', fontWeight: 400 }}>Change </span>
                                    <input
                                        id="photo-upload"
                                        type="file"
                                        accept =".jpg, .jpeg, .png, .gif"
                                        style={{ display: "none", }}
                                        onChange={(e) => handleOpenEditor(e)}
                                    />
                                </label>
                            </Button>
                            <Button
                                sx={{ fontSize: '16px', fontWeight: 400, borderRadius: '17px', color: '#0273E6', borderColor: '#0273E6', '&:hover': { background: "#EEF6FF", borderColor: '#0273E6' }, height: '30px' }}
                                size="small"
                                variant="outlined"
                                onClick={handleCrop}
                            >Crop
                            </Button>
                        </Box>


                        <Divider variant="middle" light sx={{ my: 1 }} />

                        <DialogActions>
                            <Box sx={{ fontSize: '16px', display: 'flex', gap: 2, flexWrap: 'wrap', pt: 1, flexDirection: 'row-reverse', mx: 2, mb: 2 }}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{ borderRadius: '17px', background: '#FF702A', px: 2 }}
                                    onClick={handleNextAction}
                                    disabled={isNextDisabled} 
                                > Next
                                </Button>
                                <Button
                                    sx={{ borderRadius: '17px', fontSize: '16px' }}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => setOPenDeleteDialog(true)}
                                >Back
                                </Button>
                            </Box>
                        </DialogActions>
                    </>
                }

            </Dialog>
            <ImageCropper
                open={editOpen}
                onClose={handleEditClose}
                imagePreviewUrl={result}
                setImagePreviewUrl={setImagePreviewUrl}
                setResult={setFile}
                post={true}
                photoUpload={photoUpload}
                cover={false}
                setIsNextDisabled={setIsNextDisabled}
            />

            {/* {openDeleteDialog &&
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={openDeleteDialog}
                    onClose={() => handleCloseDeleteDialog()}
                >
                    <>
                    <DialogTitle
                        className="edit-heading-name"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <IconButton
                                aria-label="close dialogbox"
                                onClick={() => handleCloseDeleteDialog()}
                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                                    event.preventDefault()
                                }
                                edge="end"
                                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
                            >
                                <img src={CloseIcon} alt="close" />
                            </IconButton>
                        </Box>
                        <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>Confirm Remove Image</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                fontSize: '16px',
                                fontWeight: 400
                            }}
                        >
                            If you go back, you will loose this edited image.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{alignSelf:'center'}}>
                        <Button
                            variant="outlined"
                            sx={{width:'200px'}}
                            onClick={() => handleCloseDeleteDialog()}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained"
                        sx={{width:'200px'}}
                            onClick={()=>handleRemoveImage()}
                        >
                            Remove image
                        </Button>
                    </DialogActions>
                    </>
                </Dialog>
            } */}

        </>
    )
}

export default ChangeImageDialog