import clsx from "clsx";
import { Link } from "react-router-dom";
import linkify from "linkify-it";
import { makeStyles } from "@mui/styles";

type Results = Array<any>;
const useStyles = makeStyles({
	messageContainer: {
		borderRadius: "5px",
		display: "inline-flex",
		alignItems: "center",
		padding: "0.5em 0.8em 0.6em",
		margin: "0.3em 0 0",
		wordBreak: "break-word",
		backgroundColor: "#454545",
		hyphens: "auto",
		whiteSpace: "pre-wrap",
		color: "#FFFFFF",
		fontSize: "0.75rem",
	},
	isLocalParticipants: {
		backgroundColor: "#7F90AD",
		color: "#FFFFFF",
	},
	outerMessageLocalParticipantContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
	},
});

interface TextMessageProps {
	body: string;
	isLocalParticipant: boolean;
}

function addLinks(text: string) {
	const matches = linkify().match(text);
	if (!matches) return text;

	const results: Results = [];
	let lastIndex = 0;

	matches.forEach((match: any, i: any) => {
		results.push(text.slice(lastIndex, match.index));
		results.push(
			<Link to={match.url} key={i}>
				{match.text}
			</Link>
		);
		lastIndex = match.lastIndex;
	});

	results.push(text.slice(lastIndex, text.length));

	return results;
}

export default function TextMessage({
	body,
	isLocalParticipant,
}: TextMessageProps) {
	const classes = useStyles();

	return (
		<>
			{
				isLocalParticipant ? (<div className={classes.outerMessageLocalParticipantContainer} >
					<div
						className={clsx(classes.messageContainer, {
							[classes.isLocalParticipants]: isLocalParticipant,
						})}
					>
						<div>{addLinks(body)}</div>
					</div>
				</div>)
					: (
						<div>
							<div
								className={clsx(classes.messageContainer, {
									[classes.isLocalParticipants]: isLocalParticipant,
								})}
							>
								<div>{addLinks(body)}</div>
							</div>
						</div>
					)
			}
		</>
	);
}
