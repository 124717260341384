import {
	Grid,
	Typography,
	Button,
	Avatar,
	Divider,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import Location from "../../images/location-white.svg";
import People from "../../images/people.svg";
import Clock from "../../images/clock.svg";
import Calendar from "../../images/calendar1.svg";
import { useNavigate } from "react-router-dom";
import { SocialUSerDetails } from "../../types/types";
import { useUser } from "../../context/UserContext";
import UserOctagon from "../../images/user-octagon3.svg";
import MusicBar from "../../images/music1.svg";
import TopBar from "../../images/Profile top gradiant orange.svg";
import BottomBar from "../../images/Profile bottom gradiant blue.svg";
// import WebsocketClient from "../../services/WebsocketClient";
import DeleteProfile from "../../images/deleteProfile.svg";
import EditProfile from "../../images/editProfile.svg";
import CloseIcon from "../../images/close.svg";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import User from "../../services/user";
import ClockNo from "../../images/clock no.svg";
import ViewImage from "./ViewImage";
import ChooseProfilePictureDialog from "./ChooseProfilePictureDialog";
import RightArrow from "../../images/right arrow.svg";

const styles = {
    sideBar: {
        height: '100%',
        display: { xs: 'block', md: 'block', xl: 'block' },
        width: '288px',
        backgroundSize: 'cover',
        borderBottomRightRadius: '60px',
        borderTopLeftRadius: '60px',
        position: 'absolute',
        color: 'white',
    },
    topSide: {
        minHeight: { xl: '14%' },
        backgroundColor: '#0273E6',
        borderTopLeftRadius: '60px',
        backgroundImage: `url('${TopBar}')`,
        backgroundRepeat: 'no-repeat, repeat',
        backgroundSize: 'contain',
    },
    middleSide: {
        minHeight: '67%',
        backgroundImage: `url('${BottomBar}')`,
        backgroundRepeat: 'no-repeat, repeat',
        backgroundPosition: 'bottom',
        borderBottomRightRadius: '60px',
        backgroundColor: '#0273E6',
        backgroundSize: 'contain',
    },
};
interface Props {
	userDetails: SocialUSerDetails;
}
const HomeSideBar = (props: Props) => {
	// const user = useUser();
	const selfDetails = useUser();
	const token = localStorage.getItem("sessionId");
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [deviceStatus, setDeviceStatus] = useState(false);
	const timeoutRef = useRef<any>(undefined);
	const permissions = props.userDetails.privacySettings;
	const user = props.userDetails.privacySettings?.userProfile;
	const viewer = props.userDetails.loggedInUser?.ViewerStatus;
	const [EditVisibility, setEditVisibility] = useState(false);
	const [imagePreviewUrl, setImagePreviewUrl] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [open, setOpen] = useState(false);
	const [openDeleteDialog, setOPenDeleteDialog] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [editImageStatus, setEditImageStatus] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);

	const handleClick = () => {
		imagePreviewUrl && setModalOpen(true);
	};

	useEffect(() => {
		setImagePreviewUrl(
			user?.profilePhotoUrl
				? `${process.env.REACT_APP_PICTURE_URL}${user?.profilePhotoUrl}`
				: ""
		);
	}, [user]);
	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleViewProfile = () => {
		if (user?.nickname) { // Ensure user.nickname is not null or undefined
			const encodedNickName = encodeURIComponent(user.nickname);
			navigate(`/socialHome/${encodedNickName}`);
		} 
	};

	const handleSubscription = () => {
navigate(`/settings/plans/plans`)
	}
	// const handlePingDevice = (deviceId: any) => {
	// 	let message = {
	// 		action: "ping_device",
	// 		timestamp: new Date().toISOString(),
	// 		created_for: deviceId,
	// 		created_by: user?.user?.id,
	// 	};
	// 	WebsocketClient.handlePingDevice(message);
	// 	timeoutRef.current = setTimeout(() => {
	// 		setDeviceStatus(false);
	// 	}, 10000);
	// };

	// const onMessageReceived = (responseMsg: any) => {
	// 	if (responseMsg.action === "ping_device") {
	// 		if (responseMsg.metadata.ping_status === "ONLINE") {
	// 			setDeviceStatus(true);
	// 			globalThis.clearTimeout(timeoutRef.current);
	// 		} else {
	// 			setDeviceStatus(false);
	// 			globalThis.clearTimeout(timeoutRef.current);
	// 		}
	// 	}
	// };
	// useEffect(() => {
	// 	handlePingDevice(props.userDetails.deviceId);
	// 	WebsocketClient.on("ping_device", onMessageReceived);
	// 	return () => {
	// 		WebsocketClient.off("ping_device", onMessageReceived);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	useEffect(() => {
		const handleUserChanged = () => {
			setEditImageStatus(false);
			handleCloseDeleteDialog();
			handleClose();
		};

		User.on("userChanged", handleUserChanged);

		return () => {
			User.off("userChanged", handleUserChanged);
		};
	}, []);

	const deleteUpload = () => {
		setEditImageStatus(true);
		let formData = new FormData();
		formData.append("email", selfDetails?.email);
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/delete-profile-photo`,
			{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status >= 400) {
					setEditImageStatus(false);
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					User.fetchUserInfo(() => {});
                    setImagePreviewUrl("");
					// fetchUserInfo()
					// handleCloseDeleteDialog();
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	// const handleOpenEditor = (e: any) => {
	//     e.preventDefault();
	//     const file = e.target.files[0];
	//     setResult(URL.createObjectURL(file))
	//     setFile(file);
	//     setOpen(true)
	// }

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const photoUpload = (destFile: any) => {
		setEditImageStatus(true);
		const reader = new FileReader();
		let formData = new FormData();
		formData.append("profilePhoto", destFile);
		formData.append("email", selfDetails.email);
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/user-profiles/update-profile-photo`,
			{
				method: "PUT",
				headers: {
					//   "Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status >= 400) {
					setEditImageStatus(false);
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					reader.readAsDataURL(destFile);
					// setImagePreviewUrl(URL.createObjectURL(destFile))
					User.fetchUserInfo(() => {});
					// fetchUserInfo()
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const handleCloseDeleteDialog = () => {
		setOPenDeleteDialog(false);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleDialogClose = (success: boolean) => {
		setOpenDialog(false);
	};

	const handleImageUrl = (callbackImageUrl: string) => {
		setImagePreviewUrl(callbackImageUrl);
	};

	const formatNameWithMaxLength = (
		name: string | undefined,
		maxChars: number
	): string => {
		return name && name.length > maxChars
			? name.substring(0, maxChars) + "..."
			: name || "";
	};

	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Box sx={styles.sideBar}>
				<Box sx={styles.topSide}>
					<Grid container sx={{ flexDirection: "column", rowGap: 1 }}>
						<Grid item sx={{ alignSelf: "center" }}>
							<IconButton
								sx={{
									"&:hover": { backgroundColor: "transparent" },
									width: 160,
									height: 160,
									marginTop: "-22px",
									marginLeft: "5px",
								}}
								onMouseEnter={() => setEditVisibility(true)}
								onMouseLeave={() => setEditVisibility(false)}
								onClick={handleClick}
								// disableRipple
							>
								<Avatar
									alt={`${user?.user?.firstName} ${user?.user?.lastName}`}
									src={imagePreviewUrl}
									// onClick={handleClick}
									sx={{
										width: 160,
										height: 160,
										border: "4px solid #e1a06c",
										marginLeft: "5px",
                                        backgroundColor:imagePreviewUrl ? '#CAD2D4' : '',
									}}
								/>
							</IconButton>
						</Grid>
					</Grid>
				</Box>
				{selfDetails.userAccountStatus !== "SUSPEND" &&
					EditVisibility &&
					viewer === "SELF" && (
						<div
							onMouseEnter={() => setEditVisibility(true)}
							onMouseLeave={() => setEditVisibility(false)}
							style={{
								backgroundColor: "rgba(255, 255, 255, 0.51)",
								margin: "auto",
								zIndex: "1",
								width: "160px",
								height: "80px",
								position: "absolute",
								marginTop: "-40px",
								left: "52%",
								transform: "translate(-50%, -50%)",
								borderRadius: "0 0 150px 150px",
							}}
						>
							<Grid container>
								<Grid xs={6} sx={{ textAlignLast: "start", mt: "3px" }}>
									<label>
										<img
											id="photo-edit"
											src={EditProfile}
											onClick={() => setOpenDialog(true)}
											alt="Upload"
											style={{
												cursor: "pointer",
												marginLeft: imagePreviewUrl !== "" ? "30px" : "60px",
												marginTop: "14px",
											}}
										/>

										{/* <input
    const formatNameWithMaxLength = (name: string | undefined, maxChars: number): string => {
        return name && name.length > maxChars ? name.substring(0, maxChars) + '...' : name || '';
    };

    return (
        <>
            <ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />
            <Box sx={styles.sideBar}>
                <Box sx={styles.topSide} >
                    <Grid container sx={{ flexDirection: 'column', rowGap: 1 }}>

                        <Grid item sx={{ alignSelf: 'center' }}>
                            <IconButton
                                sx={{
                                    "&:hover": { backgroundColor: "transparent" },
									width: 160,
									height: 160,
									marginTop: "-22px",
									marginLeft: "5px",
                                }}
                                onMouseEnter={() => setEditVisibility(true)}
                                onMouseLeave={() => setEditVisibility(false)}
                                // onClick={handleClick}
                            // disableRipple
                            >
                                <Avatar
                                    alt={`${user?.user?.firstName} ${user?.user?.lastName}`}
                                    src={imagePreviewUrl}
                                    onClick={handleClick}
                                    sx={{
                                        width: 160,
										height: 160,
										border: "4px solid #e1a06c",
										marginLeft: "5px",
                                        backgroundColor:imagePreviewUrl ? '#CAD2D4' : '',
                                    }}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                {selfDetails.userAccountStatus !== "SUSPEND" &&
                    (EditVisibility && viewer === 'SELF') &&
                    <div onMouseEnter={() => setEditVisibility(true)}
                        onMouseLeave={() => setEditVisibility(false)} className="sideBarView"
                        style={{
                            backgroundColor: "rgba(255, 255, 255, 0.51)",
								margin: "auto",
								zIndex: "1",
								width: "160px",
								height: "80px",
								position: "absolute",
								marginTop: "-40px",
								left: "52%",
								transform: "translate(-50%, -50%)",
								borderRadius: "0 0 150px 150px",
                        }}>
                        <Grid container >
                            <Grid xs={6} sx={{ textAlignLast: 'start', mt: "3px" }}>
                                <label>
                                    <img id="photo-edit"
                                        src={EditProfile}
                                        onClick={() => setOpenDialog(true)}
                                        alt="Upload"
                                        style={{ cursor: "pointer",
                                        marginLeft: imagePreviewUrl !== "" ? "30px" : "60px",
                                        marginTop: "14px", }} />

                                    {/* <input
                                        id="photo-edit"
                                        type="file"
                                        accept =".jpg, .jpeg, .png, .gif"
                                        style={{ display: "contents" }}
                                        onChange={(e) => handleOpenEditor(e)}
                                    /> */}
                                </label>
                            </Grid>
                            {imagePreviewUrl !== '' &&
                                <Grid xs={6} sx={{ textAlignLast: 'end' }}>
                                    <IconButton size="small" onClick={() => setOPenDeleteDialog(true)} 
                                    sx={{ marginRight: "30px", marginTop: "14px" }}>
                                        <img src={DeleteProfile} alt='' />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    </div>
                )}

				<Box sx={styles.middleSide}>
					<Grid
						container
						sx={{ flexDirection: "column", pl: 1, rowGap: 1, pt: 1 }}
					>
						{/* {(viewer === 'SELF')
                            // || ((permissions?.identityPermission !== 'Name is not visible. Instead just show nickname' && viewer !== 'SELF')
                            || (permissions?.identityPermission === 'Name is visible only to my connections. Other see nickname' && viewer === 'IN_CONNECTION')
                            || (permissions?.identityPermission === 'Name is visible to all')
                            ?
                            <Grid item sx={{ alignSelf: 'start', pl: 1, }}>
                                <Tooltip title={`${user?.user?.firstName} ${user?.user?.lastName}`}>
                                    <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '32px' }} >
                                        {`${user?.user?.firstName && user?.user?.firstName?.length > 10 ? user?.user?.firstName?.substring(0, 10) + "..." : user?.user?.firstName}
                                    ${user?.user?.lastName && user?.user?.lastName?.length > 10 ? user?.user?.lastName?.substring(0, 10) + "..." : user?.user?.lastName}`}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            : ''} */}

						<Typography
							variant="h3"
							sx={{ fontWeight: "bold", fontSize: "32px", ml: "9px" }}
						>
							{user?.id === selfDetails?.userProfileId ? (
								formatNameWithMaxLength(
									`${user?.user?.firstName} ${user?.user?.lastName}`,
									15
								)
							) : props?.userDetails?.privacySettings?.identityPermission ===
							  "Name is not visible. Instead just show nickname" ? (
								<>
									<Typography
										variant="h3"
										sx={{ fontSize: "24px", textAlign: "center" }}
									>
										@{formatNameWithMaxLength(user?.nickname, 10)}
									</Typography>
								</>
							) : props?.userDetails?.privacySettings?.identityPermission ===
							  "Name is visible to all" ? (
								formatNameWithMaxLength(
									`${user?.user?.firstName} ${user?.user?.lastName}`,
									15
								)
							) : props?.userDetails?.privacySettings?.identityPermission ===
							  "Name is visible only to my connections. Other see nickname" ? (
								viewer === "IN_CONNECTION" ? (
									formatNameWithMaxLength(
										`${user?.user?.firstName} ${user?.user?.lastName}`,
										15
									)
								) : (
									<>
										<Typography
											variant="h3"
											sx={{ fontSize: "24px", textAlign: "center" }}
										>
											@{formatNameWithMaxLength(user?.nickname, 10)}
										</Typography>
									</>
								)
							) : (
								""
							)}
						</Typography>

						<Grid
							item
							container
							sx={{ flexDirection: "column", pl: 1, rowGap: 1 }}
						>
							{/* {user?.nickname  ?
                                <Typography variant="caption" style={{ fontSize: "16px" }}>@
                                    {`${user?.nickname && user?.nickname?.length > 22 ? user?.nickname?.substring(0, 22) + "..." : user?.nickname}`}
                                </Typography>
                                : ""} */}
							<Typography variant="caption" style={{ fontSize: "16px" }}>
								{props?.userDetails?.privacySettings?.identityPermission ===
									"Name is visible to all" ||
								(props?.userDetails?.privacySettings?.identityPermission ===
									"Name is visible only to my connections. Other see nickname" &&
									viewer === "IN_CONNECTION") ||
								user?.id === selfDetails?.userProfileId
									? formatNameWithMaxLength(`${user?.nickname}`, 18)
									: ""}
							</Typography>
							<Grid item container>
								<Typography
									variant="caption"
									sx={{ fontSize: "1rem", marginLeft: "-4px", display: "flex" }}
								>
									<img
										src={Location}
										style={{ width: "24px", height: "24px" }}
										alt="Location"
									/>
									{user?.location}
								</Typography>
							</Grid>
						</Grid>

						<Grid item>
							<Divider
								sx={{ background: "#A5D1FF", mr: 1, mb: 1 }}
								variant="fullWidth"
							/>
						</Grid>

						<Grid item container sx={{ flexDirection: "column", rowGap: 1 }}>
							<Grid item container>
								{viewer === "SELF" ? (
									<Button
										sx={{
											backgroundColor: "#3292F3",
											":hover": { backgroundColor: "#3292F3" },
											fontFamily: "Lexend",
										}}
										startIcon={<img src={People} alt="Connections" />}
										onClick={() => {
											if (viewer === "SELF") {
												navigate("/musicians/connections");
											}
										}}
									>
										{props.userDetails.totalConnections} connections
									</Button>
								) : (
									<>
										<img
											src={People}
											style={{ width: "24px", height: "24px" }}
											alt="connection"
										/>
										&nbsp;
										<Typography
											variant="caption"
											sx={{ fontSize: "14px", fontFamily: "Lexend" }}
										>
											{props.userDetails.totalConnections} connections
										</Typography>
									</>
								)}
							</Grid>

							<Grid item container columnGap={1}>
								{viewer !== "SELF" &&
								(permissions?.onlineStatusPermission === "Visible to anyone" ||
									permissions?.onlineStatusPermission === "Hide last seen" ||
									(viewer === "IN_CONNECTION" &&
										permissions?.onlineStatusPermission ===
											"Visible only to my connections")) ? (
									<Grid item xs={3.5} container>
										<img
											src={user?.online || viewer === "SELF" ? Clock : ClockNo}
											style={{ width: "24px", height: "24px" }}
											alt="Location"
										/>
										&nbsp;
										<Typography variant="caption" sx={{ fontSize: "14px" }}>
											{user?.online || viewer === "SELF" ? "Online" : "Offline"}
										</Typography>
									</Grid>
								) : (
									""
								)}

								{/* <Grid item xs={3.5} container>
                                    <img src={deviceStatus ? Online : Offline} style={{ width: '24px', height: '24px' }} alt="Location" />&nbsp;
                                    <Typography variant="caption" sx={{ fontSize: '14px' }}>
                                        {deviceStatus ? 'Online' : 'Offline'}
                                    </Typography>
                                </Grid> */}

								{user?.openForCollab ? (
									<Grid item xs={3} container>
										<img
											src={MusicBar}
											style={{ width: "24px", height: "24px" }}
											alt="Location"
										/>
										&nbsp;
										<Typography variant="caption" sx={{ fontSize: "14px" }}>
											Open
										</Typography>
									</Grid>
								) : (
									""
								)}
							</Grid>

							<Grid item container>
								<img
									src={Calendar}
									style={{ width: "24px", height: "24px" }}
									alt="Location"
								/>
								&nbsp;
								<Typography variant="caption" sx={{ fontSize: "14px" }}>
									Member since{" "}
									{new Date(
										user?.user?.createdDate ? user?.user?.createdDate : ""
									).toLocaleString("en-us", {
										month: "short",
										year: "numeric",
									})}
								</Typography>
							</Grid>

							<Grid item sx={{ alignSelf: "center", minWidth: "259px" }}>
								<Button
									fullWidth
									sx={{
										fontSize: "16px",
										fontWeight: 600,
										fontFamily: "Lexend",
										backgroundColor: "white",
										borderRadius: "17px",
										color: "#0273E6",
										"&:hover": { backgroundColor: "#EEF6FF", color: "#0273E6" },
										border: "3px solid #0273E6",
									}}
									variant="contained"
									onClick={handleViewProfile}
								>
									<img
										src={UserOctagon}
										style={{ width: "24px", height: "24px" }}
										alt="Edit"
									/>
									View Full Profile
								</Button>
							</Grid>

							<Grid item>
                                <Divider sx={{ background: '#A5D1FF', mr: 1, mb: 1, }} variant="fullWidth" />
                            </Grid>

                            <Grid item container sx={{ flexDirection: 'column', rowGap: 1, textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ fontSize: '16px' }}>
                                    Upgrade to Premium & enjoy unlimited session and music<br />                                  
                                </Typography>
                            </Grid>

                            <Grid item sx={{ alignSelf: 'center', minWidth: '252px' }}>
                                <Button fullWidth sx={{ height: '36px', fontSize: '16px', backgroundColor: 'linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%) !important', borderRadius: '24px', color: 'white' }}
                                    className="submit-button"
                                    variant="contained" 
                                    endIcon={<img src={RightArrow} alt="Right Arrow" style={{ paddingRight: '5px', width: '24px', height: '24px' }} />}  
									onClick={() => {
										if (viewer === "SELF") {
											navigate("/home/settings/plans/plans");
										}
									}} >


                                    <Typography>Buy Subscription</Typography>
                                </Button>
                            </Grid>

							<Grid item>
								<Divider
									sx={{ background: "#A5D1FF", mr: 1, mb: 1 }}
									variant="fullWidth"
								/>
							</Grid>

							{/* <Grid item>
                                <Typography variant="h6" sx={{ textAlign: 'center', fontSize: '24px' }} >Sponsored Events </Typography>
                            </Grid> */}
							<br />
						</Grid>
					</Grid>
				</Box>
			</Box>

			{/* <ImageCropper
                open={open}
                onClose={handleClose}
                imagePreviewUrl={result}
                setImagePreviewUrl={setImagePreviewUrl}
                setResult={setFile}
                post={false}
                photoUpload={photoUpload}
                cover={false}
                editImageStatus={editImageStatus}
                homeSidebar={true}
            /> */}

			{isModalOpen && (
				<ViewImage
					open={isModalOpen}
					handleClose={() => setModalOpen(false)}
					ImageUrl={imagePreviewUrl}
				/>
			)}

			{openDialog && (
				<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid item xs={12}>
						<ChooseProfilePictureDialog
							open={openDialog}
							handleClose={handleDialogClose}
							handleImageUrlCallback={handleImageUrl}
							permissions={permissions}
							editImageStatus={editImageStatus}
						/>
					</Grid>
				</Grid>
			)}

			{openDeleteDialog && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteDialog}
					onClose={() => handleCloseDeleteDialog()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeleteDialog()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete Profile Picture</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={() => handleCloseDeleteDialog()}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							disabled={editImageStatus}
							onClick={() => deleteUpload()}
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default HomeSideBar;
