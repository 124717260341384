import { useEffect, useState } from "react";
import { Box, Grid, Typography, CircularProgress, Button } from "@mui/material";
import { ViewMessage } from "./ViewMessage";
import SessionCmp from "../jam-session/SessionCmp";
import UserCardDetailed from "../Musicians/UserCardDetailed";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import { set } from "lodash";
import { CommentReaction } from "./CommentReaction";
import InfiniteScroll from "react-infinite-scroll-component";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { EventGigReaction } from "./EventGigReaction";
import { useAlertStatus } from "../../context/AlertStatusContext";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);

export const NotificationCard = (props: any) => {
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const [allNotifications, setAllNotifications] = useState<any[]>([]);
	const [unreadNotifications, setUnreadNotifications] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);

	const [clickedNotifications, setClickedNotifications] = useState<string[]>(
		[]
	);
	const [hasMore, setHasMore] = useState(true);
	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(0);
	const [itemPerPage, setItemPerPage] = useState(40);
	const navigate = useNavigate();
	const { updateAlertStatus } = useAlertStatus();

	const startVideoSession = (session: any) => {
		if (session && session.hasOwnProperty("id")) {
			const sessionId = session.id;
			navigate(`/jamsessionlink?sid=${sessionId}`);
		}
	};

	const formatTimeDifference = (dateTime: string) => {
		const currentDateTime: Date = new Date();
		const notificationDateTime = new Date(dateTime);
		const timeDiffInMilliseconds =
			currentDateTime.getTime() - notificationDateTime.getTime();

		const minutes = Math.floor(timeDiffInMilliseconds / (1000 * 60));
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const months = Math.floor(days / 30);
		const years = Math.floor(months / 12);

		if (minutes === 0) {
			return "now";
		} else if (minutes < 60) {
			return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
		} else if (hours < 24) {
			return `${hours} hour${hours === 1 ? "" : "s"} ago`;
		} else if (days < 30) {
			return `${days} day${days === 1 ? "" : "s"} ago`;
		} else if (months < 12) {
			return `${months} month${months === 1 ? "" : "s"} ago`;
		} else {
			return `${years} year${years === 1 ? "" : "s"} ago`;
		}
	};

	const onNotificationClick = (notification: any, markRead: boolean) => {
		if (!clickedNotifications.includes(notification.id)) {
			// Add the notification ID to the clickedNotifications state
			setClickedNotifications([...clickedNotifications, notification.id]);

			if (!notification?.readStatus) {
				updateAlertStatus(true, true, 'Notification marked as read');
			}

			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/notification-read/${notification.id}/${markRead}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					}

					return res.json();
				})
				.then((data) => {

				 })
				.catch((err) => {
				});
		}
	};

	const fetchAllNotification = () => {
		setLoading(true);
		try {
			let toNotifications =
				props.activeMenu === "unread"
					? "getAllUnreadNotifications"
					: "getAllnotifications";
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/${toNotifications}?&page=${pageNumber}&size=${itemPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						id:user.id
					}),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {
						if (pageNumber === 0) {
							props.activeMenu === "unread"
								? setUnreadNotifications(data)
								: setAllNotifications(data);
						} else {
							props.activeMenu === "unread"
								? setUnreadNotifications((prevRowData) => [
										...prevRowData,
										...data,
								  ])
								: setAllNotifications((prevRowData) => [
										...prevRowData,
										...data,
								  ]);
						}
						setPageNumber(pageNumber + 1);
						setLoading(false);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	const resetAllNotifications = () => {
		setLoading(true);
		try {
			setHasMore(true);
			let toNotifications =
				props.activeMenu === "unread"
					? "getAllUnreadNotifications"
					: "getAllnotifications";
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/${toNotifications}?&page=0&size=${itemPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						id:user.id
					}),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
						props.activeMenu === "unread"
							? setUnreadNotifications([])
							: setAllNotifications([]);
					} else {
						// if (pageNumber === 0) {
						props.activeMenu === "unread"
							? setUnreadNotifications(data)
							: setAllNotifications(data);
						// } else {
						// 	props.activeMenu === "unread"
						// 		? setUnreadNotifications((prevRowData) => [
						// 				...prevRowData,
						// 				...data,
						// 		  ])
						// 		: setAllNotifications((prevRowData) => [
						// 				...prevRowData,
						// 				...data,
						// 		  ]);
						// }
						setPageNumber(1);
						setLoading(false);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		resetAllNotifications();
	}, [props.activeMenu]);

	// useEffect(() => {
	// 	const onMessageReceived = (responseMsg: any) => {
	// 		fetchAllNotification();
	// 	}
	// 	WebsocketClientUSerStatus.on("notification", onMessageReceived);
	// 	return () => {
	// 		WebsocketClientUSerStatus.off("notification", onMessageReceived);
	// 	};
	// }, [])

	const handleMarkAsRead = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/readAllNotifications`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				}
			})
			.then((data) => {
				updateAlertStatus(true, true, 'All notifications marked as read');
				resetAllNotifications();
			})
			.catch((err) => {
			});
	}
	const pauseAudioPlayer = (musicId:any) => {
		// Pause the currently playing audio player
		const audioPlayers = document.querySelectorAll("audio");
	    if(audioPlayers){
        audioPlayers.forEach((audioPlayer) => {
			const audioElement = audioPlayer as HTMLAudioElement;
             if (audioPlayer.id == musicId) {
			// //	audioElement.play();
		     }else{
			// 	//audioPlayer.pause();
				audioElement.pause();
			 }
		});
        }
	};

	return (
		<>
		 <Grid container>
			{((!loading && (props.activeMenu === "unread" && unreadNotifications.length > 0)) ||
				(!loading && (props.activeMenu !== "unread" && allNotifications.length > 0)))
			&& (
				<Grid container sx={{mb: 1.5}}>
					<Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
						<Typography sx={{fontSize: "16px", fontWeight: "400", color: "#7A7985", ml: "10px"}}>
							Click on the notification card to mark it as read
						</Typography>
						<Button onClick={handleMarkAsRead} variant="outlined" color="info" sx={{ width: "188px", height: "36px", mr: 3 }}>
							<Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "500", color: "#0273E6", padding: 'auto 30px' }}>
								Mark all as read
							</Typography>
						</Button>
					</Grid>
				</Grid>
			)}
			<Grid xs={12} container spacing={2}>
				<Grid item xs={12}>
					<InfiniteScroll
						dataLength={
							(props.activeMenu === "unread"
								? unreadNotifications
								: allNotifications
							).length
						}
						next={fetchAllNotification}
						hasMore={hasMore}
						loader={
							loading && (
							<Box
								className={classes.spinner}
							>
								<CircularProgress />
								</Box>
							)
						}
					>
						{
							(props.activeMenu === "unread"
								? unreadNotifications
								: allNotifications
							).length > 0 ? (
									// </Grid>
									(props.activeMenu === "unread"
										? unreadNotifications
										: allNotifications
									).map((notification: any) => {
										if (
											notification?.notificationType === "ConnectionRequest" &&
											notification != null
										) {
											return (
												<Grid item xs={12} margin={1}>
													<div
														onClick={() =>
															onNotificationClick(notification, true)
														}
													>
														<UserCardDetailed
															user={notification}
															notification={true}
															refreshList={fetchAllNotification}
															isNotification={true}  onItemClick={pauseAudioPlayer}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(notification.dateTime)}
														</div>
													</div>
												</Grid>
											);
										} else if (
											notification?.notificationType === "Meeting" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<div
														onClick={() =>
															onNotificationClick(notification, true)
														}
													>
														<SessionCmp
															unread={props.activeMenu === "unread" && true}
															session={notification.session}
															notification={notification}
															startVideoSession={() =>
																startVideoSession(notification.session)
															}
															inProgress={false}
															isMeeting
															displayEdit={false}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(notification.dateTime)}
														</div>
													</div>
												</Grid>
											);
										} else if (
											notification?.notificationType === "Invitation" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<div
														onClick={() =>
															onNotificationClick(notification, true)
														}
													>
														<SessionCmp
															unread={props.activeMenu === "unread" && true}
															session={notification.session}
															notification={notification}
															inProgress={false}
															isInvitation
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(notification.dateTime)}
														</div>
													</div>
												</Grid>
											);
										} else if (
											notification?.notificationType === "UpdatedInvitation" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<div
														onClick={() =>
															onNotificationClick(notification, true)
														}
													>
														<SessionCmp
															unread={props.activeMenu === "unread" && true}
															session={notification.session}
															notification={notification}
															inProgress={false}
															isUpdatedInvitation
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(notification.dateTime)}
														</div>
													</div>
												</Grid>
											);
										} else if (
											notification?.notificationType === "CommentOnPost" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<CommentReaction
															notification={notification}
															notificationType="commentOnPost"
															onNotificationClick={onNotificationClick} onItemClick={pauseAudioPlayer}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postCommentedTime
															)}
														</div>
												</Grid>
											);
										} else if (
											notification?.notificationType === "ReactedOnPost" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<CommentReaction
															notification={notification}
															notificationType="likeOnPost"
															onNotificationClick={onNotificationClick} onItemClick={pauseAudioPlayer}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(notification.postLikeTime)}
														</div>
												</Grid>
											);
										} else if (
											notification?.notificationType === "ReactionOnComment" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<CommentReaction
															notification={notification}
															notificationType="reactionOnComment"
															onNotificationClick={onNotificationClick} onItemClick={pauseAudioPlayer}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(notification.commentLikeTime)}
														</div>
												</Grid>
											);
										} else if (
											notification?.notificationType === "CommentOnComment" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<CommentReaction
															notification={notification}
															notificationType="commentOnComment"
															onNotificationClick={onNotificationClick} onItemClick={pauseAudioPlayer}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postCommentedTime
															)}
														</div>
												</Grid>
											);
										}
										else if (
											notification?.notificationType === "EventReaction" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<EventGigReaction
															notification={notification}
															notificationType="EventReaction"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postLikeTime
															)}
														</div>
												</Grid>
											);
										}
										else if (
											notification?.notificationType === "TagInPost" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<CommentReaction
															notification={notification}
															notificationType="TagInPost"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postLikeTime
															)}
														</div>
												</Grid>
											);
										}	else if (
											notification?.notificationType === "TagInComment" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<CommentReaction
															notification={notification}
															notificationType="TagInComment"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postLikeTime
															)}
														</div>
												</Grid>
											);
										}

										else if (
											notification?.eventType
											=== "NewEventNearMe" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<EventGigReaction
															notification={notification}
															notificationType="NewEventNearMe"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.dateTime
															)}
														</div>
												</Grid>
											);
										}

										else if (
											notification?.eventType
											=== "NewGigInCity" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<EventGigReaction
															notification={notification}
															notificationType="NewGigInCity"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.dateTime
															)}
														</div>
												</Grid>
											);
										}

										else if (
											notification?.notificationType === "GigReaction" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<EventGigReaction
															notification={notification}
															notificationType="GigReaction"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postLikeTime
															)}
														</div>
												</Grid>
											);
										}

										else if (
											notification?.notificationType === "CommentOnEvent" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<EventGigReaction
															notification={notification}
															notificationType="CommentOnEvent"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postLikeTime
															)}
														</div>
												</Grid>
											);
										}

										else if (
											notification?.notificationType === "CommentOnGig" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<EventGigReaction
															notification={notification}
															notificationType="CommentOnGig"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postLikeTime
															)}
														</div>
												</Grid>
											);
										}

										else if (
											notification?.notificationType === "ReactionOnEvent" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<EventGigReaction
															notification={notification}
															notificationType="ReactionOnEvent"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postLikeTime
															)}
														</div>
												</Grid>
											);
										}
										else if (
											notification?.notificationType === "ReactionOnGig" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<EventGigReaction
															notification={notification}
															notificationType="ReactionOnGig"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.postLikeTime
															)}
														</div>
												</Grid>
											);
										}


										else if (
											notification?.notificationType === "LocalArtistJoined" &&
											notification != null
										) {
											return (
												<Grid item xs={12}>
													<EventGigReaction
															notification={notification}
															notificationType="LocalArtistJoined"
															onNotificationClick={onNotificationClick}
														/>
														<div
															style={{ marginTop: "8px", marginBottom: "24px" }}
															className="notification-time"
														>
															{formatTimeDifference(
																notification.dateTime
															)}
														</div>
												</Grid>
											);
										}

									})

							) : (
									!loading && (
									<Typography
									variant = "body1"
									sx = {{
										fontSize: "1.125rem",
										display: "flex",
										justifyContent: "center",
									}}
								>
								No New Notifications
										</Typography>
									)
							)
						}
					</InfiniteScroll>
				</Grid>
			</Grid>
			</Grid>
		</>
	);
};
