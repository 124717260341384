import React, { memo, useEffect, useState } from "react";
import clsx from "clsx";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import usePeopleContext from "../../hooks/usePeopleContext/usePeopleContext";
import { usePagination } from "../GalleryView/usePagination/usePagination";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useParticipantsContext from "../../hooks/useParticipantsContext/useParticipantsContext";
import PeopleWindowHeader from "./PeopleWindowHeader/PeopleWindowHeader";
import UnMute from "../../images/microphone-2Mic-Outlined.svg";
import PeopleProfileIcon from "../../images/people-profile-icon.svg";
// import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import { useUser } from "../../context/UserContext";
import AudioButton from "./PeopleWindowHeader/AudioButton";
import { Button, Typography } from "@mui/material";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import { useFullscreenModeContext } from "../../context/FullscreenModeContext";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		peopleWindowContainer: {
			 position: "relative",
			background: "#000000",
			zIndex: 9,
			display: "flex",
			flexDirection: "column",
			border: "2px solid #7A7985",
			margin: "0px 0 10px 0",
			borderRadius: "8px",
			[theme.breakpoints.down("sm")]: {
				position: "fixed",
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				zIndex: 100,
			},
		},
		peopleNameContainer: {
			display: "flex",
			alignItems: "start",
			justifyContent: "space-between",
			flexDirection:"column",
		},
		hide: {
			display: "none",
		},
		peopleList: {
			padding: "10px",
			height:"457px",
			overflowY:"scroll",
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "14px",
		},
		connectedPeopleName: {
			display: "inline-flex",
			borderRadius: "14px",
			background: "#0273E6"
		},
		notConnectedPeopleName: {
			display: "flex",
			borderRadius: "14px",
			background: "#0273E6"
		},
		muteAllButton: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		audioText: {
			display: "flex",
			alignItems: "center",
			fontSize: "0.75rem",
			color: "#C5C5C5",
		},
	})
);

const PeopleWindow = (props: any) => {
	const userSession = localStorage.getItem("userSession") ?? "";
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const userSessions = JSON.parse(userSession);

	const classes = useStyles();
	const user = useUser();
	const { isPeopleWindowOpen } = usePeopleContext();
	const { room } = useVideoContext();
	const { speakerViewParticipants } = useParticipantsContext();
	const { participantsList } = usePagination([
		room!.localParticipant,
		...speakerViewParticipants,
	]);
	const [connectedPeoplesList, setConnectedPeoplesList] = useState([]);
	// const [showMuteAllBtn, setShowMuteAllBtn] = useState(true);
	const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
	let participantDetailList = JSON.parse(
		localStorage.getItem("participantVolumeDetails") ?? ""
	);

	const participantsConnected = userSessions.attendees.filter((item: any) =>
		participantsList.some((participant) => item.email === participant.identity)
	);


	// eslint-disable-next-line array-callback-return
	participantsConnected.map((participant: any) => {
		participant.audioStatus = "unmute";
		participant.audioLevel = 50;
	});
	const participantNotConnected = userSessions.attendees.filter(
		(item: any) =>
			!participantsList.some(
				(participant) => item.email === participant.identity
			)
	);


	useEffect(() => {
		WebsocketClientUSerStatus.on("audioControl", onMessageReceived);

		return () => {
			WebsocketClientUSerStatus.off("audioControl", onMessageReceived);
		};
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const newParticipants = participantsList.filter(
			(item: any) =>
				!connectedPeoplesList.some(
					(participant: any) => participant?.email === item.identity
				)
		);
		if (
			connectedPeoplesList.length === 0 ||
			newParticipants.length > 0 ||
			participantsList.length < connectedPeoplesList.length
		) {
			setConnectedPeoplesList(participantsConnected);
		}
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [participantsList]);

	const audioControl = () => {
		// eslint-disable-next-line array-callback-return
		connectedPeoplesList.map((participant: any) => {
			let audioStatus = "mute";
			let audioLevel = participant.audioLevel;

			const audioDetails = participantDetailList.find(
				(p: any) => p.email === participant.email
			);

			if (participant.email !== room!.localParticipant.identity) {
				let message = {
					action: "audio_control",
					timestamp: new Date().toISOString(),
					session_id: twilioSessionId,
					created_by: user.id,
					created_for: participant.email,
					audio: {
						updated_for: "master",
						master: {
							audio_status: audioStatus,
							audio_level: audioLevel,
						},
						channel_1: {
							audio_status: audioStatus,
							audio_level: audioDetails.audio.channel_1.audio_level,
						},
						channel_2: {
							audio_status: audioStatus,
							audio_level: audioDetails.audio.channel_2.audio_level,
						},
						mic: {
							audio_status: audioStatus,
							audio_level: audioDetails.audio.mic.audio_level,
						},
					},
				};
				WebsocketClientUSerStatus.handleAudioControl(message);
			}
		});
	};

	

	const onMessageReceived = (data: any) => {
		// eslint-disable-next-line array-callback-return
		connectedPeoplesList.map((participant: any) => {
			if (participant.email === data.metadata.created_by) {
				if (data.metadata.audio.master.audio_status === "mute") {
					participant.audioStatus = "mute";
				} else {
					participant.audioStatus = "unmute";
					participant.audioLevel = data.metadata.audio.audioLevel;
				}
			}
		});
	};

	return (
		<>
			<aside
				className={clsx(classes.peopleWindowContainer, {
					[classes.hide]: !isPeopleWindowOpen,
				})}
				style={{	top: isFullscreenMode ? "135px" : "62px",}}
			>
				<PeopleWindowHeader />

				<div className={classes.peopleList}>
					<div className={classes.muteAllButton}>
						<span style={{ color: "#888888" }}>
							In Session ({connectedPeoplesList.length})
						</span>

						<Button
							className="mute-button"
							size="large"
							variant="contained"
							onClick={audioControl}
						>
							<span className={classes.audioText}>
								<img
									style={{ padding: "0 5px", height: "16px", width: "16px" }}
									src={UnMute}
									alt="mic"
								/>
								Mute All
							</span>

							{/* {toggleAudio && (

                                ) : (
                                    <span className={classes.audioText}>
                                        <img
                                            style={{ padding: "0 5px", height: "16px", width: "16px" }}
                                            src={Unmute}
                                            alt="mic"
                                        />
                                        UnMute All
                                    </span>
                            )} */}
						</Button>
					</div>
					<div style={{ color: "#EAEAEA", marginTop:"8px" }}>
						{connectedPeoplesList.map((participant: any) => (
							<div
								className={classes.peopleNameContainer}
								key={participant.sid}
							>
							<div style={{display:"flex",flexDirection:"row", width:"100%"}}>
								<div style={{width:"80%"}}>
									<div className={classes.connectedPeopleName} style={{ padding: "3px 8px", marginBottom:"10px"}}>
										<img
											src={PeopleProfileIcon}
											style={{ marginRight: "5px" }}
											alt="People Profile Icon"
										/>
										<Typography
											sx={{
												wordBreak: "break-word",
												textOverflow: "ellipsis",
												overflow: "hidden",
											}}
											color="white"
										>
											{/* {`${participant.firstName} ${participant.lastName}`} */}
											{/* check when backend response comes */}
											{user?.userProfileId === participant?.userProfileId
												? participant?.firstName + " " + participant?.lastName
												: participant?.userPrivacySettingsDTO?.identityPermission ===
													'Name is not visible. Instead just show nickname'
												? `@${participant?.nickname}`
												: participant?.userPrivacySettingsDTO?.identityPermission ===
													'Name is visible to all'
												? participant?.firstName + " " + participant?.lastName
												: participant?.userPrivacySettingsDTO?.identityPermission ===
													'Name is visible only to my connections. Other see nickname'
												? participant?.isConnected
													? participant?.firstName + " " + participant?.lastName
													: `@${participant?.nickname}`
												: participant?.firstName + " " + participant?.lastName
							      			 }
										</Typography>
									</div>
								</div>
								<AudioButton participant={participant} />
							</div>
							</div>
						))}
					</div>
					<span style={{ color: "#888888" }}>
						Not Joined ({participantNotConnected.length})
					</span>
					<div style={{ color: "#EAEAEA", marginTop:"8px" }} className={classes.peopleNameContainer}>
						{participantNotConnected.map((participant: any) => (
							<div style={{ padding: "3px 8px", marginBottom:"10px"}} 
							key={participant.sid}
							className={classes.notConnectedPeopleName}>
								<img
									src={PeopleProfileIcon}
									style={{ marginRight: "5px" }}
									alt="People Profile Icon"
								/>
								<Typography
									sx={{
										wordBreak: "break-word",
										textOverflow: "ellipsis",
										overflow: "hidden",
									}}
									color="white"
								>
									{/* {`${participant.firstName} ${participant.lastName}`} */}
									{/* check when backend response comes */}
									{user?.userProfileId === participant?.userProfileId
												? participant?.firstName + " " + participant?.lastName
												: participant?.userPrivacySettingsDTO?.identityPermission ===
													'Name is not visible. Instead just show nickname'
												? `@${participant?.nickname}`
												: participant?.userPrivacySettingsDTO?.identityPermission ===
													'Name is visible to all'
												? participant?.firstName + " " + participant?.lastName
												: participant?.userPrivacySettingsDTO?.identityPermission ===
													'Name is visible only to my connections. Other see nickname'
												? participant.isConnected
													? participant?.firstName + " " + participant?.lastName
													: `@${participant?.nickname}`
												: participant?.firstName + " " + participant?.lastName
							      			 }
								</Typography>
							</div>
						))}
					</div>
				</div>
			</aside>
		</>
	);
};
export default memo(PeopleWindow);
