import { createContext, useContext, useEffect, useState } from "react";
import React, { useRef } from "react";
import { format, utcToZonedTime } from "date-fns-tz";

export const UserManageFilterContext = createContext<any>(null);

export const UserManagementFilterProvider = ({ children }: any) => {
	const token = localStorage.getItem("sessionId");
	const [pageNumber, setPageNumber] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [itemPerPage, setItemPerPage] = useState(20);
	const [role, setRole] = useState("");

	const [userStatus1, setUserStatus1] = useState("");
	const [addOns1, setAddOns1] = useState("");
	const [subscriptionStatus1, setSubscriptionStatus1] = useState("");
	const [searchUserName, setSearchUserName] = useState("");
	const inputRef = useRef(null);
	const [orderByField, setOrderByField] = useState("");

	const [userManagementFilter, setUserManagementFilter] = useState<any>({
		isGlobalSearch: true,
		genres: [],
		cities: "",
		firstName: "",
		lastName: "",
		nickName: "",
		instrument: [],
		isOpenForCollaboration: false,
		acceptGigs: false,
	});

	const [userIdByAdmin, setUserIdByAdmin] = useState("");
	const [userProfileIdByAdmin, setUserProfileIdByAdmin] = useState("");
	const [selectedUserIds, setSelectedUserIds] = useState<any>([]);

	const [isDisabledOptions, setIsDisabledOptions] = useState(false);

	interface Data {
		userName: string;
		city: string;
		joinDate: string;
		subscription: string;
		expiryDate: string;
		userStatus: string;
		actions: string;
	}

	const [isFilterActive, setIsFilterActive] = useState(false);

	const [rowData, setRowData] = useState<Data[]>([]);

	const [deviceId, setDeviceId] = useState(null);

	const setChildData = (newData: any) => {
		setDeviceId(newData);
	};

	const resetUserManagementFilter: any = () => {
		setUserManagementFilter({
			isGlobalSearch: true,
			genres: [],
			cities: "",
			firstName: "",
			lastName: "",
			nickName: "",
			instrument: [],
			isOpenForCollaboration: false,
			acceptGigs: false,
		});
	};

	useEffect(() => {
		if (
			userManagementFilter.genres.length > 0 ||
			userManagementFilter.cities.length > 0 ||
			userManagementFilter.instrument.length > 0 ||
			userManagementFilter.firstName.length > 0 ||
			userManagementFilter.lastName.length > 0 ||
			userManagementFilter.nickName.length > 0 ||
			userManagementFilter.isOpenForCollaboration ||
			userManagementFilter.acceptGigs
		) {
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
	}, [userManagementFilter]);

	const formatUserStatus = (userStatus: string) => {
		switch (userStatus) {
			case "INACTIVE":
				return "Inactive";
			case "REACTIVATE":
				return "Reactivated";
			case "TERMINATE":
				return "Terminated";
			case "BAN":
				return "Banned";
			case "SUSPEND":
				return "Suspended";
			default:
				return "Active";
		}
	};

	const getAllUsers1 = (userObj: any) => {
		try {
			const nextPage = pageNumber + 1;

			const requestBody = {
				userId: userObj?.userId ? userObj?.userId : null,
				globalSearch: userManagementFilter.isGlobalSearch,
				firstName: searchUserName,
				lastName: searchUserName,
				nickname: searchUserName,
				location: userManagementFilter.cities,
				genres: userManagementFilter.genres,
				instruments: userManagementFilter.instrument,
				openForCollab: userManagementFilter.isOpenForCollaboration,
				openForGigs: userManagementFilter.acceptGigs,
			};
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user/user-management/filter-search?userAccountStatus=${userStatus1}&subscriptionStatus=${subscriptionStatus1}&addOn=${addOns1}&page=${nextPage}&size=${itemPerPage}&orderBy=${orderByField}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestBody),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {
						const updatedRows = data.map((item: any) => ({
							user: item,
							userId: item.id,
							userProfileId: item.userProfileId,
							nickName: item.nickname,
							userName: `${item.firstName} ${item.lastName}`,
							city: item.city,
							joinDate: (() => {
								const parsedDateUTC = utcToZonedTime(
									new Date(item.createdDate),
									"UTC"
								);
								return format(parsedDateUTC, "yyyy-MM-dd");
							})(),
							// joinDate: item.createdDate.substring(0, 10),
							subscription: (() => {
								const subStr = item.subscriptionInformationDTOs.reduce(
									(accumulator: any, subscription: any) => {
										if (subscription.type === "Software") {
											if (subscription.status === "ACTIVE") {
												accumulator.push("Subscriber");
											} else if (subscription.status === "EXPIRED") {
												accumulator.push("Expired");
											} else if (subscription.status === "STOPPED") {
												accumulator.push("Cancelled");
											}
										}
										return accumulator;
									},
									[]
								);

								if (subStr.length === 0) {
									return "Free";
								} else if (subStr.length === 1) {
									return subStr[0];
								} else {
									return subStr.join(", ");
								}
							})(),
							addOn: (() => {
								const subStr = item.subscriptionInformationDTOs.reduce(
									(accumulator: any, subscription: any) => {
										if (subscription.status === "ACTIVE") {
											if (subscription.type === "Software add-on storage") {
												accumulator.push("Extra Storage");
											} else if (subscription.type === "Software add-on") {
												accumulator.push("Large Ensemble");
											}
										}
										return accumulator;
									},
									[]
								);

								if (subStr.length === 0) {
									return "None";
								} else if (subStr.length === 1) {
									return subStr[0];
								} else {
									return subStr.join(", ");
								}
							})(),

							expiryDate: (() => {
								const subscriberSubscription =
									item.subscriptionInformationDTOs.find(
										(subscription: any) => subscription.name === "Subscriber"
									);
								if (subscriberSubscription) {
									return format(
										new Date(subscriberSubscription.endDate),
										"yyyy-MM-dd"
									);
								} else {
									return "-";
								}
							})(),
							email: item.email,
							userStatus: formatUserStatus(item.userAccountStatus),
							actions: "Edit",
						}));
						setRowData((prevRowData) => [...prevRowData, ...updatedRows]);
						setPageNumber(nextPage);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					// setLoading(false);
				});
		} catch (error) {
			// setLoading(false);
		}
	};

	const handleFilterReset = (userObj: any) => {
		setRowData([]);
		try {
			setHasMore(true);
			setPageNumber(0);
			const firstName = searchUserName || "";
			const lastName = searchUserName || "";
			const nickName = searchUserName || "";

			const requestBody = {
				userId: userObj?.userId ? userObj?.userId : null,
				globalSearch: userManagementFilter.isGlobalSearch,
				firstName: firstName,
				lastName: lastName,
				nickname: nickName,
				location: userManagementFilter.cities,
				genres: userManagementFilter.genres,
				instruments: userManagementFilter.instrument,
				openForCollab: userManagementFilter.isOpenForCollaboration,
				openForGigs: userManagementFilter.acceptGigs,
			};
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user/user-management/filter-search?userAccountStatus=${userStatus1}&subscriptionStatus=${subscriptionStatus1}&addOn=${addOns1}&page=0&size=${itemPerPage}&orderBy=${orderByField}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestBody),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
						setRowData([]);
					} else {
						const updatedRows = data.map((item: any) => ({
							user: item,
							userId: item.id,
							userProfileId: item.userProfileId,
							userName: `${item.firstName} ${item.lastName}`,
							nickName: item.nickname,
							city: item.city,
							joinDate: (() => {
								const parsedDateUTC = utcToZonedTime(
									new Date(item.createdDate),
									"UTC"
								);
								return format(parsedDateUTC, "yyyy-MM-dd");
							})(),
							subscription: (() => {
								const subStr = item.subscriptionInformationDTOs.reduce(
									(accumulator: any, subscription: any) => {
										if (subscription.type === "Software") {
											if (subscription.status === "ACTIVE") {
												accumulator.push("Subscriber");
											} else if (subscription.status === "EXPIRED") {
												accumulator.push("Expired");
											} else if (subscription.status === "STOPPED") {
												accumulator.push("Cancelled");
											}
										}
										return accumulator;
									},
									[]
								);

								if (subStr.length === 0) {
									return "Free";
								} else if (subStr.length === 1) {
									return subStr[0];
								} else {
									return subStr.join(", ");
								}
							})(),
							addOn: (() => {
								const subStr = item.subscriptionInformationDTOs.reduce(
									(accumulator: any, subscription: any) => {
										if (subscription.status === "ACTIVE") {
											if (subscription.type === "Software add-on storage") {
												accumulator.push("Extra Storage");
											} else if (subscription.type === "Software add-on") {
												accumulator.push("Large Ensemble");
											}
										}
										return accumulator;
									},
									[]
								);

								if (subStr.length === 0) {
									return "None";
								} else if (subStr.length === 1) {
									return subStr[0];
								} else {
									return subStr.join(", ");
								}
							})(),

							expiryDate: (() => {
								const subscriberSubscription =
									item.subscriptionInformationDTOs.find(
										(subscription: any) => subscription.name === "Subscriber"
									);
								if (subscriberSubscription) {
									return format(
										new Date(subscriberSubscription.endDate),
										"yyyy-MM-dd"
									);
								} else {
									return "-";
								}
							})(),
							email: item.email,
							userStatus: formatUserStatus(item.userAccountStatus),
							actions: "Edit",
						}));
						setRowData(updatedRows);
					}
				})
				.catch((err) => {
					setHasMore(false);
					setRowData([]);
				})
				.finally(() => {
					// setLoading(false);
				});
		} catch (error) {
			// setLoading(false);
		}
	};

	return (
		<UserManageFilterContext.Provider
			value={{
				userManagementFilter,
				setUserManagementFilter,
				isFilterActive,
				resetUserManagementFilter,
				rowData,
				setRowData,
				getAllUsers1,
				hasMore,
				deviceId,
				setChildData,
				setPageNumber,
				role,
				setRole,
				userIdByAdmin,
				setUserIdByAdmin,
				userProfileIdByAdmin,
				setUserProfileIdByAdmin,

				subscriptionStatus1,
				addOns1,
				userStatus1,
				searchUserName,
				setSearchUserName,
				setAddOns1,
				setUserStatus1,
				setSubscriptionStatus1,
				handleFilterReset,
				inputRef,
				setSelectedUserIds,
				selectedUserIds,
				orderByField,
				setOrderByField,
				setIsDisabledOptions,
				isDisabledOptions,
			}}
		>
			{children}
		</UserManageFilterContext.Provider>
	);
};

// Custom hook to consume the context
export function useUserManageFilter() {
	return useContext(UserManageFilterContext);
}
