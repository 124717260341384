import { Link, useNavigate } from "react-router-dom";
import {
	Button,
	Box,
	Grid,
	Typography,
	Card,
	CardHeader,
} from "@mui/material";
import LeftPanel from "./LeftPanel";
import i18n from "../l10n/strings.json";
import SuccessMark from "../images/tick-circle.svg";

const JoinWaitlistSuccess = () => {

	return (
		<Grid container={true} columns={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
			<Grid item={true} xs={6} md={4}>
				<LeftPanel />
			</Grid>
			<Grid item={true} xs={6} md={4}>
				<Grid container={true} className="column" >
					<Grid
						item={true}
						xs={12}
						className="right-align"
						id="right-pannel-header"
					>
						<Grid
							container={true}
							className="column right-align"
							rowSpacing={{ xs: 1, sm: 3, md: 5, xl: 10, xxl: 20 }}
						>
							<Grid item={true} xs={12} className="right-align">
								<Box display="flex" justifyContent="flex-end" mt={2}>
									<Typography
										mr={2}
										mt={1}
										sx={{
											fontFamily: "Lexend",
											fontWeight: "500",
											fontSize: "1rem",
											lineHeight: "20px",
											color: "#5D5D65",
										}}
									>
										{i18n.signUp.notMemberMsg}
									</Typography>
									<Button
										component={Link}
										to="/signUpEmail"
										sx={{

											fontWeight: "900",
											fontSize: "1rem",
											marginRight: "5vw",
											backgroundColor: "#ffffff",
											borderRadius: "18px",
											color: "#0273E6",
											border: "1px solid #0273E6",
											height: "36px",
										}}
									>
										{i18n.signUp.title}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item={true} xs={12} className="center-align" mt={"1.3rem"}>
						<Card
							component="section"
							sx={{
								position: "relative",
								boxShadow: "none",
								paddingBottom: 0,
								marginTop: "2rem",
							}}
						>
							{/* {!joinSuccesDialog && (
								<Grid
									item={true}
									sx={{
										display: "flex",
										justifyContent: "flex-end",
										maxWidth: "25rem",
										mb: {
											xs: "-2.5rem",
											md: "-2.1rem",
										},
										mr: {
											xs: "4rem",
											md: "2rem",
										},
									}}
								>
									<Typography
										sx={{
											border: "1px solid #3EB92A",
											width: "auto",
											height: "auto",
											padding: "0.2rem 0.5rem 0.25rem 0.5rem",
											borderRadius: "0.5rem",
											gap: "0.6rem",
											bgcolor: "#3EB92A",
											fontSize: "1rem",
											lineHeight: "1.2rem",
											alignItems: "center",
											fontWeight: "700",
											color: "#FFFFFF",
										}}
									>
										BETA
									</Typography>
								</Grid>
							)} */}
							<CardHeader
								title={
									<Typography
										variant="h1"
										sx={{
											color: "customColor.black",
											display: "flex",
											justifyContent: "center",
											fontSize: {
												xs: "3rem",
												md: "4rem",
											},
										}}
									>
										{i18n.signUp.title}
									</Typography>
								}
								titleTypographyProps={{ noWrap: false, variant: "body1" }}
							/>
								<Grid
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										textAlign: "center",
										maxWidth: "27.70rem",
									}}
									mt={15}
									direction={"column"}
								>
									<Grid
										direction={"row"}
										sx={{ display: "flex", justifyContent: "flex-start" }}
									>
										<Grid style={{ marginRight: "10px" }}>
											<img src={SuccessMark} alt="success-mark" />
										</Grid>
										<Typography
											variant="h6"
											sx={{
												fontFamily: "Lexend",
												fontSize: "1.5rem",
												fontWeight: 400,
												lineHeight: "1.8rem",
												letterSpacing: "0em",
												textAlign: "left",
												color: "#000000",
											}}
										>
											{i18n.signUp.invitedata.joinSuccess}
										</Typography>
									</Grid>
									<Typography
										variant="h6"
										sx={{
											fontFamily: "Lexend",
											fontSize: "1.2rem",
											fontWeight: 400,
											lineHeight: "1.4rem",
											letterSpacing: "0em",
											textAlign: "left",
											color: "#000000",
											mt: 2,
											marginLeft: "45px",
										}}
									>
										{i18n.signUp.invitedata.joinSuccessMsg}
									</Typography>
								</Grid>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default JoinWaitlistSuccess;
