import { useState, useEffect } from "react";
import {
	Outlet,
	useParams,
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import {
	Alert,
	Box,
	MenuList,
	MenuItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Divider,
	Grid,
	Button,
} from "@mui/material";
// contexts
import { useUser } from "../context/UserContext";
import Account from "../images/account.svg";
import AddDeviceDialog from "../dialogBox/AddDeviceDialog";
//import Preferences from "../images/pref.svg";
import DeviceSetup from "../images/keyboard.svg";
import ActiveAccount from "../images/active-account.svg";
import ActiveSecurity from "../images/active-security.svg";
//import ActivePreferences from "../images/active-preff.svg";
import ActiveDeviceSetup from "../images/device-setup.svg";
import Next from "../images/next.svg";
import SettingsAlert from "./SettingsAlert";
import RemoveDeviceSetting from "./RemoveDeviceSetting";
import Privacy from "../images/setting-4.svg";
import Card from "../images/card-pos.svg";
import Profile from "../images/profile-remove.svg";
import Notification from "../images/notification-bing1.svg";
import Security from "../images/security.svg";
import GPS from "../images/gps.svg";
import Dollar from "../images/dollar-circle.svg";
import Cards from "../images/cards1.svg";
import ProfileCircle from "../images/profile-circle11.svg";
import EditSocialSetting from "../images/user-octagon edit.svg";
import { useUserManageFilter } from "../components/AdminDashboard/ManageUserDashBoard/UserManagementProvider/useUserManageFilter";
import CompatibilityPopup from "./CompatibilityTestPage";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import Document from "../images/document-forward.svg";
import ArrowLeft from "../images/arrow-left.svg";
import Back from "../images/BackArrow.svg";
import { paddingTop } from "styled-system";
import { useManageFilter } from "../components/AdminDashboard/ManageUserDashBoard/ManagementProvider/useManageFilter";
import ActivityLogs from "./ActivityLogs";

type RouteParams = {
	menuId: any;
};

const AdminSettingsMenu = () => {
	const param = useParams<RouteParams>();
	const user = useUser();
	const navigate = useNavigate();
	// let [searchParams] = useSearchParams();
	// const term = searchParams.get();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const token = localStorage.getItem("sessionId");
	const [showCompatibilityPopup, setShowCompatibilityPopup] = useState(false);
	const { isDisabledOptions } = useManageFilter();

	const [activeMenu, setActiveMenu] = useState(param.menuId ?? "deviceSetup");
	const { adminSetUserId } = useAdminContext();

	const {
		userId,
		userProfileId,
		email,
		user: userDetails,
		userName,
		userStatus,
		userObject
	} = location?.state || {};
   
	const getDevice = () => {
		const authorities = user.authorities.map(
			(authority: { name: string }) => authority.name
		);
		const fetchDeviceOf = adminSetUserId ? adminSetUserId.id : user.id;
		//alert(userId);
		// const fetchApi =
		// 	adminSetUserId && authorities.includes("ROLE_ADMIN")
		// 		? `user-devices/${adminSetUserId.id}`
		// 		: location.state?.userId
		// 			? `user-devices/${location.state?.userId}`
		// 			: "active-user-device";

		const fetchApi =  location.state?.userId
					? `user-devices/${location.state?.userId}`
					: "active-user-device";
					 
		fetch(`${process.env.REACT_APP_BASE_URL}/api/${fetchApi}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {				 
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {				 
				if (data !== null) {					
					navigate("/home/admin/settings/deviceSetup/admindevice", {
						state: { userId, userProfileId, email, type: "edit", userDetails, userName, userStatus,userObject },
					});
				}
				else {
					navigate(`/home/admin/settings/deviceSetup/addadmindevice`, {
						state: { userId, userProfileId, email, type: "edit", userDetails, userName, userStatus,userObject },
					});
				}
			})
			.catch((err) => {
				navigate(`/home/admin/settings/deviceSetup/addadmindevice`, {
					state: { userId, userProfileId, email, type: "edit", userDetails, userName, userStatus,userObject },
				});
			});
	};

	const handleCompatibilityTest = () => {
		//setShowCompatibilityPopup(true);
		const url = `${process.env.REACT_APP_BASE_URL_FRONTEND}`;
		const baseUrl = new URL(url).origin;
		window.open(baseUrl + "/speedtest", "_blank");
	};

	const handleSuccess = () => {
		if (location?.state?.userId) {
			navigate("/home/admin/settings/deviceSetup/admindevice", {
				state: { userId, userProfileId, email, type: "edit", userDetails, userName, userStatus,userObject },
			});
		} else {
			navigate(`/home/settings/${param.menuId}/device`);
		}
	};
	const handleDialogClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (param.menuId === "deviceSetup") {
			getDevice();
		}
		setActiveMenu(param.menuId);
	}, [param.menuId]);

	const changeMenu = (value: string) => {
		setActiveMenu(value);
		if (value === "deviceSetup") {
			getDevice();
		} else {			 
			navigate(`/home/admin/settings/${value}/${value}`, {
				state: { userId, userProfileId, email, type: "edit", userDetails, userName, userStatus,userObject  },
			});
		}
	};

	const isEnable = () => {		 
		if (user?.authorities[0].name === "ROLE_ADMIN"  || user?.authorities[0].name == 'ROLE_SUPER_ADMIN'  ) {
		  return true;
		} else {
		  return false;
		}
	  };
	const backToDashboard = () => {
		 
		navigate(`/users-management/`);
		//navigate('/admin-dashboard');
	};
	return (
		<>
			<Grid className="mainPanel" spacing={{ xs: 0 }}>
				<Grid
					container={true}
					className="column mainPanel"
					sx={{
						width: "80%",
					}}
				>
					<Grid item={true} sx={{ paddingTop: "10px" }}>
						<Typography
							sx={{
								fontStyle: "normal",
								fontWeight: "500",
								fontSize: "18px",								 
								color: "#000000",
								cursor: "pointer",
							}}
						>
							<img src={Back} alt="back" onClick={() => backToDashboard()} />{"   Back to Dashboard :  "}
							{userName}
						</Typography>
					</Grid>
					<Grid
						item={true}
						className="center-align"
						style={{
							padding: "10px",
						}}
					>
						<SettingsAlert />
					</Grid>
					<Grid item={true}>
						<Grid
							container={true}
							columns={{ xs: 12 }}
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								mt: 2,
							}}
							className="mainPanel"
						>

							<Grid item={true} xs={6.5} sx={{ my: 1 }}>

								<Typography
									sx={{
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "1.5rem",
										lineHeight: "35px",
										color: "#000000",
									}}
								>
									User Profile
								</Typography>
							</Grid>

							{(location.pathname === '/home/admin/settings/deviceSetup/admindevice' || location.pathname === "/home/admin/settings/device/admindevice") && (
								<>

									<Grid item={true} xs={5.5} sx={{display:"flex", justifyContent: "flex-end" }}>
										<Button
											//className="cancel-button button-field-custom-font-size"
											size="large"
											variant="outlined"
											disabled={!isEnable()}

											sx={{
												fontSize: '0.9rem', justifySelf: "right",
												lineHeight: '20px',
												border: '2px solid #0273E6',
												color: '#0273E6',												 
												transition: 'border 0.1s',

												'&:hover': {
													border: '2px solid #0273E6',
													backgroundColor: '#E9F1FF'
												},
											}}
										>
											Invoices
										</Button>
										<Button
											//className="cancel-button button-field-custom-font-size"
											size="large"
											variant="outlined"
											onClick={handleCompatibilityTest}
											disabled={!isEnable()}
											sx={{
												fontSize: '0.9rem', justifySelf: "right",
												lineHeight: '20px',
												border: '2px solid #0273E6',
												color: '#0273E6',
												ml: "15px",
												transition: 'border 0.1s',

												"&:hover": {
													border: "2px solid #0273E6",
													backgroundColor: "#E9F1FF",
												},
											}}
										>
											Compatibility Test
										</Button>

										<Button
											className="submit-button"
											size="large"
											sx={{
												minWidth: "200px",
												ml: "15px"
											}}
											disabled={!isEnable()}
											onClick={() => setOpen(true)}
											variant="contained"

										>
											<Typography>Add new device</Typography>
										</Button>
									</Grid>
								</>
							)}

							
						</Grid>
					</Grid>
					<Grid item={true} className="row mainPanel">
						<Grid
							container={true}
							columns={{ xs: 12 }}
							className=" mainPanel"
							sx={{ display: "flex", marginTop: "10px" }}
						>
							<Grid item={true} xs={3} className="leftPanelWidth">
								<Box>
									<MenuList
										sx={{
											background: "#FFFFFF",
											boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											borderRadius: "12px",
										}}
									>
										<MenuItem
											onClick={() => changeMenu("account")}
											sx={{
												padding: "10px 0 10px 14px",
												whiteSpace: "normal",
												overflowX: "auto",
												backgroundColor: `${activeMenu === "account" && "#EAEAEA"
													}`,
											}}
										>
											<ListItemIcon>
												{activeMenu === "account" ? (
													<img src={ProfileCircle} alt="Account&Login" />
												) : (
													<img src={ProfileCircle} alt="Account&Login" />
												)}
											</ListItemIcon>
											<ListItemText>
												<Typography sx={{ fontSize: "18px" }}>User</Typography>
											</ListItemText>
											<ListItemIcon>
												{activeMenu === "account" && (
													<img src={Next} alt="active" />
												)}
											</ListItemIcon>
										</MenuItem>
										<Divider variant="fullWidth" component="li" />
										{ }
										<>
											<MenuItem
												onClick={() => changeMenu("deviceSetup")}
												sx={{
													padding: "10px 0 10px 14px",
													whiteSpace: "normal",
													overflowX: "auto",
													backgroundColor: `${activeMenu === "deviceSetup" && "#EAEAEA"
														}`,
												}}
											// disabled={isDisabledOptions}
											>
												<ListItemIcon>
													{activeMenu === "deviceSetup" ? (
														<img src={ActiveDeviceSetup} alt="Device Setup" />
													) : (
														<img src={DeviceSetup} alt="Device Setup" />
													)}
												</ListItemIcon>
												<ListItemText>
													<Typography sx={{ fontSize: "18px" }}>
														Device
													</Typography>
												</ListItemText>
												<ListItemIcon>
													{activeMenu === "deviceSetup" && (
														<img src={Next} alt="active" />
													)}
												</ListItemIcon>
											</MenuItem>

											{/* <Divider variant="fullWidth" component="li" />
											<MenuItem
												onClick={() => changeMenu("subscription")}
												sx={{
													padding: "10px 0 10px 14px",
													whiteSpace: "normal",
													overflowX: "auto",
													backgroundColor: `${activeMenu === "subscription" && "#EAEAEA"
														}`,
												}}
											>
												<ListItemIcon>
													{activeMenu === "subscription" ? (
														<img src={Dollar} alt="Subscription" />
													) : (
														<img src={Dollar} alt="Subscription" />
													)}
												</ListItemIcon>
												<ListItemText>
													<Typography sx={{ fontSize: "18px" }}>
														Subscription
													</Typography>
												</ListItemText>
												<ListItemIcon>
													{activeMenu === "subscription" && (
														<img src={Next} alt="active" />
													)}
												</ListItemIcon>
											</MenuItem> */}
										</>
										{/* )} */}
									</MenuList>
								</Box>

								{
									<>
										<Grid item={true} xs={9} sx={{ my: 1 }}>
											<Typography
												sx={{
													fontStyle: "normal",
													fontWeight: "500",
													fontSize: "1.5rem",
													lineHeight: "49px",
													color: "#000000",
												}}
											>
												Social
											</Typography>
										</Grid>
										<Box>
											<MenuList
												sx={{
													background: "#FFFFFF",
													boxShadow:
														"0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
													borderRadius: "12px",
													mt: 1,
												}}
											>
												<MenuItem
													onClick={() => changeMenu("privacy")}
													sx={{
														padding: "10px 0 10px 14px",
														whiteSpace: "normal",
														overflowX: "auto",
														backgroundColor: `${activeMenu === "privacy" && "#EAEAEA"
															}`,
													}}
												>
													<ListItemIcon>
														{activeMenu === "privacy" ? (
															<img src={Privacy} alt="Privacy" />
														) : (
															<img src={Privacy} alt="Privacy" />
														)}
													</ListItemIcon>
													<ListItemText>
														<Typography sx={{ fontSize: "18px" }}>
															Privacy
														</Typography>
													</ListItemText>
													<ListItemIcon>
														{activeMenu === "privacy" && (
															<img src={Next} alt="active" />
														)}
													</ListItemIcon>
												</MenuItem>
											

													<Divider variant="fullWidth" component="li" />
													<MenuItem
														onClick={() => changeMenu("security")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "security" && "#EAEAEA"
																}`,
														}}

													>
														<ListItemIcon>
															{activeMenu === "security" ? (
																<img src={Security} alt="Security" />
															) : (
																<img src={Security} alt="Security" />
															)}
														</ListItemIcon>
														<ListItemText ><Typography sx={{ fontSize: '18px', }}>Security</Typography></ListItemText>
														<ListItemIcon>
															{activeMenu === "security" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
													<Divider variant="fullWidth" component="li" />
													<MenuItem
														onClick={() => changeMenu("notificationSettings")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "adminnotificationSettings" && "#EAEAEA"
																}`,
														}}

													>
														<ListItemIcon>
															{activeMenu === "adminnotificationSettings" ? (
																<img src={Notification} alt="Notifications" />
															) : (
																<img src={Notification} alt="Notifications" />
															)}
														</ListItemIcon>
														<ListItemText ><Typography sx={{ fontSize: '18px', }}>Notifications</Typography></ListItemText>
														<ListItemIcon>
															{activeMenu === "adminnotificationSettings" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
											
											</MenuList>
										</Box>

										<Grid item={true} xs={9} sx={{ my: 1 }}>
											<Typography
												sx={{
													fontStyle: "normal",
													fontWeight: "500",
													fontSize: "1.5rem",
													lineHeight: "49px",
													color: "#000000",
												}}
											>
												Account Actions
											</Typography>
										</Grid>
										<Box>
											<MenuList
												sx={{
													background: "#FFFFFF",
													boxShadow:
														"0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
													borderRadius: "12px",
													mt: 1,
												}}
											>
												<MenuItem
													onClick={() => changeMenu("accountactions")}
													sx={{
														padding: "10px 0 10px 14px",
														whiteSpace: "normal",
														overflowX: "auto",
														backgroundColor: `${activeMenu === "accountactions" && "#EAEAEA"
															}`,
													}}
												>
													<ListItemIcon>
														{activeMenu === "accountactions" ? (
															<img src={GPS} alt="Account Actions" />
														) : (
															<img src={GPS} alt="Account Actions" />
														)}
													</ListItemIcon>
													<ListItemText>
														<Typography sx={{ fontSize: "18px" }}>
															Account Actions
														</Typography>
													</ListItemText>
													<ListItemIcon>
														{activeMenu === "accountactions" && (
															<img src={Next} alt="active" />
														)}
													</ListItemIcon>
												</MenuItem>
												<Divider variant="fullWidth" component="li" />
												<MenuItem
													onClick={() => changeMenu("dataexport")}
													sx={{
														padding: "10px 0 10px 14px",
														whiteSpace: "normal",
														overflowX: "auto",
														backgroundColor: `${activeMenu === "dataexport" && "#EAEAEA"
															}`,
													}}
												>
													<ListItemIcon>
														<img src={Document} alt="Data Export" />
													</ListItemIcon>
													<ListItemText>
														<Typography sx={{ fontSize: "18px" }}>
															Data Export
														</Typography>
													</ListItemText>
													<ListItemIcon>
														{activeMenu === "dataexport" && (
															<img src={Next} alt="active" />
														)}
													</ListItemIcon>
												</MenuItem>
												{/* <Divider variant="fullWidth" component="li" /> */}
												<MenuItem
													onClick={() => changeMenu("adminpermission")}
													sx={{
														padding: "10px 0 10px 14px",
														whiteSpace: "normal",
														overflowX: "auto",
														backgroundColor: `${activeMenu === "adminpermission" && "#EAEAEA"
															}`,
													}}
												>
													{/* <ListItemIcon>
														{activeMenu === "adminpermission" ? (
															<img src={Dollar} alt="Admin Permission" />
														) : (
															<img src={Dollar} alt="Admin Permission" />
														)}
													</ListItemIcon>
													<ListItemText>
														<Typography sx={{ fontSize: "18px" }}>
															Admin Permission
														</Typography>
													</ListItemText> */}
													<ListItemIcon>
														{activeMenu === "adminpermission" && (
															<img src={Next} alt="active" />
														)}
													</ListItemIcon>
												</MenuItem>
											</MenuList>
										</Box>
									</>
								}
							</Grid>
							<Grid
								item
								xs={9}
								className={`${activeMenu === "socialProfileSetting" || activeMenu === "deviceSetup"
										? "centerPanelSocial"
										: "centerPanelwoRight"
									}`}
							>
								{(location.pathname ===
								"/home/admin/settings/dataexport/dataexport" ||
								location.pathname ===
									"/home/admin/settings/dataexport/dataexport") && (
								<ActivityLogs/>
							)}
								<Outlet />
							</Grid>
							{/* {user.authorities[0].name === 'ROLE_USER' &&
								<Grid
									item
									className={`hideInTabPro rightPanelWidth`}
									hidden={activeMenu === "socialProfileSetting"}
								>
									<MyActivitiesSidePanel />
								</Grid>
							} */}
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{showCompatibilityPopup && (
				<CompatibilityPopup
					open={showCompatibilityPopup}
					onClose={() => setShowCompatibilityPopup(false)}
				/>
			)}
			<AddDeviceDialog
				open={open}
				onSuccess={handleSuccess}
				onClose={handleDialogClose}
			/>
		</>
	);
};

export default AdminSettingsMenu;
