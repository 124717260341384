import { Card, CardContent, IconButton } from "@mui/material"
import CancelIcon from '../../images/Group 4282.svg';
import Edit from '../../images/Posts/edit.svg';
import Delete from '../../images/Posts/delete.svg';
import ReplaceIcon from '../../images/Posts/Replace.svg'

const PictureView = ({ image, handleDeletePicture, handleOpenEditor }: any) => {

    return (
        <Card sx={{ position: 'relative', flexGrow: 1, }}>
            <IconButton id="photo-Cancel"
                aria-label="Edit" size='small' sx={{
                    // marginLeft: 'auto', marginTop: '-66vh',
                    position: 'absolute',
                    top: '5px',
                    right: '49px',
                    cursor: 'pointer',
                    zIndex: 2,
                }}
                onClick={() => handleDeletePicture()}
            >
                <img src={Delete} alt="Edit" style={{ height: '24px', width: '24px' }} />
            </IconButton>
            <IconButton id="photo-Cancel"
                aria-label="Edit" size='small' sx={{
                    // marginLeft: 'auto', marginTop: '-65vh',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    zIndex: 2,
                }}
            >
                <label>
                    <img src={ReplaceIcon} alt='Picture' style={{ height: '24px', width: '24px', cursor: "pointer" }} />
                    <input
                        id="photo-upload"
                        type="file"
                        accept =".jpg, .jpeg, .png, .gif"
                        style={{ display: "none" }}
                        onChange={(e) => handleOpenEditor(e)}
                    />
                </label>
            </IconButton>
            <img
                src={image}
                id="upload-button"
                alt="Upload"
                style={{
                    objectFit: "fill", width: '100%', borderRadius: '12px'
                }}
            />
        </Card>
    )
}

export default PictureView