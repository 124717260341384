import { useEffect, useState } from "react";
import { Box, CardContent, Grid, Typography } from "@mui/material";

const AdminDataExport = (props: any) => {
	const [loading, setLoading] = useState(true);
	const responseData: any[] = props.response;

	useEffect(() => {
		setLoading(true);
	}, []);

	return (
		<>
			{/* <Grid container>
				
				<Grid item={true} xs={12} className="centerPanelWidth">		
					<Grid>{responseData}</Grid>
				</Grid>
			</Grid> */}

			<Box marginLeft="60px">
				
				<Box>{responseData}</Box>
			</Box>
		</>
	);
};

export default AdminDataExport;
