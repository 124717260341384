import { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	IconButton,
	Box,
	Typography,
	Grid,
} from "@mui/material";
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";

const SessionHoldDialog = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();

	const handleClose = () => {
		props.back();
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			open={props.isSessionHold}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={handleClose}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%" }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.dialog.sessionHoldTitle}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: "5px 20px",
				}}
			>
				<Grid container columnSpacing={2}>
					<Grid item={true} xs={12}>
						<FormHelperText
							sx={{
								fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.dialog.sessionHoldDesc}
						</FormHelperText>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					columnSpacing={1}
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<Grid item={true}>
						{/* <Button
							sx={{ color: "#1D2D53", width: "120px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleClose}
						>
							{i18n.dialog.cancel}
						</Button> */}
					</Grid>

					<Grid item={true}></Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};
export default SessionHoldDialog;
