import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

interface StatCardProps {
	title: string;
	value: number;
	icon: string;
	isDuration: false | null;
}

const StatCard: React.FC<StatCardProps> = ({
	title,
	value,
	icon,
	isDuration,
}) => {
	const displayValue = value >= 1000 ? `${(value / 1000).toFixed(value % 1000 === 0 ? 0 : 1)}k` : value.toString();
	return (
		<Card
			style={{
				width: "18rem",
				height: "178px",
				borderRadius: "12px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
			}}
			className="statCard"
		>
			<CardContent sx={{marginTop: "-10px"}}>
				<Typography
					variant="h6"
					gutterBottom
					style={{
						fontSize: "1.5rem",
						fontWeight: "500",
						lineHeight: "24px",
						letterSpacing: "0em",
						textAlign: "center",
						
					}}
				>
					{title}
				</Typography>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<img src={icon} alt="Icon" style={{ marginRight: "8px" }} />
					<Typography
						variant="h4"
						component="div"
						className="dispVal"
					>
						{displayValue}
					</Typography>
				</div>
			</CardContent>
		</Card>
	);
};

export default StatCard;
