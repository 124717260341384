import { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
	Link as MuiLink,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useUser } from "../context/UserContext";
import { useAlertStatus } from "../context/AlertStatusContext";
import VerifyAccount from "../dialogBox/VerifyAccount";
import i18n from "../l10n/strings.json";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import { useLocation } from "react-router-dom";

const AccountListItemText = styled(ListItemText)({
	"& .MuiListItemText-primary": {
		fontStyle: "normal",
		fontSize: "1rem",
		fontWeight: 400,
		color: "#7A7985",
	},
	"& .MuiListItemText-secondary": {
		fontStyle: "normal",
		fontSize: "1rem",
		fontWeight: 900,
		color: "#000000",
	},
});

function SecuritySettingsPage() {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const { updateAlertStatus } = useAlertStatus();
	const [verification, setVerfication] = useState(false);
	const [open, setOpen] = useState(false);
	const [userData, setUserData] = useState<any>({});
	const termURL = "https://lutefish.com/Terms.html";
	const policyURL = "https://lutefish.com/Privacy.html";
	const { adminSetUserId } = useAdminContext();
	const [loggedinUser, setLoggedinUser] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();
	const { state } = location;
	const {
		userId,
		userProfileId,
		email,
		user: userDetails,
		userName,
	} = state || {};

	useEffect(() => {
		console.log("Received state values:", {
			email,
			userId,
			userProfileId,
			userName,
		});
	}, []);

	useEffect(() => {
		const fetchUserInfo = () => {
			if (adminSetUserId) {
				fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/id`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						id: adminSetUserId?.id,
					}),
				})
					.then((response) => {
						if (response.status > 400) {
							throw new Error();
						} else {
							return response.json();
						}
					})
					.then((result) => {
						if (result !== null) {
							setVerfication(result.multifactorAuthEnabled);
						}
					});
			}
		};

		fetchUserInfo();
	}, [adminSetUserId, token]);

	useEffect(() => {
		fetchUserInfo();
		setIsLoading(true);
	}, []);

	const fetchUserInfo = () => {
		let searchId;
		const userRole = localStorage.getItem("user");
		let loggedInRole;

		if (userRole) {
			const userRoleObj = JSON.parse(userRole);
			loggedInRole = userRoleObj.authorities[0].name;
			setLoggedinUser(loggedInRole);
		}
		searchId = location.state?.userId ? location.state?.userId : user.id;

		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/id`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id: searchId,
			}),
		})
			.then((response) => {
				if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				setIsLoading(false);
				if (result !== null) {
					setUserData(result);
				}
			});
	};

	if (isLoading) {
		return <div />;
	}

	return (
		<>
			<Grid container>
				<Grid item={true} xs={9} className="centerPanelWidth">
					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;",
						}}
					>
						<Grid container={true} xs={12}>
							{verification ? (
								<Grid item={true} xs={12}>
									<CardContent sx={{ padding: "10px 20px 0px 20px" }}>
										<Grid container={true} xs={12}>
											<Grid item={true} xs={12}>
												<Typography
													className="card-title-custom-size"
													sx={{
														fontFamily: "Lexend, sans-serif",
														fontWeight: 500,
														fontSize: "24px",
														lineHeight: "30px",
														display: "flex",
														alignItems: "center",
														marginBottom: "10px",
													}}
													color="#000000"
												>
													2-Step Verification
												</Typography>
											</Grid>
											<Grid item={true} xs={12}>
												<Grid
													container={true}
													xs={12}
													sx={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}
												>
													<Grid item={true} xs={8}>
														<Typography
															className="security-content-custom-size"
															sx={{
																fontFamily: "Lexend, sans-serif",
																fontWeight: 500,
																fontSize: "18px",
																lineHeight: "22.5px",
																color: "#707081",
																display: "flex",
																alignItems: "center",
															}}
														>
															2-Step Verification is
															<span
																style={{
																	fontFamily: "Lexend, sans-serif",
																	fontWeight: 700,
																	fontSize: "18px",
																	lineHeight: "22.5px",
																	color: "#259A12",
																	marginLeft: "2%",
																}}
															>
																{verification ? "ON" : "OFF"}
															</span>
														</Typography>
													</Grid>
													<Grid item={true} xs={4}>
														<CardActions
															sx={{
																display: "flex",
																flexDirection: "row",
																justifyContent: "flex-end",
																marginBottom: "20px",
															}}
														>
															<Button
																className="submit-button button-field-custom-font-size"
																size="large"
																variant="contained"
																onClick={() => setOpen(true)}
																sx={{
																	backgroundColor: "#999999",
																	color: "#ffffff",
																}}
																disabled={true}
															>
																{verification ? "Turn OFF" : "Turn ON"}
															</Button>
														</CardActions>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid>
											<List>
												<Divider variant="fullWidth" component="li" />
												<Grid container={true} xs={12}>
													<Grid item={true} xs={12}>
														<Typography
															className="card-title-custom-size"
															sx={{
																fontFamily: "Lexend",
																fontWeight: 500,
																fontSize: "24px",
																lineHeight: "30px",
																display: "flex",
																alignItems: "center",
																marginTop: "20px",
																marginBottom: "20px",
															}}
															color="#000000"
														>
															Security verification method
														</Typography>
													</Grid>
													<Grid item={true} xs={12}>
														<Typography
															className="security-content-custom-size"
															sx={{
																fontWeight: "500",
																color: "#707081",
																Font: "Lexend",
																Weight: 400,
																size: "16px",
																lineHeight: "20px",
																height: "40px", // Added height
																width: "507px", // Added width
																marginBottom: "20px",
															}}
														>
															We will send you 6 digit verification code to your
															registered email below
														</Typography>
													</Grid>
													<Grid item={true} xs={12}>
														<ListItem sx={{ padding: "10px 0" }}>
															<AccountListItemText
																primary="Email"
																primaryTypographyProps={{
																	sx: {
																		color: "#7A7985",
																	},
																}}
																secondary={userData?.user?.email}
															/>
														</ListItem>
													</Grid>
												</Grid>
											</List>
										</Grid>
									</CardContent>
								</Grid>
							) : (
								<Grid item={true} xs={12}>
									<CardContent sx={{ padding: "10px 20px 0px 20px" }}>
										<Grid container={true} xs={12}>
											<Grid item={true} xs={12}>
												<Typography
													className="card-title-custom-size"
													sx={{
														fontFamily: "Lexend, sans-serif",
														fontWeight: 500,
														fontSize: "24px",
														lineHeight: "30px",
														display: "flex",
														alignItems: "center",
														marginBottom: "10px",
													}}
													color="#000000"
												>
													2-Step Verification
												</Typography>
											</Grid>
											<Grid item={true} xs={12}>
												<Grid
													container={true}
													xs={12}
													sx={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}
												>
													<Grid item={true} xs={8}>
														<Typography
															className="security-content-custom-size"
															sx={{
																fontFamily: "Lexend, sans-serif",
																fontWeight: 500,
																fontSize: "18px",
																lineHeight: "22.5px",
																color: "#707081",
																display: "flex",
																alignItems: "center",
															}}
														>
															2-Step Verification is
															<span
																style={{
																	fontFamily: "Lexend, sans-serif",
																	fontWeight: 700,
																	fontSize: "18px",
																	lineHeight: "22.5px",
																	color: "#259A12",
																	marginLeft: "2%",
																}}
															>
																{verification ? "ON" : "OFF"}
															</span>
														</Typography>
													</Grid>
													<Grid item={true} xs={4}>
														<CardActions
															sx={{
																display: "flex",
																flexDirection: "row",
																justifyContent: "flex-end",
															}}
														>
															<Button
																className="submit-button button-field-custom-font-size"
																size="large"
																variant="contained"
																onClick={() => setOpen(true)}
																sx={{
																	backgroundColor: "#999999",
																	color: "#ffffff",
																}}
																disabled={true}
															>
																{verification ? "Turn OFF" : "Turn ON"}
															</Button>
														</CardActions>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid>
											<List>
												<Divider variant="fullWidth" component="li" />
												<Grid container={true} xs={12}>
													<Grid item={true} xs={12}>
														<Typography
															className="card-title-custom-size"
															sx={{
																fontFamily: "Lexend, sans-serif",
																fontWeight: 500,
																fontSize: "24px",
																lineHeight: "30px",
																display: "flex",
																alignItems: "center",
																margin: "10px 0",
															}}
															color="#000000"
														>
															Security verification method
														</Typography>
													</Grid>
													<Grid item={true} xs={12}>
														<Typography
															className="security-content-custom-size"
															sx={{
																fontWeight: "500",
																color: "#707081",
																Font: "Lexend",
																Weight: 400,
																size: "16px",
																lineHeight: "15px",
															}}
														>
															We will send you 6 digit verification code to your
															registered email below
														</Typography>
													</Grid>
													<Grid item={true} xs={12}>
														<ListItem sx={{ padding: "10px 0" }}>
															<AccountListItemText
																primary="Email"
																primaryTypographyProps={{
																	sx: {
																		color: "#7A7985",
																		fontSize: "16px",
																		lineHeight: "20px",
																	},
																}}
																secondary={userData?.user?.email}
															/>
														</ListItem>
													</Grid>
												</Grid>
											</List>
										</Grid>
									</CardContent>
								</Grid>
							)}
						</Grid>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}

export default SecuritySettingsPage;
