
import { useState, ChangeEvent } from "react";
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput, Box, Typography, Grid
} from "@mui/material";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CheckIcon from "../images/check-fill.svg";
import CloseIcon from "../images/close-line.svg";
import Close from "../images/close.svg";
import VisibleOff from "../images/eye-slash.svg";
import VisibleOn from "../images/eye.svg";

const UPPERCASEREGEX = /.*[A-Z].*/;
const NUMBEREREGEX = /.*[0-9].*/;
const SPECIALCHAREREGEX = /.*[!@#$%^&*].*/;

const ChangePassword = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();

	const [password, setPassword] = useState("");
	const [newpassword, setNewPassword] = useState("");
	const [repassword, setRepassword] = useState("");
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRenewPassword, setShowRenewPassword] = useState(false);
	const [iserror, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [showPassRequirement, setShowPassRequirements] = useState(false);
	const [focuscurrentPassword, setFocusCurrPassword] = useState(false);
	const [focusNewPassword, setFocusNewPassword] = useState(false);
	const [focusRenewPassword, setFocusRenewPassword] = useState(false);

	const isEnabled =
		newpassword.length >= 8 &&
		newpassword.length <= 16 &&
		NUMBEREREGEX.test(newpassword) &&
		UPPERCASEREGEX.test(newpassword) &&
		SPECIALCHAREREGEX.test(newpassword) &&
		repassword.length >= 8 &&
		repassword.length <= 16 &&
		newpassword === repassword;

	const updatePassword = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-password`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					// id: user.id,
					id: props.userIdByAdmin ? props.userIdByAdmin : user.id,
					password: password,
					newPassword: newpassword,
				}),
			}
		)
			.then((response) => {
				if (response.status >= 400) {
					setError(true);
					setMessage(i18n.settings.accountSetup.currentPasswordErrorMsg);
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				setError(false);
				if (result.status === "BAD_REQUEST") {
					setError(true);
					setMessage(i18n.settings.accountSetup.passwordErrorMsg);
				}
				if (result.status === "OK") {
					props.handleClose(true, "passwordSuccess");
				}
			})
			.catch((error) => {
				setError(true);
			});
	};

	const handleClose = () => {
		props.handleClose()
	};

	const handleSetCurrentPassword = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFocusCurrPassword(true);
		setPassword(event.target.value);
	};
	const handleSetNewPassword = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFocusNewPassword(true);
		setNewPassword(event.target.value);
	};
	const handleSetRenewPassword = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFocusRenewPassword(true);
		setRepassword(event.target.value);
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px"
			}}
			fullWidth
			maxWidth="sm"
			open={props.changePasswordOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={handleClose}
						onMouseDown={(
							event: React.MouseEvent<HTMLButtonElement>
						) => event.preventDefault()}
						edge="end"
						sx={{ marginLeft: "90%" }}>
						<img
							src={Close}
							alt="close"
						/>
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.settings.accountSetup.changePasswordDialog}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					marginLeft: "48px",
					marginRight: "48px",
					padding: "20px 0",
				}}
			>
				<div className="password-content">
					<FormControl>
						<FormHelperText
							sx={{
								// fontSize: "1rem",
								color: "#7A7985",
								marginLeft: 0,
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.dialog.currentPassword}
						</FormHelperText>
						<OutlinedInput
							type={showOldPassword ? "text" : "password"}
							sx={{ background: "#F8F8F8", width: "240px", marginBottom: "20px", marginRight: "24px" }}
							onChange={handleSetCurrentPassword}
							onCopy={(e) => (e.preventDefault())}
							onPaste={(e) => (e.preventDefault())}
							onCut={(e) => (e.preventDefault())}
							error={iserror || (focuscurrentPassword && password.length < 8)}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowOldPassword((show) => !show)}
										onMouseDown={(
											event: React.MouseEvent<HTMLButtonElement>
										) => event.preventDefault()}
										edge="end"
									>
										{showOldPassword ? (
											<img src={VisibleOn} alt="eye" />
										) : (
											<img src={VisibleOff} alt="Eye Slash" />
										)}
									</IconButton>
								</InputAdornment>
							}
						/>
						<span style={{ color: "#FD4B24" }}>{message}</span>
						<FormHelperText
							sx={{
								// fontSize: "1rem",
								color: "#7A7985",
								marginLeft: 0,
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.dialog.newPassword}
						</FormHelperText>
						<OutlinedInput
							type={showNewPassword ? "text" : "password"}
							sx={{
								background: "#F8F8F8",
								width: "240px",
								marginBottom: "10px",
								marginRight: "24px"
							}}
							onChange={handleSetNewPassword}
							onFocus={() => {
								setShowPassRequirements(true);
							}}
							onBlur={() => setShowPassRequirements(false)}
							onCopy={(e) => (e.preventDefault())}
							onPaste={(e) => (e.preventDefault())}
							onCut={(e) => (e.preventDefault())}
							error={iserror || (focusNewPassword && (newpassword.length < 8 || newpassword.length > 16 || !UPPERCASEREGEX.test(newpassword) ||
								!NUMBEREREGEX.test(newpassword) || !SPECIALCHAREREGEX.test(newpassword)))}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowNewPassword((show) => !show)}
										onMouseDown={(
											event: React.MouseEvent<HTMLButtonElement>
										) => event.preventDefault()}
										edge="end"
									>
										{showNewPassword ? (
											<img src={VisibleOn} alt="eye" />
										) : (
											<img src={VisibleOff} alt="Eye Slash" />
										)}
									</IconButton>
								</InputAdornment>
							}
						/>
						{focusNewPassword && (newpassword.length < 8 || newpassword.length > 16) && (
							<FormHelperText sx={{
								width: "240px",
								color: "#FD4B24",
								textAlign: "right",
								marginLeft: "0px",
								marginBottom: "10px",
								marginRight: "24px",
								overflowWrap: "break-word"
							}}>
								{
									newpassword.length < 8 || newpassword.length > 16 ? "Enter 8-16 character" :
										""
								}
							</FormHelperText>
						)}
						<FormHelperText
							sx={{
								// fontSize: "1rem",
								color: "#7A7985",
								marginLeft: 0,
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.dialog.retypeNewPassword}
						</FormHelperText>
						<OutlinedInput
							type={showRenewPassword ? "text" : "password"}
							sx={{
								background: "#F8F8F8",
								width: "240px",
								marginBottom: "10px",
								marginRight: "24px"
							}}
							onChange={handleSetRenewPassword}
							onCopy={(e) => (e.preventDefault())}
							onPaste={(e) => (e.preventDefault())}
							onCut={(e) => (e.preventDefault())}
							error={iserror || (focusRenewPassword && repassword.length < 8)}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowRenewPassword((show) => !show)}
										onMouseDown={(
											event: React.MouseEvent<HTMLButtonElement>
										) => event.preventDefault()}
										edge="end"
									>
										{showRenewPassword ? (
											<img src={VisibleOn} alt="eye" />
										) : (
											<img src={VisibleOff} alt="Eye Slash" />
										)}
									</IconButton>
								</InputAdornment>
							}
						/>
						{
							focusRenewPassword && (
								<FormHelperText sx={{
									color: "#FD4B24",
									textAlign: "right",
									marginRight: "24px"
								}}>
									{
										!repassword ? "Re-enter password"
											: newpassword !== repassword ? " Password does not match"
												: ""
									}
								</FormHelperText>
							)
						}
					</FormControl>
					<div style={{ margin: "40px 0" }}><a href="/#/forgotPassword" style={{
						
						fontStyle: "normal",
						fontSize: "1rem",
						color: "#0A6BFE",
					}}>{i18n.dialog.forgotPassword}</a>
						{showPassRequirement &&
							(<div className="change-password-guideline">
								{i18n.dialog.passwordRequirments}
								<div className="password-validate" style={{ color: "#5C5C5C" }}>
									{newpassword.length >= 8 ? (
										<img src={CheckIcon} alt="Check" />
									) : (
										<img src={CloseIcon} alt="Close" />
									)}
									{i18n.dialog.eightCharacter}
								</div>
								<div className="password-validate" style={{ color: "#5C5C5C" }}>
									{UPPERCASEREGEX.test(newpassword) ? (
										<img src={CheckIcon} alt="Check" />
									) : (
										<img src={CloseIcon} alt="Close" />
									)}
									{i18n.dialog.capital}

								</div>
								<div className="password-validate" style={{ color: "#5C5C5C" }}>
									{NUMBEREREGEX.test(newpassword) ? (
										<img src={CheckIcon} alt="Check" />
									) : (
										<img src={CloseIcon} alt="Close" />
									)}
									{i18n.dialog.number}

								</div>
								<div className="password-validate" style={{ color: "#5C5C5C" }}>
									{SPECIALCHAREREGEX.test(newpassword) ? (
										<img src={CheckIcon} alt="Check" />
									) : (
										<img src={CloseIcon} alt="Close" />
									)}
									{i18n.dialog.character}
								</div >
								<div style={{ marginLeft: "25px", color: "#5C5C5C" }}>!@#%$&</div>
							</div>)
						}
					</div>

				</div>
			</DialogContent>
			<DialogActions>
				<Grid container
					columnSpacing={1}
					style={{
						display: "flex", flexDirection: "row", justifyContent: "flex-end",
					}}
				>
					<Grid item={true}>
						<Button
							sx={{ width: "135px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleClose}
						>
							{i18n.dialog.cancel}
						</Button>
					</Grid>
					<Grid item={true}>
						<Button
							className="submit-button"
							size="large"
							variant="contained"
							sx={{ width: "135px" }}
							onClick={updatePassword}
							disabled={!isEnabled}
						>
							{i18n.dialog.save}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
};
export default ChangePassword;
