import { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import { format } from "date-fns";
// import CustomAudioPlayer from "./CustomAudioPlayer";
import CustomAudioPlayer from "../../pages/CustomAudioPlayer";
import Download from "../../images/document-download.svg";
import Share from "../../images/share.svg";
import MenuDotVerticle from "../../images/menu dots verticle.svg";
import EditPencil from "../../images/edit.svg";
import Trash from "../../images/trash.svg";
import {
	Grid,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
} from "@mui/material";
import fileDownload from "js-file-download";
import ShareMusic from "../../dialogBox/ShareMusic";
import RenameMusic from "../../dialogBox/RenameMusic";
import DeleteMusic from "../../dialogBox/DeleteMusic";
import SetProfileMusic from "../../dialogBox/SetProfileMusic";
import Star from "../../images/starfull.svg";
import UserOctagon from "../../images/user-octagon.svg";
import ProfilePlaylist from "../../dialogBox/ProfilePlaylist";
import { useUser } from "../../context/UserContext";
import ErrorAlert from "../Error/ErrorAlert";
import RemoveMusic from "../../dialogBox/RemoveMusic";

const SettingsMenu = [
	{
		label: "Share",
		id: "share",
		icon: Share,
		disabled: false,
	},
	{
		label: "",
		id: "setAsProfileMusic",
		icon: Star,
		disabled: false,
	},
	{
		label: "Add to Profile Playlist",
		id: "addToProfilePlaylist",
		icon: UserOctagon,
		disabled: false,
	},
	// REMOVED BELOW OPTION AS IT IS OUT OF SCOPE
	// {
	// 	label: "Move to Folder",
	// 	id: "moveToFolder",
	// 	icon: MoveToFolder,
	//     disabled: true
	// },
	{
		label: "Download",
		id: "download",
		icon: Download,
		disabled: false,
	},
	{
		label: "Rename",
		id: "rename",
		icon: EditPencil,
		disabled: false,
	},
	// REMOVED BELOW OPTION AS IT IS OUT OF SCOPE
	// {
	// 	label: "Make a Copy",
	// 	id: "makeCopy",
	// 	icon: Copy,
	//     disabled: true
	// },
	{
		label: "Delete",
		id: "delete",
		icon: Trash,
		disabled: false,
	},
];

const MusicCmp = (props: any) => {
	// const checkLength = props.session.attendees.find((attendee:any) => attendee.firstName.length + attendee.lastName.length > 12)
	const user = useUser();
	const [currentPlayingId, setCurrentPlayingId] = useState<any | null>(null);
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const [playAudioPlayer, setPlayAudioPlayer] = useState(false);
	const [shareMusicDialog, setShareMusicDialogPopup] = useState(false);
	const [profilePlaylistDialog, setProfilePlaylistDialogPopup] =
		useState(false);
	const [renameMusicDialog, setRenameMusicDialogPopup] = useState(false);
	const [deleteMusicDialog, setDeleteMusicDialogPopup] = useState(false);
	const [removeMusicDialog, setRemoveMusicDialogPopup] = useState(false);
	const [setProfileMusicDialog, setProfileMusicDialogPopup] = useState(false);
	const token = localStorage.getItem("sessionId");
	const [profileMusicCount, setProfileMusicCount] = useState<any>("");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const isLastItem =
		Array.isArray(props.recording) &&
		props.recording.length - 1 === props.recording.id;

	// const checkDisabled = (session: RecordingInterface | null) => {
	// 	if (session != null) {
	// 		const endTime = addMinutes(new Date(session.sessionDate), session.duration);
	// 		if (isBefore(endTime, new Date())) {
	// 			return true;
	// 		} else {
	// 			return false;
	// 		}
	// 	}
	// };

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = (itemId: string) => {
		setAnchorElUser(null);
	};

	const handleMenuClick = (itemId: string, params: any) => {
		setAnchorElUser(null);
		if (itemId === "share") {
			setShareMusicDialogPopup(true);
		} else if (itemId === "setAsProfileMusic") {
			setProfileMusicDialogPopup(true);
		} else if (itemId === "rename") {
			setRenameMusicDialogPopup(true);
		} else if (itemId === "addToProfilePlaylist") {
			setProfilePlaylistDialogPopup(true);
		} else if (itemId === "delete") {
			if (props.activeMenu === "sharedMusic") {
				setRemoveMusicDialogPopup(true);
			} else {
				setDeleteMusicDialogPopup(true);
			}
		} else if (itemId === "download") {
			// fileDownload(params.url, params.fileName.split('.')[0] + '.mp3');
			let musicUrl =
				props.activeMenu === "Recording" ||
				props.recording.musicUrl.includes("https://")
					? props.recording.musicUrl
					: process.env.REACT_APP_PICTURE_URL + props.recording.musicUrl;

			// fetch(musicUrl)
			// .then((result:any) => {
			//     fileDownload(result.data, params.fileName.split('.')[0] + '.mp3');
			// })
			fetch(musicUrl, {
				mode: "cors",
				headers: {
					"Access-Control-Allow-Origin": "*",
				},
			})
				.then((response) => response.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(new Blob([blob]));
					const link: any = document.createElement("a");
					link.href = url;
					link.setAttribute(
						"download",
						!props.recording.fileName
							? `music.mp3`
							: props.recording.fileName.includes(".mp3")
							? props.recording.fileName
							: `${props.recording.fileName}.mp3`
					); // Set the desired file name here
					document.body.appendChild(link);
					link.click();
					link?.parentNode.removeChild(link);
				})
				.catch((error) => {
					// Handle any errors that occurred during the fetch
					console.error("Error downloading file:", error);
				});
		}
	};

	const handleDialogClose = (success: boolean, editMode: string) => {
		setShareMusicDialogPopup(false);
		setRenameMusicDialogPopup(false);
		setDeleteMusicDialogPopup(false);
		setRemoveMusicDialogPopup(false);
		setProfileMusicDialogPopup(false);
		setProfilePlaylistDialogPopup(false);
	};

	const pauseAudioPlayer = () => {
		// Pause the currently playing audio player
		const audioPlayers = document.getElementsByClassName("audioPlayer");
		for (const player of audioPlayers) {
			const audioPlayer = player.firstChild as HTMLAudioElement | null;
			if (audioPlayer && !audioPlayer.paused) {
				audioPlayer.pause();
				//audioPlayer.style.display = 'none';
				setCurrentPlayingId(null);
				setPlayAudioPlayer(false);
			}
		}
		setCurrentPlayingId(null);
		setPlayAudioPlayer(false);
	};

	function playAudio(
		musicId: number,
		toShow: boolean,
		eventDesc: string,
		toPlay: boolean
	) {
		var lists: any = document.getElementsByClassName(
			"audioPlayer"
		) as HTMLCollectionOf<HTMLElement>;
		for (const list of lists) {
			let audioPlayer: any = list?.firstChild;
			if (list.id == "audioPlayerFor-" + musicId) {
				if (eventDesc === "onHover") {
					if (toShow) {
						list.style.display = "flex";
					} else {
						// if(!playAudioPlayer)
						// {
						//     list.style.display = 'none';
						//     setPlayAudioPlayer(false);
						//     setCurrentPlayingId(null);
						// }
					}
				} else if (eventDesc === "onClick") {
					if (audioPlayer.paused) {
						pauseAudioPlayer();
						list.style.display = "flex";
						audioPlayer.load();
						audioPlayer.play();
						setPlayAudioPlayer(true);
						setCurrentPlayingId(musicId);
					} else if (!audioPlayer.paused) {
						audioPlayer.pause();
						list.style.display = "flex";
						setPlayAudioPlayer(false);
						setCurrentPlayingId(null);
					}
				}
			} else {
				if (audioPlayer.paused) list.style.display = "none";
			}
		}
	}

	const isItemSelected = props.isSelected(props.recording.id);
	const labelId = `enhanced-table-checkbox-${props.recording.id}`;

	const getMenuTitle = (
		id: string,
		label: string,
		isProfileMusic: boolean,
		isProfilePlaylist: boolean
	) => {
		if (id === "setAsProfileMusic") {
			return isProfileMusic ? "Remove Profile Music" : "Set as Profile Music";
		} else if (id === "addToProfilePlaylist") {
			return isProfilePlaylist
				? "Remove Profile Playlist"
				: "Add to Profile Playlist";
		} else if (props.activeMenu === "sharedMusic" && id === "delete") {
			return "Remove";
		} else {
			return label;
		}
	};

	const getReadableFileSizeString = (fileSizeInBytes: number) => {
		if (fileSizeInBytes) {
			let i = -1;
			const byteUnits = [" kb", " Mb", " Gb", " Tb", "Pb", "Eb", "Zb", "Yb"];
			do {
				fileSizeInBytes = fileSizeInBytes / 1024;
				i++;
			} while (fileSizeInBytes > 1024);

			return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
		} else {
			return null;
		}
	};

	const [duration, setDuration] = useState("");
	const getDuration = () => {
		try {
			// props.recording.musicUrl = props.recording.musicUrl.split('?')[0]
			if (props.recording.musicUrl) {
				var au = document.createElement("audio");

				// Define the URL of the MP3 audio file
				au.src =
					props.activeMenu === "Recording" || props.recording.musicUrl.includes("https://")
						? props.recording.musicUrl.split("?")[0]
						: process.env.REACT_APP_PICTURE_URL +
						  props.recording.musicUrl.split("?")[0];

				// Once the metadata has been loaded, display the duration in the console
				au.addEventListener("loadedmetadata", function () {
					// Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
					var audioDuration: any = secondsToTime(au.duration);
					// var audioDuration:any = Math.max(Number(au.duration), 0.1).toFixed(2);
					// example 12.3234 seconds
					setDuration(audioDuration);
					// Alternatively, just display the integer value with
					// parseInt(duration)
					// 12 seconds

					// return duration;
				});
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	};

	function secondsToTime(e: any) {
		const h = Math.floor(e / 3600)
				.toString()
				.padStart(2, "0"),
			m = Math.floor((e % 3600) / 60)
				.toString()
				.padStart(2, "0"),
			s = Math.floor(e % 60)
				.toString()
				.padStart(2, "0");

		return Number(h) > 0 ? h + ":" + m + ":" + s : m + ":" + s;
		//return `${h}:${m}:${s}`;
	}

	useEffect(() => {
		getDuration();
	});

	const getAllMusicListing = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/getAllMusic`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				userProfileId: user.userProfileId,
			}),
		})
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setProfileMusicCount(data.length);
			})
			.catch((err) => {});
	};
	useEffect(() => {
		getAllMusicListing();
	}, []);
	return (
		<>
			<TableRow
				hover
				role="checkbox"
				aria-checked={isItemSelected}
				tabIndex={-1}
				key={props.recording.id}
				selected={isItemSelected}
				onMouseEnter={() =>
					playAudio(props.recording.id, true, "onHover", false)
				}
				onMouseLeave={() =>
					playAudio(props.recording.id, false, "onHover", false)
				}
				sx={{ border: "none" }}
				style={{ height: "80px" }}
			>
				<TableCell
					className="selectionCheckbox"
					padding="checkbox"
					sx={{ padding: 1 }}
				>
					<Checkbox
						color="primary"
						checked={isItemSelected}
						inputProps={{
							"aria-labelledby": labelId,
						}}
						sx={{ color: "#000", cursor: "pointer" }}
						onClick={(event) => props.handleClick(event, props.recording.id)}
						onMouseEnter={() =>
							playAudio(props.recording.id, true, "onHover", false)
						}
					/>
				</TableCell>
				<TableCell sx={{ padding: 1 }}>
					<span
						onMouseEnter={() =>
							playAudio(props.recording.id, true, "onHover", false)
						}
						style={{ display: "flex", gap: "10px", cursor: "pointer" }}
					>
						<span
							onClick={() =>
								playAudio(props.recording.id, true, "onClick", !playAudioPlayer)
							}
						>
							{props.recording.fileName}
						</span>
						{props.recording.isProfileMusic ? (
							<img
								src={Star}
								onClick={() => setProfileMusicDialogPopup(true)}
							/>
						) : (
							""
						)}
						{props.recording.isProfilePlaylist ? (
							<img
								src={UserOctagon}
								onClick={() => setProfilePlaylistDialogPopup(true)}
							/>
						) : (
							""
						)}
						{props.recording.isSharedMusic ? (
							<img src={Share} onClick={() => setShareMusicDialogPopup(true)} />
						) : (
							""
						)}
					</span>
				</TableCell>
				<TableCell className="listAudioPlayers">
					<Grid
						container
						xs={12}
						className="listAudioPlayers"
						sx={{ cursor: "pointer" }}
					>
						<CustomAudioPlayer
							className="listAudioPlayers"
							musicId={props.recording.id}
							audioUrl={`${
								props.activeMenu === "Recording"
									? props.recording.musicUrl
									: props.recording.musicUrl.includes("https://")
									? props.recording.musicUrl
									: process.env.REACT_APP_PICTURE_URL + props.recording.musicUrl
							}`}
							displayDirectly={false}
							activeMenu={props.activeMenu}
							toShowPlay={playAudioPlayer}
						/>
					</Grid>
					{/* <CustomAudioPlayer className="listAudioPlayers" musicId={props.recording.id} audioUrl={`${ (props.activeMenu === "Recording") ? props.recording.musicUrl : process.env.REACT_APP_PICTURE_URL+props.recording.musicUrl }`} displayDirectly={false} /> */}
				</TableCell>
				<TableCell
					align="left"
					onClick={() =>
						playAudio(props.recording.id, true, "onClick", !playAudioPlayer)
					}
					onMouseEnter={() =>
						playAudio(props.recording.id, true, "onHover", false)
					}
					sx={{ cursor: "pointer", padding: 1 }}
				>
					{duration}
				</TableCell>
				{props.activeMenu === "sharedMusic" && (
					<TableCell
						align="left"
						onClick={() =>
							playAudio(props.recording.id, true, "onClick", !playAudioPlayer)
						}
						onMouseEnter={() =>
							playAudio(props.recording.id, true, "onHover", false)
						}
						sx={{ cursor: "pointer", padding: 1 }}
					>
						{props.recording.name}
					</TableCell>
				)}
				<TableCell
					align="left"
					onClick={() =>
						playAudio(props.recording.id, true, "onClick", !playAudioPlayer)
					}
					onMouseEnter={() =>
						playAudio(props.recording.id, true, "onHover", false)
					}
					sx={{ cursor: "pointer", padding: 1 }}
				>
					{getReadableFileSizeString(props.recording.fileSize)}
				</TableCell>
				<TableCell
					align="left"
					onClick={() =>
						playAudio(props.recording.id, true, "onClick", !playAudioPlayer)
					}
					onMouseEnter={() =>
						playAudio(props.recording.id, true, "onHover", false)
					}
					sx={{ cursor: "pointer", padding: 1 }}
				>
					{props.recording.musicDate
						? format(new Date(props.recording.musicDate), "MM/dd/yyyy")
						: ""}
				</TableCell>
				<TableCell align="left" sx={{ padding: 1 }}>
					<div
						style={{
							opacity: props?.selected?.length > 0 ? 0.5 : 1,
							cursor: props?.selected?.length > 0 ? "not-allowed" : "pointer",
						}}
					>
						<img
							src={MenuDotVerticle}
							alt="Share"
							onClick={handleOpenUserMenu}
							style={{
								pointerEvents: props?.selected?.length > 0 ? "none" : "auto",
							}}
						/>
					</div>
					<Menu
						sx={{ mt: "1%" }}
						id="menu-appbar"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
					>
						{SettingsMenu.map((menu) =>
							(props.activeMenu === "sharedMusic" &&
								(menu.id === "download" ||
									menu.id === "delete" ||
									menu.id === "setAsProfileMusic")) ||
							props.activeMenu !== "sharedMusic" ? (
								<ListItemButton
									disabled={
										menu.disabled ||
										(profileMusicCount >= 10 &&
											getMenuTitle(
												menu.id,
												menu.label,
												props.recording.isProfileMusic,
												props.recording.isProfilePlaylist
											) === "Add to Profile Playlist")
									}
									key={menu.id}
									onClick={() =>
										handleMenuClick(menu.id, {
											url: props.recording.musicUrl,
											fileName: props.recording.fileName,
										})
									}
								>
									<ListItemIcon>
										<img src={menu.icon} alt={menu.label} />
									</ListItemIcon>
									<ListItemText
										primary={getMenuTitle(
											menu.id,
											menu.label,
											props.recording.isProfileMusic,
											props.recording.isProfilePlaylist
										)}
									/>
								</ListItemButton>
							) : null
						)}
					</Menu>
				</TableCell>
			</TableRow>
			<ShareMusic
				shareMusicOpen={shareMusicDialog}
				recording={props.recording}
				handleClose={handleDialogClose}
				getRecordingList={props.getRecordingList}
			/>
			<SetProfileMusic
				getRecordingList={props.getRecordingList}
				setProfileMusicOpen={setProfileMusicDialog}
				recording={props.recording}
				handleClose={handleDialogClose}
				getUserDetails={props.getUserDetails}
			/>
			<ProfilePlaylist
				profilePlaylistOpen={profilePlaylistDialog}
				recording={props.recording}
				handleClose={handleDialogClose}
				getRecordingList={props.getRecordingList}
				selected={props.selected}
			/>
			<RenameMusic
				getRecordingList={props.getRecordingList}
				renameMusicOpen={renameMusicDialog}
				recording={props.recording}
				handleClose={handleDialogClose}
			/>
			<DeleteMusic
				getRecordingList={props.getRecordingList}
				deleteMusicOpen={deleteMusicDialog}
				recording={props.recording}
				handleClose={handleDialogClose}
				reloadStorage={props.reloadStorage}
				activeMenu={props.activeMenu}
			/>
			<RemoveMusic
				getRecordingList={props.getRecordingList}
				deleteMusicOpen={removeMusicDialog}
				recording={props.recording}
				handleClose={handleDialogClose}
			/>
		</>
	);
};

export default MusicCmp;
