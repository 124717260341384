import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { useEffect } from "react";
import useAdminAuthorized from "../useIsAdminAuthorized/useIsAdminAuthorized";

const useAdminRedirect = () => {
	const user = useUser();
	const navigate = useNavigate();
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	useEffect(() => {
		if (user && user.authorities) {
			if (isAdmin) {
				navigate("/admin-dashboard");
			}
		}
	}, [isAdmin, navigate, user]);
};

export default useAdminRedirect;
