import { useState } from "react";
import {
	Box,
	Button,
	Grid,
	Typography,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Snackbar,
	Alert,
	AlertTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import CreateTicket from "./CreateTicket";

import ExpandIcon from "../../icons/expand.svg";
import ContractIcon from "../../icons/contract.svg";
import SuccessIcon from "../../images/tick-square.svg";
import ErrorIcon from "../../images/danger.svg";
import i18n from "../../l10n/strings.json";

const HelpAndSupport = () => {
	const [createTicketDialog, setCreateTicketDialog] = useState(false);
	const [expanded, setExpanded] = useState<string | false>(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};

	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(2),
	}));

	const Accordion = styled((props: AccordionProps) => (
		<MuiAccordion disableGutters elevation={0} square {...props} />
	))(({ theme }) => ({
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
	}));

	const handleDialogClose = (success: boolean, editMode: string) => {
		setCreateTicketDialog(false);
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	return (
		<>
			<Grid className="mainPanel">
				<Grid
					container={true}
					className="column mainPanel"
					sx={{ width: "806px !important" }}
					rowSpacing={{ xs: 1 }}
				>
					<Box sx={{ textAlign: "left", m: 1 }}>
						{showAlert && (
							<Snackbar
								sx={{
									"&.MuiSnackbar-anchorOriginTopCenter": {
										top: "70px",
									},
								}}
								anchorOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								open={showAlert}
								autoHideDuration={6000}
								onClose={handleAlertClose}
							>
								<Alert
									onClose={handleAlertClose}
									icon={
										alertStatus ? (
											<img src={SuccessIcon} alt="error-icon" />
										) : (
											<img
												src={ErrorIcon}
												alt="error-icon"
												style={{ marginRight: "10px" }}
											/>
										)
									}
									sx={{
										backgroundColor: "#FFFFFF",
										boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.17)",
										borderRadius: "6px",
										fontSize: "1.125rem",
										fontWeight: "500",
										color: "black",
									}}
								>
									<AlertTitle
										sx={{
											fontSize: "1.125rem",
											fontWeight: "500",
											color: "#000000",
											display: "flex",
											margin: "auto",
											padding: "4px",
											justifyContent: "center !important",
										}}
									>
										{alertMsg}
									</AlertTitle>
								</Alert>
							</Snackbar>
						)}
					</Box>

					{/* Title */}
					<Grid item mt={3} mb={1}>
						<Typography sx={{ fontWeight: "500", fontSize: "2.25rem" }}>
							{i18n.contactUs.title}
						</Typography>
					</Grid>

					{/* Contact Us card */}
					<Grid item>
						<Card>
							<CardHeader
								title={i18n.contactUs.subTitle}
								titleTypographyProps={{ fontSize: "1.5rem", fontWeight: "500" }}
								sx={{ mx: 2, mt: 1 }}
							></CardHeader>
							<CardContent sx={{ ml: 2, pt: 0 }}>
								<Typography
									sx={{
										fontSize: "1.125rem",
										fontWeight: "400",
										color: "#5B5B65",
										mt: 1,
									}}
								>
									{i18n.contactUs.contatDesc}
								</Typography>
							</CardContent>
							<CardActions sx={{ justifyContent: "right", mx: 2, mb: 2 }}>
								<Button
									sx={{
										fontSize: "1.125rem",
									}}
									variant="contained"
									onClick={() => setCreateTicketDialog(true)}
								>
									{i18n.contactUs.subTitle}
								</Button>
							</CardActions>
						</Card>
					</Grid>

					{/* FAQ card */}
					{/* <Grid item mt={3}>
						<Card>
							<CardHeader
								title={i18n.contactUs.faqTitle}
								titleTypographyProps={{ fontSize: "1.5rem", fontWeight: "500" }}
								sx={{ mx: 2, mt: 1 }}
							></CardHeader>
							<CardContent sx={{ pt: 0 }}>
								<Box>
									<Accordion
										expanded={expanded === "panel1"}
										onChange={handleChange("panel1")}
										disabled
									>
										<AccordionSummary
											sx={{ flexDirection: "row-reverse" }}
											expandIcon={
												expanded === "panel1" ? (
													<img src={ContractIcon} alt="contract" />
												) : (
													<img src={ExpandIcon} alt="expand" />
												)
											}
											aria-controls="panel1bh-content"
											id="panel1bh-header"
										>
											<Typography ml={"10px"}>{i18n.contactUs.que1}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>{i18n.contactUs.ans1}</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === "panel2"}
										onChange={handleChange("panel2")}
										disabled
									>
										<AccordionSummary
											sx={{ flexDirection: "row-reverse" }}
											expandIcon={
												expanded === "panel2" ? (
													<img src={ContractIcon} alt="contract" />
												) : (
													<img src={ExpandIcon} alt="expand" />
												)
											}
											aria-controls="panel2bh-content"
											id="panel2bh-header"
										>
											<Typography ml={"10px"}>{i18n.contactUs.que2}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>{i18n.contactUs.ans2}</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === "panel3"}
										onChange={handleChange("panel3")}
										disabled
									>
										<AccordionSummary
											sx={{ flexDirection: "row-reverse" }}
											expandIcon={
												expanded === "panel3" ? (
													<img src={ContractIcon} alt="contract" />
												) : (
													<img src={ExpandIcon} alt="expand" />
												)
											}
											aria-controls="panel3bh-content"
											id="panel3bh-header"
										>
											<Typography ml={"10px"}>{i18n.contactUs.que3}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>{i18n.contactUs.ans3}</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === "panel4"}
										onChange={handleChange("panel4")}
										disabled
									>
										<AccordionSummary
											sx={{ flexDirection: "row-reverse" }}
											expandIcon={
												expanded === "panel4" ? (
													<img src={ContractIcon} alt="contract" />
												) : (
													<img src={ExpandIcon} alt="expand" />
												)
											}
											aria-controls="panel4bh-content"
											id="panel4bh-header"
										>
											<Typography ml={"10px"}>{i18n.contactUs.que4}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>{i18n.contactUs.ans4}</Typography>
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === "panel5"}
										onChange={handleChange("panel5")}
										disabled
									>
										<AccordionSummary
											sx={{ flexDirection: "row-reverse" }}
											expandIcon={
												expanded === "panel5" ? (
													<img src={ContractIcon} alt="contract" />
												) : (
													<img src={ExpandIcon} alt="expand" />
												)
											}
											aria-controls="panel5bh-content"
											id="panel5bh-header"
										>
											<Typography ml={"10px"}>{i18n.contactUs.que5}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>{i18n.contactUs.ans5}</Typography>
										</AccordionDetails>
									</Accordion>
								</Box>
							</CardContent>
						</Card>
					</Grid> */}
				</Grid>
			</Grid>
			<CreateTicket
				handleDialogClose={handleDialogClose}
				createTicketDialog={createTicketDialog}
				setCreateTicketDialog={setCreateTicketDialog}
				setShowAlert={setShowAlert}
				setAlertMsg={setAlertMsg}
				setAlertStatus={setAlertStatus}
				type={"support"}
			/>
		</>
	);
};

export default HelpAndSupport;
