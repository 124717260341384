import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	TextField,
	Button,
	Box,
	FormControl,
	Grid,
	Typography,
	Card,
	CardHeader,
	CardContent,
	Divider,

	Checkbox,

	Link as MuiLink,
	Snackbar,
	Alert,
	AlertTitle,
} from "@mui/material";
import LeftPanel from "./LeftPanel";
import validator from "validator";
import RightAarrow from "../images/right arrow.svg";
import ErrorMark from "../images/danger.svg";
import i18n from "../l10n/strings.json";
import { useGradient2Styles } from "../theme";
import { useSignUp } from "../context/SignUpContext";
import user from "../services/user";
import { json } from "stream/consumers";
import googleIcon from "../icons/google.svg";
import appleIcon from "../icons/apple.svg";
import facebookIcon from "../icons/facebook.svg";
import SuccessMark from "../images/tick-circle.svg";
import ErrorIcon from "../images/danger.svg";
import SuccessIcon from "../images/tick-square.svg";
import GoogleSignupButton from "./Auth/SocialLogin/GoogleSignupButton";
import FacebookSignupButton from "./Auth/SocialLogin/FacebookSignupButton";

const initialUserData =  {
	Code: '',
	Password: '',
	Repassword: '',
	FirstName: '',
	LastName: '',
	Location: '',

};

const SignUpEmail = () => {
	const token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJoYWt1bmFtYXRhdGFAeW9wbWFpbC5jb20iLCJhdXRoIjoiUk9MRV9DT0xMQUJfVVNFUixST0xFX1VTRVIiLCJpZGVudGlmaWVyIjoiZmViYTM5ZDQtOTIwMS00ZGI3LWEyZTYtNzBkMjEyMDY1ZGRjIiwiZXhwIjoxNzExNTM4MTYwfQ.R8K_N3pAUsOT-PziPROw_wo9YK06N6yL7eUHx1M5D-p2YShMeT0pCk68ugu0ZNqaPXMc10ysipeZLfD7OpQELA";
	const navigate = useNavigate();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const { userData, setUserData } = useSignUp();
	const [isLoading, setIsLoading] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [hasError, setHasError] = useState(false);

	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");

	const [email, setEmail] = useState("");
	const [joinEmail, setJoinEmail] = useState("");
	const [inviteCode, setInviteCode] = useState("");
	const [invalidEmailErr, setInvalidEmailErr] = useState(false);
	const [invalidJoinEmailErr, setInvalidJoinEmailErr] = useState(false);
	const [emptyJoinEmailerr, setEmptyJoinEmailErr] = useState(false);
	const [error, setErrorMessage] = useState(false);
	const [joinemailAlreadyExists, setJoinemailAlreadyExists] = useState(false);
	const [joinemailAlreadyReq, setJoinemailAlreadyReq] = useState(false);
	const [cardContainerHeight, setCardContainerheight] = useState<
	number | undefined
	>(0);
	const gradient = useGradient2Styles();
	const [fieldRequired, setFieldRequired] = useState(false);
	const [invalidCodeErr, setInvalidCodeErr] = useState(false);
	const [codeAlreadyUsed, setCodeAlreadyUsed] = useState(false);
	const [joinBtnDisabled, setJoinBtnDisabled] = useState(false);
	const termURL ="https://lutefish.com/policies/terms-of-service";
	const policyURL ="https://lutefish.com/policies/privacy-policy"
	const [checkRequired, setCheckRequired] = useState(false);
	const [sentMailSuccess, setSentMailSuccess] = useState(false);
	const [regProcessRem, setRegProcessRem] = useState(false);
	const [disableAccount, setDisableAccount] = useState(false);
	const [accountIsBanned, setAccountIsBanned] = useState(false);
	const [accountIsTerminated, setAccountIsTerminated] = useState(false);
	const [loginType, setLoginType] = useState("");

	const handleTermsClick = () => {
		window.open(termURL, '_blank');
	}

	const handlePolicyClick = () => {
		window.open(policyURL, '_blank');
	}

	const handleCheckboxChange = (event:any) => {
		setIsChecked(event.target.checked);
		if(checkRequired){
			setCheckRequired(false)
		}
		
  };

	useEffect(() => {
        if (hasError && validator.isEmail(email)) {
            // If there was an error and the email is now valid, enable the button
            setHasError(false);
            setIsLoading(false);
			//setErrorMessage(false);
        }
	  }, [email, hasError]);

	useEffect(() => {
		const element = document.getElementById("right-pannel-header");
		const rect = element?.getBoundingClientRect();
		setCardContainerheight(rect?.height);
	}, []);
	const expectedBaseURL = process.env.REACT_APP_BASE_URL_FRONTEND;

	useEffect(() => {
	  const currentBaseURL = `${window.location.href.split('#')[0]}#`;
	  if (currentBaseURL !== expectedBaseURL) {
			window.location.replace(`${expectedBaseURL}/login`);
			navigate('/login');
	  }
	}, []);
	const signUpMail = () => {
		if (validator.isEmail(email)) {
			const otpDTO = {
				userEmail:email,
				otpType: "SIGN_UP_OTP"
			}
			fetch(`${process.env.REACT_APP_BASE_URL}/api/generate-otp`, {
				method: "POST",
				// mode: "no-cors",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(otpDTO),
			})
			    .then((response) => response.json())
				.then((result) => {
					setIsLoading(false);
					if(result.status === 502){
						navigate('/defaultPage')
					} else if (result.status >= 400) {
						setErrorMessage(true);
						setHasError(true);
					} else {
						setErrorMessage(false);
						navigate("/sign-up", { state: { email: email } });
					}
				});
		} else {
			setInvalidEmailErr(true);
			setHasError(true);
		}
	};

	const InvitationCodeCheck = () => {
		if(inviteCode !== "" && email !== "" && isChecked){
			if (validator.isEmail(email)) {
				setIsLoading(true);
				const bodyData:any = {
					code: inviteCode,
					userEmail: email
				}
				fetch(`${process.env.REACT_APP_BASE_URL}/api/public/signup`, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body : JSON.stringify(bodyData),
				})
				.then((res) => {
					setIsLoading(false);
					if(res.status === 502){
						navigate('/defaultPage')
					} else if (res.status === 200) {
						setErrorMessage(false);
						navigate("/sign-up", { state: { email: email } });
					}
					return res.json();
			})
				.then((response) => {
					setIsLoading(false);
					switch (response.errorKey) {
						case "invalidCode":
							setInvalidCodeErr(true);
							break;
						case "codeAlreadyUsed":
							setInvalidCodeErr(true);
							break;
						case "emailexists":
						setErrorMessage(true);
						break;
						default:
							setInvalidCodeErr(false);
							setCodeAlreadyUsed(false);
							setErrorMessage(false);
							setCheckRequired(false);
						break;
					}
				})
				.catch((err) => {
					setIsLoading(false);
				});

			} else {

				setInvalidEmailErr(true);
			}
		}else{
			if(inviteCode === "" && email === "" ){
				setFieldRequired(true);
			}
			if(!isChecked){
				setCheckRequired(true)
			}
			
		}
	};
	const WaitlistJoin = () => {
	if(joinEmail!==''){
		if (validator.isEmail(joinEmail)) {
			const bodyData:any = {
					userEmail: joinEmail
				}
			try {
				setJoinBtnDisabled(true);
				fetch(`${process.env.REACT_APP_BASE_URL}/api/public/waiting-lists/add-to-list/`, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body : JSON.stringify(bodyData),
				})
				.then((response) => {
					if (response.status > 400) {
						  setJoinBtnDisabled(false);
						}
						return response.json();
			})
				.then((response) => {
					switch (response.errorKey) {
						case "alreadyExists":
							setJoinemailAlreadyReq(false);
							setJoinemailAlreadyExists(true);
							break;
						case "alreadyRequested":
							setJoinemailAlreadyExists(false);
							setJoinemailAlreadyReq(true);
							break;
						case "success":
							navigate("/joinWaitlistSuccess");
						break;
						default:
							setJoinemailAlreadyExists(false);
							setJoinemailAlreadyReq(false);
							break;
					}
				})
				.catch((err) => {
					setJoinBtnDisabled(false);
				});
			} catch (error) {
				setJoinBtnDisabled(false);
			}
		} else {
			setInvalidJoinEmailErr(true);
		}
	}else{
		setEmptyJoinEmailErr(true);
	}
	};

	const signupConfirmation = () => {
		// setSentMailSuccess(true);
		// setRegProcessRem(true);
		
			if(email !== "" && isChecked){
				if (validator.isEmail(email)) {
					setIsLoading(true);
					const bodyData:any = {
						email: email
					}
										
					fetch(`${process.env.REACT_APP_BASE_URL}/api/account/signup`, {
						method: "POST",
						headers: { "Content-Type": "application/json",  },
						body : JSON.stringify(bodyData),
					})
					.then((res) => {
						if(res.status <= 400)
						return res.json();
						else
						throw new Error("Error Occured!");
					})
					.then((response) => {
						setIsLoading(false);
						switch (response.errorKey) {
							case "success":
								setErrorMessage(false);
								setShowAlert(true);
								setAlertStatus(true);
								setAlertMsg("Mail has been sent successfully!");
								setSentMailSuccess(true);
								setRegProcessRem(false);
								break;
							case "alreadyExists":
								setErrorMessage(true);
								setShowAlert(true);
								setAlertStatus(false);
								setAlertMsg("The mail you entered already exists.");
								break;

							case "alreadySingedUp":
								setErrorMessage(true);
								// setShowAlert(true);
								setAlertStatus(false);
								// setAlertMsg("Looks like you have already registered using this email.");
								setRegProcessRem(true);
								setSentMailSuccess(true);
								break;

							default:
								setInvalidCodeErr(false);
								setCodeAlreadyUsed(false);
								setErrorMessage(false);
								setCheckRequired(false);
							break;
						}
					})
					.catch((err) => {
						setShowAlert(true);
						setAlertStatus(false);
						setRegProcessRem(false);
						setSentMailSuccess(false);
						setAlertMsg("Error, Please try again!");
						setIsLoading(false);
					});
	
				} else {
					setInvalidEmailErr(true);
					// setShowAlert(true);
					setAlertStatus(false);
					// setAlertMsg("Error, Please enter valid email!");
				}
			}else{
				if(email === "" ){
					setFieldRequired(true);
					// setShowAlert(true);
					setAlertStatus(false);
					// setAlertMsg("Error, Please enter valid email!");
				}
				if(!isChecked){
					setCheckRequired(true)
				}
			}
	}
	const signupResend = () => {
		// setSentMailSuccess(true);
		// setRegProcessRem(true);
		
			if(email !== "" && isChecked){
				if (validator.isEmail(email)) {
					setIsLoading(true);
					const bodyData:any = {
						email: email
					}				
					
					fetch(`${process.env.REACT_APP_BASE_URL}/api/account/signup/resend`, {
						method: "POST",
						headers: { "Content-Type": "application/json",  },
						body : JSON.stringify(bodyData),
					})
					.then((res) => {
						if(res.status <= 400)
						return res.json();
						else
						throw new Error("Error Occured!");
					})
					.then((response) => {
						setIsLoading(false);
						switch (response.errorKey) {
							case "success":
								setErrorMessage(false);
								setShowAlert(true);
								setAlertStatus(true);
								setAlertMsg("Mail Has been sent Successfully!");
								setSentMailSuccess(true);
								setRegProcessRem(false);
								break;

							default:
								setInvalidCodeErr(false);
								setCodeAlreadyUsed(false);
								setErrorMessage(false);
								setCheckRequired(false);
							break;
						}
					})
					.catch((err) => {
						setShowAlert(true);
						setAlertStatus(false);
						setRegProcessRem(false);
						setSentMailSuccess(false);
						setAlertMsg("Error, Please try again!");
						setIsLoading(false);
					});
	
				} else {
					setInvalidEmailErr(true);
					// setShowAlert(true);
					setAlertStatus(false);
					// setAlertMsg("Error, Please enter valid email!");
				}
			}else{
				if(email === "" ){
					setFieldRequired(true);
					// setShowAlert(true);
					setAlertStatus(false);
					// setAlertMsg("Error, Please enter valid email!");
				}
				if(!isChecked){
					setCheckRequired(true)
				}
			}
	}

	const handleEmailChange = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		disableAllErros();
		setJoinEmail('');
		setEmail(event.target.value);
	};
	const handleJoinEmailChange = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		disableAllErros();
		setEmail('');
		setInviteCode('');
		setJoinEmail(event.target.value);
	};

	const handleInviteCodeChange = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		disableAllErros();
		setJoinEmail('');
		setInviteCode(event.target.value);

	};

	const disableAllErros = () => {
		setInvalidEmailErr(false);
		setInvalidJoinEmailErr(false);
		setEmptyJoinEmailErr(false);
		setJoinemailAlreadyExists(false);
		setJoinemailAlreadyReq(false);
		setInvalidCodeErr(false);
		setCodeAlreadyUsed(false);
		setErrorMessage(false);
		setFieldRequired(false);
		setCheckRequired(false)
	}

	const updateAlreadyLoggedIn = (email: string) => {
		// setAlreadyLoggedIn(!alreadyLoggedIn);
		setLoginType("social");
		setUsername(email);
		setPassword("password");
	};

	const updateAccountDisable = (value: boolean) => {
		setDisableAccount(value);
	};

	const updateAccountBanned = (value: boolean) => {
		setAccountIsBanned(value);
	};

	const updateAccountTerminated = (value: boolean) => {
		setAccountIsTerminated(value);
	};

	useEffect (() => {
		setUserData(initialUserData);
	},[]);

	return (
		// <form autoComplete="off" noValidate>
		<Grid container={true} columns={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }} >
			{showAlert && (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "80px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showAlert}
						autoHideDuration={6000}
						onClose={() => setShowAlert(false)}
					>
						<Alert
							onClose={() => setShowAlert(false)}
							icon={
								alertStatus ? (
									<img src={SuccessIcon} alt="error-icon" />
								) : (
									<img
										src={ErrorIcon}
										alt="error-icon"
										style={{ marginRight: "10px" }}
									/>
								)
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontSize: "1.125rem",

									fontWeight: "500",
									color: "#000000",
									display: "flex",

									margin: "auto",
									padding: "4px",
									justifyContent: "center !important",
								}}
							>
								{alertMsg}
							</AlertTitle>
						</Alert>
					</Snackbar>
				)}
			<Grid item={true} xs={6} md={4}>
				<LeftPanel />
			</Grid>
			<Grid item={true} xs={6} md={4} className="alignCenterTabLogin">
				<Grid container={true} className="column" >
					<Grid
						item={true}
						xs={12}
						className="right-align"
						id="right-pannel-header"
					>
						<Grid
							container={true}
							className="column right-align"
						>
							<Grid
								container={true}
								className="column right-align"
								rowSpacing={{ xs: 1, sm: 3, md: 5, xl: 10, xxl: 20 }}
							>
								<Grid item={true} xs={12} className="right-align">
									<Box display="flex" justifyContent="flex-end" mt={2}>
										<Typography
											mr={2}
											mt={1}
											sx={{
												fontFamily: "Lexend",
												fontWeight: "500",
												fontSize: "1rem",
												lineHeight: "20px",
												color: "#5D5D65",
											}}
										>
											{i18n.signUp.alreadyMember}
										</Typography>
										<Button
											component={Link}
											to="/login"
											sx={{

												fontWeight: "900",
												fontSize: "1rem",
												marginRight: "5vw",
												backgroundColor: "#ffffff",
												borderRadius: "18px",
												color: "#0273E6",
												border: "1px solid #0273E6",
												height: "36px",
											}}
										>
											{i18n.login.title}
										</Button>
									</Box>
									
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item={true} xs={12} sx={{margin:'auto'}}>
						<Card
							component="section"
							sx={{
								position: "relative",
								boxShadow: "none",
								paddingBottom: 0,
								marginTop: "2rem",
							}}
						>
								<Grid
									item={true}
									sx={{
										display: "flex",
										justifyContent: "flex-end",
										width: "350px",
										mb: {
											xs: "-2.5rem",
											md: "-2.8rem",
										},
										mr: {
											xs: "3.2rem",
											md: "2rem",
										},
										paddingBottom:0,
									}}
								>
									<Typography
										sx={{
											border: "1px solid #3EB92A",
											width: "auto",
											height: "auto",
											padding: "0.2rem 0.5rem 0.25rem 0.5rem",
											borderRadius: "0.5rem",
											gap: "0.6rem",
											bgcolor: "#3EB92A",
											fontSize: "1rem",
											lineHeight: "1rem",
											alignItems: "center",
											fontWeight: "700",
											color: "#FFFFFF",
										}}
									>
										BETA
									</Typography>
								</Grid>
								<CardHeader
									title={
										<Typography
											variant="h1"
											sx={{
												color: "customColor.black",
												display: "flex",
												justifyContent: "center",
												fontSize: "64px",
												width: "350px",
											}}
										>
											{i18n.signUp.title}
										</Typography>
									}
									titleTypographyProps={{ noWrap: false, variant: "body1" }}
									sx={{maxWidth:'392px',paddingBottom:0,}}
								/>
								{ sentMailSuccess ? (
								<CardContent sx={{ padding: 0, overflow:'visible'}}>
										
									{/* <FormControl sx={{padding: 0}}>
										<Grid
											container={true}
											columns={12}
											rowSpacing={1}
											className="signupform"
											sx={{ maxWidth: "24.5rem"}}
										>

											<Grid item={true}>
												<div style={{ display: "flex", alignItems: "center" }}>
													<Box mb={1} mr={1}>
														{error || invalidCodeErr || fieldRequired|| codeAlreadyUsed || checkRequired ? (
															<img
																src={ErrorMark}
																alt="error-mark"
																height="32px"
																style={{ verticalAlign: "middle" }}
															/>
														) : (
															""
														)}
													</Box>
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textAlign: "start",
															color: "#FD4B24",
															display:"block",
														}}
													>
														<div>
														{fieldRequired ? i18n.signUp.invitedata.fieldRequired : ""}
														{error ? i18n.signUp.accountEmailExists : ""}
														{invalidCodeErr ? i18n.signUp.invitedata.invalidCode : ""}
														{codeAlreadyUsed ? i18n.signUp.invitedata.codeAlreadyUsed : ""}
														</div>
														{ checkRequired ? "Privacy Policy consent is required!" : ""}

													</Typography>													
												</div>
												<div style={{ display: "flex", alignItems: "center" }}>
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textAlign: "start",
															color: "#FD4B24",
														}}
													>
														{error ? (
															<a
																href="/#/login"
																style={{
																	color: "#0A6BFE",
																	margin: "0 5px",
																}}
															>
																{i18n.login.title}
															</a>
														) : (
															""
														)}
													</Typography>
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textAlign: "start",
															color: "#FD4B24",
														}}
													>
														{" "}
														{error ? i18n.signUp.or : ""}
													</Typography>
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textAlign: "start",
															color: "#FD4B24",
														}}
													>
														{error ? (
															<a
																href="#/forgotPassword"
																style={{
																	color: "#0A6BFE",
																	margin: "0 5px",
																}}
															>
																{i18n.signUp.resetPassword}
															</a>
														) : (
															""
														)}
													</Typography>
												</div>
											</Grid>

											<Grid item={true} xs={12}  sx={{pt:" 0 !important",}}>
												<Typography
													variant="subtitle1"
													className="MuiMenuItem-root"
													sx={{ color: "customColor.label", mb: "10px" }}
												>
													{i18n.login.emailFieldName}
												</Typography>
												<TextField
													type="email"
													fullWidth
													inputProps={{
														style: {
															borderColor: "customColor.gray1",
															background: "#F8F8F8",
															color: "customColor.black",
															height:23,
														},
													}}
													error={
														error ||
														(invalidEmailErr && !validator.isEmail(email))
													}
													value={email}
													onChange={handleEmailChange}
													variant="outlined"
													sx={{ mb: "15px", width: "350px" }}
													// placeholder="Enter email address"
													onKeyPress={(e) => {
															if (e.key === 'Enter' &&  email !== "" && !isChecked) {
																	// InvitationCodeCheck();
																	signupConfirmation();
															}
													}}
												/>
											</Grid>

											<Grid item={true} xs={12} mt={-2} mb={1}>
												{invalidEmailErr && !validator.isEmail(email) && (
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															color: "error.main",
															textAlign: "right",
														}}
													>
														{i18n.signUp.invalidEmailMsg}
													</Typography>
												)}
											</Grid>
											<Grid
												item={true}
												xs={12}
												style={{
													paddingLeft: 0,
												}}
											>
												<Button
													type="submit"
													className={`submit-button`}
													sx={{
														background: (!isChecked) ?  "#888888" :
															"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)" ,
														height: "48px",
														color: "#FFFFFF",
														fontSize: "1.125rem",
														fontWeight: "500",
														lineHeight: "1.125rem",
														fontFamily: "Lexend",
														borderRadius: "24px",
														width: "350px"
													}}
													size="large"
													disabled={!isChecked}
													// disabled={!isEnabled}
													endIcon={<img src={RightAarrow} alt="Right Arrow" />}
													fullWidth
													variant="contained"
													onClick={() => signupConfirmation()}
												>
													{isLoading?'Signing up':i18n.signUp.invitedata.signupbtn}
												</Button>
											</Grid>
										</Grid>
										<Grid item={true} xs={12} className="center-align"  sx={{ mt: "1rem", ml: "10px" }}>
                                                    <Checkbox color="primary" checked={isChecked}
      													onChange={handleCheckboxChange}/>
                                                    <Typography
													sx={{
														fontFamily: "Lexend",
														fontSize: "14px",
														fontWeight: 500,
														lineHeight: "19px",
														letterSpacing: "0em",
														textAlign: "left",
														color: "#888888",
														maxWidth:'385px',

														width: "350px",
													}}
>
													By signing up, I agree to Lutefish’s{" "}
													<MuiLink sx={{ color: "#0A6BFE", cursor: "pointer" }} onClick={handleTermsClick}>
														Terms of Service
													</MuiLink>{" "}
														and{" "}
													<MuiLink sx={{ color: "#0A6BFE", cursor: "pointer" }} onClick={handlePolicyClick}>
														Privacy Policy
													</MuiLink>
													.
												</Typography>
											</Grid>
									</FormControl> */}

								    <Grid xs={12}
										  className="left-align"
										  sx={{ width: "534px", mt: "50px"}}>
										<Grid item={true} xs={1}>
										    <Box mr={1}>
												{
													regProcessRem ? (
														<img src={ErrorIcon} alt="error-mark" height="32px" />
													) : (<img src={SuccessMark} alt="error-mark" height="32px" />)
												}
											</Box>
										</Grid>
										<Grid item={true} xs={11}>
											<Typography className="signup-confirm-message" sx={{color: regProcessRem ? "#FD4B24" : "#3EB92A"}}>
												{regProcessRem ? i18n.signUp.regRemConfirmationLinkSentMsg : i18n.signUp.confirmationLinkSentMsg} <br/>
												<span className="signup-links-span" style={{color: "#1D2D53"}}>{email}</span><br/>
												{!regProcessRem && (<span className="signup-links-span" style={{color: "#0A6BFE", cursor: "pointer"}} onClick={() => {setSentMailSuccess(false); setRegProcessRem(false);}}>{i18n.signUp.wrongEmail}</span>)}
											</Typography>

											<Typography className="signup-links-span" sx={{color: "#7A7985", paddingTop: "20px"}} >
												{regProcessRem ? i18n.signUp.regRemVerifyMessage :  i18n.signUp.verifyMessage}</Typography>

											<Typography className="signup-links-span" sx={{color: "#000000", paddingTop: "20px"}} >
											{regProcessRem ? i18n.signUp.didntReceive : i18n.signUp.didntReceiveEmail}</Typography>

											<Button className="submit-button" sx={{backgroundColor: "#FF702A", mt: "20px",  transition: "background-color 0.3s ease",'&:hover': { backgroundColor: "#EE702B"} }} 
											disabled={!isChecked}
											onClick={() => signupResend()}>
												{regProcessRem ? i18n.signUp.resend : i18n.signUp.resendMail}
											</Button>
										</Grid>
										
									</Grid>

								</CardContent>) : (
								<CardContent sx={{ padding: 0, overflow:'visible'}}>
										
									<FormControl sx={{padding: 0}}>
										<Grid
											container={true}
											columns={12}
											rowSpacing={1}
											className="signupform"
											sx={{ maxWidth: "24.5rem"}}
										>

											<Grid item={true}>
												<div style={{ display: "flex", alignItems: "center" }}>
													<Box mb={1} mr={1}>
														{error || invalidCodeErr || fieldRequired|| codeAlreadyUsed || checkRequired ? (
															<img
																src={ErrorMark}
																alt="error-mark"
																height="32px"
																style={{ verticalAlign: "middle" }}
															/>
														) : (
															""
														)}
													</Box>
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textAlign: "start",
															color: "#FD4B24",
															display:"block",
														}}
													>
														<div>
														{fieldRequired ? i18n.signUp.invitedata.fieldRequired : ""}
														{error ? i18n.signUp.accountEmailExists : ""}
														{invalidCodeErr ? i18n.signUp.invitedata.invalidCode : ""}
														{codeAlreadyUsed ? i18n.signUp.invitedata.codeAlreadyUsed : ""}
														</div>
														{ checkRequired ? "Privacy Policy consent is required!" : ""}

													</Typography>													
												</div>
												<div style={{ display: "flex", alignItems: "center" }}>
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textAlign: "start",
															color: "#FD4B24",
														}}
													>
														{error ? (
															<a
																href="/#/login"
																style={{
																	color: "#0A6BFE",
																	margin: "0 5px",
																}}
															>
																{i18n.login.title}
															</a>
														) : (
															""
														)}
													</Typography>
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textAlign: "start",
															color: "#FD4B24",
														}}
													>
														{" "}
														{error ? i18n.signUp.or : ""}
													</Typography>
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															textAlign: "start",
															color: "#FD4B24",
														}}
													>
														{error ? (
															<a
																href="#/forgotPassword"
																style={{
																	color: "#0A6BFE",
																	margin: "0 5px",
																}}
															>
																{i18n.signUp.resetPassword}
															</a>
														) : (
															""
														)}
													</Typography>
												</div>
											</Grid>

											<Grid item={true} xs={12}  sx={{pt:" 0 !important",}}>
												<Typography
													variant="subtitle1"
													className="MuiMenuItem-root"
													sx={{ color: "customColor.label", mb: "10px" }}
												>
													{i18n.login.emailFieldName}
												</Typography>
												<TextField
													type="email"
													fullWidth
													inputProps={{
														style: {
															borderColor: "customColor.gray1",
															background: "#F8F8F8",
															color: "customColor.black",
															height:23,
														},
													}}
													error={
														error ||
														(invalidEmailErr && !validator.isEmail(email))
													}
													value={email}
													onChange={handleEmailChange}
													variant="outlined"
													sx={{ mb: "15px", width: "350px" }}
													// placeholder="Enter email address"
													onKeyPress={(e) => {
															if (e.key === 'Enter' &&  email !== "" && !isChecked) {
																	// InvitationCodeCheck();
																	signupConfirmation();
															}
													}}
												/>
											</Grid>

											<Grid item={true} xs={12} mt={-2} mb={1}>
												{invalidEmailErr && !validator.isEmail(email) && (
													<Typography
														className="typography-field-custom-font-size"
														sx={{
															color: "error.main",
															textAlign: "right",
														}}
													>
														{i18n.signUp.invalidEmailMsg}
													</Typography>
												)}
											</Grid>
											<Grid
												item={true}
												xs={12}
												style={{
													paddingLeft: 0,
												}}
											>
												<Button
													type="submit"
													className={`submit-button`}
													sx={{
														background: (!isChecked) ?  "#888888" :
															"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)" ,
														height: "48px",
														color: "#FFFFFF",
														fontSize: "1.125rem",
														fontWeight: "500",
														lineHeight: "1.125rem",
														fontFamily: "Lexend",
														borderRadius: "24px",
														width: "350px"
													}}
													size="large"
													disabled={!isChecked}
													// disabled={!isEnabled}
													endIcon={<img src={RightAarrow} alt="Right Arrow" />}
													fullWidth
													variant="contained"
													onClick={() => signupConfirmation()}
												>
													{isLoading?'Signing up':i18n.signUp.invitedata.signupbtn}
												</Button>
											</Grid>
										</Grid>
										<Grid item={true} xs={12} className="center-align"  sx={{ mt: "1rem", ml: "10px" }}>
                                                    <Checkbox color="primary" checked={isChecked}
      													onChange={handleCheckboxChange}/>
                                                    <Typography
													sx={{
														fontFamily: "Lexend",
														fontSize: "14px",
														fontWeight: 500,
														lineHeight: "19px",
														letterSpacing: "0em",
														textAlign: "left",
														color: "#888888",
														maxWidth:'385px',

														width: "350px",
													}}
>
													By signing up, I agree to Lutefish’s{" "}
													<MuiLink sx={{ color: "#0A6BFE", cursor: "pointer" }} onClick={handleTermsClick}>
														Terms of Service
													</MuiLink>{" "}
														and{" "}
													<MuiLink sx={{ color: "#0A6BFE", cursor: "pointer" }} onClick={handlePolicyClick}>
														Privacy Policy
													</MuiLink>
													.
												</Typography>
											</Grid>


											{/* Hiding for invite-only system */}
										        {/* <Grid item={true} xs={12}>
													<Box display="flex" justifyContent="center" className="signup-or">
														OR
													</Box>
												</Grid> */}
												{/* <Grid item={true} xs={12} className="center-align" mb={3}>
													<GoogleSignupButton
														updateAccountDisable={updateAccountDisable}
														updateAccountBanned={updateAccountBanned}
														updateAccountTerminated={updateAccountTerminated}
														updateAlreadyLoggedIn={updateAlreadyLoggedIn}
													/>
												</Grid> */}
												{/* <Grid item={true} xs={12} className="center-align"  sx={{margin:'0 auto',}}>													
														<FacebookSignupButton
															updateAccountDisable={updateAccountDisable}
															updateAccountBanned={updateAccountBanned}
															updateAccountTerminated={updateAccountTerminated}
															updateAlreadyLoggedIn={updateAlreadyLoggedIn}
														/>													
												</Grid> */}
									</FormControl>

									{/* <Grid>
										<Typography className="signup-or">OR</Typography>
									</Grid>

									<Grid className="signupform" sx={{gap: "20px"}}>
										<Button variant="outlined" className="signup-google-apple-btn">
											<img
												src={googleIcon}
												alt="google"
												height="24px"
												style={{ verticalAlign: "middle", marginRight: "10px" }}
											/>
											{i18n.signUp.signupGoogle}
										</Button>

										<Button variant="outlined" className="signup-google-apple-btn">
											<img
												src={facebookIcon}
												alt="google"
												height="24px"
												style={{ verticalAlign: "middle", marginRight: "10px" }}
											/>
											{i18n.signUp.signupFacebook}
										</Button>

										<Button variant="outlined" className="signup-google-apple-btn">
											<img
												src={appleIcon}
												alt="google"
												height="24px"
												style={{ verticalAlign: "middle", marginRight: "10px" }}
											/>
											{i18n.signUp.signupApple}
										</Button>
									</Grid> */}

								</CardContent>)}
						</Card>
					</Grid>
				</Grid>
			</Grid>
			
		</Grid>
		// </form>
	);
};

export default SignUpEmail;
