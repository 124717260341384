import React from 'react';
import ReactDOM from 'react-dom/client';
import IndexRouter from './IndexRouter';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './store';

//iframe-busting logic after the imports
if (window.top && window.top !== window.self) {
  window.top.location.href = window.self.location.href;
}

const root = ReactDOM.createRoot(document.getElementById('root')as HTMLElement);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <IndexRouter />
    </Provider>
  // </React.StrictMode>
);
