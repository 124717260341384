import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function SkeletonArtists() {
    return (
        <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <div style={{ display: "flex", gap: "10px" }}>
                <Skeleton animation="wave" variant="circular" width={50} height={50} sx={{ marginTop: 1 }} />
                {/* For variant="text", adjust the height via font-size */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '2rem', marginBottom: 1 }} />
                    <Skeleton animation="wave" variant="rectangular" width={200} height={60} />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginLeft: "31px", marginTop: "30px" }}>
                <Skeleton animation="wave" variant="rounded" width={180} height={40} />
            </div>
        </Stack>
    );
}