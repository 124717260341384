import { Checkbox, ListItem, ListItemText, Typography } from "@mui/material"

const MusicList = ({ filteredItems, handleCheckboxChange, selectedItems }: any) => {
    return (
        <>
            {filteredItems && filteredItems.length > 0 ?
                filteredItems.map((music: any) => (
                    <ListItem key={music.id} button>
                        <Checkbox
                            key={music.id}
                            // checked={selectedItems.indexOf(music.id) !== -1}
                            checked={selectedItems.some((selected: any) => selected.id === music.id) && true}
                            onChange={() => handleCheckboxChange(music)}
                        />
                        <ListItemText primary={music.fileName} />
                    </ListItem>
                ))
                :
                <Typography sx={{ textAlignLast: 'center' }}>
                    No records available
                </Typography>
            }
        </>
    )
}
export default MusicList  