import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	Typography,
	Box,
	Grid,
} from "@mui/material";
import i18n from "../l10n/strings.json";
import { useUser } from "../context/UserContext";
import ErrorAlert from "../components/Error/ErrorAlert";
import ErrorAlertDisplay from "../components/Error/ErrorAlertDisplay";
import { Link, useNavigate } from "react-router-dom";

const EditTitle = (props: any) => {
	const [title, setTitle] = useState(props.title);
	const [dirtyTitle, setDirtyTitle] = useState(false);
	const user = useUser();
	const token = localStorage.getItem("sessionId");

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [originalTitle, setOriginalTitle] = useState(props.title);
	const navigate = useNavigate();

	useEffect(() => {
		setOriginalTitle(title);
	}, [props.title]);
	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleClose = () => {
		setTitle(originalTitle);
		props.handleClose(false);
	};

	const handleUpdateTitle = (event: any) => {
		// if (title.length < 100) {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-title`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					user: {
						id: user.id,
					},
					profileTitle: title,
				}),
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					props.setTitle(title);
					props.handleClose(false);
					return res.json();
				}
			})
			.then((result) => {
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
		// } else {
		// 	setShowAlert(true);
		// 	setAlertStatus(false);
		// 	setAlertMsg("Maximum 100 characters allowed");
		// }
	};
	return (
		<>
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="sm"
				open={props.titleOpen}
			>
				<ErrorAlertDisplay
					alertMsg={alertMsg}
					alertStatus={alertStatus}
					showAlert={showAlert}
					handleAlertClose={handleAlertClose}
				/>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography variant="h6">Edit Title</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid xs={4}>
							<FormHelperText>{i18n.socialProfile.titleText}</FormHelperText>
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<TextField
								id="title"
								className="social-textField"
								value={title}
								onChange={(e: any) => setTitle(e.target.value)}
								onFocus={() => setDirtyTitle(true)}
								error={dirtyTitle && title?.length >= 100}
								variant="outlined"
								// sx={{'& .MuiFormControl-root MuiTextField-root social-textField css-1vfuid6':{
								//     width:'-webkit-fill-available'
								// }}}
								required
							/>
							{dirtyTitle && (
								<FormHelperText sx={{ color: "#FD4B24", textAlign: 'right', mr: 0 }}>
									{title?.length >= 100 ? "Maximum 100 characters allowed" : ""}
								</FormHelperText>
							)}
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							flexWrap: "wrap",
							pt: 1,
							flexDirection: "row-reverse",
						}}
					>
						<Button
							sx={{ borderRadius: "17px" }}
							size="small"
							variant="outlined"
							onClick={handleClose}
						>
							Cancel
						</Button>
						<Button
							size="small"
							variant="contained"
							sx={{ borderRadius: "17px" }}
							onClick={handleUpdateTitle}
							disabled={title?.length > 100}
						>
							{" "}
							Save
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditTitle;
