import React from "react";

export default function LiveStreamingIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM14.79 16.6C14.64 16.79 14.42 16.89 14.2 16.89C14.04 16.89 13.88 16.84 13.74 16.73C12.68 15.91 11.31 15.91 10.25 16.73C9.92 16.98 9.45 16.92 9.2 16.6C8.95 16.27 9.01 15.8 9.33 15.55C10.92 14.32 13.06 14.32 14.65 15.55C14.99 15.8 15.05 16.27 14.79 16.6ZM17 13.51C16.85 13.7 16.63 13.8 16.41 13.8C16.25 13.8 16.09 13.75 15.95 13.64C13.55 11.79 10.46 11.79 8.06 13.64C7.73 13.9 7.26 13.84 7 13.51C6.74 13.18 6.81 12.71 7.13 12.46C10.08 10.18 13.9 10.18 16.85 12.46C17.19 12.71 17.25 13.18 17 13.51ZM18.59 10.42C18.44 10.61 18.22 10.71 18 10.71C17.84 10.71 17.68 10.66 17.54 10.55C14.17 7.95 9.82 7.95 6.46 10.55C6.13 10.8 5.66 10.74 5.41 10.42C5.16 10.09 5.22 9.62 5.54 9.37C9.46 6.34 14.53 6.34 18.46 9.37C18.79 9.62 18.85 10.09 18.59 10.42Z"
				fill="#259A12"
			/>
		</svg>
	);
}
