import { useEffect, useState } from "react";
import { Button, Card, CardActions, CardContent, Divider, Grid, List, ListItem, ListItemText, Typography, Link as MuiLink, } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useUser } from "../context/UserContext";
import { useAlertStatus } from "../context/AlertStatusContext";
import HelpAndTips from "./HelpAndTips";
import VerifyAccount from "../dialogBox/VerifyAccount";
import i18n from "../l10n/strings.json";
import DeviceActions from "../components/AdminDashboard/ManageUserDashBoard/DeviceActions";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";


const AccountListItemText = styled(ListItemText)(
	{
		"& .MuiListItemText-primary": {

			fontStyle: "normal",
			fontSize: "1rem",
			fontWeight: 400,
			color: "#7A7985"
		},
		"& .MuiListItemText-secondary": {

			fontStyle: "normal",
			fontSize: "1rem",
			fontWeight: 900,
			color: "#000000"
		}
	});

function SecuritySettingsPage() {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const { updateAlertStatus } = useAlertStatus();

	const [verification, setVerfication] = useState(false);
	const [open, setOpen] = useState(false);
	const termURL ="https://lutefish.com/policies/terms-of-service";
	const policyURL ="https://lutefish.com/policies/privacy-policy"

	const handleTermsClick = () => {
		window.open(termURL, '_blank');
	}

	const handlePolicyClick = () => {
		window.open(policyURL, '_blank');
	}


	useEffect(() => {
		const fetchUserInfo = () => {
			fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/id`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id:user.id
				}),
			})
				.then((response) => {
					if (response.status > 400) {
						throw new Error();
					} else {
						return response.json();
					}
				})
				.then((result) => {
					if (result !== null) {
						setVerfication(result.multifactorAuthEnabled);
					}
				});
		};

		fetchUserInfo();
	}, []);

	const handleDialogClose = (success: boolean) => {
		if (success) {
			updateAlertStatus(true, true, verification ? i18n.settings.security.turnedOffSuccess : i18n.settings.security.turnedOnSuccess);
			setVerfication((prevVerification) => !prevVerification);
		}
		setOpen(false);
	};

	return (
		<>
	<Grid container>
				<Grid item={true} xs={9} className="centerPanelWidth">
					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;",
						}}
					>
						<Grid container={true} xs={12}>
							{verification ? (
								<Grid item={true} xs={12}>
									<CardContent sx={{ padding: "10px 20px 0px 20px" }}>
										<Grid container={true} xs={12}>
											<Grid item={true} xs={12}>
												<Typography
													className="card-title-custom-size"
													sx={{
														// fontSize: 24,
														display: "flex",
														alignItems: "center",
														marginBottom: "10px",

														fontWeight: "500",
													}}
													color="#000000"
												>
													2-Step Verification
												</Typography>
											</Grid>
											<Grid item={true} xs={12}>
												<Grid
													container={true}
													xs={12}
													sx={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}
												>
													<Grid item={true} xs={8}>
														<Typography
															className="security-content-custom-size"
															sx={{

																fontWeight: "500",
																// fontSize: "1.125rem",
																color: "#707081",
																display: "flex",
																alignItems: "center",
															}}
														>
															2-Step Verification is
															<Typography
																style={{

																	fontWeight: "800",
																	// fontSize: "1.125rem",
																	color: "#259A12",
																	marginLeft: "2%",
																}}
															>
																ON
															</Typography>
														</Typography>
													</Grid>
													<Grid item={true} xs={4}>
														<CardActions
															sx={{
																display: "flex",
																flexDirection: "row",
																justifyContent: "flex-end",
															}}
														>
															<Button
																className="submit-button button-field-custom-font-size"
																size="large"
																variant="contained"
																disabled={user.userAccountStatus === "SUSPEND"}
																onClick={() => setOpen(true)}
															>
																Turn OFF
															</Button>
														</CardActions>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid>
											<List>
												<Divider variant="fullWidth" component="li" />
												<Grid container={true} xs={12}>
													<Grid item={true} xs={12}>
														<Typography
															className="card-title-custom-size"
															sx={{
																// fontSize: 24,
																display: "flex",
																alignItems: "center",
																margin: "10px 0",

																fontWeight: "500",
															}}
															color="#000000"
														>
															Security verification method
														</Typography>
													</Grid>
													<Grid item={true} xs={12}>
														<Typography
															className="security-content-custom-size"
															sx={{

																fontWeight: "500",
																// fontSize: "1.125rem",
																color: "#707081",
															}}
														>
															We will send you 6 digit verification code to your
															registered email below
														</Typography>
													</Grid>
													<Grid item={true} xs={12}>
														<ListItem sx={{ padding: "10px 0" }}>
															{/* <IconButton color="danger" onClick={() => (onRemoveRoom(itemId))}><Svg src="actions/removeCircle.svg" /></IconButton> */}

															<AccountListItemText
																primary="Email"
																primaryTypographyProps={{
																	sx: {
																		color: "#7A7985",
																	},
																}}
																secondary={user.email}
															/>
														</ListItem>
													</Grid>
												</Grid>
												{/* <ChangePassword changePasswordOpen={changePasswordOpen} handleClose={handleDialogClose} /> */}
											</List>
										</Grid>
									</CardContent>
								</Grid>
							) : (
								<Grid item={true} xs={12}>
									<CardContent sx={{ padding: "30px" }}>
										<Grid container={true} xs={12}>
											<Grid item={true} xs={12}>
												<Typography
													sx={{
														fontSize: 24,
														display: "flex",
														alignItems: "center",
														marginBottom: "15px",

														fontWeight: "500",
													}}
													color="#000000"
												>
													2-Step Verification
												</Typography>
											</Grid>
											<Grid item={true} xs={12}>
												<Grid
													container={true}
													xs={12}
													sx={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}

												>
													<Grid item={true} xs={8}>
														<Typography
															sx={{

																fontWeight: "500",
																fontSize: "1.125rem",
																color: "#707081",
																display: "flex",
																alignItems: "center",
															}}
														>
															2-Step Verification is
															<Typography
																style={{

																	fontWeight: "800",
																	fontSize: "1.125rem",
																	color: "#FD4B24",
																	marginLeft: "2%",
																}}
															>
																OFF
															</Typography>
														</Typography>
													</Grid>
													<Grid item={true} xs={4}>
														<CardActions
															sx={{
																display: "flex",
																flexDirection: "row",
																justifyContent: "flex-end",
															}}
														>
															<Button
																className="submit-button button-field-custom-font-size"
																size="large"
																variant="contained"
																disabled={user.userAccountStatus === "SUSPEND"}
																onClick={() => setOpen(true)}
															>
																Turn ON
															</Button>
														</CardActions>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</CardContent>
								</Grid>
							)}
						</Grid>
						<VerifyAccount open={open} handleClose={handleDialogClose} />
					</Card>
					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;", mt:3,
						}}
					>
						<Grid container={true} xs={12}>
						<CardContent sx={{ padding: "10px 20px 0px 20px" }}>
						<Typography
													sx={{
														// fontSize: 24,
														display: "flex",
														alignItems: "center",
														marginBottom: "10px",
														
														fontWeight: "500",
													}}
													color="#000000"
												>
													Lutefish’s &nbsp;
													<MuiLink sx={{ color: "#0A6BFE", cursor: "pointer" }} onClick={handleTermsClick}>
														Terms of Service
													</MuiLink>&nbsp;
														and &nbsp;
													<MuiLink sx={{ color: "#0A6BFE", cursor: "pointer" }} onClick={handlePolicyClick}>
														Privacy Policy
													</MuiLink>
													.
												</Typography>
						
													</CardContent>
							</Grid>
							</Card>
				</Grid>
				{/* </div> */}
				{/* </div> */}
				{/* <div style={{ width: "25%", marginTop: "70px" }}> */}
				{/* <Grid item={true} xs={3} className="rightPanelWidth"> */}
					{/* <HelpAndTips
						title={i18n.settings.security.instructions.helpAndTips.title}
						text={i18n.settings.security.instructions.helpAndTips.text}
						sx={{ boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" }}
					/> */}
					{/* <MyActivitiesSidePanel /> */}
					{/* </div> */}
				{/* </Grid> */}
				</Grid>
		</>
	);
}

export default SecuritySettingsPage;
