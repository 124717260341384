import * as React from "react";
import { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Select,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "../../../images/edit.svg";
import ActionDialog from "../../reusable-components/ActionDialog";
import { Button, FormHelperText, Typography } from "@mui/material";
import i18n from "../../../l10n/strings.json";
import { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import useFetch from "../../../hooks/useFetch.tsx/useFetch";
import { useAdminContext } from "../ManageUserDashBoard/UserDetailsProvider";
import { userRoleMenu } from "./AdminUserActionTb";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import { useNavigate } from "react-router-dom";
import useAdminAuthorized from "../../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import saveAs from "file-saver";
import { useUser } from "../../../context/UserContext";

interface CustomTypographyProps extends TypographyProps {
	variant?: "body1" | "body2";
	component?: "h6" | "p";
}

export const formatUserStatus = (userStatus: string) => {
	switch (userStatus) {
		case "ROLE_ADMIN":
			return "Admin User";
		case "ROLE_SUPER_ADMIN":
			return "Super User";
		case "ROLE_ADMIN_CONTENT_MODERATOR":
			return "Content Moderator";
		case "ROLE_ADMIN_VIEWER":
			return "Admin Viewer";
		default:
			return "Active User";
	}
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		roleBtnText: {
			color: "#0273E6",
			fontSize: "16px",
			fontWeight: "500",
		},
		resetSubHeading: {
			fontSize: "16px",
			color: "#5B5B65",
			fontWeight: "400",
		},
	})
);

const HeadingTypo: FC<CustomTypographyProps> = styled(
	Typography
)<CustomTypographyProps>({
	color: "#000",
	fontSize: "1rem",
	fontWeight: "500",
});

export default function AdminRow({ row, isItemSelected, labelId ,handleCheckBoxClick }:any) {
	const classes = useStyles();
	const navigate = useNavigate();
	const [openMainDialog, setOpenMainDialog] = useState(false);
	const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
	const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
	const [openChangeUserRoleDialog, setOpenChangeUserRoleDialog] =
		useState(false);
	const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const token = localStorage.getItem("sessionId");
	const [selectedRoleType, setSelectedRoleType] = useState<any>("");
	const { updateAlertStatus } = useAlertStatus();
	const isSuperAdmin: boolean = useAdminAuthorized("isSuperAdmin");
	const isAdminUserManagementAdmins: boolean = useAdminAuthorized("isAdminUserManagementAdmins");
    const loggedInUser = useUser();
	const {
		setIsEditing,
		setSelectedRow,
		selectedUserEmails,
		setSelectedUserEmails,
		getAllAdminUsers,
		selectedButton,
		setadminScreenShow,
		setAdminEmail,
		setSelectedUsername,
	} = useAdminContext();

	const {
		data: userDeleteData,
		loading: isDeleteOrChangeRoleFetching,
		error: userDeleteError,
		triggerFetch: triggerUserDeleteFetch,
	} = useFetch<{ errorKey: string; message: string }>();

	const {
		data: activityLogData,
		loading: activityLogLoading,
		error: activityLogError,
		triggerFetch: triggerActivityLogFetch,
	} = useFetch<any>();

	const {
		data: resetPasswordData,
		loading: resetPasswordLoading,
		error: resetPasswordError,
		triggerFetch: triggerResetFetch,
	} = useFetch();

	const[activitiesLogData, setActivitiesLogData] = useState<any>();
	const [response, setResponse] = useState<any>();
	useEffect(() => {
		if (userDeleteData || userDeleteError) {
			if (userDeleteData) {
				if (userDeleteData.errorKey === "success") {
					updateAlertStatus(
						true,
						true,
						userDeleteData?.message || "User deleted successfully"
					);
					getAllAdminUsers();
					handleCloseDeleteUserDialog();
				}
			} else if (userDeleteError) {
				updateAlertStatus(true, false, "Internal server error..");
			}
		}
	}, [userDeleteData, userDeleteError]);

	useEffect(() => {
		
		
		if (openMainDialog) {
			const requestOptions = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ email: row.email }),
				fetchOnMount: false,
			};
			triggerActivityLogFetch(
				`${process.env.REACT_APP_BASE_URL}/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=1200&action=&searchText=&page=0&size=10`,
				requestOptions
			);
		}
	}, [openMainDialog]);

	const viewActivityLogs = async () => {
		const token = localStorage.getItem("sessionId");

		let endpoint = `/api/activityLogs/user?byAdminUser=true&ofUser=true&duration=3&action=&searchText=&page=0&size=10`;
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}${endpoint}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ email: row.email }),
				}
			);
			if (!response.ok) {
				throw new Error("Failed to fetch data");
			}
			const data = await response.json();
			console.log("jsdjfsadjf;ksaf;lkdsjf;kjdsaf  " +  data)
			setActivitiesLogData(data);
		} catch (error) {
			console.error(`Error fetching data:`, error);
		}
	};


	const handleOpenMainDialog = () => {
		viewActivityLogs();
		setOpenMainDialog(true);
	};

	const handleCloseMainDialog = () => {
		setOpenMainDialog(false);
	};

	const handleOpenResetPasswordDialog = () => {
		setOpenMainDialog(false);
		setOpenResetPasswordDialog(true);
	};

	const handleOpenEditUserDialog = () => {
		setOpenMainDialog(false);
		setOpenEditUserDialog(true);
	};

	const handleCloseResetPasswordDialog = () => {
		setOpenResetPasswordDialog(false);
	};

	const handleOpenDeleteUserDialog = () => {
		setOpenDeleteUserDialog(true);
	};
	const handleCloseDeleteUserDialog = () => {
		setOpenDeleteUserDialog(false);
	};

	const handleOpenChangeUserRoleDialog = () => {
		setOpenChangeUserRoleDialog(true);
	};
	const handleCloseChangeUserRoleDialog = () => {
		setOpenChangeUserRoleDialog(false);
	};

	const handleCloseEditUserDialog = () => {
		setOpenEditUserDialog(false);
	};

	const handleAdminActions = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseAnchor = () => {
		setAnchorEl(null);
	};

	const onAdminAction = (action: string) => {
		action === "DELETE"
			? handleOpenDeleteUserDialog()
			: handleOpenChangeUserRoleDialog();

		setAnchorEl(null);
	};

	const handleDeleteUser = (email: string) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			fetchOnMount: false,
			body: JSON.stringify({ email }),
		};
		triggerUserDeleteFetch(
			`${process.env.REACT_APP_BASE_URL}/api/admin/action-admin-user?action=DeleteAdminUser`,
			requestOptions,
			(message) => {
				updateAlertStatus(true, true, "Admin user deleted successfully");
			},
			(message) => {
				updateAlertStatus(true, false, "Internal server error..");
			}
		);
	};
	const handleChangeUserRoleType = (userData: any) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			fetchOnMount: false,
			body: JSON.stringify(userData),
		};
		triggerUserDeleteFetch(
			`${process.env.REACT_APP_BASE_URL}/api/admin/action-admin-user?action=EditAdminUser`,
			requestOptions
		);
	};

	const handleEditUser = () => {
		setOpenMainDialog(false);
		setIsEditing(true);
		setSelectedRow(row);
	};

	// const handleCheckBoxClick = (event: any, row: any) => {
	// 	const selectedIndex = selectedUserEmails.findIndex(
	// 		(selectedRow: any) => selectedRow.email === row.email
	// 	);
	// 	let newSelected = [];

	// 	if (selectedIndex === -1) {
	// 		newSelected = [...selectedUserEmails, row];
	// 	} else {
	// 		newSelected = [
	// 			...selectedUserEmails.slice(0, selectedIndex),
	// 			...selectedUserEmails.slice(selectedIndex + 1),
	// 		];
	// 	}
	// 	setSelectedUserEmails(newSelected);
	// };
	const handleResetPassword = () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			fetchOnMount: false,
			body: JSON.stringify({ email: row.email }),
		};
		triggerResetFetch(
			`${process.env.REACT_APP_BASE_URL}/api/account/reset-password/init`,
			requestOptions,
			(message) => {
				updateAlertStatus(true, true, "Email sent successfully to your email");
				handleCloseResetPasswordDialog();
			},
			(message) => {
				updateAlertStatus(true, false, "Failed to sent email!");
			}
		);
	};

	const formatDate = (dateString: string): string => {
		const date = new Date(dateString);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		return `${year}/${month}/${day} - ${hours}:${minutes}`;
	};

	type DataEntry = [string, string];
	type CsvRow = { Date: string; Activity: string };

	// const convertToCSV = (data: DataEntry[]): string => {
	// 	if (!data.length) return "";

	// 	// Define the new headers
	// 	const headers = ["Date", "Activity"] as const;

	// 	// Convert data to only include the necessary fields
	// 	const modifiedData: CsvRow[] = data.map(([date, activity]) => ({
	// 		Date: date,
	// 		Activity: activity,
	// 	}));

	// 	// Generate CSV rows
	// 	const csvRows = [headers.join(",")]; // Add the headers as the first row
	// 	for (const row of modifiedData) {
	// 		const values = headers.map((header) => {
	// 			const escaped = ("" + row[header]).replace(/"/g, '\\"');
	// 			return `"${escaped}"`;
	// 		});
	// 		csvRows.push(values.join(","));
	// 	}

	// 	return csvRows.join("\n");
	// };

	// const handleExport = (selectedRows: DataEntry[]) => {
	// 	if (selectedRows.length > 0) {
	// 		const csvData = convertToCSV(selectedRows);
	// 		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
	// 		const url = URL.createObjectURL(blob);

	// 		const link = document.createElement("a");
	// 		link.href = url;
	// 		link.setAttribute("download", "data.csv");
	// 		document.body.appendChild(link);
	// 		link.click();
	// 		document.body.removeChild(link);
	// 	} else {
	// 		return;
	// 	}
	// };

	const convertToCSV = (data: any) => {
		const csvRows = data.map((row: any) => {
			const csvRow = row
				.map((cell: any) => `"${cell.replace(/"/g, '""')}"`)
				.join(",");
			return csvRow;
		});
		return csvRows.join("\n");
	};

	const prepareDataForExport = (data: any[]) => {
		if (!data || !Array.isArray(data)) {
			return [];
		}

		return data.map((item) => [item.date || "", item.action || ""]);
	};

	const handleExport = () => {
		if (!activitiesLogData || !Array.isArray(activitiesLogData) || activitiesLogData.length === 0) {
			console.error("No data to export.");
			return;
		}
		const formattedData = prepareDataForExport(activitiesLogData);
		const csvData = convertToCSV(formattedData);
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
		saveAs(blob, "exported_data.csv");
	}

	const navigateToActivities = () => {
		setAdminEmail(row.email);
		setSelectedUsername(row.name);
		setadminScreenShow(true);
	};


	return (
		<>
			<TableRow
				hover
				role="checkbox"
				aria-checked={isItemSelected}
				tabIndex={-1}
				key={row.id}
				selected={isItemSelected}
			>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						checked={
							selectedUserEmails.some(
								(selectedRow: any) => selectedRow.email === row.email
							) || isItemSelected
						}
						onClick={(event) => handleCheckBoxClick(event, row)}
						inputProps={{
							"aria-labelledby": labelId,
						}}
					/>
				</TableCell>
				<TableCell
					component="th"
					id={labelId}
					scope="row"
					padding="none"
					sx={{ maxWidth: "100px", paddingLeft: "8px", fontSize: "16px !important" }}
					align="left"
				>
					{row.name}
				</TableCell>
				<TableCell
					sx={{ maxWidth: "230px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingLeft: "8px", fontSize: "16px !important" }}
					padding="none"
					scope="row"
					align="left"
				>
					{row.email}
				</TableCell>
				<TableCell
					sx={{maxWidth: "100px", paddingLeft: "8px", fontSize: "16px !important" }}
					padding="none"
					align="left"
				>
					{formatUserStatus(row.adminRoleType)}
				</TableCell>
				<TableCell
					sx={{maxWidth: "100px", paddingLeft: "8px", fontSize: "16px !important" }}
					padding="none"
					align="left"
				>
					{row.createdDate}
				</TableCell>
				<TableCell
					sx={{ p: 0, fontSize: "16px !important" }}
					padding="none"
					align="right"
				>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "left",
						}}
					>
						<div
							style={{
								opacity:
									selectedUserEmails?.length > 0 || selectedButton === "deleted"
										? 0.5
										: 1,
								cursor:
									selectedUserEmails?.length > 0 || selectedButton === "deleted"
										? "not-allowed"
										: "pointer",
							}}
						>
							<IconButton
								aria-label="more"
								id="long-button"
								aria-haspopup="true"
								onClick={handleOpenMainDialog}
								style={{
									pointerEvents:
										selectedUserEmails?.length > 0 ||
										selectedButton === "deleted"
											? "none"
											: "auto",
								}}
							>
								<img src={EditIcon} alt="edit-icon" />
							</IconButton>
						</div>

						<div
							style={{
								height: "28px",
								width: "1px",
								backgroundColor: "#C2C2C3",
								marginLeft: "6px",
							}}
						></div>

						<div
							style={{
								opacity:
									selectedUserEmails?.length > 0 ||
									!isSuperAdmin ||
									selectedButton === "deleted"
										? 0.5
										: 1,

								cursor:
									selectedUserEmails?.length > 0 ||
									!isSuperAdmin ||
									selectedButton === "deleted"
										? "not-allowed"
										: "pointer",
							}}
						>
							<IconButton
								aria-label="more"
								id="long-button"
								aria-haspopup="true"
								onClick={(e) => handleAdminActions(e)}
								style={{
									pointerEvents:
										selectedUserEmails?.length > 0 ||
										!isSuperAdmin ||
										selectedButton === "deleted"
											? "none"
											: "auto",
								}}
							>
								<MoreVertIcon />
							</IconButton>
						</div>
					</div>
				</TableCell>
			</TableRow>

			<ActionDialog
				open={openMainDialog}
				handleClose={handleCloseMainDialog}
				title="View Admin User"
				titleStyle={{
					color: "#5B5B65",
					fontSize: "1.5rem",
					fontWeight: "500",
				}}
				titleAlignment="left"
				actionButtons={
					<>
						<Button
							sx={{ width: "12.5rem", height: "3rem", borderRadius: "24px" }}
							className="submit-button"
							size="large"
							variant="contained"
							onClick={handleEditUser}
							disabled={!isSuperAdmin}
						>
							Edit User
						</Button>
						<Button
							sx={{ width: "12.5rem", height: "3rem", borderRadius: "24px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleCloseMainDialog}
						>
							Cancel
						</Button>
					</>
				}
			>
				<FormHelperText sx={{ marginLeft: "0 !important" }}>
					<Typography variant="body4" color="customColor.label">
						Name
					</Typography>
				</FormHelperText>

				<HeadingTypo variant="body1" component="h6">
					{row.name}
				</HeadingTypo>
				<FormHelperText sx={{ marginLeft: "0 !important" }}>
					<Typography variant="body4" color="customColor.label">
						Email
					</Typography>
				</FormHelperText>

				<HeadingTypo variant="body1" component="h6">
					{row.email}
				</HeadingTypo>

				<Typography
					variant="body1"
					component="p"
					sx={{
						fontSize: "1.125rem",
						fontWeight: "400",
						color: "#5B5B65",
						mt: 5,
						mb: 1,
					}}
				>
					Role Type
				</Typography>

				<HeadingTypo variant="body1" component="h6">
					{formatUserStatus(row.adminRoleType)}
				</HeadingTypo>

				<Grid container rowSpacing={2} sx={{ mt: 1, mb: 1 }}>
					<Grid item xs={12} md={6} lg={6} xl={6}>
						<Button
							variant="outlined"
							className={classes.roleBtnText}
							sx={{ width: "13.5rem", height: "2.25rem", color: "#0A6BFE", borderColor: "#0A6BFE",
								"&:hover": {
									backgroundColor: "rgba(10, 107, 254, 0.1)",
									borderColor: "#0A6BFE"
								  }
							}}
							onClick={navigateToActivities}
						>
							View All Activities
						</Button>
					</Grid>
					<Grid item xs={12} md={6} lg={6} xl={6}>
						<Button
							variant="outlined"
							className={classes.roleBtnText}
							sx={{ width: "13.5rem", height: "2.25rem", color: "#0A6BFE", borderColor: "#0A6BFE",
								"&:hover": {
									backgroundColor: "rgba(10, 107, 254, 0.1)",
									borderColor: "#0A6BFE"
								  }
							}}
							onClick={() => handleExport()}
						>
							Download Activity log
						</Button>
					</Grid>
					<Grid item xs={12} md={6} lg={6} xl={6}>
						<Button
							variant="outlined"
							className={classes.roleBtnText}
							onClick={() => {
								console.log('isAdminUserManagementAdmins:', isAdminUserManagementAdmins);
console.log('row.id:', row.id);
console.log('loggedInUser.id:', loggedInUser.id);

								handleOpenResetPasswordDialog();
							}}
							sx={{ width: "13.5rem", height: "2.25rem",color: "#0A6BFE",borderColor: "#0A6BFE",
								"&:hover": {
									backgroundColor: "rgba(10, 107, 254, 0.1)",
									borderColor: "#0A6BFE"
								  }
							}}
							disabled={!isAdminUserManagementAdmins && row.id !== loggedInUser.id}
							
						>
							Reset Password
						</Button>
					</Grid>
					<Grid item xs={12} md={6} lg={6} xl={6}>
						<Button
							variant="outlined"
							color="primary"
							className={classes.roleBtnText}
							onClick={() => handleOpenDeleteUserDialog()}
							sx={{
								width: "13.5rem",
								height: "2.25rem",
								backgroundColor: !isSuperAdmin ? "#DDDDDD" : "#fff",
							}}
							disabled={!isSuperAdmin}
						>
							<Typography sx={{ color: "#FD4B24" }}>Delete User</Typography>
						</Button>
					</Grid>
				</Grid>

				<Divider color="#000" sx={{ my: 2 }} />

				<Typography
					variant="body2"
					component="h3"
					sx={{ fontSize: "16px", color: "#000", fontWeight: "400", mb: 2 }}
				>
					LAST 10 ACTIVITIES
				</Typography>

				{/* <Typography variant="body1" component="p">
					<span style={{ color: "#000" }}>2024/24/04 - 17:35 </span>
					<span style={{ color: "#5B5B65" }}>Suspended by </span>
					<span style={{ color: "#0A6BFE" }}>Lisa Ray</span>
				</Typography> */}

				{activitiesLogData && activitiesLogData.length > 0 ? (
					activitiesLogData.map((item: any, index: number) => {
						const date = item.date || item[0];
						const message = item.action || item[1];
						const [beforeBy, afterBy] = message.split("by");

						return (
							<Typography key={index} variant="body1" component="p">
							  <span style={{ color: "#000" }}>{formatDate(date)} </span>
							  <span style={{ color: "#5B5B65" }}>{beforeBy?.trim()} By </span>
							  <span style={{ color: "#0A6BFE" }}>{afterBy?.trim()}</span>
							</Typography>
						  );
					})
				) : (
					<Typography sx={{ color: "#FD4B24", fontSize: "14px" }}>
						No past activities found!
					</Typography>
				)}
			</ActionDialog>

			<ActionDialog
				open={openResetPasswordDialog}
				handleClose={handleCloseResetPasswordDialog}
				dialogWidth="420px"
				title="Reset Password"
				titleStyle={{
					color: "#5B5B65",
					fontSize: "1.5rem",
					fontWeight: "500",
				}}
				titleAlignment="center"
				actionButtons={
					<>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="submit-button"
							size="large"
							variant="contained"
							onClick={handleResetPassword}
						>
							Reset
						</Button>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleCloseResetPasswordDialog}
						>
							Cancel
						</Button>
					</>
				}
			>
				<Typography
					variant="body1"
					component="p"
					className={classes.resetSubHeading}
					sx={{ mb: 3 }}
				>
					This will reset password and trigger an email to the user below to
					change password
				</Typography>
				<Typography variant="body1" component="p" sx={{ mb: 6 }}>
					<span
						style={{ fontSize: "1rem", fontWeight: "500", color: "#7A7985" }}
					>
						User :{" "}
					</span>{" "}
					<span
						style={{
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "#4D4C4F",
						}}
					>
						{row.email}
					</span>
				</Typography>
			</ActionDialog>

			<ActionDialog
				open={openDeleteUserDialog}
				handleClose={handleCloseDeleteUserDialog}
				dialogWidth="420px"
				title="Delete User"
				titleStyle={{
					color: "#5B5B65",
					fontSize: "1.5rem",
					fontWeight: "500",
				}}
				titleAlignment="center"
				actionButtons={
					<>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="submit-button"
							disabled={isDeleteOrChangeRoleFetching}
							size="large"
							variant="contained"
							onClick={() => handleDeleteUser(row.email)}
						>
							Delete
						</Button>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleCloseDeleteUserDialog}
						>
							Cancel
						</Button>
					</>
				}
			>
				<Typography
					variant="body1"
					component="p"
					className={classes.resetSubHeading}
					sx={{ mb: 3 }}
				>
					Are you sure you want to delete below user?
				</Typography>
				<Typography variant="body1" component="p" sx={{ mb: 6 }}>
					<span
						style={{ fontSize: "1rem", fontWeight: "500", color: "#7A7985" }}
					>
						User :{" "}
					</span>{" "}
					<span
						style={{
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "#4D4C4F",
						}}
					>
						{row.email}
					</span>
				</Typography>
			</ActionDialog>

			<ActionDialog
				open={openChangeUserRoleDialog}
				handleClose={handleCloseChangeUserRoleDialog}
				dialogWidth="420px"
				title="Change Role"
				titleStyle={{
					color: "#5B5B65",
					fontSize: "1.5rem",
					fontWeight: "500",
				}}
				titleAlignment="center"
				actionButtons={
					<>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="submit-button"
							size="large"
							variant="contained"
							disabled={isDeleteOrChangeRoleFetching}
							onClick={() =>
								handleChangeUserRoleType({
									email: row.email,
									adminRoleType: selectedRoleType,
								})
							}
						>
							Change Role
						</Button>
						<Button
							sx={{
								width: "9.375rem",
								height: "2.25rem",
								borderRadius: "18px",
							}}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleCloseChangeUserRoleDialog}
						>
							Cancel
						</Button>
					</>
				}
			>
				<Typography variant="body1" component="p" sx={{ mb: 1 }}>
					<span
						style={{ fontSize: "1rem", fontWeight: "500", color: "#7A7985" }}
					>
						User :{" "}
					</span>{" "}
					<span
						style={{
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "#4D4C4F",
						}}
					>
						{row.email}
					</span>
				</Typography>
				<Typography variant="body1" component="p" sx={{ mb: 3 }}>
					<span
						style={{ fontSize: "1rem", fontWeight: "500", color: "#7A7985" }}
					>
						Current Role :{" "}
					</span>{" "}
					<span
						style={{
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "#4D4C4F",
						}}
					>
						{formatUserStatus(row.adminRoleType)}
					</span>
				</Typography>

				<FormHelperText sx={{ ml: 0 }}>
					<Typography variant="body4" color="customColor.label">
						New Role
					</Typography>
				</FormHelperText>

				<Select
					labelId="newRole"
					value={selectedRoleType}
					onChange={(event: any) => {
						setSelectedRoleType(event.target.value);
					}}
					sx={{
						backgroundColor: "customColor.gray1",
						color: "customColor.black",
						width: "100%",
						mb: 1,
					}}
				>
					{userRoleMenu.map((menu: any) => {
						return (
							<MenuItem value={menu.value}>
								<span
									style={{
										fontSize: "1rem",
										fontWeight: "400",
										fontFamily: "Lexend",
									}}
								>
									{menu.title}
								</span>
							</MenuItem>
						);
					})}
				</Select>
			</ActionDialog>

			<Menu
				id="admin-user-action"
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseAnchor}
			>
				<MenuItem onClick={() => onAdminAction("DELETE")}>Delete User</MenuItem>

				<MenuItem onClick={() => onAdminAction("CHANGE_ROLE")}>
					Change Role
				</MenuItem>
			</Menu>
		</>
	);
}
