import { useState, useEffect } from "react";
import { Grid, CircularProgress, Box } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import UserCardDetailed from "./UserCardDetailed";
import { musicianCardInterface } from "../../types/types";
import { useMusiciansFilter } from "./MusicProvider/useMusicianFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			// padding: "1% 2%",
			borderRadius: "15px",
			//  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
		instrumentStack: {
			margin: "5% 0",
			"& div": {
				padding: "1%",
				margin: "1% 0",
				backgroudColor: "#FAFAFA",
				borderRadius: "15px",
			},
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);

const MusicianSearchResult = () => {
	const classes = useStyles();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [searchString, setSearchString] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [itemPerPage, setItemPerPage] = useState(10);
	const [searchResult, setSearchResult] = useState<
		Array<musicianCardInterface>
	>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { musicianFilter, isFilterActive, isDistanceActive } =
		useMusiciansFilter();
	const [hasMore, setHasMore] = useState(true);
	const location = useLocation();
	const filterBy = location.pathname.split("/").slice(-1)[0];

	// useEffect(() => {
	// 	resetSearchedResult();
	// }, [musicianFilter]);

	useEffect(() => {
        const handler = setTimeout(() => {
                resetSearchedResult();
        }, 600);
        return () => {
            clearTimeout(handler);
        };
    }, [musicianFilter]);

	const isGlobalSearchTrue = (musicianFilter: any) => {
		if (
			musicianFilter.genres.length > 0 ||
			musicianFilter.cities.length > 0 ||
			musicianFilter.instrument.length > 0 ||
			musicianFilter.isOpenForCollaboration ||
			musicianFilter.acceptGigs
		)
			return false;
		return true;
	};

	const getSearchedResult = () => {

		searchResult.length === 0 && setLoading(true);
		// setSearchResult({recentJoiners: userCardDetails, mostActive: userCardDetails, recommended: userCardDetails});
		try {
			// setSearchResult([]);
			console.log(searchResult);
			let query = "";
			if (filterBy === "musicians") {
				query = `filter-search?searchString=${searchString}`;
			} else if (filterBy === "connections") {
				query = `user-connections/filter?searchString=`;
			} else {
				query = `filter-search?searchString=${searchString}`;
			}
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/${query}&page=${pageNumber}&size=${itemPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						globalSearch: isGlobalSearchTrue(musicianFilter),
						userProfileId: user.userProfileId,
						firstName: musicianFilter.firstName,
						lastName: musicianFilter.lastName,
						nickname: musicianFilter.nickName,
						pageNumber: pageNumber,
						itemPerPage: itemPerPage,
						location: musicianFilter.cities,
						genres: musicianFilter.genres,
						instruments: musicianFilter.instrument,
						openForCollab: musicianFilter.isOpenForCollaboration,
						openForGigs: musicianFilter.acceptGigs,
						filterBy: filterBy,
						distanceFromCity: musicianFilter.distanceFromCity,
						latitude: musicianFilter.latitude,
						longitude: musicianFilter.longitude,
					}),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					// setSearchResult(data);
					// if (data.length > 0) {
					//     setSearchResult([...searchResult, ...data]);
					//     var nextPageNumber = pageNumber + 1;
					//     setPageNumber(nextPageNumber);
					// }
					// if (data.length < itemPerPage || data.length === 0) {
					//     setHasMore(false);
					// }

					if (data.length === 0) {
						setHasMore(false);
					} else {
						if (pageNumber === 0) {
							setSearchResult(data);
						} else {
							setSearchResult((prevData: any) => {
								if (isDistanceActive) {
									return data;
								} else {
									return [...prevData, ...data];
								}
							});
						}
					}

					setPageNumber((prevData: any) => prevData + 1);
					setLoading(false);
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	const resetSearchedResult = () => {
		setSearchResult([]);

		// setSearchResult({recentJoiners: userCardDetails, mostActive: userCardDetails, recommended: userCardDetails});
		try {
			setLoading(true);
			setPageNumber(0);
			setHasMore(true);

			let query = "";
			if (filterBy === "musicians") {
				query = `filter-search?searchString=${searchString}`;
			} else if (filterBy === "connections") {
				query = `user-connections/filter?searchString=`;
			} else {
				query = `filter-search?searchString=${searchString}`;
			}
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/${query}&page=0&size=${itemPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						globalSearch: isGlobalSearchTrue(musicianFilter),
						userProfileId: user.userProfileId,
						firstName: musicianFilter.firstName,
						lastName: musicianFilter.lastName,
						nickname: musicianFilter.nickName,
						pageNumber: pageNumber,
						itemPerPage: itemPerPage,
						location: musicianFilter.cities,
						genres: musicianFilter.genres,
						instruments: musicianFilter.instrument,
						openForCollab: musicianFilter.isOpenForCollaboration,
						openForGigs: musicianFilter.acceptGigs,
						distanceFromCity: musicianFilter.distanceFromCity,
						latitude: musicianFilter.latitude,
						longitude: musicianFilter.longitude,
					}),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					// setSearchResult(data);
					// if (data.length > 0) {
					//     setSearchResult(data);
					//     var nextPageNumber = pageNumber + 1;
					//     setPageNumber(1);
					// }
					// if (data.length < itemPerPage || data.length === 0) {
					//     setHasMore(false);
					// }

					if (data.length === 0) {
						setSearchResult([]);
						setHasMore(false);
						setLoading(false);
					} else {
						setSearchResult(data);
						setPageNumber(1);
					}
				})
				.catch((err) => {
					setHasMore(false);
					setLoading(false);
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};
	const pauseAudioPlayer = (musicId: any) => {
		// Pause the currently playing audio player
		const audioPlayers = document.querySelectorAll("audio");
		if (audioPlayers) {
			audioPlayers.forEach((audioPlayer) => {
				const audioElement = audioPlayer as HTMLAudioElement;
				if (audioPlayer.id == musicId) {
					// //	audioElement.play();
				} else {
					// 	//audioPlayer.pause();
					audioElement.pause();
				}
			});
		}
	};

	return (
		<>
			{loading ? (
				<Grid container item justifyContent="center" sx={{ p: "16px" }}>
					<CircularProgress /> {/* The loading spinner */}
				</Grid>
			) : (
				<Grid xs={12} margin={0.5}>
					<InfiniteScroll
						dataLength={searchResult.length}
						next={getSearchedResult}
						hasMore={hasMore}
						loader={
							<Box className={classes.spinner}>
								{loading && hasMore && <CircularProgress />}
							</Box>
						}
						endMessage={
							<p style={{ textAlign: "center" }}>
								<b>Yay! You have seen it all</b>
							</p>
						}
					>
						{searchResult &&
						(searchResult.length > 0 || searchString.length > 0) ? (
							<Grid
								container
								xs={12}
								className={classes.shadowedBox}
								padding={1}
							>
								{/* <Grid xs={12} padding={1}>
                                        <Grid item={true} xs={3}>
                                            <TextField
                                                hiddenLabel
                                                id="filled-hidden-label-small"
                                                variant="outlined"
                                                placeholder="Search your connections"
                                                size="small"
                                                sx={{width: "130%"}}
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img src={Search} alt="Search" />
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                value={searchString}
                                                onChange={(e) => setSearchString(e.target.value)}
                                                focused={searchString.length > 0 ? true : false}
                                            />
                                        </Grid>
                                    </Grid> */}

								{searchResult && searchResult.length > 0 ? (
									<Grid container xs={12}>
										{searchResult.map((user) => {
											return (
												<Grid item={true} xs={12} marginBottom={"15px"}>
													<UserCardDetailed
														user={user}
														refreshList={resetSearchedResult}
														onItemClick={pauseAudioPlayer}
													/>
												</Grid>
											);
										})}
									</Grid>
								) : (
									<Grid container item justifyContent="center">
										No musicians available yet.
									</Grid>
								)}
							</Grid>
						) : (
							<Grid container item justifyContent="center">
								No connections available yet.
							</Grid>
						)}
					</InfiniteScroll>
				</Grid>
			)}
		</>
	);
};

export default MusicianSearchResult;
