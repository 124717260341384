import { Typography, Divider } from "@mui/material";

import { useEffect, useState } from "react";
import { ReccomendationTemplate } from "./ReccomendationTemplate";
import SkeletonArtists from "./SkeletonArtists";

export const ReccommendationsArtists = ({showSkeleton, similarArtists, getSimilarArtistsRecommendations} : {
	showSkeleton: boolean;
	similarArtists: any[]; 
	getSimilarArtistsRecommendations: () => void;
  } ) => {

	return (
		<>
			{showSkeleton ? (
				<SkeletonArtists />
			) : similarArtists.length > 0 && (
				<>
					{similarArtists.slice(0, 2).map((artist, index) => (
						<ReccomendationTemplate
							artist={artist}
							key={index}
							getSimilarArtists={getSimilarArtistsRecommendations}
						/>
					))}
					<Divider variant="middle" light sx={{ ml: 1, mr: 1, mb: 1 }} />
				</>
				)}
		</>
	);
}
