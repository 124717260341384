import { Typography, Grid } from "@mui/material";
import DeviceImg from "../images/Lutefish_Topview.png";
import CardMedia from "@mui/material/CardMedia";
import {  useLocation } from "react-router-dom";

import { format } from "date-fns";
export const AdminDeviceHistoryContainer = (props: any) => {
	const location = useLocation()
	const {userId, userProfileId, email, user: userDetails, userName,userStatus} = location?.state || {}

	return (
		<>
			<Typography sx={{ fontSize: "1.125rem", fontWeight: "500", marginBottom: 1 }}>
				{/* Unregistered Device */}
				{(props.device?.deviceStatus === "Unregistered" ||
					props.device?.deviceStatus === null) &&
				props.device?.userDeviceTransferredToUserId == null
					? "Unregistered Device"
					: "Transferred Device"}
			</Typography>

			<Grid container spacing={2} alignItems="center">
				<Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
					<CardMedia
						sx={{ height: "80px", width: "130px" }}
						image={DeviceImg}
						// title="green iguana"
					/>
				</Grid>
				<Grid item xs={9}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}					>
						
						<Grid item xs={6}>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#888",
								}}
							>
								{props.device?.deviceStatus === "Unregistered" &&
								props.device?.userDeviceTransferredToUserId == null
									? "Device Added Date"
									: "Transferred to"}
							</Typography>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#000",
								}}
							>
								{props.device?.deviceStatus === "Unregistered" &&
								props.device?.userDeviceTransferredToUserId == null
									? format(new Date(props.device?.activationDate), "dd/MM/yyyy")
									: props.device?.fullNameOfTransferredToUser}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#888",
								}}
							>
								{/* Unregistered Date */}
								{props.device?.deviceStatus === "Unregistered" &&
								props.device?.userDeviceTransferredToUserId == null
									? "Unregistered Date"
									: "Transfer Date"}
							</Typography>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#000",
								}}
							>
								{format(new Date(props.device?.deactivatedDate), "dd/MM/yyyy")}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#888",
								}}
							>
								Device ID (Serial Number)
							</Typography>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#000",
								}}
							>
								{/* 1553ACEE34 */}
								{props.device?.deviceId}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#888",
								}}
							>
								Device Version
							</Typography>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#000",
								}}
							>
								{/* Wenger 1.34N */}
								{props.device?.deviceModelType}
							</Typography>
						</Grid>
						<Grid item xs={9}>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#888",
								}}
							>
								Software Version
							</Typography>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: "500",
									color: "#000",
								}}
							>
								{/* Wenger 1.34N */}
								{props.device?.version}
							</Typography>
						</Grid>
						{/* <Grid item xs={3}>
						<Typography
								sx={{
									fontSize: "0.9rem",
									color: "#0273E6",
									fontWeight: "500",
									cursor: "pointer",
									
									lineHeight: "20px",
								}}
							>
								Download Invoice
							</Typography>
						</Grid> */}
					</Grid>
				</Grid>
				{/* <Grid
					item
					xs={2}
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						paddingBottom: 6,
					}}
				>
					<Typography
						sx={{
							fontSize: "1rem",
							color: "#0273E6",
							fontWeight: "400",
						}}
					>
						Invoice
					</Typography>
				</Grid> */}
			</Grid>

			<div
				style={{ height: "1px", background: "#7A7985", margin: "20px 0" }}
			></div>
		</>
	);
};
