import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Box,
	Typography,
	Grid,
	TextField,
} from "@mui/material";
import CloseIcon from "../../../images/close.svg";
import VisibleOff from "../../../images/eye-slash.svg";
import VisibleOn from "../../../images/eye.svg";
import { useUser } from "../../../context/UserContext";

const DeviceActions = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const [accountPassword, setAccountPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [iserror, setError] = useState(false);
	const [newDeviceId, setNewDeviceId] = useState("");
	const isEnabled = accountPassword.length >= 8 && accountPassword.length <= 16;
	const [isAlreadyDevicePresent, setIsAlreadyDevicePresent] = useState(false);
	const [existingDeviceId, setExistingDeviceId] = useState("");
	const [cannotReplace, setCannotReplace] = useState(false);

	useEffect(() => {
		if (props.open) {
			setAccountPassword("");
			setError(false);
			setShowPassword(false);
		}
	}, [props.open]);

	useEffect(() => {
		if (props.type === "transfer") {
			getDeviceHistory();
		}
	}, [])

	const getDeviceHistory = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-device/check`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setIsAlreadyDevicePresent(true);
					setExistingDeviceId(data.deviceId);
				}
			});
	};

	const handleDeviceActionClick = (action: string, actionObj: any) => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-devices/device-management/user-device-actions?deviceActions=${action}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					userId: actionObj.userId ? actionObj.userId : null,
					deviceId: actionObj.deviceId ? actionObj.deviceId : null,
					transferUserId: actionObj.transferUserId
						? actionObj.transferUserId
						: null,
					replaceDeviceId: actionObj.replaceDeviceId ? actionObj.replaceDeviceId : null,
				}),
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				props.handleClose(true);
				setNewDeviceId("");
			})
			.catch((err) => {
			});
	}

	const getTitle = (deviceAction: string) => {
		switch (deviceAction) {
			case "replace":
				return "Replace this device with new device. The existing device status will be marked as unregistered against some user";
				break;
			case "unregister":
				return "This device will be unregistered from the user account. It cannot be used for conducting Jam Session.";
				break;
			case "transfer":
				return "This device will be removed from the current user account and assigned to new user.";
				break;
		}
    };

    const getDialogTitle = (deviceAction: string) => {
			switch (deviceAction) {
				case "replace":
					return "Replace Device";
				case "unregister":
					return "Unregister Device";
				case "transfer":
					return "Transfer Device";
				default:
					return "";
			}
		};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			onClose={() => props.handleClose(false)}
			open={props.open}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={() => props.handleClose(false)}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%" }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						sx={{
							fontWeight: "500",
							color: "#000",
							fontSize: "30px !important",

						}}
					>
						{getDialogTitle(props.type)}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid item xs={12}>
						{props.type === "replace" && (
							<>
								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 5,
									}}
								>
									{getTitle(props.type)}
								</Typography>

								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 1,
									}}
								>
									User Name
								</Typography>

								<Typography
									variant="body1"
									sx={{
										fontSize: "1rem",
										fontWeight: "500",
										marginBottom: 4,
										marginLeft: 2,
									}}
								>
									{props.data?.userName}
								</Typography>

								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 1,
									}}
								>
									Existing Device Id
								</Typography>

								<Typography
									variant="body1"
									sx={{
										fontSize: "1rem",
										fontWeight: "500",
										marginBottom: 4,
										marginLeft: 2,
									}}
								>
									{props.data?.deviceId && props.data?.deviceStatus === "Active"
										? props.data?.deviceId
										: ""}
								</Typography>

								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 1,
									}}
								>
									New Device ID
								</Typography>

								{/* <Typography
									variant="body1"
									sx={{
										fontSize: "1rem",
										fontWeight: "500",
										marginBottom: 4,
										marginLeft: 2,
									}}
								>
									{props.data?.replaceDeviceId
										? props.data?.replaceDeviceId
										: "not available"}
								</Typography> */}

								<TextField
									id="first-name"
									fullWidth
									sx={{ marginBottom: "10px" }}
									inputProps={{
										style: { background: "#F8F8F8" },
									}}
									value={newDeviceId}
									onChange={(e) => {
										setNewDeviceId(e.target.value.trim());
										if (props.data?.deviceId === e.target.value.trim()) {
											setCannotReplace(true);
										}
									}}
									variant="outlined"
								/>

								{cannotReplace && (
									<Typography
										variant="body1"
										sx={{
											fontSize: "14px",
											fontWeight: "500",
											fontFamily: "Lexend",
											color: "#FD4B24",
										}}
									>
										Cannot replace with the same device
									</Typography>
								)}
							</>
						)}

						{props.type === "unregister" && (
							<>
								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 5,
									}}
								>
									{getTitle(props.type)}
								</Typography>

								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 1,
									}}
								>
									Device Id
								</Typography>

								<Typography
									variant="body1"
									sx={{
										fontSize: "1rem",
										fontWeight: "500",
										marginBottom: 4,
										marginLeft: 2,
									}}
								>
									{props.data?.deviceId}
								</Typography>

								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 1,
									}}
								>
									User Name
								</Typography>

								<Typography
									variant="body1"
									sx={{
										fontSize: "1rem",
										fontWeight: "500",
										marginBottom: 4,
										marginLeft: 2,
									}}
								>
									{props.data?.userName}
								</Typography>
							</>
						)}

						{props.type === "transfer" && (
							<>
								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 5,
									}}
								>
									{getTitle(props.type)}
								</Typography>

								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 1,
									}}
								>
									Device Id
								</Typography>

								<Typography
									variant="body1"
									sx={{
										fontSize: "1rem",
										fontWeight: "500",
										marginBottom: 4,
										marginLeft: 2,
									}}
								>
									{props.data?.transferData?.deviceId}
								</Typography>

								<Typography
									variant="body1"
									sx={{
										color: "#7A7985",
										fontSize: "1rem",
										fontWeight: "400",
										marginBottom: 1,
									}}
								>
									Current User
								</Typography>

								<Typography
									variant="body1"
									sx={{
										fontSize: "1rem",
										fontWeight: "500",
										marginBottom: 4,
										marginLeft: 2,
										color: "#000",
									}}
								>
									{props.data?.transferData?.userName}
								</Typography>

								<Grid container xs={12}>
									<Grid item xs={6}>
										<Typography
											variant="body1"
											sx={{
												color: "#7A7985",
												fontSize: "1rem",
												fontWeight: "400",
												marginBottom: 1,
											}}
										>
											New User to transfer to
										</Typography>

										<Typography
											variant="body1"
											sx={{
												fontSize: "1rem",
												fontWeight: "500",
												marginBottom: 4,
												marginLeft: 2,
												color: "#000",
											}}
										>
											{props.data?.userName}
										</Typography>
									</Grid>

									<Grid item xs={6}>
										<Typography
											variant="body1"
											sx={{
												color: "#F35E15",
												fontSize: "1rem",
												fontWeight: "400",
												marginBottom: 1,
											}}
										>
											Device Id
										</Typography>

										<Typography
											variant="body1"
											sx={{
												fontSize: "1rem",
												fontWeight: "500",
												marginBottom: 4,
												marginLeft: 2,
												color: "#000",
											}}
										>
											{existingDeviceId}
										</Typography>
									</Grid>
								</Grid>

								{isAlreadyDevicePresent && (
									<Typography
										variant="body1"
										sx={{
											fontSize: "14px",
											fontWeight: "500",
											fontFamily: "Lexend",
											color: "#FD4B24",
										}}
									>
										This user already has a device. Existing device will be
										unregistered from his account and replaced with this device.
									</Typography>
								)}
							</>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					columnSpacing={1}
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<Grid item={true}>
						<Button
							sx={{ width: "140px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={() => props.handleClose(false)}
						>
							Cancel
						</Button>
					</Grid>

					<Grid item={true}>
						{props.type === "replace" && (
							<Button
								sx={{ width: "217px" }}
								className="submit-button"
								size="large"
								variant="contained"
								onClick={() =>
									handleDeviceActionClick("ReplaceDevice", {
										userId: props.data?.userId,
										deviceId: props.data?.deviceId,
										replaceDeviceId: newDeviceId,
									})
								}
								disabled={cannotReplace}
							>
								Replace Device
							</Button>
						)}
						{props.type === "unregister" && (
							<Button
								sx={{ width: "217px" }}
								className="submit-button"
								size="large"
								variant="contained"
								onClick={() =>
									handleDeviceActionClick("UnregisterDevice", {
										userId: props.data?.userId,
										deviceId: props.data?.deviceId,
									})
								}
							>
								Unregister Device
							</Button>
						)}
						{props.type === "transfer" && (
							<Button
								sx={{ width: "217px" }}
								className="submit-button"
								size="large"
								variant="contained"
								onClick={() =>
									handleDeviceActionClick("TransferDevice", {
										transferUserId: props.data?.userId,
										deviceId: props.data?.transferData?.deviceId,
										userId: props.data?.transferData?.userId,
									})
								}
							>
								Transfer Device
							</Button>
						)}
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};
export default DeviceActions;
