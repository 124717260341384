import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import { DialogContentText } from "@mui/material";
import Loader from "./commonComponents/Loader";

const CenterCircularProgress = () => (
	<Dialog
		open
		fullWidth
		maxWidth="xs"
		PaperProps={{
			sx: { minWidth: "100vw", minHeight: "100vh", opacity: "0.6", overflow:"hidden"},
		}}
	>
		<DialogContent sx={{ textAlign: "center" }} style={{ margin: "10px 0" }}>
			<div data-testid="circularProgress">
				<Loader />
			</div>
		</DialogContent>
	</Dialog>
);

export default CenterCircularProgress;
