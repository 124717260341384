import {
	Box,
	Grid,
	Paper,
	Typography,
	Snackbar,
	Alert,
	AlertTitle,
	TextField,
	Button,
	DialogActions,
	Dialog,
	DialogContentText,
	DialogContent,
	DialogTitle,
	IconButton,
	CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import AdminAvatarPrompt from "./AdminAvatarPrompt";
import DummyAvatarImage from "../../../images/dummyAvatarImage.png";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "../../../images/close.svg";
import EditAdminAvatarPrompt from "./EditAdminAvatarPrompt";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import { clearLocalstorage } from "../../../utils";
import useAdminAuthorized from "../../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		folderDropdown: {
			background: "#FFF",
			border: "1.5px solid #0273E6",
			borderRadius: "15px !important",
			"& #demo-simple-select": {
				color: "#0273E6",
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiSelect-icon": {
				color: "#0273E6",
			},
		},
		promptBtn: {
			width: "100%",
			// background: (user.userAccountStatus == "ACTIVE") ? "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)" : "linear-gradient(126.25deg, rgba(0,0,0,0.2) 3.77%, rgba(0,0,0,0.2) 88.31%)",
			borderRadius: "18px !important",
			textAlign: "center",
			color: "white",
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			padding: "2% 0 !important",
			cursor: "pointer",
		},
		searchField: {
			// border: "1px solid #C5C5C5",
			borderRadius: "17px",
			"& fieldset": {
				border: "1px solid #C5C5C5",
				borderRadius: "17px",
			},
		},
		optionButtons: {
			border: "1.5px solid #0273E6 !important",
			borderRadius: "15px !important",
			color: "#0273E6 !important",
			// fontFamily: 'Lexend !important',
			fontStyle: "normal !important",
			fontWeight: "bold !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);

interface AdminAvatarGenerationProps {
	avatarPromptOpen: boolean;
	handleDialogClose: any;
}

const AdminAvatarGeneration: React.FC<AdminAvatarGenerationProps> = ({
	avatarPromptOpen,
	handleDialogClose,
}) => {
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus();
	const [isLoading, setIsLoading] = useState(false);

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [editImageStatus, setEditImageStatus] = useState(false);
	const [editItemId, setEditItemId] = useState();
	const [deleteItemId, setDeleteItemId] = useState();
	const [avatarPrompts, setAvatarPrompts] = useState<any[]>([]);
	const isAdminUserManagementAdmins: boolean = useAdminAuthorized("isAdminUserManagementAdmins");

	useEffect(() => {
		getAllPrompt();
	}, []);

	const getAllPrompt = () => {
		setIsLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/avatar-prompts/get`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status !== 200) {
					updateAlertStatus(true, false, "Network Error! Please try again..");
				} else {
					return res.json();
				}
			})
			.then((result) => {
				setAvatarPrompts(result);
				setIsLoading(false);
			})
			.catch((error) => {
				updateAlertStatus(true, false, "Network Error! Please try again..");
			});
	};

	const handlePromptSuccess = () => {
		getAllPrompt();
	};

	const deletePrompt = async (itemId: any) => {
        try {
            setEditImageStatus(true);
            fetch(
                `${process.env.REACT_APP_BASE_URL}/api/avatar-prompts/delete-by-id`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ id: itemId }),
                }
            )
                .then((response) => {
                    if (response.status !== 204) {
                        if (response.status >= 400 && response.status < 500) {
                            updateAlertStatus(true, false, 'something went wrong..');
                        } else if (response.status >= 500 && response.status < 600) {
                            updateAlertStatus(true, false, 'Internal server error..');
                        }
                    }else{
						updateAlertStatus(true, true, 'Avatar Image Generation Prompt deleted!');
					}
					setEditImageStatus(false);
					setOpenDeleteDialog(false);
					handlePromptSuccess();
                    return response.text();
                })
                .then((text) => {
                    // setTimeout(() => {
                    //     setEditImageStatus(false);
                    //     setOpenDeleteDialog(false)
                    //     handlePromptSuccess();
                    //     updateAlertStatus(true, true, 'Avatar Image Generation Prompt deleted!');
                    // }, 1000);
                })
        } catch (error) {
            setEditImageStatus(false);
            updateAlertStatus(true, false, 'Network Error! Please try again..');
        }
    };

	const handlePromptDialogClose = () => {
		handleDialogClose();
	};

	const handleEditDialogClose = () => {
		setOpenEditDialog(false);
	};

	const handleEditDialog = (id: any) => {
		setOpenEditDialog(true);
		setEditItemId(id);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	const handleDeleteDialog = (id: any) => {
		setDeleteItemId(id);
		setOpenDeleteDialog(true);
	};
	return (
		<div>
			{isLoading ? (
				<Grid sx={{ textAlign: "center" }}>
					<CircularProgress />
				</Grid>
			) : (
				<div>
					{avatarPrompts?.length === 0 ? (
						<Paper
							sx={{
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "18px !important",
							}}
						>
							<Grid container justifyContent="center">
								<Grid xs={12} sx={{ p: "16px" }}>
									<Typography
										component="h1"
										variant="h6"
										align="center"
										className="typography-field-custom-font-size center-align"
									>
										No Avatar Prompts Available
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					) : (
						avatarPrompts?.map((item) => (
							<Paper
								sx={{
									boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
									borderRadius: "18px !important",
									marginBottom: "20px",
								}}
							>
								<Grid container xs={12}>
									<Grid
										sx={{
											p: "16px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<img src={DummyAvatarImage} alt="Unknown Profile Picture" />
										<Typography
											component="h1"
											className="typography-field-custom-font-size center-align"
											sx={{ marginLeft: "16px" }}
										>
											{item.promptText}
										</Typography>
									</Grid>
								</Grid>
								<DialogActions>
									<Grid>
										<Button
											sx={{
												width: "100%",
												borderColor: "#0273E6",
												color: "#0273E6",
												"&:hover": {
													backgroundColor: "#81d3fc",
													color: "#FFFFFF",
													borderColor: "#0273E6",
												},
												pointerEvents: isAdminUserManagementAdmins ? "auto" : "none", 
												opacity: isAdminUserManagementAdmins ? 1 : 0.6,
											}}
											// className="cancel-button"
											size="large"
											variant="outlined"
											onClick={isAdminUserManagementAdmins ? () => handleEditDialog(item.id) : undefined}
										>
											<BorderColorIcon
												fontSize="small"
												sx={{ marginRight: "5px" }}
											/>
											Edit
										</Button>
									</Grid>
									<Grid>
										<Button
											sx={{
												width: "100%",
												borderColor: "#0273E6",
												color: "#0273E6",
												"&:hover": {
													backgroundColor: "#81d3fc",
													color: "#FFFFFF",
													borderColor: "#0273E6",
												},
												pointerEvents: isAdminUserManagementAdmins ? "auto" : "none", 
												opacity: isAdminUserManagementAdmins ? 1 : 0.6,
											}}
											// className="cancel-button"
											size="large"
											variant="outlined"
											onClick={isAdminUserManagementAdmins ? () => handleDeleteDialog(item.id) : undefined}
										>
											<DeleteIcon
												fontSize="small"
												sx={{ marginRight: "5px" }}
											/>
											Delete
										</Button>
									</Grid>
								</DialogActions>
							</Paper>
						))
					)}
				</div>
			)}

			{openEditDialog && (
				<EditAdminAvatarPrompt
					openEditDialog={openEditDialog}
					handleClose={handleEditDialogClose}
					editItemId={editItemId}
					onPromptSuccess={handlePromptSuccess}
				/>
			)}

			{avatarPromptOpen && (
				<AdminAvatarPrompt
					avatarPromptOpen={avatarPromptOpen}
					handleClose={handlePromptDialogClose}
					onPromptSuccess={handlePromptSuccess}
				/>
			)}

			{openDeleteDialog && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteDialog}
					onClose={() => handleCloseDeleteDialog()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeleteDialog()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete Avatar Prompt</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={() => handleCloseDeleteDialog()}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							disabled={editImageStatus}
							onClick={() => deletePrompt(deleteItemId)}
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default AdminAvatarGeneration;
