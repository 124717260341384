import {
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import calendarIcon from "../../images/calendar.svg";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleStyle: {
			fontFamily: "Lexend",
			fontSize: "1.125rem",
			fontWeight: "500",
			lineHeight: "23px",
			letterSpacing: "0em",
			textAlign: "left",
			marginTop: "2px",
		},
		dateStyle: {
			fontFamily: "Lexend",
			fontSize: "14px",
			fontWeight: "500",
			lineHeight: "20px",
			letterSpacing: "0em",
			textAlign: "left",
			marginBottom: "0 !important",
			marginTop: "5px !important",
			paddingLeft: "3px",
			maxWidth: "75%",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	})
);
const GoogleEventCardCom = (props: any) => {
	const navigate = useNavigate()
	const classes = useStyles();
	const maxAddressLength = 20;
	const maxTitleLength = 20;
	const maxDescriptionLength = 30;

	// const utcTime: string = props.data.date.when;
	// const resultArray = utcTime.split(",")
	// const timeSet = resultArray[2].split(" ")
	// const finalFormattedDate =resultArray[1] + "- " +  timeSet[1] + timeSet[2]

	return (
		<Grid
			sx={{
				width: "198px",
				height: "364px",
				cursor: "pointer",
				display: "flex",
				flexDirection: "column",
				border: "1px solid #CFD9DF",
				borderRadius: "12px",
			}}
			onClick={() => {
				navigate("/events/googleEventDetails", {state:{"googleEventDetails":props.data}})
			}}
		>
			<Grid container>
				<CardActionArea
					// onClick={() => {
					// 	 window.open(props.data.link, "_blank");
					// }}
				>
					<Grid item sx={{ width: "198px" }}>
						<CardMedia
							component="img"
							height="198px"
							width="198px"
							image={props.data.image}
							sx={{
								borderTopLeftRadius: "12px",
								borderTopRightRadius: "12px",
								objectFit: "cover",
							}}
						/>
					</Grid>
				</CardActionArea>
				<Grid
					item
					// onClick={() => {
					// 	window.open(props.data.link, "_blank");
					// }}
					style={{ height: "135px", padding: "2px 0px 0px 2px" }}
				>
					<CardContent style={{ flex: 1, marginTop: "0.1rem", padding:"1px 10px" }}>
						<Typography className={classes.titleStyle}>
							{/* {props.data.title} */}
							{props.data.title && props.data.title.length > maxTitleLength
								? props.data.title.substring(0, maxTitleLength) + "..."
								: props.data.title}
						</Typography>
						<Typography variant="body2">
							{/* {props.data.address.join()} */}
							{props.data.address.join() &&
							props.data.address.join().length > maxAddressLength
								? props.data.address.join().substring(0, maxAddressLength) +
								  "..."
								: props.data.address.join()}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{/* {props.data.description} */}
							{props.data.description &&
							props.data.description.length > maxDescriptionLength
								? props.data.description.substring(0, maxDescriptionLength) +
								  "..."
								: props.data.description}
						</Typography>
					</CardContent>
				</Grid>
			</Grid>
			<Grid
				item
				// onClick={() => {
				// 	window.open(props.data.link, "_blank");
				// }}
				sx={{ marginTop: "-7px" }}
			>
				<CardActions sx={{ height: "100%" }}>
					<Grid container sx={{ height: "100%" }}>
						<Grid container>
							<Grid
								item
								xs={12}
								md={6}
								sx={{ padding: "0", display: "contents", marginBottom: "10px" }}
							>
								<img
									style={{ marginTop: "-15px", paddingLeft: "6px" }}
									src={calendarIcon}
									alt="Date"
								/>
								<Typography variant="body2" className={classes.dateStyle}>
									{/* {props.data.date.when && finalFormattedDate} */}
									{props.data.date.when}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</CardActions>
			</Grid>
		</Grid>
	);
};
export default GoogleEventCardCom;
/*props.data.date.when.length > maxTimeLength
										? props.data.date.when.substring(0, maxTimeLength) + "..."
										: props.data.date.when*/
