import { Grid } from "@mui/material";

import AdminUserActionTb from "./AdminUserActionTb";
import { useAdminContext } from "../ManageUserDashBoard/UserDetailsProvider";
import ActivityLogs from "../../../pages/ActivityLogs";
import { useEffect } from "react";

export const AdminUsersLayout = () => {
	const { adminScreenShow, setadminScreenShow } = useAdminContext();
	const { adminEmail, setAdminEmail } = useAdminContext();


	useEffect(() => {
		return () => {
			setadminScreenShow(false);
			setAdminEmail([]);
		};
	}, []);

	return (
		<>
			{adminScreenShow ? (
				<Grid sx={{ marginTop: "50px" }}>
					<ActivityLogs />
				</Grid>
			) : (
				<Grid container spacing={1}>
					<Grid item lg={12} md={12}>
						<Grid container>
							<Grid item md={12} lg={12} xl={12}>
								<AdminUserActionTb />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};
