import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import Trash from "../../images/delete-border-delete.svg";
import Location from "../../images/location.svg";
import Calender from "../../images/calendar.svg";
import Dollar from "../../images/dollar-circle.svg";
import Edit from "../../images/editborder-edit.svg";
import { useEffect, useState } from "react";
import EventPost from "../EventAndGigPost/EventPost";
import CloseIcon from "../../images/close.svg";
import PostEvent from "./PostEvent";
import BannerImage from "../commonComponents/UploadBannerImage";

const EventView = ({ event, setEvent, handleDelete, isEdit, alertStatus, alertMsg, showAlert, setAlertMsg, setAlertStatus, setShowAlert }: any) => {
	const [eventDetails, setEventDetails] = useState(event);
	const [onEdit, setOnEdit] = useState(false);
	const [openDeleteEventPopup, setOpenDeleteEventPopup] = useState(false);

	const String = `${eventDetails.venue} ${eventDetails.cityCountry}`;
	const eventVenue = String.replace(/#/g, '');
	const startDateTimeString: string = eventDetails.startDateTime;
	const startDate: Date = new Date(startDateTimeString);
	const durationInMinutes: number = eventDetails.duration;

	const hours: number = Math.floor(durationInMinutes / 60);
	const minutes: number = durationInMinutes % 60;
	const formattedDuration: string = `${hours} Hr ${minutes} Min`;
	const [isUpdated, setIsUpdated] = useState<boolean>(false);
	const token = localStorage.getItem("sessionId");

	useEffect(()=>{
		setEventDetails(event);
	},[event])

	const handleCloseDeletePopup = () => {
		setOpenDeleteEventPopup(false);
	};

	const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
};

const formattedDate: string = startDate.toLocaleDateString("en-US", options);

	const closeEvent = () => {
		setOnEdit(false);
	};

	const fetchEventDetails = () => {
		// setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/get-event/details`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id: eventDetails.id,
			}),
		})
			.then((res) => {

				if (res.status !== 200) {
				} else {
					return res.json();
				}
			})
			.then(async (data) => {
				setEventDetails(data);
				setEvent(data);
			})
			.catch((err) => {
				//   setLoading(false);
				// setLoading(false);
			});
	};

	// useEffect(() => {
	// 	fetchEventDetails();
	// }, [onEdit]);
	return (
		<>
			<Grid container={true} xs={12} spacing={1}>
				<Grid item={true} xs={12}>
					<Grid container={true} xs={12}>
						<Grid item={true} xs={6}>
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontSize: "24px",
									fontWeight: 500,
									lineHeight: "30px",
									textAlign: "left",
								}}
							>
								Event
							</Typography>
						</Grid>
						<Grid container xs={6} justifyContent={"end"}>
							{isEdit && (
								<Button
									onClick={() => setOnEdit(true)}
									sx={{
										border: "1px",
									}}
								>
									<img
										src={Edit}
										alt="edit"
										style={{ width: "100%", height: "100%" }}
									/>
								</Button>
							)}
							<Button
								onClick={() => setOpenDeleteEventPopup(true)}
								sx={{
									border: "1px",
								}}
							>
								<img
									src={Trash}
									alt="Trash"
									style={{ width: "100%", height: "100%" }}
								/>
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item={true} xs={12}>
					<img
						src={
							eventDetails.bannerUrl !== null && eventDetails.bannerUrl !== ""  && eventDetails.bannerUrl !== undefined 
								? eventDetails.bannerUrl.includes("https://")
									? eventDetails.bannerUrl
									: `${process.env.REACT_APP_PICTURE_URL}${eventDetails.bannerUrl}`
								: `${process.env.REACT_APP_PICTURE_URL}/defaultimages/event-banner.png`

						}
						style={{ borderRadius: "12px", width: "100%" , border: (eventDetails.bannerUrl !== null && eventDetails.bannerUrl !== "") ? "none" : "2px solid #999999",}}
					/>
				</Grid>
				<Grid item={true} xs={12}>
					<Grid container xs={12} spacing={2}>
						<Grid item={true} xs={4}>
							<img
								src={
									eventDetails.thumbnailUrl !== null &&
									eventDetails.thumbnailUrl !== "" && eventDetails.thumbnailUrl !== undefined  
									? eventDetails.thumbnailUrl.includes("https://")
									? eventDetails.thumbnailUrl
									: `${process.env.REACT_APP_PICTURE_URL}${eventDetails.thumbnailUrl}`
								: `${process.env.REACT_APP_PICTURE_URL}/defaultimages/event-thumbnail.svg`
								}
								style={{ borderRadius: "12px", width: "100%" }}
							/>
						</Grid>
						<Grid item={true} xs={8}>
							<Stack sx={{ width: "100%" }} spacing={0.5}>
								<Typography
									sx={{
										fontFamily: "Lexend",
										fontSize: "18px",
										fontWeight: 600,
										lineHeight: "23px",
										textAlign: "left",
										color: "#000",
										overflow: "hidden",
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
									}}
								>
									{eventDetails.title?.substring(0, 20)}
								</Typography>

								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<img
										src={Location}
										alt="location-icon"
										style={{ alignSelf: "flex-start" }}
									/>
									<span
										style={{
											fontFamily: "Lexend",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "20px",
											letterSpacing: "0em",
											textAlign: "left",
											color: "#5B5B65",
											justifySelf: "center",
											marginLeft: "5px",
										}}
									>
										{eventVenue}
									</span>
								</Typography>
								<Typography
									style={{
										fontFamily: "Lexend",
										fontSize: "14px",
										fontWeight: 500,
										lineHeight: "20px",
										letterSpacing: "0em",
										textAlign: "left",
										color: "#5B5B65",
										justifySelf: "center",
										overflow: "hidden",
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
									}}
								>
									{eventDetails.description &&
									eventDetails.description !== "null"
										? eventDetails.description.length > 30
											? `${eventDetails.description.substring(0, 30)}...`
											: eventDetails.description
										: ""}
								</Typography>

								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<img src={Calender} alt="calender-icon" />
									<span
										style={{
											fontFamily: "Lexend",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "20px",
											letterSpacing: "0em",
											textAlign: "left",
											color: "#5B5B65",
											justifySelf: "center",
											marginLeft: "5px",
										}}
									>{`${formattedDate} | ${formattedDuration} `}</span>
								</Typography>
								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<img src={Dollar} alt="dollar-icon" />
									<span
										style={{
											fontFamily: "Lexend",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "20px",
											letterSpacing: "0em",
											textAlign: "left",
											color: "#5B5B65",
											justifySelf: "center",
											marginLeft: "5px",
										}}
									>
										{eventDetails.cost !== null ? eventDetails.cost : "Free"}
									</span>
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{openDeleteEventPopup && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteEventPopup}
					onClose={() => handleCloseDeletePopup()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeletePopup()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{
									marginLeft: "90%",
									position: "absolute",
									top: "8px",
								}}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete "{event.title}"</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={() => handleCloseDeletePopup()}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => handleDelete()}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{onEdit && (
				<PostEvent
					open={onEdit}
					eventType={"modifypost"}
					eventDetails={event}
					setEvent={setEvent}
					handleEventClose={closeEvent}
				/>
			)}
		</>
	);
};
export default EventView;
