import { useState, useEffect } from "react";
import {
	Outlet,
	useParams,
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import {
	Alert,
	MenuList,
	MenuItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Divider,
	Grid,
	Button,
	FormControl,
	FormHelperText,
	TextField,
	Stack,
	Checkbox,
	FormControlLabel,
	Autocomplete,
	Slider,
	Paper,
	InputAdornment,
	Chip,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme as muiTheme, styled } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import SettingsAlert from "../../pages/SettingsAlert";
import AllMusicians from "../Musicians/AllMusicians";
import MyConnections from "../Musicians/MyConnections";
import Invitations from "../Musicians/Invitations";
import i18n from "../../l10n/strings.json";
import InstrumentSkillLevel from "../Musicians/InstrumentSkills";

import Guitar from "../../images/guitar.svg";
import Drums from "../../images/drums.svg";
import Violin from "../../images/Violin.svg";
import Trumpet from "../../images/trumpet.svg";
import MusicNote from "../../images/musicnote.svg";
import Account from "../../images/account.svg";
import ActiveAccount from "../../images/active-account.svg";
import Security from "../../images/security.svg";
import ActiveSecurity from "../../images/active-security.svg";
import Next from "../../images/next.svg";
import InvitationSent from "../../images/InvitationSent.svg";
import InvitationsSent from "../Musicians/InvitationsSent";
import InvitationsRejected from "../Musicians/InvitationsRejected";
import MusiciansFilter from "../Musicians/MusiciansFilter";

import { useMusiciansFilter } from "../Musicians/MusicProvider/useMusicianFilter";
import { useMusicianCounts } from "../Musicians/MusicProvider/useMusicianCounts";
import MusicianSearchResult from "../Musicians/MusicianSearchResult";
import Search from "../../images/search-normal.svg";
import ConversationsList from "./conversations/ConversationsList";
import { Theme } from "@twilio-paste/theme";
import AppContainer from "./AppContainer";
import { Box } from "@twilio-paste/core";
import styles from "./styles";
import useAdminRedirect from "../../hooks/useAdminRedirect/useAdminRedirect";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

type RouteParams = {
	menuId: string;
};

const musiciansMenu = [
	{
		id: "all",
		label: "All Musicians",
		navigateMenu: "",
		countKey: "",
	},
	{
		id: "connections",
		label: "Your Connections",
		navigateMenu: "connections",
		countKey: "totalConnections",
	},
	{
		id: "invitations",
		label: "Invitations",
		navigateMenu: "invitations",
		countKey: "totalReceivedConnectionRequests",
	},
];

const invitationsMenu = [
	{
		id: "invitations-received",
		label: "Received",
		activeIcon: ActiveAccount,
		inActiveIcon: Account,
		disabled: false,
		countKey: "totalReceivedConnectionRequests",
	},
	{
		id: "invitations-sent",
		label: "Sent",
		activeIcon: InvitationSent,
		inActiveIcon: InvitationSent,
		disabled: false,
		countKey: "totalSentConnectionRequests",
	},
	{
		id: "invitations-rejected",
		label: "Rejected",
		activeIcon: ActiveSecurity,
		inActiveIcon: Security,
		disabled: false,
		countKey: "totalRejectedConnectionRequests",
	},
];

const useStyles = makeStyles((theme: muiTheme) =>
	createStyles({
		menuChips: {
			padding: "0 20px",
			fontSize: "16px",
			fontWeight: 400,
		},
		shadowedBox: {
			padding: "1% 2%",
			borderRadius: "15px",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
	})
);

const MessagingContainer = () => {
	const navigate = useNavigate();
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if ((!userDetails.firstName || !userDetails.lastName || !userDetails.location) && !isAdmin) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);
	useAdminRedirect();
	return (
		<>
			<Grid className="mainPanel" spacing={{ xs: 1 }}>
				<Grid
					container={true}
					className="column mainPanel"
					sx={{
						width: "80%",
					}}
					rowSpacing={{ xs: 1 }}
				>
					<Grid item={true} className="center-align">
						<SettingsAlert />
					</Grid>
					<Grid item={true}>
						{/* <Grid item={true} sx={{zIndex: 99, width: "100%", backgroundColor: "white"}} > */}
						<Grid container={true} xs={12} columns={{ xs: 12 }}>
							<Grid
								item={true}
								xs={3}
								id="messageDiv"
								className="leftPanelWidth"
							>
								<Typography
									className="home-title-custom-size center-align "
									sx={{
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "36px !important",
										lineHeight: "49px",
									}}
								>
									Messages
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						item={true}
						className="row"
						sx={{ zIndex: 1, marginTop: "1rem" }}
					>
						<Box style={styles.app}>
							<Theme.Provider theme="twilio">
								<AppContainer />
							</Theme.Provider>
							{/* <ConversationsList /> */}
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default MessagingContainer;
