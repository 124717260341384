import { EventEmitter } from "events";
import type { SocialSignupUserInterface } from "../types/types";
/**
 * Set user information while social login
 *
 * @event changed
 * @class SocialUser
 * @extends EventEmitter
 */
class SocialUser extends EventEmitter {
	public defaultUser: string = JSON.stringify({
		firstName: "",
		lastName: "",
		email: "",
		provider: "",
		existingUser: false,
		id_token: "",
	});
	public user: undefined = undefined;

	constructor() {
		super();
	}

	public setSocialUser(user: SocialSignupUserInterface) {
		this.emit("socialUserChanged", user);
	}

	public getDefaultUser(): SocialSignupUserInterface {
		const _user = JSON.parse(this.defaultUser);
		return _user;
	}
}
export default new SocialUser();
