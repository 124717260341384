
import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	IconButton,
	Box,
	Typography,
	Grid
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import InfoIcon from "../icons/InfoIcon";
import { Link, useNavigate } from "react-router-dom";


const EditDateOfBirth = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const navigate = useNavigate();

	const maxDate = new Date();
	maxDate.setFullYear(maxDate.getFullYear() - 13)

	const [birthDate, setBirthDate] = useState(props.dateOfBirth ?? null);

	useEffect(() => {
		if (props.birthDateOpen) {
			setBirthDate(props.dateOfBirth ?? null);
		}
	}, [props.birthDateOpen, props.dateOfBirth]);

	// const formatDateForServer = (date: Date): string => {
	// 	const dob = new
	// 	const year = date.getUTCFullYear();
	// 	const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
	// 	const day = date.getUTCDate().toString().padStart(2, "0");
	// 	return `${year}-${month}-${day}`;
	//   };

	const saveBirthDate = () => {
		// Get the user's timezone
		const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		// Convert UTC to local time
		const localDate = birthDate ? birthDate.toLocaleString('en-US', { timeZone: userTimeZone }) : null;
		const trimmedDate = localDate?.split(',')[0];
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-dateOfBirth`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					// user: { id: user.id },
					user: { id: props.userIdByAdmin ? props.userIdByAdmin : user.id },
					dateOfBirth: trimmedDate,
				}),
			}
		)
			.then((response) => {
				if(response.status === 502){
					navigate('/defaultPage')
			    } else if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				props.handleClose(true, "birthDateSuccess");
			});
	};

	const handleClose = () => {
		setBirthDate(props.dateOfBirth ?? null);
		props.handleClose(false)
	};

	const isValidBirthDate = (date: Date): boolean => {
		const currentYear = new Date().getFullYear();
		const minBirthYear = currentYear - 124; // Adjust the range as needed

		if (
			date &&
			Object.prototype.toString.call(date) === '[object Date]' &&
			!isNaN(date.getTime()) &&
			date.getFullYear() >= minBirthYear
		) {
			const selectedMonth = date.getMonth() + 1; // Adding 1 because getMonth returns zero-based index
			const selectedDate = date.getDate();

			if (selectedMonth <= 12) {
				return selectedDate <= new Date(date.getFullYear(), selectedMonth, 0).getDate();
			}
		}
		return false;
	};



	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			open={props.birthDateOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={handleClose}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%" }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.settings.accountSetup.editDobDialog}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: "5px 20px",
				}}
			>
				<Grid
					container
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Grid item={true} xs={12}>
						<FormHelperText
							sx={{
								fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.dialog.date}
						</FormHelperText>
					</Grid>
					<Grid item={true} xs={12}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								openTo="day"
								inputFormat="MM/dd/yyyy"
								views={["year", "month", "day"]}
								value={birthDate}
								disableFuture
								maxDate={maxDate}

								desktopModeMediaQuery="@media (pointer: fine)"
								onChange={(newValue: Date | null) => {
									// // Get the user's timezone
									// const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

									// // Convert UTC to local time
									// const localDate = newValue ? newValue.toLocaleString('en-US', { timeZone: userTimeZone }) : null;
									// const trimmedDate = localDate?.split(',')[0];
									// setBirthDate(trimmedDate);

									// Get the user's timezone
									setBirthDate(newValue);
								  }}
								renderInput={(params) => (
									<TextField
										sx={{
											background: "#F8F8F8",
											width: "100%",
											// width: "504px",
											// marginBottom: "130px",
										}}
										{...params}
									/>
								)}
							/>
						</LocalizationProvider>
						<Grid sx={{display: "flex", flexDirection: "row", justifyContent: 'flex-end', mt: "5px", mb: "100px"}}>
								<InfoIcon/>
							    <Typography sx={{ fontSize: "14px", marginLeft: "2px" }}>Minimum age limit of 13 years.</Typography>
							</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					columnSpacing={1}
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<Grid item={true}>
						<Button
							sx={{ width: "120px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleClose}
						>
							{i18n.dialog.cancel}
						</Button>
					</Grid>
					<Grid item={true}>
						<Button
							sx={{ width: "120px" }}
							className="submit-button"
							size="large"
							variant="contained"
							disabled={
								birthDate === null ||
								birthDate === props.dateOfBirth ||
								birthDate > maxDate ||
								!isValidBirthDate(birthDate)
							}
							onClick={saveBirthDate}
						>
							{i18n.dialog.save}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};
export default EditDateOfBirth;
