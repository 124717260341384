import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
	Card,
	CardActions,
	CardContent,
	Button,
	Typography,
	Grid,
} from "@mui/material";
import AddDeviceDialog from "../dialogBox/AddDeviceDialog";
import HelpAndTips from "./HelpAndTips";
import Danger from "../images/danger.svg";
import RightAarrow from "../images/right arrow.svg";
import i18n from "../l10n/strings.json";
import { DeviceHistoryContainer } from "../components/DeviceHistoryContainer";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import { useUser } from "../context/UserContext";

const AddDeviceSetting = () => {
	const navigate = useNavigate();
	const param = useParams();
	const user = useUser();
	const [loading, setLoading] = useState(true);
	const [subscriptionPlans, setSubscriptionPlans] = useState([]);
	const [open, setOpen] = useState(false);
	const [deviceHistory, setDeviceHistory] = useState<any>([]);
	const token = localStorage.getItem("sessionId");
	const location = useLocation();
	const { adminSetUserId } = useAdminContext();
	const authorities = user.authorities.map(
		(authority: { name: string }) => authority.name
	);
	const {
		userId,
		userProfileId,
		email,
		user: userDetails,
		userName,
		userStatus,
	} = location?.state || {};

	const handleSuccess = () => {
		if (location?.state?.userId) {
			navigate("/home/admin/settings/deviceSetup/admindevice", {
				state: {
					userId,
					userProfileId,
					email,
					type: "edit",
					userDetails,
					userName,
					userStatus,
				},
			});
		} else {
			navigate(`/home/settings/${param.menuId}/device`);
		}
		//alert(location.pathname);
	};

	const handleDialogClose = () => {
		setOpen(false);
	};

	const isEnable = () => {
		if (
			user?.authorities[0].name === "ROLE_ADMIN" ||
			user?.authorities[0].name == "ROLE_SUPER_ADMIN" ||
			user?.authorities[0].name == "ROLE_USER" ||
			user?.authorities[0].name == "ROLE_COLLAB_USER"
		) {
			return true;
		} else {
			return false;
		}
	};
	useEffect(() => {
		getSubscriptionPlans();

		getDeviceHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSubscriptionPlans = async () => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/master-subscription-plans`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})

			.then((data) => {
				if (data.length > 0) {
					// Parse features if they are JSON strings
					const plansWithParsedFeatures = data.map((plan: any) => {
						if (typeof plan.features === "string") {
							try {
								plan.features = JSON.parse(plan.features);
							} catch (error) {
								console.error(
									"Failed to parse features for plan:",
									plan.name,
									error
								);
								plan.features = [];
							}
						} else if (!Array.isArray(plan.features)) {
							plan.features = [];
						}
						return plan;
					});
					setSubscriptionPlans(plansWithParsedFeatures);
				}
			})
			.catch((err) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const getDeviceHistory = () => {
		const validId =
			adminSetUserId && authorities.includes("ROLE_ADMIN")
				? adminSetUserId.id
				: location.state?.userId
				? location.state?.userId
				: user.id;
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-devices/get-devices-by-user`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify([
					{
						userId: validId,
					},
				]),
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setDeviceHistory(data);
				}
			});
	};
	//const sealToken: string = process.env.REACT_APP_SEAL_SUB || "";
	const checkOut = (plan: any) => {
		// fetch(`https://app.sealsubscriptions.com/shopify/merchant/api/quick-checkout-url`, {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/public/checkout`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			//	"X-Seal-Token": sealToken,
				// "X-Seal-Token": "seal_token_04gf07v4d5nbhanxzwrfaicre65bl3yiiindmhxq"      //pick this from env as per environment
			},
			body: JSON.stringify({
				action: "create",
				items: [
					{
						variant_id: plan.shopifyVariantId,
						quantity: 1,
						//selling_plan: plan.shopifySellingPlanId
						inventory_item_id: plan.inventoryItemId, //fetch from master api
						product_id: plan.shopifyProductId,
					},
				],
				attributes: {
					checkout: {
						email: user.email,
						shipping_address: {
							first_name: user.firstName,
							last_name: user.lastName,
							address1: user.location ? user.location : "",
							phone: "",
							zip: "",
							city: "",
							province: "",
							country: "",
						},
					},
				},
			}),
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data.success) {
					// To Open in a new browsr window
					window.open(data.payload.quick_checkout_url, "_blank");
				}
			})
			.catch((err) => {
				// setActiveDevice(null);
			})
			.finally(() => {
				// setLoading(false);
			});
	};
	return (
		<>
			<Grid container>
				<Grid item={true} xs={12} className="centerPanelSocial">
					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;",
							paddingBottom: "32px",
							// height: "376px",
						}}
					>
						<CardContent sx={{ paddingLeft: "125px", paddingTop: "35px" }}>
							<Grid container={true} xs={12}>
								<Grid item={true} xs={12} color="#000000">
									<Grid
										container={true}
										xs={12}
										sx={{
											display: "flex",
											alignItems: "center",
											flexWrap: "wrap",
										}}
									>
										<Grid item={true} xs={12}>
											<Typography
												className="card-title-custom-size"
												style={{ paddingLeft: "10px",display: "inline-flex", alignItems: "center" }}
											>
												<img
													src={Danger}
													alt="Preferences"
													style={{ marginRight: "10px" }}
												/>
												<span>
													No Device added or purchased for this account
												</span>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item={true} xs={12}>
									<Typography
										sx={{
											lineHeight: "25px",
											padding: "30px 0px 30px 0px",
										}}
									>
										If you already purchased device, then use <Typography	component="span"
										sx={{
											 color:"red"
										}}
									> [Add New Device]</Typography> button to link your new device to this account.	
									<Typography sx={{
											lineHeight: "25px",
											padding: "20px 0px 0px 0px",
										}}>
										If you have not purchased device, then use the button to buy new device.
									</Typography>
									<Typography sx={{
											lineHeight: "25px",										
										}}> Without adding device to your account, you cannot upgrade subscription plans.
									</Typography>
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
						{subscriptionPlans.map((plan: any, index) => {
							return plan.type == "Electronics" &&
								plan.name === "LutefishStream" ? (
								// <CardActions
								// 	sx={{
								// 		justifyContent: "flex-start",
								// 		marginLeft: "125px",
								// 	}}
								// >
								// 	<Grid>
								// 		<Button
								// 			className="submit-button"
								// 			size="large"
								// 			sx={{
								// 				minWidth: "200px",
								// 			}}
								// 			endIcon={<img src={RightAarrow} alt="Right Arrow" />}
								// 			variant="contained"
								// 			//onClick={() => window.open('https://lutefish.com/products/lutefish')}
								// 			onClick={() => {
								// 				checkOut(plan);
								// 			}}
								// 		>
								// 			<Typography>Buy Device</Typography>
								// 		</Button>
								// 	</Grid>
								// </CardActions>
								""
							) : (
								""
							);
						})}
						{/* <CardActions
							sx={{
								justifyContent: "flex-end",
								marginRight: "32px",
							}}
						>
							<Grid>
								<Button
									className="submit-button"
									disabled={user.userAccountStatus === "SUSPEND"}
									size="large"
									sx={{
										minWidth: "200px",
									}}
									endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									variant="contained"
									disabled={!isEnable()}
									onClick={() => setOpen(true)}
								>
									<Typography>{i18n.settings.deviceSetup.add}</Typography>
								</Button>
							</Grid>
						</CardActions> */}
					</Card>

					<Grid container xs={12} rowSpacing={2} sx={{ marginTop: 3 }}>
						<Grid item xs={12}>
							<Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
								History
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Card
								sx={{
									background: "#FFFFFF",
									boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
									borderRadius: "border-radius: 12px;",
									padding: 2,
								}}
							>
								{deviceHistory.length === 0 && (
									<Grid
										container
										xs={12}
										sx={{ display: "flex", textAlign: "center" }}
									>
										<Grid item xs={12}>
											<Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
												No Device History Found
											</Typography>
										</Grid>
									</Grid>
								)}
								{deviceHistory
									.filter((item: any) => item.status === "Inactive")
									.map((item: any) => (
										<div key={item.id}>
											<DeviceHistoryContainer device={item} />
										</div>
									))}
							</Card>
						</Grid>
					</Grid>
				</Grid>

				{/* <Grid item={true} xs={3}  className="rightPanelWidth">
					<HelpAndTips
						title={
							i18n.settings.deviceSetup.instructions.howToFindSerialNo.title
						}
						text={i18n.settings.deviceSetup.instructions.howToFindSerialNo.text}
					/>
				</Grid> */}
			</Grid>

			<AddDeviceDialog
				open={open}
				onSuccess={handleSuccess}
				onClose={handleDialogClose}
			/>
		</>
	);
};

export default AddDeviceSetting;
