import {
	Avatar,
	Backdrop,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Collapse,
	Divider,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
	Slider,
	TableCell,
	TableRow,
	Typography,
	styled,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandSquare from "../../../images/add-square1.svg";
import MinimizeSquare from "../../../images/minus-square.svg";
import ArrowRight from "../../../images/arrow-right-next.svg";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Like from "../../../images/Admin/like.svg";
import MessageIcon from "../../../images/Admin/messages.svg";
import ErrorAlert from "../../Error/ErrorAlert";
import ErrorAlertDisplay from "../../Error/ErrorAlertDisplay";
import Shield from "../../../images/Admin/shield-tick.svg";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import useAdminAuthorized from "../../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import CloseIcon from "../../../images/close.svg";


const ContentView = ({
	row,
	isItemSelected,
	labelId,
	getAllUsers,
	closeView,
	setViewClose,
	updateUserStatus,
}: any) => {
	const [viewPost, setViewPost] = useState(false);
	const [postDetails, setPostDetails] = useState<any>({});
	const token = localStorage.getItem("sessionId");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleAlertClose = () => {
		setShowAlert(false);
	};
	const [isPlaying, setIsPlaying] = useState(false);
	const audioRef = useRef(
		new Audio(`${process.env.REACT_APP_PICTURE_URL}mediaUrl`)
	);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [viewMusic, setViewMusic] = useState(false);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();
	const [contentHide, setContentHide] = useState(false);
	// const [userAccountStatus, setUserAccountStatus] = useState(row.userAccountStatus);
	const [profilePhotoUrl, setProfilePhotoUrl] = useState("");
	const isModeratorAdmin: boolean = useAdminAuthorized("isModeratorAdmins");
    const { updateAlertStatus } = useAlertStatus();
	const [openSuspendDialog, setOpenSuspendDialog] = useState(false);
	const [openReactivateDialog, setOpenReactivateDialog] = useState(false);
	const [openCloseDialog, setOpenCloseDialog] = useState(false);
	const [openReinstateDialog, setOpenReinstateDialog] = useState(false);
	useEffect(() => {
		setViewPost(false);
		setViewMusic(false);
	}, [row]);

	useEffect(() => {
		if (row.reportType === "PROFILE MUSIC") {
			setProfilePhotoUrl(
				row.profilePhoto
					? `${process.env.REACT_APP_PICTURE_URL}${row.profilePhoto.profilePicture}`
					: ""
			);
		} else {
			setProfilePhotoUrl(
				postDetails.profilePicture
					? `${process.env.REACT_APP_PICTURE_URL}${postDetails.profilePicture}`
					: ""
			);
		}
	}, [postDetails]);
	useEffect(() => {
		closeView && setViewPost(false);
		closeView && setViewMusic(false);
	}, [closeView]);

	// useEffect(() => {
	// 	setUserAccountStatus(row.userAccountStatus);
	// }, [row.userAccountStatus]);

	useEffect(() => {
		setContentHide(row.contentHide);
	}, [row]);

	const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const CustomSlider = styled(Slider)({
		color: "#5D5D65",
		height: 8,
		"& .MuiSlider-track": {
			border: "none",
		},
		"& .MuiSlider-thumb": {
			height: 24,
			width: 24,
			backgroundColor: "#fff",
			border: "2px solid currentColor",
			"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
				boxShadow: "inherit",
			},
			"&:before": {
				display: "none",
			},
		},
	});


	const handleCloseSuspendDialog = () => {
		setOpenSuspendDialog(false);
	  };

	const handleCloseReactivateDialog = () => {
		setOpenReactivateDialog(false);
	}

	const handleCloseCloseDialog = () => {
		setOpenCloseDialog(false);
	}

	const handleCloseReinstateDialog = () => {
		setOpenReinstateDialog(false);
	}

	const handleTimeUpdate = () => {
		const currentTime = audioRef.current.currentTime;
		setCurrentTime(currentTime);
		if (audioRef.current.duration === currentTime) {
			setCurrentTime(0);
			setIsPlaying(false);
		}
	};

	const handleSliderChange = (event: any, newValue: number | number[]) => {
		audioRef.current.currentTime = Number(newValue);
		setCurrentTime(Number(newValue));
	};

	const formatDuration = (duration: number) => {
		const minutes = Math.floor(duration / 60);
		const seconds = Math.floor(duration % 60);
		return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

	const togglePlay = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};
	const viewUserProfile = () => {
		const url = `${process.env.REACT_APP_BASE_URL_FRONTEND}/postView?postId=${row.postId}&nickName=${row.nickName}`;
		window.open(url, "_blank");
		// navigate(
		// 	`/postView?postId=${row.postId}&userProfileId=${row.offenderUserProfileId}`;
		// 	window.open(url, "_blank");
		// );
	};
	const handleViewPost = (postId: any) => {
		setViewClose(false);
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/getSinglePost/${postId}?sort=RECENT`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((res) => {
					if (res.status >= 400) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					setPostDetails(data);
					setViewPost(!viewPost);
				})
				.catch((err) => {})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};
	const handleActions = (option: any) => {
		try {
			const moderationDTOs = [
				{
					offenderUserProfileId: row.offenderUserProfileId,
					contentID: row.contentId,
				},
			];
			setLoading(true);
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/moderations/action-on-user-account?userAccountStatus=${option}&forType=Content`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(moderationDTOs),
				}
			)
				.then((res) => {
					if (res.status !== 200 && res.status !== 201) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						updateUserStatus(row.offenderUserProfileId, option);
						const displayText:any ={
							SUSPEND: "Account suspended successfully",
							REACTIVATE: "Account activated successfully",
							INACTIVE: "Account closed successfully"
						}
						updateAlertStatus(true, true, displayText[option]);
						if (option === "SUSPEND") {
							handleCloseSuspendDialog();
						}
						else if (option === "REACTIVATE") {
							handleCloseReactivateDialog();
							handleCloseReinstateDialog();
						}
						else if (option === "INACTIVE"){
							handleCloseCloseDialog();
						}
						setAnchorEl(null);
						// getAllUsers()
						// setUserAccountStatus(option)
						return res.json();
					}
				})
				.then((data) => {})
				.catch((err) => {})
				.finally(() => {
					setAnchorEl(null);
					setLoading(false);
				});
		} catch (error) {
			setAnchorEl(null);
			setLoading(false);
		}
	};
	const handleHideContent = () => {
		try {
			setLoading(true);
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/moderations/toggle-content-hidden-status`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						id: row.contentId, //Required if forType is "Content"
					}),
				}
			)
				.then((res) => {
					if (res.status !== 200 && res.status !== 201) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						setContentHide(!contentHide);
						setAnchorEl(null);
						getAllUsers();
						return res.json();
					}
				})
				.then((data) => {})
				.catch((err) => {})
				.finally(() => {
					setAnchorEl(null);
					setLoading(false);
				});
		} catch (error) {
			setAnchorEl(null);
			setLoading(false);
		}
	};

	const handleViewMusic = () => {
		setViewMusic(!viewMusic);
	};
	const convertTime = (createdAt: any) => {
		const originalTimestamp = new Date(createdAt);
		const year = originalTimestamp.getFullYear();
		const month = String(originalTimestamp.getMonth() + 1).padStart(2, "0");
		const day = String(originalTimestamp.getDate()).padStart(2, "0");
		const hours = originalTimestamp.getHours();
		const minutes = originalTimestamp.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		const formattedHours = hours % 12 || 12;
		const NewTime = `${formattedHours}:${String(minutes).padStart(
			2,
			"0"
		)} ${ampm} ${year}-${month}-${day}`;
		return NewTime;
	};
	const getUserStatus = (userStatus: any) => {
		if (userStatus === null) {
			return row.status;
		} else {
			if (userStatus === "SUSPEND") {
				return "Suspended";
			} else if (userStatus === "BAN") {
				return "Banned";
			} else if (userStatus === "TERMINATE") {
				return "Terminated";
			} else if (userStatus === "ACTIVE") {
				return "Active";
			} else {
				return userStatus;
			}
		}
	};

	const getTime = (date: any) => {
		const originalTimestamp = new Date(date);
		const year = originalTimestamp.getFullYear();
		const month = String(originalTimestamp.getMonth() + 1).padStart(2, "0");
		const day = String(originalTimestamp.getDate()).padStart(2, "0");
		const hours = originalTimestamp.getHours();
		const minutes = originalTimestamp.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		const formattedHours = hours % 24 || 12;
		const NewTime = `${year}-${month}-${day} - ${formattedHours}:${String(
			minutes
		).padStart(2, "0")}`;
		return NewTime;
	};

	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<TableRow
				hover
				// onClick={(event) => handleClick(event, row.name)}
				role="checkbox"
				aria-checked={isItemSelected}
				tabIndex={-1}
				key={row.name}
				selected={isItemSelected}
				sx={{
					cursor: "pointer",
					fontWeight: 400,
					fontFamily: "Lexend",
				}}
			>
				<TableCell padding="checkbox">
					{/* <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        "aria-labelledby": labelId,
                    }}
                /> */}
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => {
							if (row) {
								if (row.myMusicId) {
								  handleViewMusic();
								} else if (row.postId) {
								  handleViewPost(row.postId);
								}
							}
						}}
					>
						{row?.reportType === "PROFILE MUSIC" ? (
							viewMusic ? (
								<img src={MinimizeSquare} />
							) : (
								<img src={ExpandSquare} />
							)
						) : viewPost ? (
							<img src={MinimizeSquare} />
						) : (
							<img src={ExpandSquare} />
						)}
					</IconButton>
				</TableCell>
				<TableCell
					component="th"
					id={labelId}
					scope="row"
					padding="none"
					sx={{ fontSize: "16px !important", paddingLeft: "8px !important" }}
				>
					{row.name}
				</TableCell>
				<TableCell align="left" sx={{ fontSize: "16px !important", p: 0 }}>
					{getTime(row.reportDate)}
				</TableCell>
				<TableCell align="left" sx={{ fontSize: "16px !important", p: 0 }}>
					{row.reportType}
				</TableCell>
				<TableCell align="left" sx={{ fontSize: "16px !important", p: 0 }}>
					{row.count}
				</TableCell>
				<TableCell align="left" sx={{ fontSize: "16px !important", p: 0 }}>
					{row.status}
				</TableCell>
				<TableCell align="left" sx={{ fontSize: "16px !important", p: 0 }}>
					<IconButton
						aria-label="more"
						id="long-button"
						aria-controls={open ? "long-menu" : undefined}
						aria-expanded={open ? "true" : undefined}
						aria-haspopup="true"
						onClick={handleClick1}
						disabled={!isModeratorAdmin}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						MenuListProps={{
							"aria-labelledby": "long-button",
						}}
						sx={{
							"& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
								{
									boxShadow:
										"0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
								},
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						{row.userAccountStatus === "SUSPEND" ? (
							<MenuItem
								disabled={
									!isModeratorAdmin || row.userAccountStatus === "INACTIVE"
								}
								onClick={() => setOpenReinstateDialog(true)}
							>
								Reinstate
							</MenuItem>
						) : (
							<MenuItem
								disabled={
									!isModeratorAdmin || row.userAccountStatus === "INACTIVE"
								}
								onClick={()=>setOpenSuspendDialog(true)}
							>
								Suspend account
							</MenuItem>
						)}

						{row.userAccountStatus === "INACTIVE" ? (
							<MenuItem
								disabled={!isModeratorAdmin}
								onClick={() => setOpenReactivateDialog(true)}
							>
								Reactivate account
							</MenuItem>
						) : (
							<MenuItem
								disabled={!isModeratorAdmin}
								onClick={() => setOpenCloseDialog(true)}
							>
								Close Account
							</MenuItem>
						)}
						{openSuspendDialog && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openSuspendDialog}
          onClose={() => handleCloseSuspendDialog()}
        >
          <DialogTitle
            className="edit-heading-name"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                aria-label="close dialogbox"
                onClick={() => handleCloseSuspendDialog()}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
                edge="end"
                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
            <Typography>Suspend Account</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to suspend this account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleCloseSuspendDialog()}
            >
              Cancel
            </Button>
            <Button variant="contained" 
			onClick={() => handleActions("SUSPEND")}
			>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

{openCloseDialog && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openCloseDialog}
          onClose={() => handleCloseCloseDialog()}
        >
          <DialogTitle
            className="edit-heading-name"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                aria-label="close dialogbox"
                onClick={() => handleCloseCloseDialog()}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
                edge="end"
                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
            <Typography>Close Account</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to Close this account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleCloseCloseDialog()}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleActions("INACTIVE")}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

{openReactivateDialog && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openReactivateDialog}
          onClose={() => handleCloseReactivateDialog()}
        >
          <DialogTitle
            className="edit-heading-name"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                aria-label="close dialogbox"
                onClick={() => handleCloseReactivateDialog()}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
                edge="end"
                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
            <Typography>Reactivate Account</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to Reactivate this account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleCloseReactivateDialog()}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleActions("REACTIVATE")}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

{openReinstateDialog && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openReinstateDialog}
          onClose={() => handleCloseReinstateDialog()}
        >
          <DialogTitle
            className="edit-heading-name"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                aria-label="close dialogbox"
                onClick={() => handleCloseReinstateDialog()}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
                edge="end"
                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
            <Typography>Reinstate Account</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to reinstate this account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleCloseReinstateDialog()}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleActions("REACTIVATE")}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
					</Menu>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{
						paddingBottom: 0,
						paddingTop: 0,
						backgroundColor: "#E7E7E7",
						width: "100%",
					}}
					colSpan={7}
				>
					<Collapse
						in={row.reportType === "PROFILE MUSIC" ? viewMusic : viewPost}
						timeout="auto"
						unmountOnExit
					>
						<Box sx={{ margin: 1 }}>
							<Grid container columnGap={1}>
								<Grid item xs={8}>
									<Card
										sx={{
											minWidth: 250,
											flexGrow: 1,
											minHeight: "30%",
											mt: 1,
											borderRadius: "12px",
										}}
									>
										<CardContent sx={{ alignItems: "center", gap: 1 }}>
											<Grid
												container
												rowGap={1}
												columns={{ xs: 12, sm: 6, md: 12 }}
											>
												<Grid item xs={2}>
													<Avatar
														alt={postDetails.name || row.name}
														src={profilePhotoUrl}
														sx={{ height: "50px", width: "50px" }}
													/>
												</Grid>
												<Grid item xs={10}>
													<Grid container direction={"column"}>
														<Grid item sx={{ height: "20px" }}>
															<Grid container>
																<Grid item xs={5}>
																	<Typography
																		variant="body1"
																		sx={{ fontWeight: 500, fontSize: "16px" }}
																	>
																		{row.reportType === "PROFILE MUSIC"
																			? `${
																					row.name && row.name?.length > 25
																						? row.name?.substring(0, 25) + "..."
																						: row.name
																			  }`
																			: `${
																					postDetails.name &&
																					postDetails.name?.length > 25
																						? postDetails.name?.substring(
																								0,
																								25
																						  ) + "..."
																						: postDetails.name
																			  }`}
																	</Typography>
																</Grid>
																<Grid
																	item
																	xs={6}
																	sx={{ textAlign: "right" }}
																></Grid>
																<Grid item xs={1}>
																	{row.reportType === "PROFILE MUSIC" ? (
																		""
																	) : (
																		<IconButton
																			aria-label="next"
																			onClick={viewUserProfile}
																		>
																			<img
																				src={ArrowRight}
																				alt="close"
																				height={24}
																				width={24}
																			/>
																		</IconButton>
																	)}
																</Grid>
															</Grid>
														</Grid>
														<Grid item>
															<Typography
																variant="caption"
																sx={{ color: "#888888", fontSize: "12px" }}
															>
																{row.reportType === "PROFILE MUSIC"
																	? `${
																			row.profileTitle &&
																			row.profileTitle?.length > 35
																				? row.profileTitle?.substring(0, 35) +
																				  "..."
																				: row.profileTitle
																	  }`
																	: `${
																			postDetails.profileTitle &&
																			postDetails.profileTitle?.length > 35
																				? postDetails.profileTitle?.substring(
																						0,
																						35
																				  ) + "..."
																				: postDetails.profileTitle
																	  }`}
															</Typography>
														</Grid>
														<Grid item>
															{row.reportType === "PROFILE MUSIC" ? (
																""
															) : (
																<Typography
																	variant="caption"
																	sx={{ color: "#888888", fontSize: "12px" }}
																>
																	{convertTime(postDetails.createdAt)}
																</Typography>
															)}
														</Grid>
													</Grid>
												</Grid>
												{postDetails.feedText !== "undefined" ? (
													<Grid
														item
														xs={12}
														sx={{ filter: contentHide ? "blur(1px)" : "" }}
													>
														<Typography
															sx={{
																color: "#888888",
																fontSize: "1rem",
																fontWeight: 400,
																overflowWrap: "anywhere",
															}}
														>
															{postDetails.feedText}
														</Typography>
													</Grid>
												) : (
													""
												)}
												{postDetails.postType === "PHOTO" ? (
													<Grid
														item
														xs={12}
														sx={{ filter: contentHide ? "blur(1px)" : "" }}
													>
														<img
															src={`${process.env.REACT_APP_PICTURE_URL}${postDetails.mediaUrl}`}
															id="upload-button"
															alt="Upload"
															style={{
																objectFit: "fill",
																borderRadius: "unset",
																width: "100%",
																height: "100%",
															}}
														/>
													</Grid>
												) : (
													""
												)}
												{postDetails.postType === "MUSIC" ? (
													<Grid
														item
														container
														xs={12}
														columnGap={1}
														sx={{
															background: "#F7F8FB",
															border: "none",
															borderRadius: "20px",
															padding: "12px",
															filter: contentHide ? "blur(1px)" : "",
														}}
													>
														<Grid item xs={1.5}>
															<IconButton onClick={togglePlay}>
																{isPlaying ? (
																	<StopCircleIcon
																		sx={{
																			width: 50,
																			height: 50,
																			color: "#1D2D53",
																		}}
																	/>
																) : (
																	<PlayCircleIcon
																		sx={{
																			width: 50,
																			height: 50,
																			color: "#1D2D53",
																		}}
																	/>
																)}
															</IconButton>
														</Grid>
														<Grid container direction={"column"} item xs={10}>
															<Grid item>
																<Typography
																	sx={{
																		fontSize: "1.125rem",
																		fontWeight: "500 !important",
																	}}
																>
																	music Title
																</Typography>
															</Grid>
															<Grid container item sx={{ mt: 1 }}>
																<Grid item xs={9}>
																	<audio
																		ref={audioRef}
																		src={`${process.env.REACT_APP_PICTURE_URL}${postDetails.mediaUrl}`}
																		onTimeUpdate={handleTimeUpdate}
																		crossOrigin="anonymous"
																	/>
																	<CustomSlider
																		min={0}
																		step={0.01}
																		value={currentTime}
																		max={duration}
																		onChange={handleSliderChange}
																	/>
																</Grid>
																<Grid
																	item
																	xs={3}
																	sx={{
																		paddingLeft: "18px",
																		paddingTop: "4px",
																	}}
																>
																	<Typography
																		sx={{
																			fontSize: "14px",
																			fontWeight: "500 !important",
																			color: "#7A7985",
																		}}
																	>
																		{formatDuration(currentTime) +
																			" / " +
																			formatDuration(duration)}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												) : (
													""
												)}

												{row?.reportType === "PROFILE MUSIC" ? (
													<Grid
														item
														container
														xs={12}
														columnGap={1}
														sx={{
															background: "#F7F8FB",
															border: "none",
															borderRadius: "20px",
															padding: "12px",
															filter: contentHide ? "blur(1px)" : "",
														}}
													>
														<Grid item xs={1.5}>
															<IconButton onClick={togglePlay}>
																{isPlaying ? (
																	<StopCircleIcon
																		sx={{
																			width: 50,
																			height: 50,
																			color: "#1D2D53",
																		}}
																	/>
																) : (
																	<PlayCircleIcon
																		sx={{
																			width: 50,
																			height: 50,
																			color: "#1D2D53",
																		}}
																	/>
																)}
															</IconButton>
														</Grid>
														<Grid container direction={"column"} item xs={10}>
															<Grid item>
																<Typography
																	sx={{
																		fontSize: "1.125rem",
																		fontWeight: "500 !important",
																	}}
																>
																	{row.musicTitle?.replace(
																		/^PROFILE_MUSIC:\s*/,
																		""
																	)}
																</Typography>
															</Grid>
															<Grid container item sx={{ mt: 1 }}>
																<Grid item xs={9}>
																	<audio
																		ref={audioRef}
																		src={`${process.env.REACT_APP_PICTURE_URL}${row.myMusicUrl}`}
																		onTimeUpdate={handleTimeUpdate}
																		crossOrigin="anonymous"
																	/>
																	<CustomSlider
																		min={0}
																		step={0.01}
																		value={currentTime}
																		max={duration}
																		onChange={handleSliderChange}
																	/>
																</Grid>
																<Grid
																	item
																	xs={3}
																	sx={{
																		paddingLeft: "18px",
																		paddingTop: "4px",
																	}}
																>
																	<Typography
																		sx={{
																			fontSize: "14px",
																			fontWeight: "500 !important",
																			color: "#7A7985",
																		}}
																	>
																		{formatDuration(currentTime) +
																			" / " +
																			formatDuration(duration)}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												) : (
													""
												)}
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={3}>
									<Card
										sx={{
											minWidth: 250,
											flexGrow: 1,
											minHeight: "30%",
											mt: 1,
											borderRadius: "12px",
										}}
									>
										<CardContent sx={{ alignItems: "center", gap: 1 }}>
											<Grid container direction={"column"}>
												<Grid item container>
													<Grid item xs={9}>
														<Typography
															sx={{ color: "#5B5B65", fontSize: "14px" }}
														>
															Total users reporting
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Typography
															sx={{ color: "black", fontSize: "14px" }}
														>
															:{row.totalUserReporting}
														</Typography>
													</Grid>
												</Grid>
												<Grid item container>
													<Grid item xs={9}>
														<Typography
															sx={{ color: "#5B5B65", fontSize: "14px" }}
														>
															Total Issues count
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Typography
															sx={{ color: "black", fontSize: "14px" }}
														>
															: {row.totalIssueCount}
														</Typography>
													</Grid>
												</Grid>
												<Divider
													sx={{
														color: "#999999",
														borderBottomWidth: "1px",
														my: 1,
													}}
												/>
												<Grid item>
													{Object.entries(row.violationsType).map(
														([key, value], index: any) => {
															return (
																<Chip
																	key={index}
																	sx={{
																		backgroundColor: "#EDEDED",
																		color: "#5B5B65",
																		mr: 1,
																		mb: 1,
																		fontSize: "1rem",
																		fontWeight: 400,
																		height: "30px",
																	}}
																	label={`${key} (${value})`}
																/>
															);
														}
													)}
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12}>
									<Box
										sx={{
											display: "flex",
											gap: 2,
											flexWrap: "wrap",
											pt: 1,
											flexDirection: "row-reverse",
											mx: 2,
											mb: 2,
										}}
									>
										{contentHide ? (
											<Button
												size="small"
												variant="contained"
												onClick={() => handleHideContent()}
												sx={{
													borderRadius: "24px",
													background: "#259A12",
													px: 2,
													height: "40px",
												}}
												disabled = {!isModeratorAdmin}
											>
												Unhide Content
											</Button>
										) : (
											<Button
												size="small"
												variant="contained"
												onClick={() => handleHideContent()}
												sx={{
													borderRadius: "24px",
													background: "#FF702A",
													px: 2,
													height: "40px",
												}}
												disabled = {!isModeratorAdmin}
											>
												Hide Content
											</Button>
										)}
										{contentHide && (
											<>
												<Typography sx={{ mr: "auto", mt: 1 }}>
													Content marked removed
												</Typography>
												<img
													src={Shield}
													alt=""
													style={{
														marginTop: "8px",
														height: "24px",
														width: "24px",
													}}
												/>
											</>
										)}
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default ContentView;
